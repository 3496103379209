import PropTypes from "prop-types";
import React, { Component } from "react";
import { createRoot } from 'react-dom/client';
import popOver from "../lib/popOver";
import styles from "../styles/popover.less";
import $ from "jquery";

export const DIR_LEFT = "left";
export const DIR_RIGHT = "right";
export const DIR_UP = "up";
export const DIR_DOWN = "down";

export default class Popover extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    render: PropTypes.element.isRequired,
    cssPosition: PropTypes.oneOf(["fixed", "absolute"]),
    position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    align: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    valign: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    trigger: PropTypes.oneOf(["click", "mouseover"]),
    onOpen: PropTypes.func
  };

  static defaultProps = {
    trigger: "click",
    cssPosition: "fixed",
    position: "bottom",
    align: "left",
    valign: "top",
    onOpen: () => {}
  };

  constructor(props) {
    super(props);

    this.popOverObject = $("<div></div>").appendTo(document.body).hide();
    this.root = createRoot(this.popOverObject[0]);
  }

  componentDidMount() {
    const {
      position,
      cssPosition,
      align,
      valign,
      trigger,
      onOpen
    } = this.props;

    this.root.render(this.props.render);

    this.detachPopover = popOver.attach({
      el: $(this.ref),
      //pos: HFN.config.isRtl() ? 'left' : 'right',
      pos: position,
      //position: cssPosition,
      //align: HFN.config.isRtl() ? 'left' : 'right',
      align: align,
      valign: valign,
      obj: this.popOverObject,
      trigger: trigger,
      onOpen: onOpen,
      genClass: "popover-nothing",
      appendTo: "body"
    });
  }

  componentDidUpdate() {
    this.root.render(this.props.render);
  }

  componentWillUnmount() {
    if (this.detachPopover) {
      this.detachPopover();
    }

    this.root.unmount();
    this.popOverObject.remove();
  }

  render() {
    return (
      <div className="popover-holder" ref={ref => (this.ref = ref)}>
        {this.props.children}
      </div>
    );
  }
}
