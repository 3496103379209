import React from 'react';

const Label = ({ label, children }) => {
  return (
    <label>
      <div className="label">{label}</div>
      {children}
    </label>
  );
}

export default Label;
