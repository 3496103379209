// @flow

import {
  SHARES_LOAD,
  SHARES_UNLOAD,
  LIST_SHARES,
  LOAD_FOLDER_SHARES,
  CLEAR_FOLDER_SHARES,
  ADD_SHARE_TO_FOLDER,
  DELETE_SHARE_TO_FOLDER,
  UPDATE_SHARE_TO_FOLDER,
  ACCEPTED_SHARE_TO_FOLDER
} from "../constants/shares";

export type SharesAction =
  | LoadSharesAction
  | UnloadSharesAction
  | ListSharesAction
  | LoadFolderShares
  | ClearFolderShares
  | AddShareToFolder
  | AddShareToFolder
  | DeleteShareToFolder;

type ListShares = {
  shares: Array<any>,
  requests: Array<any>
};

type LoadSharesAction = { type: typeof SHARES_LOAD, shareditems: number };
type UnloadSharesAction = { type: typeof SHARES_UNLOAD };
type ListSharesAction = { type: typeof LIST_SHARES, shares: ListShares };
type LoadFolderShares = { type: typeof LOAD_FOLDER_SHARES, shares: any };
type ClearFolderShares = { type: typeof CLEAR_FOLDER_SHARES };
type AddShareToFolder = { type: typeof ADD_SHARE_TO_FOLDER, share: any };
type DeleteShareToFolder = {
  type: typeof DELETE_SHARE_TO_FOLDER,
  share: any
};
type UpdateShareToFolder = {
  type: typeof UPDATE_SHARE_TO_FOLDER,
  share: any
};
type AcceptedShareToFolder = {
  type: typeof ACCEPTED_SHARE_TO_FOLDER,
  share: any
};

export function load(shareditems: number): LoadSharesAction {
  return { type: SHARES_LOAD, shareditems: shareditems };
}

export function unload(): UnloadSharesAction {
  return { type: SHARES_UNLOAD };
}

export function listShares(shares: ListShares): ListSharesAction {
  return { type: LIST_SHARES, shares: shares };
}

export function loadFolderShares(shares: any): LoadFolderShares {
  return { type: LOAD_FOLDER_SHARES, shares: shares };
}

export function clearFolderShares(): ClearFolderShares {
  return { type: CLEAR_FOLDER_SHARES };
}

export function addShareToFolder(share: any): AddShareToFolder {
  return { type: ADD_SHARE_TO_FOLDER, share: share };
}

export function deleteShareToFolder(share: any): DeleteShareToFolder {
  return { type: DELETE_SHARE_TO_FOLDER, share: share };
}

export function updateShareToFolder(share: any): UpdateShareToFolder {
  return { type: UPDATE_SHARE_TO_FOLDER, share: share };
}

export function acceptedShareToFolder(share: any): AcceptedShareToFolder {
  return { type: ACCEPTED_SHARE_TO_FOLDER, share: share };
}

export const sharesActionCreators = {
  load,
  unload,
  listShares,
  loadFolderShares,
  clearFolderShares,
  clearFolderShares,
  addShareToFolder,
  deleteShareToFolder,
  updateShareToFolder,
  updateShareToFolder,
  acceptedShareToFolder
};
