// @flow

import type { Action } from "../actions/types";
import {
  LOAD_ACCOUNT_INFO,
  CLEAR_ACCOUNT_INFO,
  UPDATE_COMPANY_INFO,
  LOAD_BILLING_INFO,
  UPDATE_BILLING_INFO,
  CLEAR_BILLING_INFO,
  SET_PROCESSING_PROFILE,
  LOAD_USERS,
  LOAD_TEAMS,
  CLEAR_USERS,
  CLEAR_TEAMS,
  EDIT_USER
} from "../constants/business";

export type BusinessState = {
  accountInfo: any,
  billingInfo: any,
  users: Array<any>,
  teams: Array<any>
};

const initialState = {
  accountInfo: { account : {} },
  billingInfo: {},
  users: [],
  teams: []
};

const editUser = (state, user) => {
  const { users } = state;
  const { id: userid } = user;

  if (userid) {
    const updatedUsers = users.map(businessUser => {
      const { id } = businessUser;
      if (id === userid) {
        return { ...businessUser, ...user };
      } else {
        return businessUser;
      }
    });
    return updatedUsers;
  } else {
    return users;
  }
};

export function businessReducer(
  state: BusinessState = initialState,
  action: Action
): BusinessState {
  switch (action.type) {
    case LOAD_ACCOUNT_INFO:
      return { ...state, accountInfo: action.accountInfo };
    case CLEAR_ACCOUNT_INFO:
      return { ...state, accountInfo: {} };
    case UPDATE_COMPANY_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          account:{ ...state.accountInfo.account, ...action.companyInfo}
        }
      };
    case LOAD_BILLING_INFO:
      return { ...state, billingInfo: action.billingInfo };
    case UPDATE_BILLING_INFO:
      return {
        ...state,
        billingInfo: { ...state.billingInfo, ...action.billingInfo, processing: false }
      };
    case CLEAR_BILLING_INFO:
      return { ...state, billingInfo: {} };
    case SET_PROCESSING_PROFILE:
      return { ...state, billingInfo: { ...state.billingInfo, processing: true } };
    case LOAD_USERS:
      return { ...state, users: action.users };
    case LOAD_TEAMS:
      return { ...state, teams: action.teams };
    case CLEAR_USERS:
      return { ...state, users: [] };
    case CLEAR_TEAMS:
      return { ...state, teams: [] };
    case EDIT_USER:
      return { ...state, users: editUser(state, action.user) };
    default:
      return state;
  }
}
