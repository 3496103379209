// @flow

export const parseBillingInfo = ({ billingaddress, ...rest }) => {
  const info = { ...rest };
  if (billingaddress) {
    info.billingAddress = billingaddress;
  }
  return info;
};

export const parsePayment = ({
  amount,
  currency,
  instrument,
  paymentid,
  billingprovider,
  invoicenumber,
  paymentdate,
  cancreateinvoice,
  invoicecode,
  receiptcode
}) => ({
  amount,
  currency,
  instrument,
  paymentId: paymentid,
  billingProvider: billingprovider,
  invoiceNumber: invoicenumber,
  paymentDate: paymentdate,
  canCreateInvoice: cancreateinvoice,
  invoiceCode: invoicecode,
  receiptCode: receiptcode
});

export const parseSettingsInfo = ({ billinginfo = {}, payments = [], twofacauth = {} }) => ({
  billingInfo: parseBillingInfo(billinginfo),
  payments: payments.map(parsePayment),
  twoFacAuth: twofacauth
});
