import React from 'react';
import styled from "styled-components";
import { hiDPI } from "polished";

import { TString } from './intl';

const AddFiles = ({ shouldCollapse, receiveFiles }) => {
	const onClick = () => {
		document.getElementById('fileid').click();
	}

	const renderExpande = () => {
		return (
			<div className="upload-file-wrapper" onClick={onClick}>
				<AddIcon />
				<input type="file" id="fileid" multiple onChange={(event) => { receiveFiles(event.currentTarget.files); }} />
				<div className="upload-click">
					<TString tagName="div" id="ul_click_to_add_files" text="Click Here to Add Files" />
					<TString id="ul_or_just_dnd" text="or just drag and drop files anywhere on the screen" />
				</div>
			</div>
		);
	}

	const renderCollapse = () => {
		return (
			<div className="upload-file-wrapper collapse" onClick={onClick}>
				<AddIcon className="collapse" />
				<input type="file" id="fileid" multiple onChange={(event) => { receiveFiles(event.currentTarget.files); }} />
				<div className="upload-click-collapse">
					<TString tagName="div" id="transfer_add_morefiles" text="Add more files" />
				</div>
			</div>
		);
	}

	return shouldCollapse ? renderCollapse() : renderExpande();
}

export default AddFiles;

const AddIcon = styled.div`
  display: block;
  margin-top: 15px;
  width: 24px;
	height: 24px;

	&.collapse {
		margin-top: 0;
	}

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/data-location/add-files.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/data-location/add-files@2x.png")});
  }
`;
