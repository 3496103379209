// @flow
import React, { Component } from "react";
import { hiDPI } from "polished";

import { ButtonSmall } from "./FacebookButton"; // DONE
import GL_Login from "../../../lib/gl_login"; // DONE
import { DEVICE_OS, EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { __ } from "../../../lib/translate"; // DONE
import apiMethod from "../../../api/apiMethod"; // DONE

import SocialButton from "../MultiplatformComponents/Elements/SocialButton"; // DONE
import GoogleLogo from "../../../../root/img/svg/signin-with-google.svg"; // DONE

import styled from "styled-components";

type Props = {
  os: number,
  size?: "big" | "small",
  receiveToken: () => void,
  getRequestId: () => void,
  getToken: () => void,
  redirect_uris: object,
  passpcloud: boolean
};

class GoogleButton extends Component<Props, {}> {
  static defaultProps = {
    os: 4,
    size: "small",
    passpcloud: false
  };

  constructor(props: Props) {
    super(props);

    (this: any).onButtonClick = this.onButtonClick.bind(this);
    (this: any).receiveTokenCb = this.receiveTokenCb.bind(this);
  }

  onButtonClick() {
    const { redirect_uris, getRequestId, getToken, receiveToken, passpcloud, os, language } = this.props;

    if (redirect_uris) {
      let params = "";

      if (redirect_uris && os === DEVICE_OS.Web) {
        params = "is_extension";
      }

      if (passpcloud) {
        params = "passpcloud";
      }
      getRequestId(params)
        .then(({ request_id }) => {
          GL_Login.initGetToken(redirect_uris, request_id, passpcloud, os, language);
          getToken('gl_oauth2_token')
            .then(({ access_token }) => GL_Login.getTokenCallback(receiveToken(access_token, EXTERNAL_APPS.GOOGLE), redirect_uris, request_id, passpcloud));
        });
    } else {
      GL_Login.getTokenCallback(this.receiveTokenCb, null, null, passpcloud, os, language);
    }
  }

  receiveTokenCb(token: string) {
    const { receiveToken } = this.props;

    receiveToken(token, EXTERNAL_APPS.GOOGLE);
  }

  render() {
    const { size, os } = this.props;

    if (size === "big") {
      return <GoogleBig className="login-button-google" onClick={this.onButtonClick}>{__("sign_in_with_google", "Sign in with Google")}</GoogleBig>
    };

    return (
      <SocialButton os={os} className="login-button-google" onClick={this.onButtonClick}>
        <GoogleLogo width="20px"/>
      </SocialButton>
    );
  }
}

export default GoogleButton;

const GoogleSmall = styled(ButtonSmall)`
  border: none !important;
  border-radius: 8px;
  background-color: #f2f2f4;
  margin: 0;
  background-image: url(${require("../../../../root/img/google.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/google@2x.png")});
  }
`;

const GoogleBig = styled(GoogleSmall)`
  width: 100% !important;
  background-color: #4285f4;
  border-radius: 6px;
  border: 1px solid #4285f4 !important;
  border-radius: 2px !important;
  color: #fff !important;
  margin-top: 35px;

  background-position: 0px center;
  background-size: 38px 38px;
  background-image: url(${require("../../../../root/img/g.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/g@2x.png")});
  }
`;
