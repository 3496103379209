import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import browser from "../lib/browser";

let FB_Login = {
  defaults: {
    graphUri: "graph.facebook.com",
    req: "/",
    async: true,
    onXhrError: function(xhr, status, error) {
      console.log("log FB xhr error: ", arguments);
      if (xhr.responseJSON && xhr.responseJSON.error) {
        HFN.message(
          "Facebook Error: " + xhr.responseJSON.error.message,
          "error"
        );

        if (xhr.responseJSON.error.code == 190) {
          console.log("NOW this", this);

          if (this.onAuthError) this.onAuthError();
          else Facebook.initGetToken();
        }
      }
    }
  },
  appId: "563322893738786",
//  scope: "email,user_birthday",
  scope: "email",
  token: null,
  expires: null,
  postTokenAction: null,
  initGetToken: function(redirect_uris, request_id, passpcloud, os, language, stateParams) {
    console.trace("🚀 ~ file: fb_login.js ~ line 32 ~ redirect_uris, request_id, passpcloud", redirect_uris, request_id, passpcloud)
    const displ = browser.mobile ? "touch" : "popup";
    const useHost = location.host;
    //useHost = "pclmy.pclocal.kom";
    let redirect_uri;

    if (!stateParams) {
      stateParams = { request_id, os, lang: language }
    }

    if (redirect_uris) {
      redirect_uri = redirect_uris.facebook;
    } else if (os == DEVICE_OS.Android || os == DEVICE_OS.iOS) {
      redirect_uri = location.origin + location.pathname;
    } else {
      redirect_uri = encodeURIComponent(location.protocol + "//" + useHost + "/fblogin.html");
    }

    if (redirect_uris && os === DEVICE_OS.Web) {
      stateParams.is_extension = 1
    }

    if (stateParams.device) {
      stateParams.device = encodeURIComponent(stateParams.device);
    }

    if (stateParams.deviceid) {
      stateParams.deviceid = encodeURIComponent(stateParams.deviceid);
    }

    if (passpcloud) {
      stateParams.passpcloud = 1;
    };

    const stateString = JSON.stringify(stateParams);
    let stateEncoded;

    try {
      stateEncoded = btoa(unescape(encodeURIComponent(stateString)));
    } catch (error) {}

    const state = `&state=${stateEncoded || stateString}`;    

    console.log("https://www.facebook.com/dialog/oauth?client_id=" +
      this.appId +
      "&redirect_uri=" +
      redirect_uri +
      "&scope=" +
      this.scope +
      "&display=" +
      displ +
      "&response_type=token" +
      state
    );

    if (location.protocol.indexOf('extension') !== -1 && !redirect_uris) {
      window.open(
        "https://www.facebook.com/dialog/oauth?client_id=" +
          this.appId +
          "&redirect_uri=" +
          encodeURIComponent('https://my.pcloud.com/fbpoll.html') +
          "&scope=" +
          this.scope +
          "&display=" +
          displ +
          "&response_type=token" +
          state,
        "facebook-login",
        "width=550,height=450"
      ).close();
    } else {
      const url = "https://www.facebook.com/dialog/oauth?client_id=" +
        this.appId +
        "&redirect_uri=" +
        redirect_uri +
        "&scope=" +
        this.scope +
        "&display=" +
        displ +
        "&response_type=token" +
        state;

      if (os == DEVICE_OS.Android || os == DEVICE_OS.iOS) {
        location.href = url;
      } else {
        window.open(url, "google-login", "width=550,height=450");
      }
    }
  },
  hasToken: function() {
    return this.token && (1 || this.expires > new Date().getTime());
  },
  setToken: function(token, expires, iopts) {
    var opts = $.extend(
      {},
      {
        afterSet: function() {
        },
        errorCallback: function(ret) {
          HFN.message(ret.error, "error");
          //self.postTokenAction(false, false);
          self.postTokenAction = null;
          Facebook.fbCall(
            "DELETE",
            "me/permissions?access_token=" + FB_Login.token,
            {},
            function(r) {
            }
          );
        }
      },
      iopts
    );

    //if (token == this.token) return;

    this.token = token;
    this.expires = new Date().getTime() + (parseInt(expires) || 86400);

    if (this.postTokenAction) {
      this.postTokenAction(token, expires);
      this.postTokenAction = null;
    }
    /*
		var self = this;
		HFN.apiMethod('fb_assign', { access_token: FB_Login.token }, function (ret) {
			self.token 	 = ret.access_token;
			self.expires = ret.expire;

			opts.afterSet(self.token, self.expires);

			if ( self.postTokenAction ) {
				self.postTokenAction(token, expires);
				self.postTokenAction = null;
			}
		}, opts);
		*/
  },
  getToken: function() {
    return this.hasToken() ? this.token : false;
  },
  remToken: function() {
    this.token = null;
    this.expires = null;
  },
  getTokenCallback: function(callback, redirect_uris, request_id, passpcloud, os, language, stateParams) {
    if (!("FBToken" in window)) {
      window.FBLogin = this;
    }

    console.log('get token', callback);

    this.postTokenAction = callback;
    if (!redirect_uris) {
      this.initGetToken(redirect_uris, request_id, passpcloud, os, language, stateParams);
    }
  },
  fbCall: function(type, req, params, callback, opts) {
    type = type || "GET";
    params = params || {};
    callback = callback || function() {
      };
    opts = opts || {};

    var preOpts = opts;
    opts = $.extend({}, this.defaults, opts);
    console.log(arguments);

    if (!params.access_token && !opts.forceurl) {
      console.log("nqma access token", params);

      if (this.hasToken()) params.access_token = this.getToken();
      else {
        FB_Login.remToken();

        if (!opts.async)
          this.getTokenCallback(
            this.fbCall.bind(this, type, req, params, callback, opts)
          );
        else if (opts.onMissingToken) opts.onMissingToken();
        else {
          HFN.message("Facebook token expired. Please, start over.");
          console.log("for CALL: ", arguments);
        }
        return;
      }
    }

    opts = $.extend({}, this.defaults, opts);

    var url = "https://" + opts.graphUri + "/" + req, self = this, errorCall;

    if (opts.forceurl) // stupid stupid FB_Login
      url = opts.forceurl;

    console.log("opts before call", url, type, params, opts);

    return $.ajax({
      url: url,
      type: type,
      dataType: "json",
      async: opts.async,
      data: params,
      error: function(xhr, status, error) {
        console.log("log FB xhr error: ", arguments);
        if (xhr.responseJSON && xhr.responseJSON.error) {
          if (xhr.responseJSON.error != 190)
            HFN.message(
              "FB_Login Error: " + xhr.responseJSON.error.message,
              "error"
            );

          if (xhr.responseJSON.error.code == 190) {
            if (params.access_token) delete params.access_token;

            FB_Login.remToken();

            console.log("ASYNC", opts.async);

            if (!opts.async) {
              console.log("vzimame token");
              self.getTokenCallback(
                self.fbCall.bind(self, type, req, params, callback, opts)
              );
            } else if (opts.onMissingToken) opts.onMissingToken();
            else HFN.message("Facebook token expired. Please, start over.");
          }
        }
      },
      success: function(data) {
        console.log(data);

        callback(data);
      }
    });
  },
  buildGraphUrl: function(method, params, opts) {
    params = params || {};
    opts = opts || {};

    if (!params.access_token) if (this.token) params.access_token = this.token;
      else {
        this.getTokenCallback(
          this.fbCall.bind(this, type, req, params, callback, opts)
        );
        return;
      }

    opts = $.extend({}, this.defaults, opts || {});

    var qarr = [], n;
    for (n in params) qarr.push(
        encodeURIComponent(n) + "=" + encodeURIComponent(params[n])
      );

    return "https://" + opts.graphUri + "/" + method + "?" + qarr.join("&");
  },
  fbCallGet: function(req, params, callback, opts) {
    return this.fbCall("GET", req, params, callback, opts);
  },
  deletePermissions: function(token) {
    return this.fbCall('DELETE', 'me/permissions?access_token=' + token, {}, function (r) {});
  }
};

if (
  [
    "mob2.n1k.me",
    "mob.n1k.me",
    "127.0.0.1",
    "localhost:8081",
    "localhost"
  ].indexOf(location.host) !==
    -1
) {
  FB_Login.appId = 563322893738786;
} else {
  FB_Login.appId = 397181747048725;
}

export default FB_Login;
