import PropTypes from 'prop-types';
import React from 'react';

const Button = ({ children, active, loading, tooltip, ...rest }) => {
  let classes = ['butt'];
  let tooltipObj = { 'data-toggle': 'remove-tooltip' };

	if (!active) {
		classes.push('inactive-butt');
    if (rest.onClick) {
      rest.onClick = () => {};
    }
	}

	if (tooltip) {
		tooltipObj.title = tooltip;
		tooltipObj['data-toggle'] = 'tooltip';
		//tooltipObj['data-container'] = '.upload-link-wrap';
	}

  return (
    <div className="butt-wrap">
      <a
        href="javascript:;"
        target="_top"
        className={classes.join(' ')}
        {...tooltipObj}
        {...rest}>
        {children}
      </a>
    </div>
  );
}

Button.defaultProps = {
  active: true,
  loading: false
}

Button.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button;
