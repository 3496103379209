// @flow

import React from "react";
import styled from "styled-components";

import Separator from "./Separator";

type SeparatorType = {
  width: string,
  backgroundColor?: string
};

type Fraction = {
  percent: string,
  color: string,
  title: string,
  size: string
};

type Props = {
  fractions: Array<Fraction>,
  separator: SeparatorType,
  progressHeight: string
};

class ProgressSmart extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  renderSection(fraction: Fraction, index: number, lastIndex: number) {
    const { progressHeight, separator: { width: sepWidth, backgroundColor } } = this.props;
    const { color, percent } = fraction;

    if (Number(percent) > 0) {
      if (lastIndex !== index) {
        return [
          <Fractions
            key={`fract${index}`}
            color={color}
            width={Number(percent) * 100}
            progressHeight={progressHeight}
          />,
          <Separator
            key={`sep${index}`}
            separatorWidth={sepWidth}
            separatorHeight={progressHeight}
            backgroundColor={backgroundColor}
          />
        ];
      } else {
        return (
          <Fractions
            key={`fract${index}`}
            color={color}
            width={Number(percent) * 100}
            progressHeight={progressHeight}
          />
        );
      }
    }
  }

  render() {
    const { fractions, progressHeight, ...rest } = this.props;
    const lastIndex = fractions.length - 1;

    return (
      <Wrapper progressHeight={progressHeight} {...rest}>
        {fractions.map((fractions, index) =>
          this.renderSection(fractions, index, lastIndex)
        )}
      </Wrapper>
    );
  }
}

export default ProgressSmart;

const Wrapper = styled.div`
  line-height: ${props => props.progressHeight ? props.progressHeight : "2px"};
  width: 100%;
  overflow-y: hidden;

  div:last-child {
    float: none;
    width: auto;
    overflow: hidden;
    display: block;
  }
`;

const Fractions = styled.div`
  float: left;
  display: inline-block;

  height: ${props => (props.progressHeight ? props.progressHeight : "2px")};
  background-color: ${props => (props.color ? props.color : "#fff")};

  width: ${props => (props.width ? `${props.width}%` : "100%")};
  width: calc(${props => `${props.width}%`} - 1px);
`;
