import React from "react";
import styled, { keyframes } from "styled-components";

import LoaderIcon from "../../web-shared/images/svg/pcloud-pass/loading_blue.svg";
import TransitionAnimation from "./Animations/TransitionAnimation";
import LoaderWrapper from "../containers/UserAuthentication2/MultiplatformComponents/Elements/LoaderWrapper";

const Loader = ({
  os,
  show,
  text,
  delay = 0,
  timeout,
  fixedPosition = false,
  disableAnimationOnShow = false
}) => (
  <TransitionAnimation disableAnimationOnShow={disableAnimationOnShow} trigger={show} timeout={timeout} delay={delay}>
    <LoaderWrapper os={os} fixedPosition={fixedPosition}>
      <LoaderContent>
        <FetchLoadingSpinner width="48px" height="48px">
          <LoaderIcon width="48px" height="48px" />
        </FetchLoadingSpinner>
      </LoaderContent>
    </LoaderWrapper>
  </TransitionAnimation>
);

export default Loader;

const Spin = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

export const FetchLoadingSpinner = styled.div`
  display: inline-block;
  width: ${({width}) => width || "48px"};
  height: ${({height}) => height || "48px"};
  animation-name: ${Spin};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  /* animation-fill-mode */
`;

export const LoaderText = styled.div`
  font-size: 15px;
  line-height: 1.53;
  text-align: center;
  color: #000;
  margin-top: 25px;
`;

export const LoaderContent = styled.div`
  min-height: 100px;
  text-align: center;
`;