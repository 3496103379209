// WARNING: Do not use HFN in this component

import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { __ } from "../../../lib/translate";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper";
import Form from "../MultiplatformComponents/Elements/Form";
import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import SigninOptionsWrapper from "../MultiplatformComponents/Elements/SigninOptionsWrapper";
import CheckBox from "../MultiplatformComponents/Elements/CheckBox";
import Input from "../MultiplatformComponents/Elements/Input";
import Button from "../MultiplatformComponents/Elements/Button";
import Footer from "../MultiplatformComponents/Elements/Footer";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper";
import { notify } from "../MultiplatformComponents/Notification";
import TfaCodeIcon from "../../../../root/img/svg/tfa-code.svg";
import TfaCodeiOSIcon from "../../../../root/img/svg/tfa-code-ios.svg";
import TfaCodeAndroidIcon from "../../../../root/img/svg/tfa-code-android.svg";
import VerificationCodeInputs from "../MultiplatformComponents/VerificationCodeInputs";
import InputInnerButton from "../LoginOptions/InputInnerButton";
import NarrowArrow from "../../../../web-shared/images/svg/narrow-arrow.svg";
import Wrapper from "../MultiplatformComponents/Elements/Wrapper";
import Message from "./Message";
import { TFA_NOTIFICATION_FORM_ID } from "../constants.signin";
import PcloudLogo from "../../../../web-shared/images/svg/pcloud-gradient-logo.svg"

const TFALoginCodeFormUI = ({
  os = 4,
  formID,
  isSafeDevice = false,
  error = {
    showError: false
  },
  userPhoneNumber,
  code,
  hide,
  canResend = false,
  requestFinished,
  sessionTimedOut,
  waitingTimeForSend,
  showLoading = false,
  updateCode = () => { },
  tfaLogin = () => { },
  toggleHelpSteps = () => { },
  toggleCanResend = () => { },
  toggleLoggedDevices = () => { },
  changeForm = () => { },
  updateIsSafeDevice = () => { },
  sendSMS = () => { },
  sendNotification = () => { },
  hasLoggedDevices,
}) => {
  const { showError, wrongCode } = error;
  const showWrongCode = wrongCode && showError;
  const [validCode, setValidCode] = useState(false);
  const [isCodeReady, setIsCodeready] = useState(false);

  useEffect(() => {
    if (formID == 1) {
      sendNotification();
    }
    if (formID == 2) {
      sendSMS();
    }
  }, [])

  const validateCode = code => {
    const codeLengths = {
      "1": "6",
      "2": "6",
      "3": "8",
      "4": "6",
    };
    if (codeLengths[formID] >= code.length) {
      setValidCode(true);
    } else {
      setValidCode(false)
    }
  }

  const disableInput = e => {
    if (sessionTimedOut || e.keyCode == 32) {
      e.preventDefault();
    }
  }

  const submitCode = e => {
    e.preventDefault();

    if (validCode && !showError) {
      tfaLogin();
    }
  }

  const submitCodeOnEnter = e => {
    e.preventDefault();

    if (!showError && validCode && e.keyCode == 13) {
      tfaLogin();
    }
  }

  const iconByOs = {
    [DEVICE_OS.iOS]: <TfaCodeiOSIcon />,
    [DEVICE_OS.Android]: <TfaCodeAndroidIcon />,
    [DEVICE_OS.Web]: <TfaCodeIcon />,
    [DEVICE_OS.Win]: <TfaCodeIcon />,
    [DEVICE_OS.Linux]: <TfaCodeIcon />,
    [DEVICE_OS.MacOSX]: <TfaCodeIcon />
  };

  const forms = {
    "1": {
      form: "Notification form",
      title: __("tfa_login_notification_header", "Enter security code"),
      intructions: (
        <div>
          <span>{__("tfa_login_notification_subheader1.1", "We've sent you the code as a pCloud notification on your") + " "}</span>
          <ButtonLink type="button" os={os} style={{ fontSize: "inherit" }} className="tfa-link" onClick={toggleLoggedDevices}>
            {__("tfa_login_notification_subheader1.2", "logged devices.")}
          </ButtonLink>
        </div>
      ),
      codeLength: 6,
      anotherOption: __("tfa_login_notification_another_option", "Send code via SMS"),
      helperFunction: () => {
        if (canResend) {
          changeForm(2);
          toggleCanResend();
        }
      },
      allowLetters: false
    },
    "2": {
      form: "SMS form",
      title: __("tfa_login_sms_header", "Code sent"),
      intructions: userPhoneNumber ? (
        <div>
          <span style={{ marginRight: "4px" }}>{__("tfa_login_sms_subheader", "We've sent you the code to number")}</span>
          <b>{userPhoneNumber}</b>
        </div>
      ) : (
        <div>
          <span>{__("tfa_login_sms_subheader", "We've sent you the code to number")}</span>
          <UnsentPhoneNumber />
        </div>
      ),
      codeLength: 6,
      anotherOption: __("tfa_login_sms_another_option", "Can't receive code?"),
      helperFunction: () => toggleHelpSteps(),
      allowLetters: false
    },
    "3": {
      form: "Recovery code form",
      title: __("tfa_login_recovery_header", "Recovery code"),
      intructions: __("tfa_login_recovery_subheader", "Provide any of your one-time recovery codes, which you haven't used yet"),
      codeLength: 8,
      anotherOption: __("tfa_login_recovery_another_option", "Contact support team"),
      helperFunction: () => window.open(URLSITE.indexOf('extension') ? "https://pcloud.com/request-help.html" : URLSITE + "request-help.html"),
      allowLetters: true
    },
    "4": {
      form: "Google authenticator form",
      title: __("tfa_login_authenticator_header", "Enter security code"),
      intructions: __("tfa_login_authenticator_subheader", "Enter the security code generated by your authenticator app"),
      codeLength: 6,
      anotherOption: __("tfa_login_authenticator_another_option", "Use recovery code"),
      helperFunction: () => {
        changeForm(3);
      },
      allowLetters: false
    }
  };

  return (
    hide ? null : (
      <Wrapper os={os}>
        <FormWrapper os={os}>
          {os !== DEVICE_OS.Web &&
            <LogoWrapper>
              <PcloudLogo />
            </LogoWrapper>
          }
          <Heading className="login-title" os={os}>{forms[formID].title}</Heading>
          <SubHeading os={os}>{forms[formID].intructions}</SubHeading>
          <Form os={os} showWrongCode={showWrongCode} onSubmit={submitCode}>
            <Message error={error.errorMessage} os={os} />
            {(formID === TFA_NOTIFICATION_FORM_ID && !hasLoggedDevices) ?
              null :
              (
                <React.Fragment>
                  <AlignHorizontaly>
                    <AlignVerticaly>
                      <SecurityCodeWrapper addMargin={os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android}>
                        <VerificationCodeInputs
                          os={os}
                          codeLength={forms[formID].codeLength}
                          hasError={false}
                          erorrMessage={""}
                          handleCodeState={({ isCodeReady, code: inputCode }) => {
                            const arrOfCharsToString = inputCode.join("");
                            setIsCodeready(isCodeReady);
                            updateCode(arrOfCharsToString);
                            validateCode(arrOfCharsToString);
                          }}
                          allowLetters={forms[formID].allowLetters}
                        />
                        {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android &&
                          <Button
                            os={os}
                            onClick={submitCode}
                            loading={showLoading && validCode}
                            disabled={!isCodeReady}
                            disabledColor="#ebedf0"
                            className="tfa-button"
                          >
                            <NarrowArrow />
                          </Button>}
                      </SecurityCodeWrapper>
                      <SigninOptionsWrapper os={os}>
                        <CheckBox
                          os={os}
                          checked={isSafeDevice}
                          onChange={updateIsSafeDevice}
                          disabled={sessionTimedOut}
                          text={__("tfa_login_form_dont_ask", "Don't ask for a code in the next 30 days")}
                          size="small"
                        />
                      </SigninOptionsWrapper>
                    </AlignVerticaly>
                  </AlignHorizontaly>
                  {(os === DEVICE_OS.Android || os === DEVICE_OS.iOS) &&
                    <Button
                      os={os}
                      onClick={submitCode}
                      loading={showLoading && validCode}
                      disabled={!isCodeReady}
                      disabledColor="#ebedf0" >
                      {__("continue", "Continue")}
                    </Button>}
                </React.Fragment>
              )}
            <ButtonWrapper os={os}>
              {sessionTimedOut ? (
                <Button
                  os={os}
                  type="button"
                  color="cyan"
                  size="big"
                  onClick={() => { location.reload() }}
                  style={{ width: "100%" }}
                  className="twofactauth-button"
                >
                  {__("tfa_login_tryagain", "Try again")}
                </Button>
              ) : (
                // <Button
                //   os={os}
                //   type="button"
                //   loading={showLoading && validCode}
                //   disabled={!validCode}
                //   color="cyan"
                //   size="big"
                //   onClick={submitCode}
                //   style={{width: "100%"}}
                //   className="twofactauth-button"
                // >
                //   {__("Continue")}
                // </Button>
                <div></div>
              )}
            </ButtonWrapper>
          </Form>
        </FormWrapper>
        <Footer os={os}>
          <ButtonLink type="button" disabled={formID == 1 && !canResend} onClick={forms[formID].helperFunction} className="twofactauth-link">
            {forms[formID].anotherOption}
            {formID == 1 && !canResend ? (
              <Counter>({waitingTimeForSend})</Counter>
            ) : null}
          </ButtonLink>
        </Footer>
      </Wrapper>
    )
  );
}

export default TFALoginCodeFormUI;

const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

const Helper = styled.div`
  color: ${({ forbidden }) => forbidden ? "#999999" : "#17bed0"};
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  cursor: ${({ forbidden }) => forbidden ? "not-allowed" : "pointer"};
  display: inline-block;
`;

const SafeDeviceCheck = styled.div`
  margin-top: 12px;
  text-align: left;
  & label {
    color: #888;
    font-weight: 400;
    display: inline;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ span {
        background-color: #17bed0;
        border: none;
        &:after {
          display: block;
        }
      }
    }
    &:hover input:not(:checked) ~ span {
      background-color: #eee;
    }
    & span {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #fafafa;
      border: 1px solid #bbb;
      border-radius: 3px;
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 3px;
        width: 3px;
        height: 6px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;

const dotsLoading = keyframes`
  0%  {box-shadow: 15px 0 #17bed0, -15px 0 #0002;background: #17bed0 }
  33% {box-shadow: 15px 0 #17bed0, -15px 0 #0002;background: #0002}
  66% {box-shadow: 15px 0 #0002,-15px 0 #17bed0; background: #0002}
  100%{box-shadow: 15px 0 #0002,-15px 0 #17bed0; background: #17bed0 }
`

const UnsentPhoneNumber = styled.div`
  margin-left: 25px;
  display: inline-block;
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: ${dotsLoading} 1s infinite ease-in-out forwards;
`;

const spin = keyframes`
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
`;

const Counter = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: red !important;
`;

const SecurityCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* gap: 6px; */
  .verification-code-wrapper {
    ${({ addMargin }) => addMargin && "margin-right: 6px;"}
  }
`;

const AlignVerticaly = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AlignHorizontaly = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
