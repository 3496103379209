// @flow
// WARNING: Do not use HFN in this component

import React, { useState, useEffect, useCallback } from "react";
import Componentify from "react-componentify";

import apiMethod from "../../api/apiMethod"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { boldConverter, spanClassTagConverter } from "../../../web-shared/componentifyConverters"; // DONE
import Notification, { notify } from "./MultiplatformComponents/Notification"; // DONE
import { setGlobalLocationById } from "../../api/utils"; // DONE
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE
import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper"; // DONE
import Input from "./MultiplatformComponents/Elements/Input"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import Footer from "./MultiplatformComponents/Elements/Footer"; // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import MessageBox from "./MultiplatformComponents/Elements/MessageBox"; // DONE
import Message from "./MultiplatformComponents/Elements/Message"; // DONE
import CheckEmailIcon from "../../../root/img/svg/check-email.svg"; // DONE
import CheckEmailIconiOS from "../../../root/img/svg/check-email-ios.svg"; // DONE
import CheckEmailIconAndroid from "../../../root/img/svg/check-email-android.svg"; // DONE
import ErrorIconAndroid from "../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "./MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "./MultiplatformComponents/Elements/ErrorMessage"; // DONE
import { sendSetupPassMailMethod } from "../UserAuthentication2/methods.signin";

const RegisterVerificationCode = ({
  isWebview = false,
  email = "",
  language = "en",
  os = DEVICE_OS.Web,
  resend = false,
  setShowCreatePassword = () => {},
  setShowRegisterVerificationCode = () => {},
  setLocationId = () => {}
}) => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    sendSetupPassMailMethod({ email });
  }, [])

  useEffect(() => {
    if (resend) {
      onResendCodeClick();
    }
  }, [onResendCodeClick, resend]);

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message);
    } else {
      notify(message, type, { os });
    }
  };

  const onCodeChange = ({ target }) => {
    const value = target.value;

    if (value.length < 8) {
      setDisabledForm(true);
    } else {
      setDisabledForm(false);
    }

    if (error) {
      setError("");
    }

    setVerificationCode(target.value);
  };

  const onResendCodeClick = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      setError("");
    }

    setIsLoading(true);

    sendSetupPassMailMethod({ email })
      .then(() => {
        showMessage("Verification code was sent to your email.", "success");
        setIsLoading(false);
      })
  });

  const iconByOs = {
    [DEVICE_OS.iOS]: <CheckEmailIconiOS />,
    [DEVICE_OS.Android]: <CheckEmailIconAndroid />,
    [DEVICE_OS.Web]: <CheckEmailIcon />,
    [DEVICE_OS.Win]: <CheckEmailIcon />,
    [DEVICE_OS.Linux]: <CheckEmailIcon />,
    [DEVICE_OS.MacOSX]: <CheckEmailIcon />
  };

  const onContinueClick = e => {
    e.preventDefault();
    if (isLoading || disabledForm) {
      return;
    }

    setIsLoading(true);

    apiMethod(
      "user/checksetuppasscode",
      {
        email: email,
        code: verificationCode.trim()
      },
      ({ result, ...rest }) => {
        if (result === 0) {
          setIsLoading(false);
          setShowCreatePassword(true);
          setShowRegisterVerificationCode(false, email, verificationCode);
        }
      },
      {
        errorCallback: ({ result }) => {
          console.log(result, "result");
          setIsLoading(false);
          if (result === 2012) {
            showMessage(__("login_error_2012", "Incorrect security code."), "error");
          }
        }
      }
    );

    // Errors:
    // 1130="Please provide 'email'."
    // 1022="Please provide 'code'."
    // 2012="Invalid 'code' provided."
  };

  const renderMessage = () => {
    const errorMessageIcons = {
      [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24" />,
      [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24" />,
      [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24" />
    };

    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && error) {
      return (
        <ErrorBox os={os}>
          <div className="icon-wrapper">{errorMessageIcons[os]}</div>
          <ErrorMessage className="login-error-message" os={os}>
            {error}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    return <Notification os={os} />;
  };

  return (
    <React.Fragment>
      <FormWrapper os={os}>
        <Heading os={os} className={isWebview ? "webview" : "login-title"}>
          {__("verification_code_title", "Verify your email")}
        </Heading>
        <Form os={os} onSubmit={onContinueClick}>
          <MessageBox os={os}>
            <div className="icon-wrapper">{iconByOs[os]}</div>
            <Message os={os}>
              <Componentify
                text={__(
                  "verification_code_message",
                  "We've sent an email with a verification code to <span class='ellipsis'><b>%email%</b></span>",
                  {
                    email: email
                  }
                )}
                converters={[boldConverter, spanClassTagConverter]}
              />
            </Message>
          </MessageBox>
          {renderMessage()}
          <InputWrapper os={os}>
            <Input
              os={os}
              type="text"
              value={verificationCode}
              onChange={onCodeChange}
              placeholder={__("tfa_login_login_enter_code", "Enter code:")}
              autoFocus
              autoComplete="one-time-code"
            />
          </InputWrapper>
          <ButtonWrapper os={os}>
            <Button os={os} onClick={onContinueClick} disabled={disabledForm}>
              {__("Continue")}
            </Button>
          </ButtonWrapper>
        </Form>
      </FormWrapper>
      <Footer os={os}>
        <ButtonLink os={os} type="button" onClick={onResendCodeClick}>
          {`${__("verification_code_resend_button_text", "Didn't receive the code?")} ${__(
            "verification_code_resend_button",
            "Send again"
          )}`}
        </ButtonLink>
      </Footer>
    </React.Fragment>
  );
};

export default RegisterVerificationCode;
