// @flow

import PropTypes from "prop-types";
import React, { Component } from "react";

type InputTextProps = {
  name: ?string,
  value: any,
  placeholder: ?string,
  className: ?string,
  innerRef?: null
};

export default class InputText extends Component<InputTextProps> {
  static defaultProps = {
    name: "",
    value: "",
    placeholder: "",
    className: "",
    innerRef: null
  };

  render() {
    const { innerRef, name, value, placeholder, className, ...rest } = this.props;

    return (
      <input
        ref={innerRef}
        className={className + " text"}
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        {...rest}
      />
    );
  }
}
