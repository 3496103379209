import apiMethod from "../../api/apiMethod";

export const prepareLoginMethod = params => (
  new Promise ((resolve, reject) => {
    apiMethod("user/preparelogin", params, (response) => {
      resolve(response)
    }, {
      errorCallback: (error) => {
        reject(error)
      },
      onTimeout: (error) => {
        reject({error: __("backup_service_connection_timeout", "Service connection timeout. Please, try again later.") })
      },
      onXhrError: (error) => {
        reject({ error: __("something_went_wrong_refresh_and_try_again", "Something went wrong. Please reload the page and try again") })
      }
    })
  }) 
);

export const sendSetupPassMailMethod = params => (
  new Promise ((resolve, reject) => {
    apiMethod("user/sendsetuppassmail", params, (response) => {
      resolve(response)
    }, {
      errorCallback: (error) => {
        reject(error)
      }
    })
  })
);

export const sendVerificationCodeMailMethod = params => (
  new Promise ((resolve, reject) => {
    apiMethod("user/sendemailtfamail", params, (response) => {
      resolve(response)
    }, {
      errorCallback: (error) => {
        reject(error)
      }
    })
  })
);

export const tfaSendNotification = params => (
  new Promise ((resolve, reject) => {
    apiMethod("tfa_sendcodeviasysnotification", params, (response) => {
      resolve(response)
    }, {
      errorCallback: (error) => {
        reject(error)
      }
    })
  })
)

export const tfaSendCodeViaSMS = params => (
  new Promise ((resolve, reject) => {
    apiMethod("tfa_sendcodeviasms", params, (response) => {
      resolve(response)
    }, {
      errorCallback: (error) => {
        reject(error)
      }
    })
  })
)
