import React from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Componentify from "react-componentify";

import { boldConverter, linkConverter, linkConverterV2 } from "../../../lib/componentifyConverters";

import NotificationCloseIcon from "../../../../root/img/svg/notification-close.svg";
import NotificationInfoIcon from "../../../../root/img/svg/notification-info.svg";
import NotificationErrorIcon from "../../../../root/img/svg/notification-error.svg";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Notification = ({ os = "" }) => {
  if (os === DEVICE_OS.Web) {
    return (
      <WrapperWeb>
        <ToastContainer />
      </WrapperWeb>
    );
  }

  return (
    <NotificationWrapper className="notification-wrapper">
      <ToastContainer />
    </NotificationWrapper>
  );
};

const activeNotifications = new Map();

export const notify = (message, type, opts) => {
  const options = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    ...opts
  };

  const webOptions = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: options.autoClose || true,
    hideProgressBar: true,
    closeOnClick: true
  };

  const existingNotificationId = activeNotifications.get(message);

  if (existingNotificationId) {
    activeNotifications.delete(message);
    toast.dismiss(existingNotificationId);
  }

  let toastId;

  if (options.os === DEVICE_OS.Web) {
    if (type === "success") {
      toastId = toast.success(<Componentify text={message} converters={[boldConverter, linkConverter, linkConverterV2]} />, webOptions);
    } else {
      toastId = toast.error(<Componentify text={message} converters={[boldConverter, linkConverter, linkConverterV2]} />, webOptions);
    }
  } else {
    if (type === "success") {
      toastId = toast.info(
        <NotificationMessageWrapper>
          <NotificationInfoIcon className="notification-icon" />
          <NotificationText>
            <Componentify text={message} converters={[boldConverter, linkConverter, linkConverterV2]} />
          </NotificationText>
        </NotificationMessageWrapper>,
        options
      );
    } else if (type === "error") {
      toastId = toast.error(
        <NotificationMessageWrapper>
          <NotificationErrorIcon className="notification-icon" />
          <NotificationText>
            <Componentify text={message} converters={[boldConverter, linkConverter, linkConverterV2]} />
          </NotificationText>
        </NotificationMessageWrapper>,
        options
      );
    } else if (type === "warning") {
      toastId = toast.warn(
        <NotificationMessageWrapper>
          <NotificationInfoIcon className="notification-icon" />
          <NotificationText>
            <Componentify text={message} converters={[boldConverter, linkConverter, linkConverterV2]} />
          </NotificationText>
        </NotificationMessageWrapper>,
        options
      );
    }
  }
  activeNotifications.set(message, toastId);
};

export default Notification;

const NotificationWrapper = styled.div`
  .Toastify__toast {
    border-radius: 0px;
    padding: 10px;
    width: 100%;
    min-height: 32px;
    box-shadow: none;
    font-family: inherit;
    margin: 0px;
  }

  .Toastify__toast-container {
    z-index: 250000;
    width: calc(100vw - 20px);
    left: 10px;
    top: 10px;
    transform: translate(0);
  }

  .Toastify__toast--info {
    background-color: #f3f2f1;
  }

  .Toastify__toast--error {
    background-color: #f7e1e3;
  }

  .Toastify__toast--warning {
    background-color: #f9eec8;
  }

  .Toastify__close-button {
    align-self: center;
    margin-left: 10px;
    height: 17px;

    svg {
      fill: #363535;
    }
  }
`;

const NotificationMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #201f1e;
  font-size: 12px;
  font-family: inherit;

  svg {
    flex-shrink: 0;
  }

  .notification-icon {
    margin-right: 8px;
  }
`;

const NotificationText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;

const WrapperWeb = styled.div`
  .Toastify__toast {
    border-radius: 4.8px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 25px;
    min-width: 400px;
    max-width: 600px;
    color: #fff !important;
    a {
      color: #fff !important;
    }
  }

  .Toastify__toast-container {
    z-index: 250000;
    width: auto;
  }
  .Toastify__toast--success {
    background-color: #212225;
  }

  .Toastify__toast--error {
    background-color: #d92d2d;
  }

  .Toastify__close-button {
    align-self: center;
    margin-left: 10px;
    height: 17px;
  }
`;
