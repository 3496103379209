// @flow

import React from "react";
import styled from "styled-components";

type Props = {
  backgroundColor?: string,
  separatorWidth?: string,
  separatorHeight?: string
};

const Separator = (props: Props) => (
  <Wrap {...props}></Wrap>
);

export default Separator;

const Wrap = styled.div`
  float: left;
  display: inline-block;
  height: ${props => props.separatorHeight ? props.separatorHeight : "1px"};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : "#fff"};
  width: ${props => props.separatorWidth ? props.separatorWidth : "1px"};
`;