import React from "react";
import styled from "styled-components";

import EmailLoginOption from "../LoginOptions/EmailLoginOption";
import InputInnerButton from "../LoginOptions/InputInnerButton";
import {
  APPLE_LOGIN_METHOD_ID,
  EMAIL_LOGIN_METHOD_ID,
  FACEBOOK_LOGIN_METHOD_ID,
  GOOGLE_LOGIN_METHOD_ID,
  LOGIN_FLOW_TYPE,
  LOGIN_OR_SIGNUP_SCREEN,
  REGISTER_FLOW_TYPE,
  SETUP_PASS_LOGIN_METHOD_ID
} from "../constants.signin";

import NarrowArrow from "../../../../web-shared/images/svg/narrow-arrow.svg";
import FacebookButton from "../LoginOptions/SocialLogins/FacebookButton";
import AppleButton from "../LoginOptions/SocialLogins/AppleButton";
import GoogleButton from "../LoginOptions/SocialLogins/GoogleButton";
import Wrapper from "../MultiplatformComponents/Elements/Wrapper";
import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper";
import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import Form from "../MultiplatformComponents/Elements/Form";
import ContinueWith from "../MultiplatformComponents/Elements/ContinueWith";
import { FORM_TEXTS } from "../texts.signin";
import Message from "./Message";
import { validateEmail } from "../../../lib/utils";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import Button from "../MultiplatformComponents/Elements/Button";
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper";
import { __ } from "../../../lib/translate";
import PcloudLogo from "../../../../web-shared/images/svg/pcloud-gradient-logo.svg"

const LoginOrSignupUI = ({
  buttinIsOutsideInput = false,
  buttonText = "",
  showArrowInButton = false,
  disabledByDefault = true,
  os,
  isWebview,
  email,
  error,
  onEmailChange,
  onSubmit,
  redirect_uris,
  receiveToken = () => { },
  getRequestId = () => { },
  getToken = () => { },
  loading,
  showTitle = true,
  title = "",
  formFields = {
    preferredOption: [],
    secondaryOption: [],
    socialLoginsOptionsGroup: []
  },
  forceValidateForm = false,
  language,
  requestId,
  state
}) => {
  const renderLoginOption = (loginmethod, preferred) => {
    const disabledButton = !validateEmail(email)

    if (loginmethod === EMAIL_LOGIN_METHOD_ID) {
      return (
        <EmailLoginOption
          placeholder={__("login_or_signup_placeholder")}
          onChange={onEmailChange}
          value={email}
          os={os}
          error={error || forceValidateForm}
        >
          {(os === DEVICE_OS.Android || buttinIsOutsideInput) &&
            <ButtonWrapper os={os}>
              <Button
                loading={loading}
                os={os}
                onClick={onSubmit}
                disabled={disabledButton && disabledByDefault}
                disabledColor="#ebedf0"
              >
                <span>{buttonText ? __(buttonText) : __("continue", "Continue")}</span>
                <NarrowArrow style={{ marginLeft: "8px" }}fill="currentColor"/>
              </Button>
            </ButtonWrapper>
          }
          {os !== DEVICE_OS.Android && !buttinIsOutsideInput &&
            <InputInnerButton loading={loading} os={os} onClick={onSubmit} disabled={disabledButton && disabledByDefault} disabledColor="#ebedf0">
              <NarrowArrow />
            </InputInnerButton>
          }
        </EmailLoginOption>
      )
    };

    if (loginmethod === SETUP_PASS_LOGIN_METHOD_ID) {
      return null;
    }

    if (loginmethod === FACEBOOK_LOGIN_METHOD_ID) {
      return (
        <FacebookButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          className="login-buttons-facebook"
          language={language}
          state={state}
        />
      )
    }

    if (loginmethod === APPLE_LOGIN_METHOD_ID) {
      return (
        <AppleButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          language={language}
          state={state}
        />
      )
    }

    if (loginmethod === GOOGLE_LOGIN_METHOD_ID) {
      return (
        <GoogleButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          className="login-buttons-google"
          language={language}
          state={state}
        />
      )
    }
  };

  const renderOption = (optionsArray = [], preferred) => {
    if (!optionsArray || !optionsArray.length) {
      return null;
    }

    return optionsArray.map(login => (
      renderLoginOption(login, preferred)
    ))
  }

  return (
    <Wrapper os={os} className="responsive-wrap">
      <FormWrapper os={os}>
        {os !== DEVICE_OS.Web &&
          <LogoWrapper>
            <PcloudLogo />
          </LogoWrapper>
        }
        {
          showTitle ? (
            <Heading os={os} className={isWebview ? "webview" : "login-title"}>
              {title || __("login_or_signup_title")}
            </Heading>
          ) : null
        }
        <SubHeading os={os}>
          {__("login_or_signup_subtitle")}
        </SubHeading>
        <Form os={os} onSubmit={onSubmit}>
          <Message error={error} os={os} />
          {
            formFields.preferredOption.length ? (
              <FlexBox>
                {renderOption(formFields.preferredOption, true)}
              </FlexBox>
            ) : null
          }
          {
            formFields.secondaryOption.length ? (
              <React.Fragment>
                <ContinueWith os={os} className="login-text-or">
                  {__("login_or_signup_orloginwith", "or log in with")}
                </ContinueWith>
                <FlexBox>
                  {renderOption(formFields.secondaryOption)}
                </FlexBox>
              </React.Fragment>
            ) : null
          }
          {
            formFields.socialLoginsOptionsGroup.length ? (
              <React.Fragment>
                <ContinueWith os={os} className="login-text-or">
                  {__("login_or_signup_oruse", "or use")}
                </ContinueWith>
                <FlexBox>
                  {renderOption(formFields.socialLoginsOptionsGroup)}
                </FlexBox>
              </React.Fragment>
            ) : null
          }
        </Form>
      </FormWrapper>
    </Wrapper>
  )
};

export default LoginOrSignupUI;

const FlexBox = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
