// @flow

import {
  PUBLIC_LINK_INIT,
  PUBLIC_LINK_DISPOSE,
  PUBLIC_LINK_OPEN_FOLDER
} from "../constants/publiclink";
import type { Metadata } from "../../../types/filesystem";
import type { Action } from "../actions/types";

export type PublicLinkState = {
  folders: { [id: number]: Array<Metadata> },
  openFolderId: number,
  loaded: boolean
};

const initialState = { loaded: false, folders: {}, openFolderId: 0 };

export function publicLinkReducer(state: PublicLinkState = initialState, action: Action) {
  switch (action.type) {
    case PUBLIC_LINK_INIT:
      return { ...state, loaded: true, folders: flattenMetadata(action.metadata) };
    case PUBLIC_LINK_OPEN_FOLDER:
      return { ...state, openFolderId: action.folderid };
    case PUBLIC_LINK_DISPOSE:
      return initialState;
    default:
      return state;
  }
}

function flattenMetadata(metadata: Metadata, flat = {}) {
  if (metadata.isfolder) {
    flat[metadata.folderid] = metadata;

    if (metadata.isfolder && metadata.contents) {
      for (let i = 0; i < metadata.contents.length; ++i) {
        flattenMetadata(metadata.contents[i], flat);
      }
    }
  }

  return flat;
}
