// @flow

import React, { useState } from "react";
import styled from "styled-components";

import { activateGracePeriod } from "../../api/business";
import { __ } from "../../lib/translate";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

import Modal, { OvalModalWrap, OvalModalCloseV2, CloseModalIcon } from "../../components/Modal";
import Heading from "./MultiplatformComponents/Elements/Heading";
import Message from "./MultiplatformComponents/Elements/Message";
import MessageBoxWarning from "./MultiplatformComponents/Elements/MessageBoxWarning";
import MessageBoxSuccess from "./MultiplatformComponents/Elements/MessageBoxSuccess";
import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper";
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper";
import Button from "./MultiplatformComponents/Elements/Button";
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink";
import Wrapper from "./MultiplatformComponents/Elements/Wrapper";
import Footer from "./MultiplatformComponents/Elements/Footer";

import WarningIconWeb from "../../../root/img/svg/ba_grace_period/warning_web.svg";
import WarningIconIos from "../../../root/img/svg/ba_grace_period/warning_ios.svg";
import SuccessIconWeb from "../../../root/img/svg/ba_grace_period/success_web.svg";
import SuccessIconIos from "../../../root/img/svg/ba_grace_period/success_ios.svg";
import LogoIcon from "../../../root/img/svg/ba_grace_period/pcloud_logo.svg";
import ModalWindow from "./MultiplatformComponents/Elements/ModalWindow";
import { AndroidModalClose } from "./MultiplatformComponents/AndroidStyles";

const iconByOsDescription = {
  [DEVICE_OS.iOS]: <WarningIconIos width="46px" height="46px" />,
  [DEVICE_OS.Android]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.Web]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.Win]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.Linux]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.MacOSX]: <WarningIconIos width="46px" height="46px" />
};

const iconByOsSuccess = {
  [DEVICE_OS.iOS]: <SuccessIconIos width="46px" height="46px" />,
  [DEVICE_OS.Android]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.Web]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.Win]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.Linux]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.MacOSX]: <SuccessIconIos width="46px" height="46px" />
};

const buttonNameByOs = {
  [DEVICE_OS.iOS]: "continue_to_pcloud",
  [DEVICE_OS.Android]: "continue_to_pcloud",
  [DEVICE_OS.Web]: "continue_to_pcloud",
  [DEVICE_OS.Win]: "continue_to_pcloud_drive",
  [DEVICE_OS.Linux]: "continue_to_pcloud_drive",
  [DEVICE_OS.MacOSX]: "continue_to_pcloud_drive"
};

const GracePeriodBisiness = ({
  open = false,
  isModal = false,
  onClose = () => {},
  os = 4,
  auth = false,
  gracePeriodActivated = () => {}
}) => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    activateGracePeriod(
      { auth: auth },
      ret => {
        setShowSuccessMsg(true);
        gracePeriodActivated();
        setLoading(false);
      },
      err => {
        console.log("ERROR", err);
        setLoading(false);
      }
    );
  };

  const renderActivateSuccessMsg = () => {
    return (
      <React.Fragment>
        <FormWrapper os={os} isModal={isModal}>
          {/* {os === DEVICE_OS.Android || os === DEVICE_OS.iOS ? <LogoIcon width="4rem" style={{ marginBottom: "1rem" }} /> : null} */}
          <Header>
            <Heading os={os} style={{ marginBottom: "10px" }}>
              {__("ba_account_reactivated", "Account reactivated")}
            </Heading>
            <MessageBoxSuccess os={os}>
              <div className="icon-wrapper">{iconByOsSuccess[os]}</div>
              <SubHeadingOverwrite os={os}>
                {__(
                  "ba_account_reactivated_success_msg",
                  "Your business account has been reactivated successfully. Please check your e-mail for further information."
                )}
              </SubHeadingOverwrite>
            </MessageBoxSuccess>
          </Header>
          <ButtonWrapper os={os}>
              {
                os === DEVICE_OS.Android && (
                  <AndroidModalClose>
                    <ButtonLink
                      className="modal-link"
                      loading={loading}
                      os={os}
                      onClick={() => {
                        /**continue to pCloud or pCloud Drive */
                        setLoading(true);
                        setTimeout(() => {
                          document.dispatchEvent(new Event("grace-period-activated"));
                        }, 1000);
                      }}
                      os={os}
                    >
                      {__(buttonNameByOs[os])}
                    </ButtonLink>
                  </AndroidModalClose>
                )
              }
              {
                os !== DEVICE_OS.Android && (
                  <Button
                    type="submit"
                    os={os}
                    minWidth="100%"
                    loading={loading}
                    className="grace-period-button"
                    onClick={() => {
                      /**continue to pCloud or pCloud Drive */
                      setLoading(true);
                      setTimeout(() => {
                        document.dispatchEvent(new Event("grace-period-activated"));
                      }, 1000);
                    }}
                  >
                    {__(buttonNameByOs[os])}
                  </Button>
                )
              }
          </ButtonWrapper>
        </FormWrapper>
        {os === DEVICE_OS.Win || os === DEVICE_OS.Linux || os === DEVICE_OS.MacOSX ? (
          <Footer>
            <ButtonLink
              type="button"
              os={os}
              style={{ fontSize: "inherit" }}
              onClick={() => {
                setTimeout(() => {
                  document.dispatchEvent(new CustomEvent("grace-period-activated", { detail: "GO_TO_BILLING" }));
                }, 1000);
              }}
            >
              {__("ba_renew_account_btn", "Go to pCloud billing to renew your account")}
            </ButtonLink>
          </Footer>
        ) : null}
      </React.Fragment>
    );
  };

  const renderDescription = () => {
    return (
      <React.Fragment>
        <FormWrapper os={os} isModal={isModal}>
          {/* {os === DEVICE_OS.Android || os === DEVICE_OS.iOS ? <LogoIcon width="4rem" style={{ marginBottom: "1rem" }} /> : null} */}
          <Header>
            <Heading os={os} style={{ marginBottom: "15px" }}>
              {__("ba_expired_owner_grace_period_title", "Your business subscription has expired")}
            </Heading>
            <Message className="centered" style={{ fontSize: "15px" }} os={os}>
              {__("ba_expired_owner_grace_period_description")}
            </Message>
            <MessageBoxWarning os={os}>
              <div className="icon-wrapper">{iconByOsDescription[os]}</div>
              <SubHeadingOverwrite os={os}>{__("ba_expired_owner_grace_period_warning")}</SubHeadingOverwrite>
            </MessageBoxWarning>
          </Header>
          <ButtonWrapper os={os}>
              {
                os === DEVICE_OS.Android && (
                  <AndroidModalClose>
                    <ButtonLink className="modal-link" loading={loading} os={os} onClick={onClick} os={os}>{__("ba_get_grace_period", "Get grace period")}</ButtonLink>
                  </AndroidModalClose>
                )
              }
              {
                os !== DEVICE_OS.Android && (
                  <Button className="grace-period-button" type="submit" os={os} minWidth="100%" onClick={onClick} loading={loading}>
                    {__("ba_get_grace_period", "Get grace period")}
                  </Button>
                )
              }
          </ButtonWrapper>
        </FormWrapper>
      </React.Fragment>
    );
  };

  const renderBody = () => {
    return showSuccessMsg ? renderActivateSuccessMsg() : renderDescription();
  };

  const renderModal = () => {
    return (
      <Modal animate onClose={onClose} opened={open} shouldCloseOnOverlayClick={false} fullSize={true}>
        <ModalWindow os={os} style={os === DEVICE_OS.iOS ? { paddingLeft: "24px", paddingRight: "24px" } : undefined}>
          {os !== DEVICE_OS.Android &&
            <CloseModalIconStyled onClick={onClose} />
          }
          {renderBody()}
        </ModalWindow>
      </Modal>
    );
  };

  return isModal ? renderBody() : renderModal();
};

export default GracePeriodBisiness;

const Header = styled.div`
  text-align: center;
  /* padding: 20px 10px 0px 10px; */

  @media (max-width: 460px) {
    /* padding: 20px 10px; */
    text-align: left;
  }
`;

const SubHeadingOverwrite = styled(Message)`
  display: block;
  margin-left: 0;
  text-align: center;
  margin-bottom: 0px;
`;

const CloseModalIconStyled = styled(CloseModalIcon)`
  fill: ${({ theme }) => theme.textPrimary};
  position: absolute;
  top: 16px;
  right: 16px;
`
