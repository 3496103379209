import React from "react";
import styled from "styled-components";

const MacCheckBox = ({
  children,
  checked = false,
  text = "",
  onChange = () => { },
  ...rest
}) => {
  return (
    <Label className="container">
      {text ? <TextWrapper>{text}</TextWrapper> : null}
      {children}
      <Input type="checkbox" onChange={onChange} checked={checked} {...rest} />
      <Checkmark className="checkmark"></Checkmark>
    </Label>
  )
};

export default MacCheckBox;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  font-size: inherit;
  user-select: none;
  color: ${({ theme }) => theme.textPrimary};
  font-weight: normal;
  padding-bottom: 0px;
  padding-bottom: 0px;
  line-height: 20px;
  cursor: pointer;

  .regsitration-terms {
    color: ${({ theme }) => theme.textPrimary};
  }
  
  & input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.primary};
    border: none;

    &:after {
      display: block;
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const TextWrapper = styled.div`
  margin-left: 10px;
`;

const Checkmark = styled.span`
  left: 0;
  height: 13px;
  width: 13px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.border};
  box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.28);
  background-color: ${({ theme }) => theme.inputBackground};

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
