import React, { useEffect } from "react";
import { hiDPI } from "polished";

import { ButtonSmall } from "./FacebookButton"; // DONE
import APPLELogin from "../../../../lib/apple_login"; // DONE
import { EXTERNAL_APPS, APPLE_SDK, DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { __ } from "../../../../lib/translate"; // DONE
import { loadScript } from "../../../../lib/utils"; // DONE
import SocialButton from "../../MultiplatformComponents/Elements/SocialButton"; // DONE
import AppleLogo from "../../MultiplatformComponents/Elements/AppleLogo" // DONE
import { LogoWrapper } from "../../MultiplatformComponents/CommonStyles";

const AppleButton = props => {
  const {
    os = 4,
    size = "small",
    passpcloud = false,
    receiveToken = () => {},
    redirect_uris,
    getRequestId,
    requestId,
    getToken,
    state,
  } = props;

  useEffect(() => {
    loadScript(APPLE_SDK, () => {
      APPLELogin.init(os === DEVICE_OS.Android ? false : true, { ...state, app: EXTERNAL_APPS.APPLE }, os);
    });
  }, []);

  const onAuthSuccess = ({ authorization, user }) => {
    const token = authorization.id_token;

    receiveToken(token, EXTERNAL_APPS.APPLE);

    if (user) {
      try {
        window.localStorage.setItem("apple_user", JSON.stringify(user));
      } catch (error) {}
    }
  }

  const onAuthError = (error) => {
    console.error("ERROR>>>>>>>>>>>", error);
  }

  const onButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (redirect_uris) {
      let params = "";

      if (redirect_uris && os === DEVICE_OS.Web) {
        params = "is_extension";
      }
      if (passpcloud) {
        params = "passpcloud";
      }

      getRequestId(params)
        .then(({ request_id }) => {
          window.open(redirect_uris.apple + "?request_id=" + request_id, "apple-login", "width=550,height=450");
          getToken('apple_oauth2_token', request_id)
            .then(({ access_token }) => {
              receiveToken(access_token, EXTERNAL_APPS.APPLE);
            })
        });
    } else {
      const promise = APPLELogin.signIn();
      promise.then(onAuthSuccess, onAuthError);
    }
  }

  if (size === "big") {
    return (
      <SocialButton os={os} className="login-button-apple" onClick={onButtonClick}>
        <LogoWrapper>
          <AppleLogo os={os} />
        </LogoWrapper>
        <span>{__("sign_in_with_apple", "Sign in with Apple")}</span>
      </SocialButton>
    );
  }

  return (
    <SocialButton os={os} className="login-button-apple" onClick={onButtonClick}>
      <AppleLogo os={os} />
    </SocialButton>
  );
}

export default AppleButton;
