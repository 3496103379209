import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 340px;
`

export const FormWrap = styled.div`
  max-width: 340px;
  margin: 0 auto;
`;

export const FormContent = styled.div`
  width: 100%;
  & input {
    border-radius: 0px !important;
  }
`;

export const Title = styled.div`
  text-align: center;
  text-transform: uppercase;
  margin: 0px 0px 50px 0px;
  font-size: 13px;
  color: ${({error}) => error ? "#ff4d4d" : "#222"};
  font-family: "Roboto", sans-serif;
  @media (max-width: 768px) {
    margin: 0px 0px 30px 0px;
  }
`

export const Error = styled.div`
  text-align: center;
  display: inline-block;
  font-size: 13px;
  position: relative;
  bottom: 5px;
  color: #ff4d4d;
  font-family: "Roboto", sans-serif;
`

export const Input = styled.input`
  ${({ align }) => {
    if (align === 'left') {
      return ` text-align: left; padding-left: 74px`
    } else if (align === 'center') {
      return 'text-align: center'
    } else {
      return ` text-align: left`
    }
  }};
  width: 100%;
  height: 38px;
  font-size: 13px;
  border: none;
  color: #222;
  background: none;
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: 340px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius + 'px'};
  border-color: ${({ error }) => error ? "#fb9797" : "#ccc"};
  background-color: ${({ error }) => error ? "#fff8f8" : "#fff"};
`;

export const InputPrefix = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 54px;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #222;
  font-weight: bolder;
  border-right: 1px solid #ccc;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: ${({error}) => error ? "#fff" : "#fafafa"};
  border-right-color: ${({ error }) => error ? "#fb9797" : "#ccc"};
`;

export const Message = styled.div`
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #222;
  text-align: center;
`

export const Icon = styled.div`
  width: 64px;
  height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-bottom: 27px;
`

export const Link = styled.a`
  margin: 27px 0px 0px 0px;
  display: block;
  text-decoration: none;
  color: #17bed0;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: inline-block;
  width: 100%;
`