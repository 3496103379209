import styled from "styled-components";

export const ButtonLink = styled.button`
  font-size: ${({fontSize}) => fontSize ? fontSize : "1rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({color}) => color ? color : "#17bed0"};
  padding: 0px;
  cursor: pointer;

  &:disabled {
    color: #999;
    cursor: auto;
  }
`;