import React from "react";
import { SubHeading as WebSubHeading } from "../WebStyles";
import { SubHeading as WindowsSubHeading } from "../WindowsStyles";
import { SubHeading as MacSubHeading } from "../MacStyles";
import { SubHeading as LinuxSubHeading } from "../LinuxStyles";
import { SubHeading as IosSubHeading } from "../iOSStyles";
import { SubHeading as AndroidSubHeading } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const SubHeading = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidSubHeading {...rest}/>
    case DEVICE_OS.iOS:
      return <IosSubHeading {...rest}/>
    case DEVICE_OS.Web:
      return <WebSubHeading {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsSubHeading {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacSubHeading {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxSubHeading {...rest}/>
    default:
      return <WebSubHeading {...rest}/>
  }
};

export default SubHeading;
