import PropTypes from 'prop-types';
import React from 'react';
import { formatSize } from '../lib/utils';
import { TString } from './intl';

const Progress = ({ progress, total, speed }) => {
	let percent;
	let percentInfo;

	if (total) {
		percent = Math.round(progress / total * 100, 1) + '%';
		percentInfo = <div className="percent">
			<TString id="upload_percent" replace={{percent: percent}}>
				%percent% uploaded
			</TString>
		</div>;

		if (speed) {
			percentInfo = <div className="percent">
				<TString id="upload_percent" replace={{percent: percent}}>
					%percent% uploaded
				</TString>
				<span>({formatSize(speed.showSpeed) + '/s'})</span>
				<TString id="time_left" replace={{time: speed.timeLeft}}>
					%time% Left
				</TString>
			</div>;
		}
	} else {
		percent = '100%';
		percentInfo = <div className="percent">100% uploaded</div>;
	}

	return (
		<div className="progress-wrap clearfix">
			<div className="progress">
				<div className="fill" style={{ width: percent }}></div>
			</div>
			<div className="progress-status">
				{percentInfo}
				<div className="total">{formatSize(total)}</div>
			</div>
		</div>
	);
}

Progress.propTypes = {
	progress: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	speed: PropTypes.shape({
		timeLeft: PropTypes.string.isRequired,
		showSpeed: PropTypes.number.isRequired
	})
}

export default Progress;
