import React from "react";
import GoogleLogoSVG from "../../../../../root/img/svg/signin-with-google.svg"
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const GoogleLogo = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <GoogleLogoSVG width="23.5" height="24" {...rest} />
    case DEVICE_OS.iOS:
      return <GoogleLogoSVG width="27.4" height="28" {...rest} />
    case DEVICE_OS.Web:
      return <GoogleLogoSVG width="19.575" height="20" {...rest} />
    case DEVICE_OS.Win:
      return <GoogleLogoSVG width="19.575" height="20" {...rest} />
    case DEVICE_OS.MacOSX:
      return <GoogleLogoSVG width="19.575" height="20" {...rest} />
    case DEVICE_OS.Linux:
      return <GoogleLogoSVG width="19.575" height="20" {...rest} />
    default:
      return <GoogleLogoSVG width="19.575" height="20" {...rest} />
  }
};

export default GoogleLogo;
