// @flow

export const containNumbers = (value: string) => {
    return /\d+/.test(value);
};

export const containOnlyNumbers = (value: string) => {
    return /^\d*$/.test(value);
};

export const containLowercase = (value: string) => {
    return /[a-z]+/.test(value);
};

export const containUppercase = (value: string) => {
    return /[A-Z]+/.test(value);
};

export const containLetters = (value: string) => {
    return /[a-zA-Z]+/.test(value);
};

export const containSpaces = (value: string) => {
    return /\s+/.test(value);
};

export const startsWith = (value: string, prefixes: Array<string>) => {
    return prefixes.some(prefix => value.startsWith(prefix));
};

export const endsWith = (value: string, suffixes: Array<string>) => {
    return suffixes.some(suffix => value.startsWith(suffix));
};

export const hasLengthOf = (value: string, length: number) => {
    return value.length === length;
}

export const hasLengthBetween = (value: string, min: number, max: number) => {
    return (value.length >= min && value.length <= max);
}