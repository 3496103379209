// @flow

import subscriptions from "../subscriptions";
import apiMethod from "../../api/apiMethod";


let authToken = false;
let isRunning = false;
let listeners = [];

// used for both pollins and reading
let readXhr = null;

const businessInstance = {
  handleEvents: handleEvents,
  getParams: () => {
    return {
      auth: authToken
    };
  }
};

function handleEvents (ret) {
  console.log("New event: ", ret);
  
  if (ret.from) {
    processEvents(ret.from);
  }

  subscriptions.setState('users', 'ready');
  subscriptions.setState('teams', 'ready');
}

function processEvents(result) {
  listeners.forEach(listener => listener(result));
}


function listen(token: string, callback: () => void) {
  if (authToken !== token) {
    authToken = token;
  }

  listeners.push(callback);

  if (!isRunning) {
    start();
  }

  return () => {
    listeners.splice(listeners.indexOf(callback));

    if (!listeners.length) {
      stop();
    }
  };
}

function start() {
  if (isRunning) {
    stop();
  }

  isRunning = true;
  subscriptions.add('users', businessInstance);
  subscriptions.add('teams', businessInstance);
  subscriptions.setState('users', 'ready');
  subscriptions.setState('teams', 'ready');
}

function stop() {
  if (readXhr) {
    readXhr.abort();
  }

  isRunning = false;
  subscriptions.remove('users');
  subscriptions.remove('teams');
}

export default { listen };
