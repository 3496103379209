import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import apiMethod from "../../api/apiMethod"; // DONE
import ButtonDefault from "../../components/ButtonDefault" // DONE
import { __ } from "../../lib/translate"; // DONE
import { htmlentities } from "../../lib/utils"; // DONE
import Modal, { ModalWrap, OvalModalWrap, OvalModalClose, OvalModalBody } from "../../components/Modal"; // DONE

const buttonStyle = {
  width: "100%",
  fontFamily: "'Roboto', sans-serif",
  fontSize: "15px"
}

const modalStyle = {
  padding: "20px"
}

type Props = {
  verifyToken: string,
  email: string
};

type State = {
  loading: boolean,
  emailSent: boolean
};

export class RestrictedAccessWarning extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      emailSent: false
    };

    this.verifyEmail = this.verifyEmail.bind(this);
  }
  
  verifyEmail() {
    const { verifyToken } = this.props;

    this.setState({ loading: true });

    apiMethod('sendverificationemail', { verifytoken: verifyToken }, () => {
      this.setState({ loading: false, emailSent: true });
    }, {
      errorCallback: ret => {
        this.setState({ loading: false });
        console.log("ERROR:", ret);
      }
    })
  }

  renderVerifyEmail() {
    const { loading } = this.state;

    return (
      <Wrapper>
        <Title>{__("Attention")}</Title>
        <Warning/>
        <Text>{__("verify_restricted_access")}</Text>
        <ButtonDefault style={buttonStyle} loading={loading} onClick={this.verifyEmail}>{__("verify_restricted_button1")}</ButtonDefault>
      </Wrapper>
    )
  }

  renderSuccess() {
    const { email } = this.props;

    return (
      <Wrapper>
        <Title>{__("Verify your account")}</Title>
        <Success/>
        <SuccessText dangerouslySetInnerHTML = {{
          __html: __("verify_email_success_description", "", {
            styleattr: `class="modal-info"`,
            email
          })
        }} />
      </Wrapper>
    )
  }

  render() {
    const { emailSent } = this.state;

    return emailSent ? this.renderSuccess() : this.renderVerifyEmail();
  }
}

type ModalProps = {
  verifyToken: string,
  email: string,
  onClose: () => void
};

export class RestrictedAccessWarningModal extends React.Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props);
  }

  render() {
    const { onClose } = this.props
    return (
      <Modal onClose={onClose} opened={true}>
        <OvalModalWrap style={modalStyle}>
          <OvalModalClose onClick={onClose}/>
          <RestrictedAccessWarning {...this.props}/>
        </OvalModalWrap>
      </Modal>
    )
  }
}

const Wrapper = styled.div`
  width: 340px;
  text-align: center;
  box-sizing: border-box;
  @media(max-width: 400px) {
    width: 100%;
  }
`
const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  text-align: center;
  color: #000000;
`
const Icon = styled.div`
  display: inline-block;
  width: 38px;
  height: 34px;
  background-size: 38px 34px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 27px;
`

const Warning = styled(Icon)`
  background-image: url(${require("../../../root/img/warning-red.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/warning-red@2x.png")});
  }
`

const Success = styled(Icon)`
  height: 38px;
  background-size: 38px 38px;
  background-image: url(${require("../../../root/img/managesubscriptions/modal-check.png")});
`

const Text = styled.div`
  margin: 23px auto;
  width: 255px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.2px;
  color: #000000;
  text-align: center;
  overflow: hidden;
  b {
    font-size: 16px !important;
  }

  .modal-info {
    display: inline-block;
    color: #999;
    margin-top: 17px;
    font-size: 13px;
  }
`

const SuccessText = styled(Text)`
  width: 100%;
  margin-bottom: 0px;
`
