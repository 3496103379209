import apiMethod from "./apiMethod";
import { __ } from "../lib/translate";
import { setcookie, rcookie, getDeviceID, checkPassToken } from "../lib/utils";
import { DEVICE_OS, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import {sendLoginEvent} from "../lib/sendAnalyticsEvent";

export default function login(email, password, rememberme, options) {
  if (!email || !password) {
    throw Error("Email and Password are both required for login.");
  }
  const {
    os = DEVICE_OS.Web,
    osversion = "",
    appversion = "",
    appversioncode = "",
    device = "",
    deviceid = "",
    clientid = "",
    passpcloud = false,
    startgraceperiod = false,
    gresponsetoken = false,
    registertemptoken = "",
    emailtfacode = ""
  } = options;

  const opts = {
    forceFresh: true,
    cacheTime: false,
    type: "post",
    apiServer: options.apiServer,
    errorCallback: ret => {
      if (ret.result === 2000) {
        ret.error = __("login_wrong_information", "Wrong e-mail and password combination.");
      }

      if (options.errorCallback) {
        options.errorCallback(ret);
      }
    },
    showErrorMessage: false,
    onTimeout: () => {
      if (options.onTimeout) {
        options.onTimeout();
      }
    }
  };

  let _params = {
    username: email,
    password: password,
    device: device || navigator.userAgent,
    deviceid: deviceid || getDeviceID(),
    language: options.language || "en"
  };

  if (gresponsetoken) {
    _params.gresponse = gresponsetoken;
  }

  if (registertemptoken) {
    _params.registertemptoken = registertemptoken;
  }

  if (emailtfacode) {
    _params.emailtfacode = emailtfacode;
  }

  if (os === DEVICE_OS.Web) {
    _params = {
      ..._params,
      _t: $.now,
      logout: 1,
      getlastsubscription: 1,
      promoinfo: 1,
      os: 4,
      osversion: "0.0.0"
    };

    if (rcookie("restrictedauth")) {
      _params.restrictedauth = rcookie("restrictedauth");
      setcookie("restrictedauth", "", -1);
    }
  } else {
    if (os) {
      _params.os = passpcloud ? DEVICE_OS.Web : os;
    }

    if (osversion) {
      _params.osversion = osversion;
    }

    if (appversion) {
      _params.appversion = appversion;
    }

    if (appversioncode) {
      _params.appversioncode = appversioncode;
    }

    if (clientid) {
      _params.clientid = clientid;
    }

    if (startgraceperiod) {
      _params.startgraceperiod = startgraceperiod;
    }
  }

  if (!rememberme && os !== DEVICE_OS.Android && os !== DEVICE_OS.iOS ) {
    _params.authexpire = 86400;
    _params.authinactiveexpire = 7200;
  }

  return apiMethod(
    "login",
    _params,
    function(ret) {
      if (options.successCallback) {
        var email = ret.email || "";
        checkPassToken(email);
        options.successCallback(ret);
      }
      try {
        sendLoginEvent('email', !!options.isRegister)
      } catch (error) {}
    },
    opts
  );
}
