import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const NotificationsWrap = styled.div`
  /*box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);*/
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.45), 4px 4px 10px rgba(0, 0, 0, 0.15);
  background: #fff;
  /*border: 1px solid #ddd;*/
  border-radius: 5px;
`;

const NotificationsTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
  text-indent: 15px;
  border-bottom: 1px solid #f0f0f0;
  color: #000;
`;

const Tip = styled.div`
  position: absolute;
  top: 0;
  right: 14px;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 7px;
  top: -6px;
  border-top-width: 0;
  border-bottom-color: #cbcbcb;
`;

const TipInner = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  border-bottom-color: #fff;
  top: -5px;
  left: -6px;
`;

const NotificationsInner = styled.div`
  max-height: 370px;
  width: 360px;
  overflow-y: auto;
  border-radius: 5px;

  .mob & {
    width: 300px;
  }
`;

const NotificationsPopoverWrap = ({ children }) => (
  <NotificationsWrap>
    <NotificationsTitle>Notifications</NotificationsTitle>
    <Tip><TipInner></TipInner></Tip>
    <NotificationsInner>{children}</NotificationsInner>
  </NotificationsWrap>
);

NotificationsPopoverWrap.propTypes = {
  children: PropTypes.any.isRequired
}

export default NotificationsPopoverWrap;
