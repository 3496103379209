import React from "react";


import HidePassAndroid from "../../../../../root/img/svg/reveal-password/android/eye-white.svg";
import HidePassiOS from "../../../../../root/img/svg/reveal-password/ios/eye.svg";
import HidePassMac from "../../../../../root/img/svg/reveal-password/mac/eye.svg";
import HidePassWeb from "../../../../../root/img/svg/reveal-password/web/eye-white.svg";
import HidePassWin from "../../../../../root/img/svg/reveal-password/win/eye-white.svg";
import { PassIconWrapper as LinuxPassIconWrapper } from "../LinuxStyles";
import { PassIconWrapper as IosPassIconWrapper } from "../iOSStyles";
import { PassIconWrapper as AndroidPassIconWrapper } from "../AndroidStyles";
import { PassIconWrapper as WebPassIconWrapper } from "../WebStyles";
import { PassIconWrapper as MacPassIconWrapper } from "../MacStyles";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const HidePassword = ({ os, themeMode, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return (
        <AndroidPassIconWrapper {...rest}>
          <HidePassAndroid fill={themeMode === "dark" ? "#bfc8ca" : "#3f484a"} width="20px" height="14px"  />
        </AndroidPassIconWrapper>
      )
    case DEVICE_OS.iOS:
      return (
        <IosPassIconWrapper {...rest}>
          <HidePassiOS fill={themeMode === "dark" ? "#7f7f7f" : "#7f7f7f"} width="24px" height="15px"/>
        </IosPassIconWrapper>
      )
    case DEVICE_OS.Web:
      return (
        <WebPassIconWrapper {...rest}>
          <HidePassWeb fill={themeMode === "dark" ? "#cdd0d4" : "#656668"} width="16px" height="11px"/>
        </WebPassIconWrapper>
      )
    case DEVICE_OS.Win:
      return (
        <LinuxPassIconWrapper {...rest}>
          <HidePassWin fill={themeMode === "dark" ? "#cdd0d4" : "#212121"} width="12px" height="12px"/>
        </LinuxPassIconWrapper>
      )
    case DEVICE_OS.MacOSX:
      return (
        <MacPassIconWrapper {...rest}>
          <HidePassMac fill={themeMode === "dark" ? "#cfcfcf" : "#cfcfcf"} width="16px" height="10px"/>
        </MacPassIconWrapper>
      )
    case DEVICE_OS.Linux:
      return (
        <LinuxPassIconWrapper {...rest}>
          <HidePassWin fill={themeMode === "dark" ? "#cdd0d4" : "#212121"} width="12px" height="7px"/>
        </LinuxPassIconWrapper>
      )
    default:
      return (
        <WebPassIconWrapper {...rest}>
          <HidePassWeb fill={themeMode === "dark" ? "#cdd0d4" : "#656668"} width="16px" height="11px"/>
        </WebPassIconWrapper>
      )
  }
};

export default HidePassword;
