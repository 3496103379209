import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 2.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "SF UI Display", sans-serif;
  position: relative;
`;

export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  color: #000;
`;

export const SubHeading = styled.div`
  display: none;
  font-size: 1.125rem;
  text-align: center;
  margin-top: 0.85rem;
  letter-spacing: 0.2px;
`;

export const MessageBox = styled.div`
  padding: 1.625rem 1.5rem;
  border-radius: 17px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.07);
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25rem;

  .icon-wrapper {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #17bed0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MessageBoxWarning = styled(MessageBox)`
  border: none;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.07);
  background-color: #f8eccf;
  margin-top: 2.375rem;
  flex-direction: column;
  gap: 1.242rem;

  .icon-wrapper {
    background-color: transparent;
  }
`;

export const MessageBoxSuccess = styled(MessageBoxWarning)`
  background-color: #dff8e2;
`;

export const Message = styled.div`
  font-size: 1rem;
  letter-spacing: 0.06rem;
  text-align: left;
  color: #000;
  margin-left: 1.438rem;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 225px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }

  &.centered {
    text-align: center;
    margin-left: 0;
  }
`;

export const ErrorBox = styled.div`
  padding: 17px 22px;
  border-radius: 17px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #ff3b30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .icon-wrapper {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  svg {
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 1rem;
  line-height: 1.24;
  letter-spacing: 0.96px;
  text-align: left;
  color: #fff;
  flex-grow: 1;

  strong, b {
    font-weight: bold;
  }
`;

export const Form = styled.form`
  margin-top: 2rem;
  width: 100%;
  max-width: 340px;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 1.375rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0.375rem 1rem;
  border-radius: 10px;
  border: solid 1px #a1a1a1;
  background-color: #fff;
  font-family: inherit !important;
  font-size: 1.125rem;
  text-align: left;
  color: #201f1e;
  outline: none;

  &:focus {
    border-color: #17bed0;
  }

  &:disabled {
    border: solid 1px rgba(161, 161, 161, 0.45);;
    color: rgba(0, 0, 0, 0.3);
  }

  &[type="email"],
  &:read-only {
    padding-right: 35px !important;
  }

  &[type="password"] {
    padding-right: 35px !important;
  }
`;

export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.063rem;
  margin: 1.375rem auto;
  margin-bottom: -1rem;

  label {
    margin-right: 0.625rem;
  }

  button {
    margin-left: 0.625rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2.375rem;
  position: relative;
`;

export const Button = styled.button`
  font-family: inherit !important;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.625rem;
  border-radius: 10px;
  background-color: #17bed0;
  border: none;
  font-size: 1.063rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;

  &:disabled {
    background-color: #e9e9eb;
    color: #b4b4b8;
    cursor: auto;
  }
`;

export const ButtonLink = styled.button`
  font-size: ${({fontSize}) => fontSize ? fontSize : "1rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({color}) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
`;

export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`

export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 340px;
`;

export const SocialButton = styled.div`
  width: 33.33%;
  height: 50px;
  padding: 0.375rem;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #f2f2f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  cursor: pointer;

  &.login-button-apple {
    order: 1;
    margin-left: 0px;
  }

  &.login-button-google {
    order: 2;
  }

  &.login-button-facebook {
    order: 3;
    margin-right: 0px;
  }
`;

export const ContinueWith = styled.div`
  font-size: 1rem;
  text-align: center;
  color: #b2b2b2;
  margin: 1.25rem auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 0.625rem;
  text-align: center;

  & button {
    font-size: 1.063rem;
  }
`;