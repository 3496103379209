import React, { useState } from "react";

import { Input as AndroidInput } from "./AndroidStyles";
import { Container as AndroidInputContainer } from "./AndroidStyles";
import { Label as AndroidInputLabel } from "./AndroidStyles";

// used for Android
const InputWithFlyingLabel = React.forwardRef(({ disabled, ...rest }, ref) => {
  const [isFocused, setIsFocused] = useState(false);

  return <AndroidInputContainer>
    <AndroidInput
      {...rest}
      ref={ref}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      hasValue={rest.value !== ''}
      placeholder={''}
      isForgotPass={rest.isForgotPass}
      disabled={disabled}
    />
    {!disabled ? <AndroidInputLabel isForgotPass={rest.isForgotPass} isFocused={isFocused} hasValue={rest.value !== ''}>{rest.placeholder}</AndroidInputLabel> : null}
  </AndroidInputContainer>
});

export default InputWithFlyingLabel
