import React from "react";
import { CSSTransition } from "react-transition-group";
import styled, { keyframes } from "styled-components";

const TransitionAnimation = ({
  children,
  trigger = false,
  type = "fade",
  timeout = 500,
  height = 300,
  delay = 0,
  disableAnimationOnShow = false,
  onEnter = () => {},
  onExited = () => {},
}) => (
  <AnimationContainer disableAnimationOnShow={disableAnimationOnShow} timeout={timeout} height={height} delay={delay}>
    <CSSTransition
      in={trigger}
      timeout={timeout + delay}
      classNames={type}
      unmountOnExit
      onEnter={onEnter}
      onExited={onExited}
    >
      {children}
    </CSSTransition>
  </AnimationContainer>
);

export default TransitionAnimation;

const AnimationContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: inherit;

  .slide-enter {
    opacity: 0;
    transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(-150px)" : "translateX(150px)"};
  }
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0px);
  }
  .slide-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .slide-exit-active {
    opacity: 0;
    transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(150px)" : "translateX(-150px)"};
  }
  .slide-enter-active,
  .slide-exit-active {
    transition: opacity ${({timeout}) => timeout}ms, transform ${({timeout}) => timeout}ms;
    transition-delay: ${({delay}) => delay}ms;
  }

  .fade-enter {
    opacity: ${({disableAnimationOnShow}) => disableAnimationOnShow ? '1' : '0'};
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity ${({timeout}) => timeout}ms;
    transition-delay: ${({delay}) => delay}ms;
  }

  .expand-enter {
    opacity: 0;
    max-height: 0px;
  }
  .expand-enter-active {
    opacity: 1;
    max-height: ${({height}) => height}px;
  }
  .expand-exit {
    opacity: 1;
    max-height: ${({height}) => height}px;
  }
  .expand-exit-active {
    opacity: 0;
    max-height: 0px
  }
  .expand-enter-active,
  .expand-exit-active {
    transition: all ${({timeout}) => timeout}ms;
    transition-delay: ${({delay}) => delay}ms;
  }

  .page-enter {
    .inner-animation.animation-type-slide {
      opacity: 0;
      transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(-150px)" : "translateX(150px)"};
    }

    .inner-animation.animation-type-fade {
      opacity: 0;
    }
  }
  .page-enter-active {
    .inner-animation.animation-type-slide {
      opacity: 1;
      transform: translateX(0px);
    }
    .inner-animation.animation-type-fade {
      opacity: 1;
    }
  }
  .page-exit {
    .inner-animation.animation-type-slide {
      opacity: 1;
      transform: translateX(0px);
    }
    .inner-animation.animation-type-fade {
      opacity: 1;
    }
  }
  .page-exit-active {
    .inner-animation.animation-type-slide {
      opacity: 0;
      transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(150px)" : "translateX(-150px)"};
    }
    .inner-animation.animation-type-fade {
      opacity: 0;
    }
  }
  .page-enter-active,
  .page-exit-active {
    .inner-animation.animation-type-slide {
      transition: opacity ${({timeout}) => timeout}ms, transform ${({timeout}) => timeout}ms;
      transition-delay: ${({delay}) => delay}ms;
    }
    .inner-animation.animation-type-fade {
      transition: opacity ${({timeout}) => timeout}ms;
      transition-delay: ${({delay}) => delay}ms;
    }
  }
  
`;
