import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const NotificationsBadge = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 11px;
  font-size: 9px;
  text-align: center;
  font-weight: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 18px;
  background: rgb(255,112,64);
  margin-top: -18px;
  color: #fff;
`;

const NotificationsNew = ({ badge }) =>
  badge ? <NotificationsBadge>{badge}</NotificationsBadge> : null;

NotificationsNew.propTypes = {
  badge: PropTypes.number.isRequired
};

export default NotificationsNew;
