import styled, { css } from "styled-components";
import { fadein } from "../AuthenticationStepsUI/TFALoggedDevicesModalUI"

export const lightTheme = {
  background: '#fafdfd',
  backgroundDisabled: "#f4f4f4",
  borderColorDisabled: "rgba(0, 0, 0, 0.12)",
  primary: '#006973',
  secondary: '#6f7272',
  textPrimary: '#191c1d',
  textSecondary: '#3f484a',
  disabled: '#eff2f3',
  modalBackground: "#ddf1f4",
  forgotPassDisabledBtn: "#1f2223",
  modalText: "#000000",
  modalButtonText: "#008d9a"
};

export const darkTheme = {
  background: '#191c1d',
  backgroundDisabled: "#333",
  borderColorDisabled: "#444",
  primary: '#48d8eb',
  secondary: '#899294',
  textPrimary: '#e1e3e3',
  textSecondary: '#bfc8ca',
  disabled: '#222527',
  modalBackground: "#073239",
  forgotPassDisabledBtn: "#c2cacb",
  modalText: "#e1e3e3",
  modalButtonText: "#48d8eb"
};

export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 1.875rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  font-family: "Roboto", sans-serif;
  background-color: ${({ theme }) => theme.background};

  .change-link, .tfa-link {
    color: ${({ theme }) => theme.primary};
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.background} inset;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: ${({ fixedPosition }) => fixedPosition ? "fixed" : "absolute"};
  border-radius: 18px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  z-index: 1002;
`;

export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.div`
  font-size: ${({ isModal }) => isModal ? 24 : 22}px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme, isModal }) => isModal ? theme.modalText : theme.textPrimary};

  ${({ isModal }) => isModal && css`
    letter-spacing: -0.24px;
  `}
`;

export const SubHeading = styled.div`
  text-align: center;
  color: ${({ theme, isModal }) => isModal ? theme.modalText : theme.textPrimary};
  font-size: ${({ isModal }) => isModal ? 16 : 14}px;
  line-height: 24px;
  margin-top: ${({ isModal }) => isModal ? 18 : 5.5}px;
  width: 100%;

  ${({ isModal }) => isModal && css`
    letter-spacing: -0.16px;
  `}
`;

export const MessageBox = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: solid 1px #f6f6f6;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.125rem;

  .icon-wrapper {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MessageBoxWarning = styled(MessageBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 10px;
  border: solid 1px #f6e2a6;
  background-color: #fff9e4;
  margin-bottom: 0;
  margin-top: 2.125rem;

  .icon-wrapper {
    background-color: transparent;
  }
`;

export const MessageBoxSuccess = styled(MessageBoxWarning)`
  border-radius: 10px;
  border: solid 1px #e3f3e2;
  background-color: #e7f8e6;
`;

export const Message = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.06rem;
  text-align: left;
  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 20px;
  margin-left: 1.438rem;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 210px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }

  &.centered {
    text-align: center;
    margin-left: 0;
  }
`;

export const ErrorBox = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: solid 1px #fbdada;
  background-color: #fff7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .icon-wrapper {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 0.875rem;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.86);
  flex-grow: 1;
  width: 100%;

  strong, b {
    font-weight: bold;
  }
`;

export const Form = styled.form`
  margin-top: ${({ isModal }) => isModal ? '11px' : "2.313rem"};
  width: 100%;
  max-width: 340px;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 1.125rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }

  .login-input-password-icon {
    right: 17.5px;
  }

  .register-input-password-icon {
    right: 17.5px;
  }

  color: ${({ theme }) => theme.textPrimary} !important;
`;

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;

  box-sizing: border-box;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  border: solid 1px ${({ hasValue, theme }) => (hasValue) ? theme.primary : theme.secondary};
  background-color: ${({ theme, isForgotPass }) => isForgotPass ? theme.modalBackground : theme.background};
  font-size: 1rem;
  color: ${({ theme }) => theme.textPrimary};
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }

  &:disabled {
    border: solid 1px ${({ theme }) => theme.borderColorDisabled};
    color: #9f9f9f;
    background-color: ${({ theme }) => theme.backgroundDisabled};
  }

  &[type="password"] {
    padding-right: 45px !important;
  }

  &[type="text"] {
    padding-right: 45px !important;
  }
`;

export const Label = styled.label`
  position: absolute;
  top: ${({ isFocused, hasValue }) => (isFocused || hasValue) ? '1px' : '50%'};
  transform: translateY(-50%);
  left: 17px;
  font-size: ${({ isFocused, hasValue }) => (isFocused || hasValue) ? '12px' : '16px'};
  color: ${({ isFocused, hasValue, theme }) => (isFocused || hasValue) ? theme.primary : '#3f484a'};
  transition: top 0.3s, font-size 0.3s, color 0.3s;
  pointer-events: none;

  background-color: ${({ theme, isForgotPass }) => isForgotPass ? theme.modalBackground : theme.background};
  padding: 6px;
`;

export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;

  label {
    margin-right: 0.625rem;
    
    a {
      text-decoration: none !important;
      color: ${({ theme }) => theme.primary};
    }
  }

  button {
    margin-left: 0.625rem;
    color: ${({ theme }) => theme.primary};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  position: relative;

  .login-button-submit {
    margin-top: 0px;
  } 

  .register-button-submit {
    margin-top: 3px;
  }
`;

export const Button = styled.button`
  font-family: inherit !important;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.625rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.background};
  cursor: pointer;
  margin-top: 32px;
  position: relative;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.secondary};
    cursor: auto;
  }
`;

export const InputButton = styled.button``;

export const ButtonLink = styled.button`
  font-size: ${({ fontSize }) => fontSize ? fontSize : "1rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({ color }) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
`;

export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`

export const SecurityCodeStyledInput = styled.input`
  width: ${({ maxLength }) => {
    const singleInputMargin = maxLength > 6 ? 6 : 8;
    const separationSpace = maxLength > 6 ? 16 : 18;
    const margins = (maxLength - 2) * singleInputMargin;

    return "calc(((100% - " + (separationSpace + margins) + "px) / " + maxLength + "))"
  }};
  height: 56px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.secondary};
  line-height: 1;
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.textPrimary};

  &:nth-child(${({ chunk }) => chunk}) {
    margin-right: ${({ maxLength }) => maxLength > 6 ? "16px" : "18px"};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
  }
`;

export const VerificationCodeInputsError = styled.div``;

export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 340px;
`;

export const SocialButton = styled.div`
  width: 100%;
  height: 40px;
  padding: 0.375rem;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: solid 1px ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.background};
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.primary};
`;

export const ContinueWith = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};;
  opacity: 0.4;
  margin: 1rem auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 18.5px;
  text-align: center;
  
  button {
    color: ${({ theme }) => theme.primary};
    font-size: 0.875rem;
  }
`;

export const InputButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 46px;
`;

export const ModalWindow = styled.div`
  padding: 20px 24px 22px 24px;
  box-sizing: border-box;
  position: relative;
  background: ${({ theme }) => theme.modalBackground};
  max-width: 300px;
  width: 100%;
  border-radius: 25px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.modalBackground} inset;
  }

  .modal-link {
    color: ${({ theme }) => theme.modalButtonText};
    margin-top: 22px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    &:disabled {
      color: ${({ theme }) => theme.forgotPassDisabledBtn};
      opacity: 0.38;
    }
  }
`;

export const AndroidModalClose = styled.div`
  text-align: right;
  width: 100%;
`

export const PassIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 11px;
`;