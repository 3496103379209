// @flow

import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";

import Notification from "../components/Notification";
import type { Notification as NotificationType } from "../lib/notifications/types";

const NotificationsList = ({ list, ...props }: { list: Array<NotificationType> }) =>
  <div {...props}>
    {list.length
      ? list.map(item => <Notification notification={item} key={item.notificationid} url={item.url} />)
      : <NotificationEmpty>No Notifications</NotificationEmpty>}
  </div>;

export default NotificationsList;

const NotificationEmpty = styled.div`
  min-height: 36px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 12px;
  padding: 10px 15px 10px 15px;
  background: ${props => (props.isnew ? "#e1f9fd" : "#fff")};
  cursor: pointer;
  text-align: center;
  line-height: 36px;
  height: 36px;
  color: #000;

  &:hover {
    background-color: #f3fdfe;
  }
`;
