// @flow

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "styled-components";

export type Props = {
  id?: string,
  text?: string,
  name: ?string,
  color?: string,
  size?: "small" | "big",
  checked?: boolean,
  children?: any,
  onChange: any => void
};

class InputStyledCheckbox extends PureComponent<Props> {
  static defaultProps = {
    id: "",
    text: "",
    name: "",
    size: "big",
    color: "#17bed0",
    checked: false,
    children: null,
    onChange: () => { }
  };

  render() {
    const { id, text, name, onChange, children, size, color, ...rest } = this.props;

    return (
      <Container size={size} color={color} className="checkbox-checkmark">
        {text ? <TextWrapper className="text-wrapper">{text}</TextWrapper> : null}
        {children}
        <input id={id} type="checkbox" name={name} onChange={onChange} {...rest} />
        <Checkmark data-testid="terms-checkbox" size={size} className="input-checkbox" />
      </Container>
    );
  }
}

export default InputStyledCheckbox;

const Container = styled.label`
  display: block;
  position: relative;
  line-height: ${props => props.size == "small" ? "13px" : "20px"};
  padding-left: ${props => props.size == "small" ? "13px" : "18px"};
  padding-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  input ~ span {
    transition: background 200ms ease-in-out;
    background: #fff;
  }

  &:hover input ~ span {
    background-color: #fafafa;
  }

  input[type="checkbox"]:checked ~ span {
    background-color: ${props => props.color};
    border: ${props => `1px solid ${props.color}`};
    border-radius: 2px;
  }

  input[type="checkbox"]:checked ~ span:after {
    opacity: 1;
  }

  span:after {
    top: ${props => props.size == "small" ? "1px" : "0px"};
    left: ${props => props.size == "small" ? "4px" : "5px"};
    height: ${props => props.size == "small" ? "5px" : "10px"};
    width: ${props => props.size == "small" ? "3px" : "4px"};
    border: solid white;
    border-width: ${props => props.size == "small" ? "0 1px 1px 0" : "0 2px 2px 0"};
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.size == "small" ? "13px" : "18px"};
  height: ${props => props.size == "small" ? "13px" : "18px"};
  border: ${props => props.size == "small" ? "1px solid #b9b9b9" : "2px solid #b9b9b9" };
  border-radius: 2px;
  box-sizing: border-box;

  &:after {
    content: "";
    position: absolute;
    opacity: 0;
  }
`;

const TextWrapper = styled.div`
  display: inline-block;
  margin-left: 12px;
`;

