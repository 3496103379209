// @flow

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Popover from "../components/Popover";
import NotificationsList from "../components/NotificationsList";
import NotificationsPopoverWrap from "../components/NotificationsPopoverWrap";
import NotificationsBell, { NotificationsBellLight, NotificationContainer } from "../components/NotificationsBell";
import NotificationsNew from "../components/NotificationsNew";

import type { NotificationsState } from "../lib/state/reducers/notifications";
import type { Notification } from "../lib/notifications/types";
import type { UserState } from "../lib/state/reducers/user";
import { read, show } from "../lib/state/actions/notifications";
import { isEULocation } from "../lib/utils";

import apiMethod from "../api/apiMethod";

type NotificationsProps = {
  notifications: NotificationsState,
  user: UserState,
  storeSubscription: any,
  readNotifications: () => void,
  showNotifications: () => void
};

class Notifications extends React.Component<NotificationsProps, {}> {
  constructor(props: NotificationsProps) {
    super(props);

    (this: any).onOpen = this.onOpen.bind(this);
  }

  onOpen() {
    console.log("onOpen");
    this.props.showNotifications();

    const { user, notifications } = this.props;
    if (user.logged && notifications.loaded) {
      const lastUnreadNotificationId = getLastUnreadNotificationId(notifications.notifications);
      if (lastUnreadNotificationId) {
        apiMethod(
          "readnotifications",
          { auth: user.token, notificationid: lastUnreadNotificationId },
          () => {
            this.props.showNotifications();
          }
        );
      }
    }
  }

  render() {
    const {
      notifications,
      showNotifications,
      readNotifications,
      storeSubscription,
      europeUI,
      ...restProps
    } = this.props;
    const badge = notifications.loaded
      ? notifications.notifications.filter(n => n.isnew).length
      : 0;

    const NotificationsPopover = (
      <NotificationsPopoverWrap>
        {notifications.loaded ? (
          <NotificationsList list={notifications.showNotifications} />
        ) : (
          <NotificationsLoading>
            <div style={{ lineHeight: "42px", textAlign: "center", fontSize: "12px" }}>
              Loading ...
            </div>
          </NotificationsLoading>
        )}
      </NotificationsPopoverWrap>
    );

    return (
      <Popover
        trigger="click"
        render={NotificationsPopover}
        onOpen={this.onOpen}
        cssPosition="absolute"
        position="bottom"
        align="right"
      >
        { europeUI ? (
          <NotificationContainer>
            <NotificationsBellLight/>
            <NotificationsNew badge={badge} />
          </NotificationContainer>

        ) : (
          <NotificationsBell>
            <NotificationsNew badge={badge} />
          </NotificationsBell>
        )}

      </Popover>
    );
  }
}

export default connect(
  state => ({ notifications: state.notifications, user: state.user }),
  dispatch => ({
    readNotifications: () => dispatch(read()),
    showNotifications: () => dispatch(show())
  })
)(Notifications);

const NotificationsLoading = styled.div`
  height: 42px;
  background: url("/ZCDNURL/img/loading_button.png") no-repeat center center;
`;

function getLastUnreadNotificationId(notifications: Array<Notification>): number | false {
  if (notifications.length) {
    return notifications[0].notificationid;
  } else {
    return false;
  }
}
