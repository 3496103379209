import PropTypes from 'prop-types';
import React, { Component } from "react";
import { AutoSizer, Table, Column } from "react-virtualized";
import styled from "styled-components";
import { hiDPI } from "polished";
import Scrollbar from "../Scrollbar";
import GridSortableHeader from "./SortableHeader";
import DownloadStatsNoStats from "./NoStats";
import { arraySort, rowClassName } from "../../lib/gridUtils";
import "react-virtualized/styles.css";
import "../../styles/virtualized.less";
import browser from "../../lib/browser";

export default class GridDownloadLinkCountryStats extends Component {
  constructor(props) {
    super(props);

    this.state = { list: arraySort(props.list, "views", -1), sortBy: "views" };

    this._sort = this._sort.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    const {
      sortBy: prevSortBy /*, sortDirection: prevSortDirection*/
    } = this.state;

    if (
      nextState.sortBy !==
      prevSortBy /* ||
      nextState.sortDirection !== prevSortDirection*/
    ) {
      const { sortBy /*, sortDirection*/ } = nextState;

      let { list } = this.props;

      if (sortBy) {
        list = arraySort(
          list,
          sortBy,
          -1 /*, sortDirection === "DESC" ? -1 : 1*/
        );
      }
    }
  }

  render() {
    const { list, sortBy /*, sortDirection*/ } = this.state;

    if (!list.length) {
      return (
        <DownloadStatsNoStats>
          This link has no country stats yet. <br />
          Stats are updated once every 15 minutes.
        </DownloadStatsNoStats>
      );
    }

    return (
      <Scrollbar
        style={{
          height: Math.min(30 * (list.length + 1), 120),
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.05)"
        }}
      >
        <div style={{ height: 30 * (list.length + 1) }}>
          <AutoSizer>
            {({height, width}) => (
              <Table
                headerHeight={30}
                width={width}
                height={height}
                rowCount={list.length}
                rowGetter={({ index }) => list[index]}
                rowHeight={30}
                sort={this._sort}
                sortBy={sortBy}
                //sortDirection={sortDirection}
                rowClassName={rowClassName}
              >
                <Column
                  label="Country"
                  dataKey="country"
                  width={130}
                  flexGrow={1}
                  disableSort={true}
                />
                <Column
                  label="Views"
                  dataKey="views"
                  headerClassName="value"
                  className="value"
                  width={55}
                  headerRenderer={props => (
                    <ViewsHeader active={sortBy === "views"} />
                  )}
                />
                <Column
                  label="Traffic"
                  dataKey="traffic"
                  headerClassName="value"
                  className="value"
                  headerRenderer={props => (
                    <TrafficHeader active={sortBy === "traffic"} />
                  )}
                  width={55}
                />
                <Column
                  label="Downloads"
                  dataKey="downloads"
                  headerClassName="value"
                  headerRenderer={props => (
                    <DownloadsHeader active={sortBy === "downloads"} />
                  )}
                  className="value"
                  width={55}
                />
              </Table>
            )
            //</Scrollbar>
            }
          </AutoSizer>
        </div>
      </Scrollbar>
    );
  }

  _sort({ sortBy, sortDirection }) {
    const {
      sortBy: prevSortBy /*, sortDirection: prevSortDirection*/
    } = this.state;

    /*
    // If list was sorted DESC by this column.
    // Rather than switch to ASC, return to "natural" order.
    if (prevSortDirection === sortDirection.DESC) {
      sortBy = null;
      sortDirection = null;
    }
    */

    this.setState({ sortBy /*, sortDirection*/ });
  }
}

const ViewsHeader = styled(GridSortableHeader)`
  background-image: url(${require("../../../root/img/dlink/views.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/views@2x.png")});
  }
`;

const TrafficHeader = styled(GridSortableHeader)`
  background-image: url(${require("../../../root/img/dlink/traffic.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/traffic@2x.png")});
  }
`;

const DownloadsHeader = styled(GridSortableHeader)`
  background-size: 10px 10px;
  background-image: url(${require("../../../root/img/dlink/downloads.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/downloads@2x.png")});
  }
`;
