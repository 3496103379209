import React from "react";
import { Input as WebInput } from "../WebStyles";
import { Input as WindowsInput } from "../WindowsStyles";
import { Input as MacInput } from "../MacStyles";
import { Input as LinuxInput } from "../LinuxStyles";
import { Input as IosInput } from "../iOSStyles";
import InputWithFlyingLabel from "./../InputWithFlyingLabel"
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Input = React.forwardRef(({ os, ...rest }, ref) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <InputWithFlyingLabel {...rest} ref={ref} />
    case DEVICE_OS.iOS:
      return <IosInput {...rest} ref={ref} />
    case DEVICE_OS.Web:
      return <WebInput {...rest} ref={ref} />
    case DEVICE_OS.Win:
      return <WindowsInput {...rest} ref={ref} />
    case DEVICE_OS.MacOSX:
      return <MacInput {...rest} ref={ref} />
    case DEVICE_OS.Linux:
      return <LinuxInput {...rest} ref={ref} />
    default:
      return <WebInput {...rest} ref={ref} />
  }
});

export default Input;
