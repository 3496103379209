export const LOGIN_FLOW_TYPE = 1;
export const REGISTER_FLOW_TYPE = 2;

export const FACEBOOK_LOGIN_METHOD_ID = 1;
export const GOOGLE_LOGIN_METHOD_ID = 2;
export const APPLE_LOGIN_METHOD_ID = 3;
export const EMAIL_LOGIN_METHOD_ID = 4;
export const SETUP_PASS_LOGIN_METHOD_ID = 5;
export const VERIFICATION_CODE_METHOD_ID = 6;

export const CHECK_ACCOUNT_STEP = 1;
export const LOGIN_STEP = 2;
export const REGISTRATION_STEP = 3;
export const LOGIN_TFA_STEP = 4;
export const LOGIN_BUSINESS_GRACE_PERIOD_STEP = 5;

export const LOGIN_OR_SIGNUP_SCREEN = "loginorsignup";
export const SIGNUP_SCREEN = "signupstep";
export const LOGIN_SCREEN = "login";
export const CREATEPASS_SCREEN = "createpass";

export const FORGOT_PASS_MODAL = "forgotpassmodal";
export const SOCIAL_LOGIN_UNAVAILABLE_MODAL = "socialloginunavailablemodal";

export const TFA_NOTIFICATION_FORM_ID = 1;
export const TFA_SMS_FORM_ID = 2;
export const TFA_RECOVERY_FORM_ID = 3;
export const TFA_AUTH_FORM_ID = 4;
