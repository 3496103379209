import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import browser from "../lib/browser";

let GL_Login = {
  token: null,
  expires: null,
  auth_config: {
    url: "https://accounts.google.com/o/oauth2/auth",
    params: {
      client_id:
        "972093246167-v9cg3f1ln0v4bg520qhvk3d30e71qvak.apps.googleusercontent.com",
      redirect_uri: "https://myte.pcloud.com/gllogin.html",
      scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      response_type: "code",
      approval_prompt: "force",
      access_type: "offline",
      prompt: "select_account"
    }
  },
  postTokenAction: null,
  initGetToken: function(redirect_uris, request_id, passpcloud, os, language, stateParams) {
    const displ = browser.mobile ? "touch" : "popup";
    const useHost = location.host;

    if (!stateParams) {
      stateParams = { request_id, os, lang: language }
    }

    let redirect_uri;
    
    if (redirect_uris) {
      redirect_uri = redirect_uris.google;
    } else if (os == DEVICE_OS.Android || os == DEVICE_OS.iOS) {
      redirect_uri = location.origin + location.pathname;
    } else {
      redirect_uri = encodeURIComponent(location.protocol + "//" + useHost + "/gllogin.html");
    }

    if (redirect_uris && os === DEVICE_OS.Web) {
      stateParams.is_extension = 1 ;
    }

    if (stateParams.device) {
      stateParams.device = encodeURIComponent(stateParams.device);
    }

    if (stateParams.deviceid) {
      stateParams.deviceid = encodeURIComponent(stateParams.deviceid);
    }

    if (passpcloud) {
      stateParams.passpcloud = 1;
    }

    const stateString = JSON.stringify(stateParams).replace(/\s/g, '');
    let stateEncoded;

    try {
      stateEncoded = btoa(JSON.stringify(stateParams));
    } catch (error) {}
    
    const state = `&state=${stateEncoded || stateString}`;

    if (location.protocol.indexOf('extension') !== -1 && !redirect_uris) {
      window.open(
        "https://accounts.google.com/o/oauth2/auth?client_id=" +
          "&redirect_uri=" +
          encodeURIComponent('https://my.pcloud.com/glpoll.html') +
          "&hl=" +
          (language || "en") +
          "&scope=" +
          this.auth_config.params.scope +
          "&display=" +
          displ +
          "&response_type=token" +
          state,
        "google-login",
        "width=550,height=450"
      ).close();
    } else {
      const url = "https://accounts.google.com/o/oauth2/auth?client_id=" +
        this.auth_config.params.client_id +
        "&hl=" +
        (language || "en") +
        "&redirect_uri=" +
        redirect_uri +
        "&scope=" +
        this.auth_config.params.scope +
        "&prompt=" +
        this.auth_config.params.prompt +
        "&display=" +
        displ +
        "&response_type=token" + 
        state;

      if (os == DEVICE_OS.Android || os == DEVICE_OS.iOS) {
        location.href = url;
      } else {
        window.open(url, "google-login", "width=550,height=450");
      }
    }
  },
  hasToken: function() {
    return this.token && (1 || this.expires > new Date().getTime());
  },
  setToken: function(token, expires, iopts) {
    var opts = $.extend(
      {},
      {
        afterSet: function() {},
        errorCallback: function(ret) {
          HFN.message(ret.error, "error");
          self.postTokenAction(false, false);
          self.postTokenAction = null;
        }
      },
      iopts
    );

    this.token = token;
    this.expires = new Date().getTime() + (parseInt(expires) || 86400);

    if (this.postTokenAction) {
      this.postTokenAction(token, expires);
      this.postTokenAction = null;
    }
  },
  getToken: function() {
    return this.hasToken() ? this.token : false;
  },
  remToken: function() {
    this.token = null;
    this.expires = null;
  },
  getTokenCallback: function(callback, redirect_uris, request_id, passpcloud, os, language, stateParams) {
    if (!("GLToken" in window)) {
      window.GLLogin = this;
    }

    this.postTokenAction = callback;

    if (!redirect_uris) {
      this.initGetToken(redirect_uris, request_id, passpcloud, os, language, stateParams);
    }
  },
  deletePermissions: function(token) {
    console.log("deletePermissions call")
    return $.ajax({
        url: "https://accounts.google.com/o/oauth2/revoke",
        type: "POST",
        dataType: "json",
        data: {token: token},
        error: function(xhr, status, error) {
          throw new Error(error);
        },
        success: function(data) {
          console.log("success", data);
        }
      });
  }
};

export default GL_Login;
