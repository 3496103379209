// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled, { keyframes } from "styled-components";
import Componentify from "react-componentify";
import { getPromoParam } from "@pcloud/web-utilities/dist/lib/ptr";

import GL_Login from "../../../lib/gl_login"; // DONE
import userinfo from "../../../api/userinfo"; // DONE
import apiMethod from "../../../api/apiMethod"; // DONE
import { DEVICE_OS, SHAREASALE_REFS, EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import * as FormStyle from "../../../components/FormSharedComponents"; // DONE
import DataCenterDropdown from "../DataCenterDropdown"; // DONE
import { boldConverter, linkConverter, linkConverterV2 } from "../../../lib/componentifyConverters"; // DONE
import { setGlobalLocations, setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api"; // DONE
import { __ } from "../../../lib/translate"; // DONE
import { rcookie, setcookie, getHash, getSearch, getDeviceID, validateEmail } from "../../../lib/utils"; // DONE
import { setUserRegionId } from "../../../lib/state/actions/user"; // DONE

import { getCJData } from "../../../../web-shared/cjAffiliate"; // DONE
import Notification, { notify } from "../MultiplatformComponents/Notification"; // DONE
import Heading from "../MultiplatformComponents/Elements/Heading"; // DONE
import SubHeading from "../MultiplatformComponents/Elements/SubHeading"; // DONE
import Footer from "../MultiplatformComponents/Elements/Footer"; // DONE
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink"; // DONE
import Input from "../MultiplatformComponents/Elements/Input"; // DONE
import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "../MultiplatformComponents/Elements/Form"; // DONE
import SigninOptionsWrapper from "../MultiplatformComponents/Elements/SigninOptionsWrapper"; // DONE
import CheckBox from "../MultiplatformComponents/Elements/CheckBox"; // DONE
import Button from "../MultiplatformComponents/Elements/Button"; // DONE
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import InputWrapper from "../MultiplatformComponents/Elements/InputWrapper"; // DONE
import ErrorIconAndroid from "../../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "../MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "../MultiplatformComponents/Elements/ErrorMessage"; // DONE
import LoadingIconSmall from "../../../../root/img/loading-icon(small).svg"; // done

import type { SuccessData } from "../../Business/types"; // DONE
import {sendLoginEvent} from "../../../lib/sendAnalyticsEvent";
import { detectOS } from "../../../../web-shared/utils";

require("../../../styles/forms.less"); // DONE
require("../../../styles/registration.less"); // DONE

type Props = {
  token: string,
  language: string,
  externalApp: string,
  userRegionId: number,
  passpcloud: boolean,
  theme?: string,
  promoCode?: string,
  noDefaultFiles?: boolean,
  onSuccess: SuccessData => void,
  os: number,
  onBackClick: () => void,
  setTFALoginInfo: (string, boolean, string, ?string) => void,
  setVerifyInfo: (string, string) => void,
  setUserRegionId: number => void,
  setShowRegisterVerificationCode: () => void,
  setShowLoginVerificationCode: () => void
};

type State = {
  email: string,
  terms: boolean,
  loading: boolean,
  preloading: boolean,
  needEmail: boolean,
  error: string,
  newUser: boolean,
  tokenError: string
};

class ExternalRegister extends Component<Props, State> {
  static defaultProps = {
    os: 4,
    theme: {},
    noDefaultFiles: false,
    promoCode: "",
    setShowRegisterVerificationCode: () => {},
    setShowLoginVerificationCode: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      terms: false,
      loading: false,
      preloading: true,
      needEmail: false,
      error: "",
      newUser: false,
      tokenError: ""
    };
    (this: any).xhr = [];

    (this: any).onEnter = this.onEnter.bind(this);
    (this: any).onTermsClick = this.onTermsClick.bind(this);
    (this: any).createAccount = this.createAccount.bind(this);
    (this: any).showMessage = this.showMessage.bind(this);
  }

  componentDidMount() {
    this.checkToken();
    document.addEventListener("keydown", this.onEnter);
  }

  componentWillUnmount() {
    (this: any).xhr.forEach(x => "abort" in x && x.abort());
    document.removeEventListener("keydown", this.onEnter);
  }

  onEnter(e: any) {
    if (e.keyCode === 13) {
      this.createAccount();
    }
  }

  onTermsClick() {
    this.setState(({ terms: prevTerms }) => ({
      terms: !prevTerms
    }));
  }

  cannotCreateReason() {
    const { terms, email, needEmail } = this.state;

    if (needEmail && !validateEmail(email)) {
      return __("error_invalid_email", "Enter a valid email to continue.");
    } else if (!terms) {
      return __("error_terms_of_service", "You must accept Terms of Service.");
    }

    return false;
  }

  createAccount(e: SyntheticEvent<HTMLButtonElement>) {
    e.preventDefault();

    const cannotReason = this.cannotCreateReason();

    console.log("reason", cannotReason);

    if (cannotReason) {
      this.showMessage(cannotReason, "error");
    } else {
      this.checkToken();
    }
  }

  getParams() {
    const { token, language, externalApp, noDefaultFiles, promoCode, passpcloud, os, deviceid } = this.props;
    const { email, terms, newUser } = this.state;
    let params = {
      termsaccepted: terms ? "yes" : "no",
      os: os,
      deviceid: deviceid || getDeviceID(),
      language: language,
      osversion: "0.0.0"
    };
    const logOS = detectOS(false, true);

    if (promoCode) {
      params.promocode = promoCode;
    }

    if (passpcloud) {
      params.passpcloud = 1;
    }

    if (externalApp === EXTERNAL_APPS.FACEBOOK) {
      params.fb_access_token = token;
    } else if (externalApp === EXTERNAL_APPS.GOOGLE) {
      params.gl_access_token = token;
    } else if (externalApp === EXTERNAL_APPS.APPLE) {
      params.id_token = token;
    }

    if (email) {
      params.mail = email;
    }

    if (rcookie("invcd")) {
      params.invite = rcookie("invcd");
    }

    if (parseInt(rcookie("ref"))) {
      params.ref = parseInt(rcookie("ref"));
    } else if (parseInt(getHash("ref"))) {
      params.ref = parseInt(getHash("ref"));
    } else if (parseInt(getSearch("ref"))) {
      params.ref = parseInt(getSearch("ref"));
    }

    if (noDefaultFiles) {
      params.nodefaultfiles = 1;
    }

    if (logOS === "ios") {
      params.logos = DEVICE_OS.iOS;
    } else if (logOS === "android") {
      params.logos = DEVICE_OS.Android;
    } else {
      params.logos = DEVICE_OS.Web;
    }

    if (typeof sessionStorage !== "undefined" && sessionStorage.logreferrer) {
      params.logreferrer = sessionStorage.logreferrer;
    } else if (typeof document !== "undefined" && typeof document.cookie !== "undefined" && rcookie("logreferrer")) {
      params.logreferrer = rcookie("logreferrer");
    }

    if (getPromoParam("label")) {
      params.channelid = getPromoParam("channelid");
      params.label = getPromoParam("label");
    }

    return params;
  }

  _onSuccess(method, params, ret) {
    const { userRegionId, onSuccess, externalApp } = this.props;
    const { newUser } = this.state;

    setcookie("ref", 0);
    setcookie("invcd", 0);

    if (typeof params.ref !== "undefined" && SHAREASALE_REFS.some(ref => params.ref == ref)) {
      setcookie("shareasale", ret.userid);
    }

    const appLowerCase = externalApp.toLowerCase();
    let methodInfo = "";
    if (EXTERNAL_APPS.GOOGLE === externalApp) {
      methodInfo = "gl_login_info";
    } else if (EXTERNAL_APPS.FACEBOOK === externalApp) {
      methodInfo = "fb_login_info";
    }

    userinfo(ret.auth, userinfo => {
      if (methodInfo) {
        apiMethod(
          methodInfo,
          { auth: ret.auth },
          ({ firstname, lastname }) => {
            sendLoginEvent(appLowerCase, newUser);
            onSuccess({
              action: newUser ? appLowerCase + "-register" : appLowerCase + "-login",
              userinfo: userinfo,
              firstName: firstname,
              lastName: lastname,
              locationid: userRegionId
            });
          },
          {
            errorCallback: () => {
              sendLoginEvent(appLowerCase, newUser);
              onSuccess({
                action: newUser ? appLowerCase + "-register" : appLowerCase + "-login",
                userinfo: userinfo,
                locationid: userRegionId
              });
            }
          }
        );
      } else {
        try {
          const { name } = JSON.parse(window.localStorage.getItem("apple_user")) || {};
        } catch (error) {
          const name = null;
        }

        sendLoginEvent(appLowerCase, newUser);
        onSuccess({
          action: newUser ? appLowerCase + "-register" : appLowerCase + "-login",
          userinfo: userinfo,
          firstName: name ? name.firstName : "",
          lastName: name ? name.lastName : "",
          locationid: userRegionId
        });
      }
    });
  }

  checkToken() {
    const {
      externalApp,
      onSuccess,
      userRegionId,
      setTFALoginInfo,
      setVerifyInfo,
      setUserRegionId,
      setShowRegisterVerificationCode,
      setShowLoginVerificationCode
    } = this.props;
    const { email } = this.state;
    let method = "fb_login";
    const params = this.getParams();

    if (EXTERNAL_APPS.GOOGLE === externalApp) {
      method = "gl_login";
    } else if (EXTERNAL_APPS.APPLE === externalApp) {
      method = "apple_login";
    }

    const CJData = getCJData();

    if (CJData && CJData.cjtoken) {
      params.cjevent = CJData.cjtoken;

      if (CJData.cjexpiration) {
        params.cjexpiration = CJData.cjexpiration;
      }
    }

    this.setState({ preloading: true, loading: true });
    setGlobalLocationById(userRegionId);
    getApiServerCall();

    this.xhr.push(
      apiMethod(
        method,
        params,
        data => {
          console.log(" check token method ", method, params, data);
          this._onSuccess(method, params, data);
          setcookie("logininfo", externalApp);
          setGlobalLocationById(userRegionId);
          getApiServerCall();
        },
        {
          errorCallback: ret => {
            if (ret.result === 1033) {
              this.setState({
                needEmail: true,
                newUser: true
              });
            } else if (ret.result === 2037) {
              this.setState({
                needEmail: false,
                email: ret.email,
                newUser: true
              });
            } else if (ret.result === 2038 && email) {
              this.setState({ email: "" });
              this.showMessage(ret.error, "error");
            } else if (ret.result === 2038) {
              this.deleteToken();
              let error = "";
              if (externalApp === EXTERNAL_APPS.FACEBOOK) {
                error = "fb_login_mail_exists";
              } else {
                error = "gl_login_mail_exists";
              }
              this.setState({ tokenError: __(error) });
            } else if (ret.result == 2315) {
              this.deleteToken();
              this.setState({ tokenError: __("gl_login_already_associated") });
            } else if (ret.result == 2297) {
              setTFALoginInfo(ret.token, ret.hasdevices, ret.tfatype, externalApp);
            } else if (ret.result == 2306) {
              setVerifyInfo(ret.verifytoken, ret.email);
            } else if (ret.result === 2321) {
              setGlobalLocations([ret.location]);
              setUserRegionId(ret.location.id);
              this.checkToken();
              return;
            } else if (ret.result == 2330) {
              this.deleteToken();
              this.setState({
                tokenError: __(
                  "error_2330",
                  "Access to your account has been temporarily stopped, due to your move to a new data region"
                )
              });
            } else if (ret.result == 2360) {
              console.log("🚀 ~ file: ExternalRegister.js:348 ~ ExternalRegister ~ checkToken ~ ret", ret)
              setShowRegisterVerificationCode(true, ret.email);
              return;
            } else {
              this.deleteToken();
              this.setState({ tokenError: ret.error });
            }

            this.setState({ preloading: false, loading: false });
          }
        }
      )
    );
  }

  deleteToken() {
    const { externalApp, token } = this.props;
    if (EXTERNAL_APPS.GOOGLE === externalApp) {
      GL_Login.deletePermissions(token);
    }
  }

  showMessage(message, type) {
    const { os } = this.props;

    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      this.setState({ error: message });
    } else {
      notify(message, type, { os });
    }
  }

  renderMessage() {
    const { error } = this.state;
    const { os } = this.props;
    const errorMessageIcons = {
      [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24" />,
      [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24" />,
      [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24" />
    };

    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && error) {
      return (
        <ErrorBox os={os}>
          <div className="icon-wrapper">{errorMessageIcons[os]}</div>
          <ErrorMessage className="login-error-message" os={os}>
            {error}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    return <Notification os={os} />;
  }

  renderLoginWith(content: any) {
    const { externalApp, os } = this.props;
    const loginWith = __("login_with", "Login with %app%").replace("%app%", externalApp);

    return (
      <React.Fragment>
        <Heading os={os} className="login-with login-title">
          {loginWith}
        </Heading>
        {content}
      </React.Fragment>
    );
  }

  renderEmail() {
    const { email, error } = this.state;
    const { os } = this.props;

    return (
      <InputWrapper os={os}>
        <Input os={os} type="email" autoComplete="email" disabled readonly className="text disabled" value={email} />
      </InputWrapper>
    );
  }

  renderEmailInput() {
    const { email, error } = this.state;
    const { os } = this.props;

    return (
      <InputWrapper os={os}>
        <Input
          os={os}
          name="email"
          autoComplete="email"
          type="email"
          placeholder={__("Email", "Email")}
          onChange={({ target }) => this.setState({ email: target.value })}
          value={email}
        />
      </InputWrapper>

);
  }

  renderCancelRegistration() {
    const { externalApp, onBackClick, os } = this.props;

    return (
      <Footer os={os} className="external">
        <FormStyle.BlockElement>
          <ButtonLink type="submit" os={os} onClick={onBackClick}>
            {__("cancel_registration", "Cancel %app% registration.", {app: externalApp})}
          </ButtonLink>
        </FormStyle.BlockElement>
        <FormStyle.BlockElement>
          <ButtonLink type="submit" os={os} onClick={onBackClick}>
            {__("back")}
          </ButtonLink>
        </FormStyle.BlockElement>
      </Footer>
    );
  }

  render() {
    const { preloading, loading, needEmail, terms, tokenError } = this.state;
    const { os, language } = this.props;
    const isMobileOrMacOSX = os === DEVICE_OS.iOS || os === DEVICE_OS.Android || os === DEVICE_OS.MacOSX;
    const pcloudTermsKey = isMobileOrMacOSX
      ? __(
          "i_accept_pcloud_terms_mobile",
          "I Accept pCloud's https://www.pcloud.com/policy/terms_conditions/[Terms & Conditions], https://www.pcloud.com/policy/privacy_policy/[Privacy Policy] and https://www.pcloud.com/policy/intellectual_privacy/[Intellectual Property Policy]'"
        )
      : __(
          "i_accept_pcloud_terms",
          "I Accept pCloud's https://www.pcloud.com/terms_and_conditions.html[Terms & Conditions], https://www.pcloud.com/privacy_policy.html[Privacy Policy] and https://www.pcloud.com/int_pr_policy.html[Intellectual Property Policy]"
        );

    if (preloading) {
      const content = <LoadingWrapper><LoadingIconSmall width="24px" height="24px"/></LoadingWrapper>;

      return this.renderLoginWith(content);
    } else if (tokenError) {
      const content = <ErrorTokenMessage>{tokenError}</ErrorTokenMessage>;

      return this.renderLoginWith(content);
    } else {
      return (
        <FormWrapper os={os}>
          <Heading os={os} className="login-title">
            {needEmail
              ? __("complete_registration", "Complete pCloud registration")
              : __("confirm_registration", "Confirm pCloud registration:")}
          </Heading>
          <SubHeading os={os} className="login-subtitle">
            {needEmail
              ? __("provide_valid_email", "You need to provide a valid email to continue integration.")
              : __("confirm_registration_email", "Your pCloud account will be created with the following email:")}
          </SubHeading>
          <Form os={os}>
            {this.renderMessage()}
            <div>
              {needEmail ? this.renderEmailInput() : this.renderEmail()}
              <InputWrapper os={os}>
                <DataCenterDropdown os={os} />
              </InputWrapper>
            </div>
            <SigninOptionsWrapper os={os}>
              <CheckBox
                os={os}
                id="terms"
                name="terms"
                size="small"
                checked={terms}
                onChange={e => this.setState({ terms: e.target.checked })}
              >
                <FormStyle.Terms className="regsitration-terms" onClick={this.onTermsClick}>
                  <Componentify
                    className="terms-text"
                    text={__(pcloudTermsKey)}
                    converters={[boldConverter, linkConverter, linkConverterV2]}
                  />
                </FormStyle.Terms>
              </CheckBox>
            </SigninOptionsWrapper>
            <ButtonWrapper os={os} language={language}>
              <Button type="submit" os={os} loading={loading} active={!loading} onClick={this.createAccount}>
                {__("create_account", "Create Account")}
              </Button>
            </ButtonWrapper>
          </Form>
          {this.renderCancelRegistration()}
        </FormWrapper>
      );
    }
  }
}

export default connect(
  ({ user: { userRegionId } = {} }) => {
    return { userRegionId };
  },
  dispatch =>
    bindActionCreators(
      {
        setUserRegionId
      },
      dispatch
    )
)(ExternalRegister);

const Wrapper = styled.div`
  width: 100%;

  input[type="text"],
  input[type="email"] {
    margin: 0 0 15px 0;
  }

  .read-only {
    font-size: 16px;
    font-weight: bold;
    color: "#000";
    text-align: center;
    padding: 25px 0 0 0;
  }

  .butt {
    width: 100% !important;
  }
`;

const ErrorTokenMessage = styled.div`
  color: #ff4d4d;
  text-align: center;
  line-height: 15px;
  margin: 8px 0 5px 0;
  min-width: initial;
  padding: 0;
  padding: 20px;
`;

const spin = keyframes`
  100% { transform: rotate(360deg) };
`;

const LoadingWrapper = styled.div`
  text-align: center;
  min-width: 280px;
  min-height: 100px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    animation: ${spin} 1.5s linear infinite;
  }
`;