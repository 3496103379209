import React from "react";
import styled from "styled-components";
import { __ } from "../../lib/translate";
import { FormWrap, FormContent, Title, Input, InputWrapper, ButtonWrapper } from "./MainComponents";
import SubmitButton from "../SubmitButton";

export const VerificationCodeStep = ({ value, handleChange, submit, error, loadingState}) => {
  const handleCodeChange = (event) => {
    handleChange(event.target.value);
  }

  const onEnter = (event) => {
    if (event.keyCode == 13 ) {
      submit();
    }
  }

  return (
    <FormWrap>
      <Title error={error}>{error ? error : __("vivacom_code_placeholder")}</Title>
      <FormContent>
        <InputWrapper error={error}>
          <Input onChange={handleCodeChange} type="text" placeholder="XXXX" value={value} align="center" onKeyDown={onEnter} autoFocus/>
        </InputWrapper>
        <ButtonWrapper>
          <SubmitButton
            onClick={submit}
            loading={loadingState}
            style={{ width: "100%" }}
          >
            {__("vivacom_submit")}
          </SubmitButton>
        </ButtonWrapper>
      </FormContent>
    </FormWrap>
  );
}
