export const UPLOAD_STATUS = {
  PENDING: 1,
  COMPLETED: 3,
  FAILED: 4,
  // used for fade out animations
  FADING_ACTIVE_COMPLETED: 5,
  FADING_ACTIVE_FAILED: 6,
  FADING_FAILED_RETRY: 7,
};

export const UPLOAD_TAB = {
  ALL: 0,
  ACTIVE: 1,
  COMPLETED: 2,
  FAILED: 3,
}

export const UPLOAD_OPTIONS = {
  REPLACE: "0",
  DUPLICATE: "1",
  SKIP: "2",
}

export const MAX_CONCURRENT_FILE_UPLOADS = 2;

export const MAX_CONCURRENT_FOLDER_UPLOADS = 10;

export const FAILED_BADGE_COLOR = "#F73C3C";

export const MAX_FAILED_UPLOADS_RETRIES = 3;

export const FILE_ITEM_SIZE = 56;

export const FILE_LIST_HEIGHT = 280;

export const FILE_LIST_HEIGHT_WITH_FAILED_UPLOADS = FILE_LIST_HEIGHT - FILE_ITEM_SIZE;

export const FILE_LIST_WIDTH = 400;

export const FILE_LIST_HEIGHT_FAILED_FOLDER = 141;

export const FILE_HEIGHT_FAILED_FOLDER = 55;

export const FILE_LIST_WIDTH_FAILED_FOLDER = 420;

// 4 mb
export const FILE_SIZE_FOR_CHUNKS = 1024 * 1024 * 4

export const CRYPTOLOCKED_PAGE = "cryptolocked";

export const FILEMANAGER_PAGE = "filemanager";

export const MINIMIZED_HEIGHT = 42;

export const errorsCodeForShown = [
  2000,
  2001,
  2003,
  2005,
  2008,
  2041,
  2075,
  "link-not-accessible",
  4001,
];

export const DEFAULT_PASTE_ITEM_NAME = 'image.png';