import React from "react";
import { Heading as WebHeading } from "../WebStyles";
import { Heading as WindowsHeading } from "../WindowsStyles";
import { Heading as MacHeading } from "../MacStyles";
import { Heading as LinuxHeading } from "../LinuxStyles";
import { Heading as IosHeading } from "../iOSStyles";
import { Heading as AndroidHeading } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Heading = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return  <AndroidHeading {...rest}/>
    case DEVICE_OS.iOS:
      return  <IosHeading {...rest}/>
    case DEVICE_OS.Web:
      return <WebHeading {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsHeading {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacHeading {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxHeading {...rest}/>
    default:
      return <WebHeading {...rest}/>
  }
};

export default Heading;
