import apiMethod from "../api/apiMethod";
import { v4 as uuidv4 } from 'uuid';
import { setTracking } from "../lib/tracking"

const getStoredAnalyticsParams = () => {
  return [ ...JSON.parse(localStorage.analyticsData) ];
}

export const sendAnalyticsEvent = (params, data) => {
	const etime = Math.round(new Date().getTime() / 1000);
  const _params = { ...params, etime };

	if (data) {
    let keys = [];

    Object.keys(data).map((keyName) => {
      const val = data[keyName];
      _params["key" + keyName] = val;
      keys.push(keyName);
    });

	  if (keys.length) {
      _params.keys = keys.join(',');
    }
  }

  const updateLocalStorageData = ({ result }) => {
    let localStorageParams = result == 0 ? [] : [{ ..._params, retries: 1, id: uuidv4() }];

    if (localStorage.analyticsData) {
      localStorageParams = localStorageParams.concat(getStoredAnalyticsParams());
    }

    if (localStorageParams.length) {
      localStorage.setItem('analyticsData', JSON.stringify(localStorageParams));
    } else {
      localStorage.removeItem('analyticsData');
    }
  }

  apiMethod("lae", _params, updateLocalStorageData, {
    errorCallback: updateLocalStorageData
  });
}

export const sendLoginEvent = (loggedBy, newUser) => {
  const type = newUser ? 'sign_up' : 'login';
  const provider = loggedBy.charAt(0).toUpperCase() + loggedBy.slice(1);
  setTracking({ initialCall: false, forceTrack: true });

  if (typeof ga4 == 'function') {
    ga4({
      eventname: type,
      authentication_method: provider,
      method: provider
    });
  }
}

export const sendAnalyticsEventFromStorage = () => {
  let localStorageParams = [];
  let promises = [];
  const deleteParamsIds = {};

  if (localStorage.analyticsData) {
    localStorageParams = getStoredAnalyticsParams();
  }

  const updateLocalStorageData = () => {
    if (localStorage.analyticsData) {
      localStorageParams = getStoredAnalyticsParams();
    }

    if (Object.keys(deleteParamsIds).length === localStorageParams.length) {
      localStorage.removeItem('analyticsData');
    } else {
      let newLocalStorageParams = localStorageParams
        .filter(params => !deleteParamsIds[params.id])
        .map(({ retries, ...params }) => {
          return { retries: ++retries, ...params };
        })
        
      localStorage.setItem('analyticsData', JSON.stringify(newLocalStorageParams))
    }
  }

  if (localStorageParams.length) {
    promises = localStorageParams.map(({ retries, id, ...params }) => {
      const onSuccess = () => {
        deleteParamsIds[id] = 1;
      }

      const onError = () => {
        if (retries >= 3) {
          deleteParamsIds[id] = 1;
        }
      }
  
      return new Promise((resolve, reject) => {
        apiMethod(
          "lae",
          params,
          data => {
            onSuccess();
            resolve(data);
          }, {
            errorCallback: data => {
              reject(data);
            }
          }
        );
      }).catch(error => {
        onError();
      });
    })
  }

  Promise.all(promises).then(updateLocalStorageData, updateLocalStorageData);
}