export function userInitials({ firstname, lastname, email }) {
  if (firstname && lastname) {
    return firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
  }

  return emailInitials(email);
}

export const getNames = user =>
  user.account
    ? user.account.firstname + " " + user.account.lastname
    : user.firstname + " " + user.lastname;

export function emailInitials(email) {
  const mailPart = email.substr(0, email.indexOf("@"));
  let chars = [mailPart.charAt(0)];

  if (mailPart.search(/[\.\-\_]{1}/) !== -1) {
    chars.push(mailPart.substr(mailPart.search(/[\.\-\_]{1}/) + 1, 1));
  }

  return chars.map(c => c.toUpperCase()).join("");
}
