// @flow

import * as React from "react";
import ReactDOM from "react-dom";

type PortalProps = {
  children: any
};

export default class Portal extends React.Component<PortalProps> {
  node: ?Node;

  constructor(props: PortalProps) {
    super(props);
  }

  componentDidMount() {
    this.openPortal(this.props);
  }

  componentWillReceiveProps(nextProps: PortalProps) {
    this.openPortal(nextProps);
  }

  componentWillUnmount() {
    this.closePortal();
  }

  openPortal(props: PortalProps) {
    if (!this.node) {
      this.node = document.createElement("div");

      if (document.body) {
        document.body.appendChild(this.node);
      }
    }

    console.log(this);
    console.log(props.children);
    console.log(this.node);

    ReactDOM.unstable_renderSubtreeIntoContainer(this, props.children, this.node);
  }

  closePortal() {
    if (this.node) {
      ReactDOM.unmountComponentAtNode(this.node);
    }

    if (document.body && this.node) {
      document.body.removeChild(this.node);
    }

    this.node = null;
  }

  render() {
    return null;
  }
}
