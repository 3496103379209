import PropTypes from 'prop-types';
import React, { createElement } from "react";
import { __ } from '../../lib/translate';
import { obLength } from "../../lib/utils";

// <TString id="my_files" text="My Files" />
// <TString id="my_files">My Files</TString>
// <TString id="my_files" text="My %personal% Files" replace={{ personal: 'Лични' }} />

/**
 * return: React
 */
const TString = ({ id, tagName, replace, text, children, ...props }) => {
  props["data-translate"] = id;

  return createElement(tagName, props, __(id, text || children, replace));
};

TString.propTypes = {
  id: PropTypes.string.isRequired,
  replace: PropTypes.object,
  children: PropTypes.string,
  text: PropTypes.string
};

TString.defaultProps = {
  replace: {},
  tagName: "span"
};

export default TString;
