export function strFit (str, length = 50) {
  if (str.length <= length) {
    return str;
  }

  length -= 3;
  let head = Math.floor(length * .75);
  let tail = length - head;

  return `${str.substring(0, head)} ... ${str.substring(str.length - tail)}`;
};

export function strFitWidthPx(str, maxWidth = 100, font = '14px Roboto') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;

  const measureTextWidth = (text) => {
    return context.measureText(text).width;
  };

  if (measureTextWidth(str) <= maxWidth) {
    return str;
  }

  const ellipsis = '...';
  const ellipsisWidth = measureTextWidth(ellipsis);
  
  const availableWidth = maxWidth - ellipsisWidth;

  const headWidth = Math.floor(availableWidth * 0.85);
  const tailWidth = availableWidth - headWidth;

  let headLength = 0;
  for (let i = 1; i <= str.length; i++) {
    if (measureTextWidth(str.substring(0, i)) > headWidth) {
      break;
    }
    headLength = i;
  }

  let tailLength = 0;
  for (let i = 1; i <= str.length; i++) {
    if (measureTextWidth(str.substring(str.length - i)) > tailWidth) {
      break;
    }
    tailLength = i;
  }

  return `${str.substring(0, headLength)}${ellipsis}${str.substring(str.length - tailLength)}`;
}

export const preloadImage = url => {
  const img = new Image();
  img.src = url;
};

export const getProperThumbnailSize = (num) => {
  if (num % 4 || num % 5) {
    do {
      num--;
    } while ( num % 4 || num % 5 )
  }
  return Math.max(Math.min(num, 2048), 16);
}

export const getBestVideo = variants => {
  let n = 0;
  let k = -1;
  let max = 0;
  for (; n < variants.length; ++n) {
    if (max < variants[n].videobitrate) {
      max = variants[n].videobitrate;
      k = n;
    }
  }

  if (k == -1) {
    return null;
  }

  return variants[k];
};

export const getLowestVideo = variants => {
  let n = 0;
  let k = -1;
  let max = -1;
  for (; n < variants.length; ++n) {
    if (max > variants[n].videobitrate || max == -1) {
      max = variants[n].videobitrate;
      k = n;
    }
  }

  if (k == -1) {
    return null;
  }

  return variants[k];
};

const getVideoUrlData = (variant) => {
  return {
    isoriginal: variant.isoriginal, // Optional param
    bitrate: variant.videobitrate * 1000, // kbps to bps.
    src: HFN.prepUrl(variant),
    // !!!Important to not set type!!! Otherwise onError won't be triggered when link is expired.
    // type: "video/mp4",
    width: variant.width || 0,
    height: variant.height || 0,
    duration: variant.duration
  };
};

export const getVideoUrlsAndMaxVideoSize = (videoVariants, allowOriginalVideoVariant = true) => {
  let additionalVideoVariants = [];
  let includeOriginal = allowOriginalVideoVariant;
  let originalIsBroken = false;

  const videoData = {
    videoUrls: [],
    videoMaxWidth: 0,
    videoMaxHeight: 0
  };

  //if (videoVariants.length <= 1 || additionalVideoVariants.length == 0) {
  // Add original as extra option, but it won't be played except manually selected by the user.
  const originalVideoVariant = videoVariants.find(({ isoriginal }) => isoriginal);

  // Check if the file is not a broken video.
  if (
      typeof originalVideoVariant.duration !== "undefined" && Number(originalVideoVariant.duration) <= 0 && 
      typeof originalVideoVariant.videobitrate !== "undefined" && Number(originalVideoVariant.videobitrate) <= 0
  ) {
    includeOriginal = false;
    originalIsBroken = true;
  }

  videoData.videoMaxWidth = videoData.videoMaxWidth < originalVideoVariant.width ? originalVideoVariant.width : videoData.videoMaxWidth;
  videoData.videoMaxHeight = videoData.videoMaxHeight < originalVideoVariant.height ? originalVideoVariant.height : videoData.videoMaxHeight;
  //}

  if (videoVariants.length > 1) {
    additionalVideoVariants = videoVariants.filter(
      variant => variant.videocodec == "h264" && fileext(variant.path) == "mp4" && !variant.rotate && !variant.isoriginal
    );

    if (additionalVideoVariants.length > 0) {
      const lowestVideoVariant = getLowestVideo(additionalVideoVariants);
      // lowestVideoVariant.path = "/dpZYMDSQeZNw6953ZSaNbZ7Z3dyVkkZQ5ZZbFkZZhxDa3CEgxnLeCYx8YaVpoSgaC5qV/Screen%20Recording%202024-06-07%20at%2014.55.36.mp4";
      if (originalVideoVariant.height == lowestVideoVariant.height) {
        // Remove the original when we have transcoded video with the same resolution,
        // because sometimes the original video cannot play.
        includeOriginal = false;
      }

      videoData.videoUrls.push(getVideoUrlData(lowestVideoVariant));

      videoData.videoMaxWidth = videoData.videoMaxWidth < lowestVideoVariant.width ? lowestVideoVariant.width : videoData.videoMaxWidth;
      videoData.videoMaxHeight = videoData.videoMaxHeight < lowestVideoVariant.height ? lowestVideoVariant.height : videoData.videoMaxHeight;
    }

    if (!$.browser.mobile && additionalVideoVariants.length > 1) {
      const bestVideoVariant = getBestVideo(additionalVideoVariants);

      if (originalVideoVariant.height == bestVideoVariant.height) {
        // Remove the original when we have transcoded video with the same resolution,
        // because sometimes the original video cannot play.
        includeOriginal = false;
      }

      videoData.videoUrls.push(getVideoUrlData(bestVideoVariant));

      videoData.videoMaxWidth = videoData.videoMaxWidth < bestVideoVariant.width ? bestVideoVariant.width : videoData.videoMaxWidth;
      videoData.videoMaxHeight = videoData.videoMaxHeight < bestVideoVariant.height ? bestVideoVariant.height : videoData.videoMaxHeight;
    }
  }

  if (includeOriginal) {
    videoData.videoUrls.push(getVideoUrlData(originalVideoVariant));
  }

  // Fallback to original, even if allowOriginalVideoVariant is false.
  if (!originalIsBroken && videoData.videoUrls.length === 0) {
    videoData.videoUrls.push(getVideoUrlData(originalVideoVariant));
  }

  return videoData;
}