// @flow
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Componentify from "react-componentify";
import { getPromoParam } from "@pcloud/web-utilities/dist/lib/ptr";

import GL_Login from "../../../../lib/gl_login";
import userinfo from "../../../../api/userinfo";
import apiMethod from "../../../../api/apiMethod";
import { DEVICE_OS, SHAREASALE_REFS, EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants";
import * as FormStyle from "../../../../components/FormSharedComponents";
import DataCenterDropdown from "../../DataCenterDropdown";
import { boldConverter, linkConverter, linkConverterV2 } from "../../../../lib/componentifyConverters";
import { setGlobalLocations, setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api";
import { __ } from "../../../../lib/translate";
import { rcookie, setcookie, getHash, getSearch, getDeviceID, validateEmail, hrefSearch, isValidUrl } from "../../../../lib/utils";
import { setUserRegionId } from "../../../../lib/state/actions/user";

import { getCJData } from "../../../../../web-shared/cjAffiliate";
import { notify } from "../../MultiplatformComponents/Notification";
import Heading from "../../MultiplatformComponents/Elements/Heading";
import SubHeading from "../../MultiplatformComponents/Elements/SubHeading";
import Footer from "../../MultiplatformComponents/Elements/Footer";
import ButtonLink from "../../MultiplatformComponents/Elements/ButtonLink";
import Input from "../../MultiplatformComponents/Elements/Input";
import FormWrapper from "../../MultiplatformComponents/Elements/FormWrapper";
import Form from "../../MultiplatformComponents/Elements/Form";
import SigninOptionsWrapper from "../../MultiplatformComponents/Elements/SigninOptionsWrapper";
import CheckBox from "../../MultiplatformComponents/Elements/CheckBox";
import Button from "../../MultiplatformComponents/Elements/Button";
import ButtonWrapper from "../../MultiplatformComponents/Elements/ButtonWrapper";
import InputWrapper from "../../MultiplatformComponents/Elements/InputWrapper";

import type { SuccessData } from "../../../Business/types";
import Message from "../../AuthenticationStepsUI/Message";
import Loader from "../../../../components/Loader";
import Wrapper from "../../MultiplatformComponents/Elements/Wrapper";
import {sendLoginEvent} from "../../../../lib/sendAnalyticsEvent";
import PcloudLogo from "../../../../../web-shared/images/svg/pcloud-gradient-logo.svg"
import { detectOS } from "../../../../../web-shared/utils";

require("../../../../styles/forms.less");
require("../../../../styles/registration.less");

const ExternalRegister = ({
  os = 4,
  noDefaultFiles = false,
  promoCode = "",
  token,
  language,
  externalApp,
  passpcloud,
  setShowRegisterVerificationCode = () => {},
  onSuccess,
  userRegionId,
  setTFALoginInfo,
  setVerifyInfo,
  setUserRegionId = () => {},
  formLoading,
  setFormLoading,
  onBackClick,
  osversion,
  appversion,
  appversioncode,
  device,
  deviceid,
  clientid
}) => {
  const isMobileOrMacOSX = os === DEVICE_OS.iOS || os === DEVICE_OS.Android || os === DEVICE_OS.MacOSX;
  const pcloudTermsKey = isMobileOrMacOSX
  ? __(
      "i_accept_pcloud_terms_mobile",
      "I Accept pCloud's https://www.pcloud.com/policy/terms_conditions/[Terms & Conditions], https://www.pcloud.com/policy/privacy_policy/[Privacy Policy] and https://www.pcloud.com/policy/intellectual_privacy/[Intellectual Property Policy]'"
    )
  : __(
      "i_accept_pcloud_terms",
      "I Accept pCloud's https://www.pcloud.com/terms_and_conditions.html[Terms & Conditions], https://www.pcloud.com/privacy_policy.html[Privacy Policy] and https://www.pcloud.com/int_pr_policy.html[Intellectual Property Policy]"
    );
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preloading, setPreloading] = useState(true);
  const [needEmail, setNeedEmail] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const xhr = useRef([]);

  useEffect(() => {
    if (externalApp) {
      checkToken();
    }
  }, [externalApp])

  const onTermsClick = () => {
    setTerms(!terms);
  }

  const cannotCreateReason = () => {
    if (needEmail && !validateEmail(email)) {
      return __("error_invalid_email", "Enter a valid email to continue.");
    } else if (!terms) {
      return __("error_terms_of_service", "You must accept Terms of Service.");
    }

    return false;
  }

  const createAccount = e => {
    e.preventDefault();
    const cannotReason = cannotCreateReason();

    if (cannotReason) {
      showMessage(cannotReason, "error");
    } else {
      checkToken();
    }
  }

  const getParams = () => {
    let params = {
      termsaccepted: terms ? "yes" : "no",
      os: os,
      deviceid: deviceid || getDeviceID(),
      language: language,
      osversion: "0.0.0"
    };
    const logOS = detectOS(false, true);

    if (promoCode) {
      params.promocode = promoCode;
    }

    if (passpcloud) {
      params.passpcloud = 1;
    }

    if (externalApp === EXTERNAL_APPS.FACEBOOK) {
      params.fb_access_token = token;
    } else if (externalApp === EXTERNAL_APPS.GOOGLE) {
      params.gl_access_token = token;
    } else if (externalApp === EXTERNAL_APPS.APPLE) {
      params.id_token = token;
    }

    if (email) {
      params.mail = email;
    }

    if (rcookie("invcd")) {
      params.invite = rcookie("invcd");
    }

    if (parseInt(rcookie("ref"))) {
      params.ref = parseInt(rcookie("ref"));
    } else if (parseInt(getHash("ref"))) {
      params.ref = parseInt(getHash("ref"));
    } else if (parseInt(getSearch("ref"))) {
      params.ref = parseInt(getSearch("ref"));
    }

    if (noDefaultFiles) {
      params.nodefaultfiles = 1;
    }

    if (logOS === "ios") {
      params.logos = DEVICE_OS.iOS;
    } else if (logOS === "android") {
      params.logos = DEVICE_OS.Android;
    } else {
      params.logos = DEVICE_OS.Web;
    }

    if (typeof sessionStorage !== "undefined" && sessionStorage.logreferrer) {
      params.logreferrer = sessionStorage.logreferrer;
    } else if (typeof document !== "undefined" && typeof document.cookie !== "undefined" && rcookie("logreferrer")) {
      params.logreferrer = rcookie("logreferrer");
    }

    if (getPromoParam("label")) {
      params.channelid = getPromoParam("channelid");
      params.label = getPromoParam("label");
    }

    if (os !== DEVICE_OS.Web) {
      if (osversion) {
        params.osversion = osversion;
      }
  
      if (appversion) {
        params.appversion = appversion;
      }
  
      if (appversioncode) {
        params.appversioncode = appversioncode;
      }
  
      if (device) {
        params.device = device;
      }
  
      if (clientid) {
        params.clientid = clientid;
      }
    }

    return params;
  }

  const _onSuccess = (method, params, ret, userLocationIDByApi) => {
    setcookie("ref", 0);
    setcookie("invcd", 0);

    if (typeof params.ref !== "undefined" && SHAREASALE_REFS.some(ref => params.ref == ref)) {
      setcookie("shareasale", ret.userid);
    }

    const appLowerCase = externalApp.toLowerCase();
    let methodInfo = "";

    if (EXTERNAL_APPS.GOOGLE === externalApp) {
      methodInfo = "gl_login_info";
    } else if (EXTERNAL_APPS.FACEBOOK === externalApp) {
      methodInfo = "fb_login_info";
    }

    userinfo(ret.auth, userinfo => {
      if (methodInfo) {
        apiMethod(
          methodInfo,
          { auth: ret.auth },
          ({ firstname, lastname }) => {
            sendLoginEvent(appLowerCase, newUser);
            onSuccess({
              action: newUser ? appLowerCase + "-register" : appLowerCase + "-login",
              userinfo: userinfo,
              firstName: firstname,
              lastName: lastname,
              locationid: userLocationIDByApi || userRegionId
            });
          },
          {
            errorCallback: () => {
              sendLoginEvent(appLowerCase, newUser);
              onSuccess({
                action: newUser ? appLowerCase + "-register" : appLowerCase + "-login",
                userinfo: userinfo,
                locationid: userLocationIDByApi || userRegionId
              });
            }
          }
        );
      } else {
        try {
          const { name } = JSON.parse(window.localStorage.getItem("apple_user") || getSearch("user")) || {};
        } catch (error) {
          const name = null;
        }

        sendLoginEvent(appLowerCase, newUser);
        onSuccess({
          action: newUser ? appLowerCase + "-register" : appLowerCase + "-login",
          userinfo: userinfo,
          firstName: name ? name.firstName : "",
          lastName: name ? name.lastName : "",
          locationid: userLocationIDByApi || userRegionId
        });
      }
    });
  }

  const checkToken = (userLocationIDByApi) => {
    let method;
    const params = getParams();

    if (EXTERNAL_APPS.GOOGLE === externalApp) {
      method = "gl_login";
    } else if (EXTERNAL_APPS.APPLE === externalApp) {
      method = "apple_login";
    } else if (EXTERNAL_APPS.FACEBOOK === externalApp) {
      method = "fb_login";
    }

    const CJData = getCJData();

    if (CJData && CJData.cjtoken) {
      params.cjevent = CJData.cjtoken;

      if (CJData.cjexpiration) {
        params.cjexpiration = CJData.cjexpiration;
      }
    }

    setPreloading(true);
    setLoading(true);
    setGlobalLocationById(userLocationIDByApi || userRegionId);
    getApiServerCall();

    xhr.current.push(
      apiMethod(
        method,
        params,
        data => {
          const redirecturl = hrefSearch("redirecturl");

          _onSuccess(method, params, data, userLocationIDByApi);
          setcookie("logininfo", externalApp);
          setGlobalLocationById(userLocationIDByApi || userRegionId);
          getApiServerCall();

          if (redirecturl && isValidUrl(redirecturl)) {
            window.location = redirecturl;
          }
        },
        {
          errorCallback: ret => {
            if (ret.result === 1033) {
              setNeedEmail(true);
              setEmail("");
              setNewUser(true);
            } else if (ret.result === 2037) {
              setNeedEmail(false);
              setEmail(ret.email);
              setNewUser(true);
            } else if (ret.result === 2038 && email) {
              setEmail("");
              showMessage(__("error_2038"), "error");
            } else if (ret.result === 2038) {
              deleteToken();
              let error = "";
              if (externalApp === EXTERNAL_APPS.FACEBOOK) {
                error = "fb_login_mail_exists";
              } else {
                error = "gl_login_mail_exists";
              }
              showMessage(__(error), "error")
            } else if (ret.result == 2315) {
              deleteToken();
              showMessage(__("gl_login_already_associated"), "error");
            } else if (ret.result == 2297) {
              setTFALoginInfo(ret.token, ret.hasdevices, ret.tfatype, externalApp);
            } else if (ret.result == 2306) {
              setVerifyInfo(ret.verifytoken, ret.email);
            } else if (ret.result === 2321) {
              setGlobalLocations([ret.location]);
              setUserRegionId(ret.location.id);
              checkToken(ret.location.id);
              return;
            } else if (ret.result == 2330) {
              deleteToken();
              showMessage(__(
                "error_2330",
                "Access to your account has been temporarily stopped, due to your move to a new data region"
              ), "error");
            } else if (ret.result == 2360) {
              console.log("🚀 ~ file: ExternalRegister.js:348 ~ ExternalRegister ~ checkToken ~ ret", ret)
              setShowRegisterVerificationCode(true, ret.email);
              return;
            } else {
              deleteToken();
              showMessage(ret.error, "error");
            }
            setPreloading(false);
            setLoading(false);
          },
          showErrorMessage: false,
        }
      )
    );
  }

  const deleteToken = () => {
    if (EXTERNAL_APPS.GOOGLE === externalApp) {
      GL_Login.deletePermissions(token);
    }
  }

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message)
    } else {
      notify(message, type, { os });
    }
  }

  const renderLoginWith = content => {
    const loginWith = __("login_with", "Login with %app%").replace("%app%", externalApp);

    return (
      <React.Fragment>
        <Heading os={os} className="login-with login-title">
          {loginWith}
        </Heading>
        {content}
      </React.Fragment>
    );
  }

  const renderEmail = () => (
    <InputWrapper os={os}>
      <Input os={os} type="email" placeholder={__("Email", "Email")} autoComplete="email" disabled readonly className="disabled" value={email} />
    </InputWrapper>
  );

  const renderEmailInput = () =>  (
    <InputWrapper os={os}>
      <Input
        os={os}
        name="email"
        autoComplete="email"
        type="email"
        placeholder={__("Email", "Email")}
        onChange={({ target }) => setEmail(target.value)}
        value={email}
      />
    </InputWrapper>
  );

  const renderCancelRegistration = () => (
    <Footer os={os} className="external">
      <FormStyle.BlockElement>
        <ButtonLink type="submit" os={os} onClick={onBackClick}>
          {__("cancel_registration", "Cancel %app% registration.", {app: externalApp})}
        </ButtonLink>
      </FormStyle.BlockElement>
    </Footer>
  );


  return (
    <React.Fragment>
      <Loader os={os} show={preloading}/>
      <Wrapper os={os}>
        <FormWrapper os={os}>
          {os !== DEVICE_OS.Web &&
            <LogoWrapper>
              <PcloudLogo />
            </LogoWrapper>
          }
          <Heading os={os} className="login-title">
            {needEmail
              ? __("complete_registration", "Complete pCloud registration")
              : __("confirm_registration", "Confirm pCloud registration:")}
          </Heading>
          <SubHeading os={os} className="login-subtitle">
            {needEmail
              ? __("provide_valid_email", "You need to provide a valid email to continue integration.")
              : __("confirm_registration_email", "Your pCloud account will be created with the following email:")}
          </SubHeading>
          <Form os={os} onSubmit={createAccount}>
            <Message error={error} os={os}/>
            <div>
              {needEmail ? renderEmailInput() : renderEmail()}
              <InputWrapper os={os}>
                <DataCenterDropdown os={os} />
              </InputWrapper>
            </div>
            <SigninOptionsWrapper os={os}>
              <CheckBox
                os={os}
                id="terms"
                name="terms"
                size="small"
                checked={terms}
                onChange={e => setTerms(e.target.checked)}
              >
                <FormStyle.Terms className="regsitration-terms" onClick={onTermsClick}>
                  <Componentify
                    className="terms-text"
                    text={__(pcloudTermsKey)}
                    converters={[boldConverter, linkConverter, linkConverterV2]}
                  />
                </FormStyle.Terms>
              </CheckBox>
            </SigninOptionsWrapper>
            <ButtonWrapper os={os}>
              <Button type="submit" os={os} loading={loading} active={!loading} onClick={createAccount}>
                {__("create_account", "Create Account")}
              </Button>
            </ButtonWrapper>
          </Form>
          {renderCancelRegistration()}
        </FormWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

export default connect(
  ({ user: { userRegionId } = {} }) => {
    return { userRegionId };
  },
  dispatch =>
    bindActionCreators(
      {
        setUserRegionId
      },
      dispatch
    )
)(ExternalRegister);

const ErrorTokenMessage = styled.div`
  color: #ff4d4d;
  text-align: center;
  line-height: 15px;
  margin: 8px 0 5px 0;
  min-width: initial;
  padding: 0;
  padding: 20px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
