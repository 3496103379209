import apiMethod from "./apiMethod";

const loadShareContacts = (auth, onSuccess, opts, onlyBusiness = false) => {
  let wait = [];
  let contacts = [];
  let activeUserCount = 0;
  let bContacts = [];

  if (!onlyBusiness) {
    wait.push(
      apiMethod(
        "contactlist",
        { auth: auth },
        ({ contacts: contactsList }) => {
          contacts = contacts.concat(HFN.filterMailContacts(contactsList));
        },
        opts
      )
    );
  }

  if (HFN.config.isBusiness()) {
    wait.push(
      apiMethod(
        "account_users",
        { auth: auth, withavatars: 1 },
        ({ users }) => {
          for (var n = 0; n < users.length; ++n)
            if (users[n].active || users[n].frozen) {
              contacts.push({
                name: users[n].firstname + " " + users[n].lastname,
                value: users[n].id,
                source: 1001, // business user
                userobj: users[n]
              });

              bContacts.push(users[n].email);

              activeUserCount++;
            }
        },
      opts
      )
    );

    wait.push(
      apiMethod(
        "account_teams",
        { auth: auth, showlinks: 1, showeveryone: 1 },
        ({ teams }) => {
          console.log("got the teams", teams);

          for (var n = 0; n < teams.length; ++n) {
            if (teams[n].active) {
              contacts.push({
                name: teams[n].name,
                value: teams[n].id,
                source: 1002, // business team
                teamobj: teams[n]
              });
            }
          }
        },
        opts
      )
    );
  }

  $.when.apply($, wait).then(function() {
    for (var n = 0; n < contacts.length; ++n) {
      if (contacts[n].source == 1002 && contacts[n].value == 0) {
        contacts[n].teamobj.membersCnt = activeUserCount;
        break;
      }
    }

    for (var n = 0; n < bContacts.length; ++n) {
      for (var i = 0; i < contacts.length; ++i) {
        if (contacts[i].value == bContacts[n]) {
          console.log("splicing", contacts[i].value);
          contacts.splice(i, 1);
          break;
        }
      }
    }

    console.log("!!!!!!!!!!!", contacts);

    onSuccess(contacts);
  });
};

export default loadShareContacts;
