// @flow
// WARNING: Do not use HFN in this component

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import register from "../../../api/register";
import { setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api";
import { __ } from "../../../lib/translate";
import { setcookie, loadScript } from "../../../lib/utils";
import { errorKeys } from "../../../lib/errors";

import SubmitButton from "../../../components/SubmitButton";
import ButtonsWrapper from "../SocialLogins/ButtonsWrapper";
import ExternalRegister from "../SocialLogins/ExternalRegister";
import { boldConverter, linkConverter, linkConverterV2 } from "../../../lib/componentifyConverters";
import TwoFactorAuthLogin from "../TwoFactorAuthLogin";
import InputPassword from "../../../components/InputPassword";
import InputText from "../../../components/InputText";
import InputEmail from "../../../components/InputEmail";
import { RestrictedAccessWarning, RestrictedAccessWarningModal } from "../RestrictedAccessWarning";
import * as Form from "../../../components/FormSharedComponents";
import { EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import DataCenterDropdown from "../DataCenterDropdown";
import InputStyledCheckbox from "../../../components/InputStyledCheckbox";

import type { TooltipType, LocationType } from "../../../types/dataregion";
import type { Userinfo } from "../../../types/user";
import RegisterVerificationCode from "../RegisterVerificationCode";
import LoginVerificationCode from "../LoginVerificationCode";
import LoginCreatePassword from "../LoginCreatePassword";
import SocialLoginUnavailableModal from "../SocialLogins/SocialLoginUnavailableModal";
import { apiMethod } from "../../../api";

type SuccessData = {
  action: string,
  userinfo: Userinfo,
  firstName?: string,
  lastName?: string
};

type Props = {
  title: string,
  email?: string,
  language: string,
  userRegionId: number,
  noDefaultFiles?: boolean,
  formWidth?: string,
  shouldRednerNames?: boolean,
  submitButtonText?: string,
  initLoginRegModal?: (string, () => void, any) => void,
  tooltip?: TooltipType,
  locations?: Array<LocationType>,
  onSuccess: SuccessData => number
};

type State = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  terms: boolean,
  error: string,
  externalToken: string,
  externalApp: string,
  loading: boolean,
  showRestrictionWarning: boolean,
  shouldRednerNames: boolean,
  showTFALoginForm: boolean,
  hasdevices: boolean,
  tfatype: string,
  logininfo: string,
  token: string,
  verifyToken: string,
  isLoginModal: boolean,
  showPassword: boolean
};

class RegistrationForm extends PureComponent<Props, State> {
  static defaultProps = {
    title: "",
    email: "",
    language: "en",
    formWidth: "450px",
    submitButtonText: "",
    noDefaultFiles: false,
    shouldRednerNames: false,
    locations: [],
    os: 4,
    initLoginRegModal: () => {},
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: props.email || "",
      password: "",
      terms: false,
      error: "",
      externalToken: "",
      externalApp: "",
      loading: false,
      showTFALoginForm: false,
      showRestrictionWarning: false,
      shouldRednerNames: props.shouldRednerNames || false,
      hasdevices: false,
      tfatype: "",
      logininfo: "",
      token: "",
      verifyToken: "",
      isLoginModal: false,
      showPassword: false,
      verificationCode: "",
      showRegisterVerificationCode: false,
      showLoginVerificationCode: false,
      showCreatePassword: false,
      resendVerificationCode: false,
      locationId: EU_LOCATIONID,
      showFacebookUnavailableModal: false,
      usegrecaptcha: false
    };

    (this: any).onEnter = this.onEnter.bind(this);
    (this: any)._register = this._register.bind(this);
    (this: any).validateInputs = this.validateInputs.bind(this);
    (this: any).changePassword = this.changePassword.bind(this);
    (this: any).changeEmail = this.changeEmail.bind(this);
    (this: any).changeFirstName = this.changeFirstName.bind(this);
    (this: any).changeLastName = this.changeLastName.bind(this);
    (this: any).onTermsClick = this.onTermsClick.bind(this);
    (this: any).openSignin = this.openSignin.bind(this);
    (this: any).onRegistration = this.onRegistration.bind(this);
    (this: any).setTFALoginInfo = this.setTFALoginInfo.bind(this);
    (this: any).setVerifyInfo = this.setVerifyInfo.bind(this);
    (this: any).closeWarning = this.closeWarning.bind(this);
    (this: any).onBackClick = this.onBackClick.bind(this);
    (this: any).handlePasswordIconClick = this.handlePasswordIconClick.bind(this);

    (this: any).setShowRegisterVerificationCode = this.setShowRegisterVerificationCode.bind(this);
    (this: any).setShowLoginVerificationCode = this.setShowLoginVerificationCode.bind(this);
    (this: any).setShowCreatePassword = this.setShowCreatePassword.bind(this);
    (this: any).resetView = this.resetView.bind(this);
    (this: any).onSendCodeClick = this.onSendCodeClick.bind(this);
    (this: any).setShowFacebookUnavailableModal = this.setShowFacebookUnavailableModal.bind(this);
    (this: any).closeFacebookUnavailableModal = this.closeFacebookUnavailableModal.bind(this);
    (this: any).onFacebookUnavailableContinue = this.onFacebookUnavailableContinue.bind(this);
  }

  onEnter(e: any) {
    e.stopPropagation();
    if (e.keyCode === 13) {
      this.onRegistration(e);
    }
  }

  componentDidMount() {
    const { os } = this.props;
    apiMethod("user/preparelogin", {os}, ({ result, usegrecaptcha }) => {
      if (result === 0) {
        if (usegrecaptcha) {
          if (typeof grecaptcha === "undefined" ) {
            try {
              loadScript("https://www.google.com/recaptcha/api.js?render=6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR", () => {
                this.setState({ usegrecaptcha: !!usegrecaptcha});
              }, { dontcache: true }); 
            } catch (error) {
              console.error("Error loading recaptcha script:", error);
            }
          } else {
            const recaptchaBadge = document.querySelector(".grecaptcha-badge");

            if (recaptchaBadge) {
              recaptchaBadge.style.visibility = "visible";
            }
          }
        }
        
        this.setState({ usegrecaptcha: !!usegrecaptcha });
      }
    }, {
      errorCallback: (error) => {
        console.error("Error user/preparelogin:", error);
      }
    })
  }

  componentDidUpdate(prevProps: Props) {
    const { email: nextEmail } = this.props;
    const { email: prevEmail } = prevProps;
    if (prevEmail !== nextEmail) {
      this.setState({ email: nextEmail });
    }
  }

  onRegistration(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault();
    const self = this;
    this.validateInputs(() => {
      const { usegrecaptcha } = this.state;
      
      if (typeof grecaptcha !== "undefined" && usegrecaptcha) {
        console.log("grecaptcha init");
        grecaptcha.ready(function() {
          grecaptcha.execute('6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR', {action: 'submit'}).then((token) => {
            console.log("grecaptcha ready");
            self._register({ gresponsetoken: token })
          });
        });
      } else {
        self._register({})
      }     
    });
  }

  onBackClick() {
    this.setState({ externalToken: "", externalApp: "" });
  }

  handlePasswordIconClick() {
    this.setState(prevState => {
      return { showPassword: !prevState.showPassword };
    });
  }

  setVerifyInfo(verifyToken: string, email: string) {
    this.setState({ showRestrictionWarning: true, verifyToken, email });
  }

  setTFALoginInfo(token: string, hasdevices: boolean, tfatype: string, logininfo?: string) {
    this.setState({
      showTFALoginForm: true,
      hasdevices: hasdevices,
      token: token,
      tfatype: tfatype,
      logininfo: logininfo || ""
    });
  }

  changePassword(event: SyntheticEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({ password: value });
  }

  changeEmail(event: SyntheticEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({ email: value });
  }

  changeFirstName(event: SyntheticEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({ firstName: value });
  }

  changeLastName(event: SyntheticEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({ lastName: value });
  }

  onTermsClick() {
    this.setState(({ terms: prevTerms }) => ({
      terms: !prevTerms
    }));
  }

  openSignin() {
    const { initLoginRegModal } = this.props;

    initLoginRegModal("login", null, { canSwitch: false });
  }

  closeWarning() {
    this.setState({
      showRestrictionWarning: false,
      externalToken: "",
      externalApp: ""
    });
  }

  validateInputs(callback: () => void) {
    const { shouldRednerNames } = this.state;
    const { email, password, terms, firstName, lastName } = this.state;
    let error = "";

    if (shouldRednerNames && firstName.length === 0) {
      error = __("error_enter_fName");
    } else if (shouldRednerNames && lastName.length === 0) {
      error = __("error_enter_lName");
    } else if (email.length === 0) {
      error = __("error_invalid_email");
    } else if (password.length === 0) {
      error = __("password_required");
    } else if (password.length < 6) {
      error = __("Password too short. Minimum length is 6 characters.");
    } else if (!terms) {
      error = __("error_terms_of_service");
    }

    if (error) {
      this.setState({ error });
    } else {
      callback();
    }
  }

  setShowFacebookUnavailableModal() {
    this.setState({
      showFacebookUnavailableModal: true,
    })
  }

  closeFacebookUnavailableModal() {
    this.setState({
      showFacebookUnavailableModal: false,
    })
  }

  onFacebookUnavailableContinue() {
    setcookie("logininfo", "");
    this.setState({
      loginWith: "",
      showFacebookUnavailableModal: false
    })
  }

  _register({ gresponsetoken } = {}) {
    const { onSuccess, language, noDefaultFiles, userRegionId } = this.props;
    const { email, password, firstName, lastName } = this.state;

    this.setState({ loading: true });
    setGlobalLocationById(userRegionId);
    getApiServerCall();

    register({
      username: email,
      password: password,
      language: language,
      noDefaultFiles: noDefaultFiles,
      gresponsetoken: gresponsetoken,
      onDone: userinfo => {
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");
        this.setState({ loading: false });
        setcookie("email", email);
        onSuccess({
          action: "register",
          userinfo,
          firstName,
          lastName,
          locationid: userRegionId
        });
        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = "hidden";
        }
      },
      onError: ret => {
        const errorMessage = errorKeys[ret.result] || ret.error;
        this.setState({ error: __(errorMessage), loading: false });
      },
      onTimeout: () => {
        this.setState({ error: __("error_connection_timed_out"), loading: false });
      }
    });
  }

  setLocationId(id) {
    this.setState({ locationId: id });
  }

  setShowRegisterVerificationCode(show = false, email = "", code = "") {
    this.setState({ showRegisterVerificationCode: show, email, verificationCode: code });
  }

  setShowLoginVerificationCode(show = false, email = "", code = "") {
    this.setState({ showLoginVerificationCode: show, email, verificationCode: code });
  }

  setShowCreatePassword(show = false) {
    this.setState({ showCreatePassword: show });
  }

  resetView() {
    this.setState({
      email: "",
      verificationCode: "",
      showRegisterVerificationCode: false,
      showCreatePassword: false
    });
  }

  onSendCodeClick() {
    this.setState({
      verificationCode: "",
      showCreatePassword: false,
      showRegisterVerificationCode: true,
      resendVerificationCode: true
    });
  }

  renderExternalFlow() {
    const { onSuccess, noDefaultFiles, language } = this.props;
    const { externalToken, externalApp } = this.state;

    return (
      <React.Fragment>
        <ExternalRegister
          token={externalToken}
          externalApp={externalApp}
          onSuccess={onSuccess}
          language={language}
          noDefaultFiles={noDefaultFiles}
          setTFALoginInfo={this.setTFALoginInfo}
          setVerifyInfo={this.setVerifyInfo}
          onBackClick={this.onBackClick}
          setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
          setShowLoginVerificationCode={this.setShowLoginVerificationCode}
        />
      </React.Fragment>
    );
  }

  renderNames() {
    const { firstName, lastName } = this.state;

    return (
      <React.Fragment>
        <InputText
          type="fName"
          name="firstName"
          value={firstName}
          onChange={this.changeFirstName}
          onKeyDown={this.onEnter}
          placeholder={__("First Name")}
        />
        <InputText
          type="lName"
          name="lastName"
          value={lastName}
          onChange={this.changeLastName}
          onKeyDown={this.onEnter}
          placeholder={__("Last Name")}
        />
      </React.Fragment>
    );
  }

  renderRegistration() {
    const { title, shouldRednerNames, submitButtonText, formWidth, tooltip, locations } = this.props;
    const { email, password, terms, error, loading, showPassword } = this.state;
    const Input = showPassword ? InputText : InputPassword;
    const PassIcon = showPassword ? Form.ShowPasswordIcon : Form.HidePasswordIcon;

    return (
      <React.Fragment>
        {title ? <Form.Title className="invite-form">{title}</Form.Title> : null}
        <ErrorMessage>{error}</ErrorMessage>
        {shouldRednerNames ? this.renderNames() : null}
        <InputEmail
          name="email"
          value={email}
          autoFocus
          onChange={this.changeEmail}
          onKeyDown={this.onEnter}
          placeholder={__("form_email", "Email")}
        />
        <Form.InputPassWrapper className="input-pass-wrapper">
          <Input
            className="input-pass"
            name="password"
            value={password}
            onChange={this.changePassword}
            onKeyDown={this.onEnter}
            placeholder={__("form_password", "Password")}
          />
          {password.length ? <PassIcon className="invite-form" onClick={this.handlePasswordIconClick} /> : null}
        </Form.InputPassWrapper>
        <DataCenterDropdown tooltip={tooltip} locations={locations} />
        <Form.TermsWrapper>
          <InputStyledCheckbox
            name="terms"
            size="small"
            checked={terms}
            onChange={e => this.setState({ terms: e.target.checked })}
          />
          <Form.Terms onClick={this.onTermsClick}>
            <Componentify
              className="terms-text"
              text={__("i_accept_pcloud_terms", "I Accept pCloud\'s https://www.pcloud.com/terms_and_conditions.html[Terms & Conditions], https://www.pcloud.com/privacy_policy.html[Privacy Policy] and https://www.pcloud.com/int_pr_policy.html[Intellectual Property Policy]")}
              converters={[boldConverter, linkConverter, linkConverterV2]}
            />
          </Form.Terms>
        </Form.TermsWrapper>
        <ButtonContainer formWidth={formWidth}>
          <SubmitButton onClick={this.onRegistration} loading={loading} style={{ width: "100%", borderRadius: "7px" }}>
            {submitButtonText || __("Join")}
          </SubmitButton>
          <Form.Or>{__("or_continue", "or continue with")}</Form.Or>
          <ButtonsWrapper receiveToken={(token, externalApp) => this.setState({ externalToken: token, externalApp })} openUnavailableModal={this.setShowFacebookUnavailableModal}/>
        </ButtonContainer>
        <LoginLabel onClick={this.openSignin}>{__("family_invite_reg_use_existing")}</LoginLabel>
      </React.Fragment>
    );
  }

  render() {
    const { formWidth, language, onSuccess, os } = this.props;
    const {
      showRestrictionWarning,
      showTFALoginForm,
      logininfo,
      hasdevices,
      token,
      tfatype,
      verifyToken,
      email,
      isLoginModal,
      externalToken,
      locationId,
      showRegisterVerificationCode,
      showLoginVerificationCode,
      verificationCode,
      showCreatePassword,
      resendVerificationCode,
      showFacebookUnavailableModal
    } = this.state;

    if (showRegisterVerificationCode) {
      return (
        <RegisterVerificationCode
          email={email}
          language={language}
          resend={resendVerificationCode}
          setShowCreatePassword={this.setShowCreatePassword}
          setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
          setLocationId={this.setLocationId}
          os={os}
        />
      );
    }

    if (showLoginVerificationCode) {
      return (
        <LoginVerificationCode
          email={email}
          language={language}
          resend={resendVerificationCode}
          setShowCreatePassword={this.setShowCreatePassword}
          setShowLoginVerificationCode={this.setShowLoginVerificationCode}
          setLocationId={this.setLocationId}
          os={os}
        />
      );
    }

    if (showCreatePassword) {
      return (
        <LoginCreatePassword
          email={email}
          locationId={locationId}
          verificationCode={verificationCode}
          resetView={this.resetView}
          onSendCodeClick={this.onSendCodeClick}
          onSuccess={onSuccess}
          os={os}
        />
      );
    }

    if (showTFALoginForm) {
      return <TwoFactorAuthLogin hasLoggedDevices={hasdevices} token={token} tfatype={tfatype} {...this.props} />;
    }

    if (isLoginModal && showRestrictionWarning) {
      return <RestrictedAccessWarning verifyToken={verifyToken} email={email} />;
    }

    return (
      <React.Fragment>
        <SocialLoginUnavailableModal
          os={os}
          isModal={false}
          onClose={this.closeFacebookUnavailableModal}
          onContinue={this.onFacebookUnavailableContinue}
          open={showFacebookUnavailableModal}
        />
        <FormWrapper formWidth={formWidth}>
          {showRestrictionWarning ? (
            <RestrictedAccessWarningModal
              verifyToken={verifyToken}
              email={email}
              show={showRestrictionWarning}
              onClose={this.closeWarning}
            />
          ) : null}
          <Form.Container className="form" formWidth={formWidth}>
            {externalToken ? this.renderExternalFlow() : this.renderRegistration()}
          </Form.Container>
        </FormWrapper>
      </React.Fragment>
    );
  }
}

export default connect(
  ({ user: { userRegionId } = {} }) => {
    return { userRegionId };
  },
  dispatch => bindActionCreators({}, dispatch)
)(RegistrationForm);

const FormWrapper = styled.div`
  display: table;
  max-width: ${props => (props.formWidth ? props.formWidth : "450px")};
  width: 100%;
  margin: 10px auto;
  text-align: center;
  font-family: "Roboto", sans-serif;

  input:focus {
    outline: none;
  }

  .back-btn {
    margin-left: 5px;
    color: #17bed0;
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.div`
  width: 100%;
  max-width: 640px;
  min-height: 20px;
  color: #ff4d4d;
  text-align: center;
  font-size: 13px;
`;

const LoginLabel = styled.div`
  width: 100%;
  font-size: 13px;
  line-height: 1.2;
  text-align: left;
  color: #17bed0;
  cursor: pointer;
  bottom: 14px;
  margin-bottom: 15px;
  text-align: center;

  @media (max-width: 768px) {
    position: static;
    bottom: 0px;
  }
`;

const ButtonContainer = styled.div`
  max-width: ${props => (props.formWidth ? props.formWidth : "450px")};
  margin: 15px auto;
  clear: both;

  .butt {
    width: 100% !important;
  }

  @media (max-width: 768px) {
    max-width: 450px;
  }
`;
