import React from "react";
import styled from "styled-components";

const UserQuotaTooltip = ({ quota }) => {
  return (
    <div>
      {quota.map((section, index) => {
        return (
          <QuotaInfo key={`${index}${section.titleKey}`}>
            <QuotaLegend color={section.color} />
            <QuotaTitle>
              {section.title + " " + section.size}
            </QuotaTitle>
          </QuotaInfo>
        );
      })}
    </div>
  );
};

const QuotaLegend = styled.span`
  display: inline-block;
  position: relative;
  top: -1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 6px;
`

const QuotaInfo = styled.div`
  margin: 5px;
`

const QuotaTitle = styled.span`
  font-weight: 500;
  font-size: 11px;
`

export default UserQuotaTooltip;
