// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import Componentify from "react-componentify";

import {
  brTagConverter,
  boldConverter,
  linkConverter,
  linkConverterV2,
  linkConverterSelf,
  spanClassTagConverter
} from "../lib/componentifyConverters";

import PlainTimerTemplate from "./PlainTimerTemplate";
import TimerContainer from "../containers/TimerContainer";
import { rcookie } from "../lib/utils";

type Props = {
  title: string,
  titlecolor: string,
  description: string,
  descriptioncolor: string,
  btntext: string,
  btntextcolor: string,
  btnlink: string,
  btnbackground: string,
  disclaimer: string,
  disclaimercolor: string,
  img: string,
  img2x: string,
  img2xSize: string,
  imgSize: string,
  backgroundcolor: string,
  label: string,
  channelid: number | string,
  timerStartTime?: string | number | boolean,
  timerEndTime?: string | number | boolean,
  timertextcolor?: string | boolean,
  timerbackgroundcolor?: string | boolean,
  useLocalTime?: boolean,
  sendAnalyticsEvent: () => void,
  closeModal: () => void
};

class PromoOld extends React.Component<Props, {}> {
  static defaultProps = {
    timerStartTime: false,
    timerEndTime: false,
    useLocalTime: false
  };

  constructor(props: Props) {
    super(props);
  }

  onButtonClick = () => {
    const { label, channelid, sendAnalyticsEvent, closeModal } = this.props;
    sendAnalyticsEvent({
      category: "Promo modal button",
      action: "click",
      label: label,
      os: 4
    }, {
      channelid: channelid
    });
    closeModal();
  };

  getTimerData = () => {
    const { timerStartTime, timerEndTime, useLocalTime } = this.props;

    if (!timerStartTime || !timerEndTime) {
      return false;
    }

    const formattedStart = timerStartTime.replace(" ", "T");
    const formattedEnd = timerEndTime.replace(" ", "T");

    const timer = {
      start: new Date(formattedStart).getTime(),
      end: new Date(formattedEnd).getTime()
    };

    if (!useLocalTime) {
      const offset = new Date().getTimezoneOffset()*60*1000;
      timer.start += offset * -1;
      timer.end += offset * -1;
    }

    return timer;
  };

  constructURL = () => {
    const { btnlink, label, channelid } = this.props;
    const link = new URL(btnlink);
    
    link.searchParams.delete("channelid");
    link.searchParams.delete("label");
    link.searchParams.append("channelid", channelid);
    link.searchParams.append("label", label);

    return link.href;
  }

  renderTimer = () => {
    const { descriptioncolor, timertextcolor, timerbackgroundcolor } = this.props;
    const timerData = this.getTimerData();
    const timeNow = new Date().getTime();
    
    if (!timerData || timerData.start > timeNow || timerData.end < timeNow) {
      return null;
    }

    return (
      <TimerContainer
        timeExpire={timerData.end}
        timeExpireCallback={() => {}}
        // showLabels={false}
        renderTimer={PlainTimerTemplate}
        textColor={timertextcolor}
        timerColor={timerbackgroundcolor}
      ></TimerContainer>
    );
  };

  render() {
    const {
      title,
      titlecolor,
      description,
      descriptioncolor,
      imgSize,
      btntext,
      btntextcolor,
      btnbackground,
      disclaimer,
      disclaimercolor,
      img,
      img2x,
      backgroundcolor
    } = this.props;
    const imgsrc = window.devicePixelRatio > 1 ? img2x : img;

    const imgWidth = imgSize.split("x")[0];
    const imgHeight = imgSize.split("x")[1];
    const promoLink = this.constructURL();
    const converters = [brTagConverter, boldConverter, linkConverterV2, linkConverterSelf, spanClassTagConverter];

    return (
      <Wrapper className="promo-modal-wrapper" backgroundColor={backgroundcolor}>
        <Title color={titlecolor}>
          <Componentify
            text={title}
            converters={converters}
          />
        </Title>
        <Description color={descriptioncolor}>
          <Componentify
            text={description}
            converters={converters}
          />
        </Description>
        <Banner src={imgsrc} width={imgWidth} height={imgHeight} />
        {this.renderTimer()}
        <Button
          href={promoLink}
          color={btntextcolor}
          background={btnbackground}
          onClick={this.onButtonClick}
          target="_blank"
        >
          {btntext}
        </Button>
        {disclaimer ? (
          <Disclaimer color={disclaimercolor}>{disclaimer}</Disclaimer>
        ) : null}
      </Wrapper>
    );
  }
}

export default PromoOld;

const Wrapper = styled.div`
  text-align: center;
  max-width: 656px;
  padding: 10px;
  box-sizing: border-box;
  min-height: 416px;
  background-color: ${({backgroundColor}) => backgroundColor ? "#" + backgroundColor : "transparent"};
  border-radius: 17px;
`;

const Title = styled.div`
  display: inline-block;
  width: 100%;
  /* margin: 16px 0px; */
  margin: 16px 0px 6px 0px;
  font-size: 23px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -0.3px;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#222")};
`;

const Banner = styled.img`
  margin: 16px auto;
  @media (max-width: 430px) {
    width: 100% !important;
    height: auto !important;
  }
`;

const Description = styled.div`
  display: inline-block;
  /* margin: 16px auto; */
  margin: 0px auto;
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#222")};
`;

const Button = styled.a`
  display: inline-block;
  width: 270px;
  text-decoration: none;
  box-sizing: border-box;
  padding: 11px 17px;
  margin: 29px auto 15px auto;
  border-radius: 8px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.05);
  background-color: ${props =>
    props.background ? "#" + props.background : "#17bed0"};
  font-size: 17px;
  font-weight: bold;
  line-height: 1.18;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#fff")};
  cursor: pointer;
`;

const Disclaimer = styled.div`
  display: inline-block;
  margin: 10px auto;
  width: 100%;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.18;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#868686")};
`;
