// @flow
import {
  LOAD_FILE_REQUEST_DATA,
  SET_MESSAGE_SETTINGS,
  SET_LIMIT_SETTINGS,
  SET_EXPIRES_SETTINGS
} from "../constants/fileRequestSettings";
import type { Action } from "../actions/types";

export type FileRequestData = {
  code: string,
  comment: string,
  name: string,
  uploadlinkid: number,
  link: string,
  mail: string,
  linkName: string,
  hasPassword: false,
  expirationDate: false,
  created: string,
  expires: string,
  modified: string,
  maxspace: number,
  metadata: any,
  files: number,
  id: number,
  space: number
};

export type FileRequestSettingsState = {
  loaded: boolean,
  linkData: LinkData
};

const initialState: FileRequestSettingsState = {
  loaded: false,
  linkData: {
    code: "",
    comment: "",
    name: "",
    uploadlinkid: 0,
    link: "",
    mail: "",
    linkName: "",
    hasPassword: false,
    expirationDate: false,
    created: "",
    expires: "",
    modified: "",
    maxspace: 0,
    metadata: {},
    files: 0,
    id: 0,
    space: 0
  }
};

export const fileRequestSettingsReducer = (
  state: FileRequestSettingsState = initialState,
  action: Action
): FileRequestSettingsState => {
  switch (action.type) {
    case LOAD_FILE_REQUEST_DATA:
      return { ...state, loaded: true, linkData: action.linkData };
    case SET_MESSAGE_SETTINGS:
      return { ...state, linkData: { ...state.linkData, comment: action.comment } };
    case SET_LIMIT_SETTINGS:
      return { ...state, linkData: { ...state.linkData, hasLimit: action.hasLimit } };
    case SET_EXPIRES_SETTINGS:
      return { ...state, linkData: { ...state.linkData, expires: action.expires } };
    default:
      return state;
  }
};
