import React from "react";
import { Form as WindowsForm } from "../WindowsStyles";
import { Form as WebForm } from "../WebStyles";
import { Form as MacForm } from "../MacStyles";
import { Form as LinuxForm } from "../LinuxStyles";
import { Form as IosForm } from "../iOSStyles";
import { Form as AndroidForm } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Form = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidForm {...rest} />
    case DEVICE_OS.iOS:
      return <IosForm {...rest} />
    case DEVICE_OS.Web:
      return <WebForm {...rest} />
    case DEVICE_OS.Win:
      return <WindowsForm {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacForm {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxForm {...rest} />
    default:
      return <WebForm {...rest} />
  }
};

export default Form;
