import React from "react";
import PropTypes from 'prop-types';
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";
import { Wrapper, Message, Icon, Link } from "./MainComponents"
import SubmitButton from "../SubmitButton";
import { myUrl, siteUrl} from "@pcloud/web-utilities/dist/config"

export const SuccessStep = ({ option, subscribe }) => {
  let message = "";
  let submit = null;
  let buttontxt = "";
  let icon = null;
  let link = null;
  const isMobile = detectIsMobile();
  const downloadLink = siteUrl("download-free-online-cloud-file-storage.html");

  if (option === 1) {
    message = __("vivacom_success128_txt");
    buttontxt = isMobile ? __("vivacom_download_app") : __("vivacom_success128_button");
    submit = () => { isMobile ? window.open(downloadLink) : window.open(myUrl(""), "_self")};
    icon = <SuccessIcon/>;
  } else if (option === 2) {
    message = __("vivacom_success15_offer");
    buttontxt = __("vivacom_success15_button");
    submit = subscribe;
    icon = <BuyMoreIcon/>;
    link = <Link href={isMobile ? downloadLink : myUrl("")}>{isMobile ? __("vivacom_download_app") : __("vivacom_success15_later")}</Link>;
  }

  return (
    <Wrapper>
        {icon}
        <Message>{message}</Message>
        <SubmitButton onClick={submit} style={{ width: "100%", margin: "30px 0px", display: "block"}}>
            {buttontxt}
        </SubmitButton>
        {link}
    </Wrapper>
  )

}

const SuccessIcon = styled(Icon)`
  background-image: url(${require("../../../root/img/vivacom/set.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/vivacom/set@2x.png")});
  }
`

const BuyMoreIcon = styled(Icon)`
  background-image: url(${require("../../../root/img/vivacom/get-more.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/vivacom/get-more@2x.png")});
  }
`


