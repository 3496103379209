// @flow

import React, { useState } from "react";
import styled from "styled-components";

import Modal from "../../components/Modal";
import * as FormStyle from "../../components/FormSharedComponents";
import { BackButton } from "../TwoFactorAuth/SharedComponents";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate"; // DONE
import { validateEmail } from "../../lib/utils"; // DONE

import SubHeading from "./MultiplatformComponents/Elements/SubHeading";
import Heading from "./MultiplatformComponents/Elements/Heading";
import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper";
import Form from "./MultiplatformComponents/Elements/Form";
import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper";
import Input from "./MultiplatformComponents/Elements/Input";
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper";
import Button from "./MultiplatformComponents/Elements/Button";
import Wrapper from "./MultiplatformComponents/Elements/Wrapper";
import MessageBox from "./MultiplatformComponents/Elements/MessageBox";
import Message from "./MultiplatformComponents/Elements/Message";

import NarrowArrow from "../../../web-shared/images/svg/narrow-arrow.svg";
import InputInnerButton from "./LoginOptions/InputInnerButton";
import ModalWindow from "./MultiplatformComponents/Elements/ModalWindow";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import { AndroidModalClose } from "./MultiplatformComponents/AndroidStyles";
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink";
import CloseModalIcon from "../../../root/img/svg/signin-modal-close.svg"

const ForgotPassModal = ({ open = false, isModal = false, mail = "", passpcloud = false, onClose = () => { }, os = 4 }) => {
  const [email, setEmail] = useState(mail);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const alignLeftText = os === DEVICE_OS.Android || os === DEVICE_OS.Win ? { textAlign: "left" } : undefined

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
      setError("");
      setEmail("");
      setShowSuccessMsg(false);
    }
  };

  const onEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onResetPass();
    }
  };

  const onResetPass = (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }
    const params = { mail: email };

    if (passpcloud) {
      params.passpcloud = passpcloud;
    }

    if (!validateEmail(email)) {
      setError(__("invalid_email", "Invalid Email"));
      return;
    }

    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "lostpassword",
      params,
      res => {
        setShowSuccessMsg(true);
        setIsLoading(false);
      },
      {
        errorCallback: ({ error }) => {
          setIsLoading(false);
          HFN.message(
            __("something_went_wrong_refresh_and_try_again", "Something went wrong. Please reload the page and try again"),
            "error"
          );
          throw new Error(error);
        },
        showErrorMessage: false,
      }
    );
  };

  const renderSuccessMsg = () => {
    return (
      <React.Fragment>
        <SubHeading os={os} style={alignLeftText} isModal>
          {__("forgot_pass_succ_msg", "Check your email for further instructions.")}
        </SubHeading>
        {os === DEVICE_OS.Android && <AndroidModalClose onClick={handleClose}>
          <ButtonLink os={os} className="modal-link">{__("Close")}</ButtonLink>
        </AndroidModalClose>}
      </React.Fragment>
    );
  };

  const renderBody = () => {
    const disabledButton = !validateEmail(email);

    return (
      <React.Fragment>
        {error ? (
          <FormStyle.ErrorMessage>{error}</FormStyle.ErrorMessage>
        ) : (
          <SubHeading style={alignLeftText} os={os} isModal>{__("forgot_pass_enter_mail", "Enter your email to reset your password:")}</SubHeading>
        )}
        <Form os={os} isModal>
          <div>
            <InputWrapper os={os} osSubmit={onResetPass}>
              <Input
                os={os}
                type="email"
                autoComplete="email"
                name="email"
                placeholder={__("form_email", "Email")}
                onChange={onEmailChange}
                value={email}
                autoFocus
                onKeyDown={onEnter}
                isForgotPass
              />
              {os !== DEVICE_OS.Android &&
                <InputInnerButton os={os} onClick={onResetPass} disabled={disabledButton} loading={isLoading} disabledColor="#ebedf0">
                  <NarrowArrow/>
                </InputInnerButton>
              }
              {os === DEVICE_OS.Android &&
                <AndroidModalClose>
                  <ButtonLink className="modal-link" loading={isLoading} os={os} onClick={onResetPass} disabled={disabledButton} os={os}>{__("send", "Send")}</ButtonLink>
                </AndroidModalClose>
              }
            </InputWrapper>
          </div>
        </Form>
      </React.Fragment>
    );
  };

  const renderWrapper = () => {
    return (
      <FormWrapper os={os} isModal={isModal} style={os === DEVICE_OS.Android || os === DEVICE_OS.Win ? { alignItems: "start" } : undefined}>
        <Heading os={os} isModal>{__("forgot_password", "Forgot Password")}</Heading>
        {showSuccessMsg ? renderSuccessMsg() : renderBody()}
      </FormWrapper>
    );
  };

  const renderModal = () => {
    return (
      <Modal onClose={handleClose} opened={open} fullSize={true}>
        <ModalWindow os={os} style={os === DEVICE_OS.iOS ? { paddingLeft: "24px", paddingRight: "24px" } : undefined}>
          {os !== DEVICE_OS.Android &&
            <CloseModalIconStyled onClick={handleClose} />
          }
          {renderWrapper()}
        </ModalWindow>
      </Modal>
    );
  };

  return renderModal();
};

export default ForgotPassModal;

const SuccessMessageWrapper = styled.div`
  margin-top: 20px;
`;
const CloseModalIconStyled = styled(CloseModalIcon)`
  fill: ${({ theme }) => theme.textPrimary};
  position: absolute;
  top: 16px;
  right: 16px;
`
