import apiMethod from "./apiMethod";

export default function userinfo(auth, cb) {
  if (!auth || !cb) {
    throw Error("Auth & Callback are required.");
  }

  return apiMethod("userinfo", { auth: auth }, function(ret) {
    cb({ ...ret, auth });
  });
}
