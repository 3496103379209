import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

import { __ } from "../../lib/translate";
import { isUSLocation, getLocationById } from "../../lib/utils";
import Signin from "../../containers/UserAuthentication/Signin"
import Signup from "../../containers/UserAuthentication/Signup"
import { FormWrap, FormContent } from "./MainComponents"
import { Warning } from "./Warning"
import { LogoutButton } from "./LogoutButton"
import { US_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import { siteUrl } from "@pcloud/web-utilities/dist/config";

export class LoginStep extends React.Component {

  static propTypes = {
    premiumUser: PropTypes.bool,
    onRegSuccess: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
    logoutUser: PropTypes.func
  };
  
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };
    this.toggleActiveTab = this.toggleActiveTab.bind(this);
  }

  toggleActiveTab(activeTab) {
    this.setState({ activeTab: activeTab })
  }

  render() {
    const {
      onRegSuccess,
      onLoginSuccess,
      premiumUser,
      logoutUser,
      resetPassword,
      planName,
      logged
    } = this.props;

    const { activeTab } = this.state;
    let renderForm = null;
    let warning;

    if (!isUSLocation() && logged) {
      warning = (
        <div>
          <div>{__("vivacom_wrong_region_message")}</div>
          <a href={siteUrl("/data-regions/")}>{__("learn_more_data_regions")}</a>
        </div>
      );
    } else if (premiumUser) {
      warning = (<span>{__("vivacom_already_premium").replace("%plan%", planName)}</span>);
    }
    
    if (activeTab === 1) {
      renderForm = <Signup showTitle={false} onSuccess={onRegSuccess} locations={[getLocationById(US_LOCATIONID)]}/>
    } else if (activeTab === 2) {
      renderForm = <Signin showTitle={false} onSuccess={onLoginSuccess} />
    }

    return (
      <FormWrap>
        {warning ? (
          <FormContent>
            <Warning warningMessage={warning} /> 
            <LogoutButton logoutUser={logoutUser}/>
          </FormContent>
        ) : (
          <div>
            <TabsHolder>
              <Tab className={activeTab===1 ? "active_tab" : ""} onClick={()=>this.toggleActiveTab(1)}>
                {__("vivacom_register")}
              </Tab>
              <Tab className={activeTab===2 ? "active_tab" : ""}  onClick={()=>this.toggleActiveTab(2)}>{__("vivacom_login")}</Tab>
              <ActiveLine tab={activeTab} />
            </TabsHolder>
            <FormContent>
              {renderForm}
            </FormContent>
          </div>
        )
      }
      </FormWrap>
    );
  }
}

const TabsHolder = styled.div`
  border-bottom: 1px solid #eee;
  max-width: 340px;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
`;

const Tab = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0px 0px 30px 0px;
  text-align: center;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #222;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  &.active_tab {
    color: #17bed0;
    cursor: default;
  }
  @media (max-width: 768px) {
    padding: 0px 0px 20px 0px;
  }
`;

const ActiveLine = styled.div`
  width: 50%;
  position: absolute;
  bottom: -1px;
  height: 2px;
  background: #17bed0;
  transition: left 0.25s ease-in-out;
  ${props => (props.tab === 1 ? "left: 0%" : "left: 50%")};
`;
