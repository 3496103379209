import React from "react";
import styled from "styled-components";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import InputButton from "../MultiplatformComponents/Elements/InputButton";
import InputButtonWrapper from '../MultiplatformComponents/Elements/InputButtonWrapper'

const InputInnerButton = ({
  os = DEVICE_OS.Web,
  onClick = () => {},
  children,
  ...rest
}) => (
  <InputButtonWrapper os={os} {...rest}>
    <InputButton os={os} onClick={onClick} {...rest}>
      { children }
    </InputButton>
  </InputButtonWrapper>
);

export default InputInnerButton;
