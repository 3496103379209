import uniqueNum from "../lib/uniqueNum";

const popOver = {
  defaultOpts: {
    el: '',

    pos: 'left',
    align: 'top',
    valign: 'center',

    trigger: 'click',
    to: null,

    objClass: '',
    genClass: 'popoverObj',
    position: 'fixed',
    evNamespace: '.po',
    spacing: 11,
    centered: true,
    single: true,
    elOpenClass: 'popover-opened',
    appendTo: 'sibling',
    onOpen: function() {},
    onClose: function() {}
  },
  detach: function(opts) {
    this.closeAll();

    this.close(opts.el);
    opts.el.removeData();
    opts.el.

    el.removeData();
  },
  attach: function (opts) {
    opts = $.extend({}, this.defaultOpts, opts);

    console.log(opts.el);

    if (typeof opts.el === "string" && ['#', '.'].indexOf(opts.el.charAt(0)) != -1) {
      opts.el = $(opts.el);
    }

    console.log(opts);

    // is updating
    if (opts.el.data('popover')) {
      this.close(opts.el);
      opts.el.removeData();
    }

    if (opts.obj && !opts.el.data('popover')) {
      opts.obj = opts.el.data('popover', opts.obj);
      delete opts.obj;
    }

    opts.objClass = uniqueNum.get('popoverObj');
    console.log('attach', opts);

    var self = this, el = opts.el;
    delete opts.el;

    if (!el.data('popover')) {
      return;
    }

    el
      .data('opts', opts)
      .off(opts.evNamespace)
      .on(opts.trigger + opts.evNamespace, function (e) {
        //e.stopPropagation();

        console.log("already open", self.isOpen(opts));
        console.log(opts.trigger);

        if (self.isOpen(opts) && opts.trigger == 'click') {
          self.close($(this));
        } else {
          self.open($(this));
        }
      });

    return function() {
      popOver.close(el);

      el.removeData();
      el.off(opts.evNamespace);
    }
  },
  update: function (el, popover, updateUI) {
    el.data('popover', popover);
    updateUI = updateUI || false;
    if (updateUI) {
      el.data('obj').off('.po').detach();
      popOver.draw(el);
    }
  },
  setupClose: function (el) {
    var self = this;
    var obj = el.data('obj');
    var opts = el.data('opts');

    if (opts.trigger == 'click') {
      $(document).on('click' + opts.evNamespace, function (e) {
        if ( !$(e.target).hasClass(opts.objClass) && !$(e.target).closest('.' + opts.objClass).length ) {
          //e.stopImmediatePropagation();
          self.close(el);
        }
      });

      /*
      this.handleTouch = function(e) {
        if (!$(e.target).hasClass(opts.objClass) && !$(e.target).closest('.' + opts.objClass).length) {
          e.stopImmediatePropagation();
          e.preventDefault();
          self.close(el);
        }
      };
      document.addEventListener('touchstart', this.handleTouch);
      */
    } else {
      el.mouseleave(this.set.bind(this, el));
      obj.on('mouseleave.po', this.set.bind(this, el));
      obj.on('mouseenter.po', this.stop.bind(this, el));
      el.mouseenter(this.stop.bind(this, el));
    }

    $(window).on('resize' + opts.evNamespace, function (e) {
      self.position(el);
    });

    $(window).on('keyup' + opts.evNamespace, function (e) {
      self.closeAll();
    });
  },

  draw: function(el) {
    var opts = el.data('opts');
    var obj = el.data('popover');
    var parent;

    el.data('obj', obj);

    if (opts.appendTo == 'sibling') {
      parent = el.parent();
    } else {
      parent = $(opts.appendTo)
    }

    obj.appendTo(parent)
      .show()
      .addClass(opts.objClass)
      .addClass('popover-base')
      .addClass(opts.genClass)

    obj.addClass(opts.pos)
      .addClass('a' + opts.align)
      .addClass('v' + opts.valign)
      .css('display', 'table');
      //.show();

    this.position(el);
  },

  open: function (el) {
    var opts = el.data('opts');
    var obj = el.data('popover');

    if (opts.single && $('.popover-opened').length) {
      console.log('closing dude')
      this.close( $('.popover-opened') );
    }

    this.draw(el);
    el.addClass(opts.elOpenClass);

    setTimeout(() => { this.setupClose(el); }, 0);

    opts.onOpen();
  },
  position: function (el) {
    var obj = el.data('popover');
    var opts = el.data('opts');
    var x = el.offset().left;
    var y = el.offset().top - $(window).scrollTop();

    if (opts.position === "absolute" && 0) {
      y += $(window).scrollTop();
    }

    console.log('offset', el.offset());
    console.log('obj dim', obj.outerWidth(), obj.outerHeight());
    console.log('opts', opts);

    console.log('pos', opts.pos);
    console.log('align', opts.align);
    console.log('valign', opts.valign);

    // position
    if (opts.pos == 'top')
      y -= obj.outerHeight() + opts.spacing;
    else if (opts.pos == 'bottom')
      y += el.outerHeight() + opts.spacing;
    else if (opts.pos == 'left')
      x -= obj.outerWidth() + opts.spacing;
    else if (opts.pos == 'right')
      x += el.outerWidth() + opts.spacing;

    // align
    if (opts.align == 'left')
      1;
    else if (opts.align == 'right')
      x -= obj.outerWidth() - el.outerWidth();
    else if (opts.align == 'top')
      1;
    else if (opts.align == 'bottom')
      y -= obj.outerWidth() - el.outerHeight();
    else if (opts.align == 'center')
      x -= obj.outerWidth() / 2 - el.outerWidth() / 2;

    // valign
    if (opts.valign == 'top')
      1;
    else if (opts.valign == 'bottom')
      y -= obj.outerHeight() - el.outerHeight();
    else if (opts.valign == 'center')
      y -= (obj.outerHeight() / 2 - el.outerHeight() / 2 );

    obj.css({
      top: y,
      left: x,
      position: opts.position
    });
  },
  close: function (el) {
    var opts = el.data('opts'), obj = el.data('obj');

    console.log('closing', el, opts, obj)

    $(window).off(opts.evNamespace);
    $(document).off(opts.evNamespace);
    document.removeEventListener('touchstart', this.handleTouch);
    //$(opts.el).off(opts.evNamespace)

    el.removeClass(opts.elOpenClass);

    console.log('obj is this', obj)

    if (obj) {
      obj.off('.po').detach();
    }

    opts.onClose();
    console.log('closed', opts);
  },
  isOpen: function (opts) {
    return $('.'+opts.objClass).length;
  },

  // timeouts
  set: function (el) {
    var self = this;
    var opts = el.data('opts');

    if (opts.to) {
      clearTimeout(opts.to);
    }

    opts.to = setTimeout(function () {
      self.close(el);
    }, 350);
  },
  stop: function (el) {
    var opts = el.data('opts');
    clearTimeout(opts.to);
  },

  closeAll: function () {
    console.log('closing all');
    var self = this;
    $('.popover-opened').each(function (n, item) {
      console.log(item, n);
      self.close($(item));
    });
  }
};

export default popOver;
