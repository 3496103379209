// @flow

import { formatTime } from './utils';

const historyLength = 100;
const speedSmooth = 0.4;

function save(progress, bytes: number, moment: number) {
  if (!moment) {
    moment = Date.now();
  }

  progress.history.push({ time: moment, bytes: bytes });

  if (progress.history.length > historyLength) {
    progress.history.shift();
  }
}

function speed({ total, history }) {
  if (history.length < 2) {
    return;
  }

  const n = history.length;
  const bytes = history[n - 1].bytes - history[0].bytes;
  const time = history[n - 1].time    - history[0].time;
  const avgspeed = bytes / (time / 1000);
  const lastspeed = ((history[n - 1].bytes - history[n - 2].bytes) / ( ( history[n - 1].time - history[n - 2].time ) / 1000 ));
  const showspeed = speedSmooth * lastspeed + (1 - speedSmooth) * avgspeed;

  if (isNaN(showspeed)) {
    return ' ';
  }

  return {
    showSpeed: showspeed,
    timeLeft: formatTime(Math.round((total - bytes) / showspeed), true)
  };
}

function createProgress(total: number) {
  let progress = {
    total: total,
    history: []
  };

  return {
    save: (bytes: number, moment: number = 0) => save(progress, bytes, moment),
    speed: () => speed(progress)
  }
}

export { createProgress };
