// WARNING: Do not use HFN in this component

import React, { useState, useEffect, useCallback, useRef } from "react";
import Componentify from "react-componentify";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { notify } from "./MultiplatformComponents/Notification";
import { setGlobalLocationById } from "../../api/utils";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import RegisterVerificationCodeUI from "./AuthenticationStepsUI/RegisterVerificationCodeUI";
import { sendSetupPassMailMethod } from "./methods.signin";

const RegisterVerificationCode = ({
  isWebview = false,
  email = "",
  language = "en",
  os = DEVICE_OS.Web,
  resend = false,
  setShowCreatePassword = () => {},
  setShowRegisterVerificationCode = () => {},
  setLocationId = () => {}
}) => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [canResendCode, setCanResendCode] = useState(true);
  const [waitingTimeForSend, setWaitingTimeForSend] = useState(60);
  const [error, setError] = useState("");

  useEffect(() => {
    sendSetupPassMailMethod({ email })
  }, [])

  useEffect(() => {
    if (resend) {
      onResendCodeClick();
    }
  }, [onResendCodeClick, resend]);

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message);
    } else {
      notify(message, type, { os });
    }
  };

  const onCodeChange = (value) => {
    if (error) {
      setError("");
    }

    setVerificationCode(value);
  };

  const onResendCodeClick = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      setError("");
    }

    setIsLoading(true);

    sendSetupPassMailMethod({ email })
      .then(() => {
        showMessage("Verification code was sent to your email.", "success");
        setIsLoading(false);
        toggleCanResendCode();
      })
  });

  const onContinueClick = e => {
    e.preventDefault();
    if (isLoading || disabledForm) {
      return;
    }

    setIsLoading(true);

    apiMethod(
      "user/checksetuppasscode",
      {
        email: email,
        code: verificationCode.trim()
      },
      ({ result, ...rest }) => {
        if (result === 0) {
          setIsLoading(false);
          console.log("user/checksetuppasscode result === 0")
          setShowRegisterVerificationCode(false, "", verificationCode);
          setShowCreatePassword(true);
        }
      },
      {
        errorCallback: ({ result }) => {
          console.log(result, "result");
          setIsLoading(false);
          if (result === 2012) {
            showMessage(__("login_error_2012", "Incorrect security code."), "error");
          }
        },
        showErrorMessage: false,
      }
    );

    // Errors:
    // 1130="Please provide 'email'."
    // 1022="Please provide 'code'."
    // 2012="Invalid 'code' provided."
  };

  const toggleCanResendCode = () => {
    if (waitingTimeForSend == 0) {
      setCanResendCode(!canResendCode);
    }

    if (canResendCode) {
      let remainingTime = waitingTimeForSend;
      const timer = setInterval(() => {
        remainingTime--;

        if (remainingTime === 0) {
          clearInterval(timer);
          setCanResendCode(true);
          remainingTime = 60;
        }
        setWaitingTimeForSend(remainingTime)
      }, 1000);
      
      setCanResendCode(false);
    }
  }

  return (
    <RegisterVerificationCodeUI
      os={os}
      isWebview={isWebview}
      email={email}
      verificationCode={verificationCode}
      error={error}
      disabled={disabledForm}
      loading={isLoading}
      setDisabledForm={setDisabledForm}
      onVerificationCodeChange={onCodeChange}
      onResendCodeClick={onResendCodeClick}
      onSubmit={onContinueClick}
      canResendCode={canResendCode}
      waitingTimeForSend={waitingTimeForSend}
    />
  )
};

export default RegisterVerificationCode;
