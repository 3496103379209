// @flow

import type { Action } from "../actions/types";
import {
  PLAYER_ADD_LOADED_SONG,
} from "../constants/audioPlayer";

export type AudioPlayerState = {
  loadedSongs: Array<any>,
};

const initialState = {
  loadedSongs: {},
};

export function audioPlayerReducer(
  state: AudioPlayerState = initialState,
  action: Action
): AudioPlayerState {
  switch (action.type) {
    case PLAYER_ADD_LOADED_SONG:
      const loadedSongs = Object.assign({}, state.loadedSongs);
      loadedSongs[action.id] = action.song;
      return { ...state, loadedSongs };
    default:
      return state;
  }
}
