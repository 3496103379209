// @flow
import apiMethod from "../../api/apiMethod";

export const parseLinkData = ({
  code,
  linkid,
  link,
  shortlink = false,
  type,
  metadata,
  haspassword = false,
  expires = false,
  enableduploadforchosenusers = false,
  enableduploadforeveryone = false,
  disableupload = true,
  created,
  traffic,
  downloads,
  downloadenabled
}) => ({
  code: code,
  linkId: linkid,
  linkUrl: link,
  shortLinkUrl: shortlink,
  type: type,
  linkName: metadata.name,
  hasPassword: haspassword,
  expirationDate: expires,
  created: created,
  downloads: downloads,
  traffic: traffic,
  uploadSettings: {
    isUploadDisabled: disableupload,
    canEveryoneUpload: enableduploadforeveryone,
    isRestrictedUpload: enableduploadforchosenusers
  },
  canDownload: downloadenabled,
  emailsWithUploadAccess: []
});

export const getEmailsWithAccessApiCall = (token, linkId, onSuccess, onError) => {
  apiMethod(
    "publink/listemailswithaccess",
    {
      auth: token,
      linkid: linkId
    },
    ({ list }) => {
      onSuccess(list);
    },
    {
      forceFresh: true,
      cacheTime: 0,
      errorCallback: ({ error }) => {
        onError();
        throw new Error(error);
      }
    }
  );
};

export const changePublinkApiCall = (params, onSuccess, onError) => {
  apiMethod(
    "changepublink",
    params,
    (res) => {
      onSuccess(res);
    },
    {
      errorCallback: (res) => {
        onError(res);
      },
    }
  );
};