// @flow

import * as React from "react";
import $ from "jquery";
import { getIconInfo } from "../lib/icons";

import type { Metadata } from "../types/filesystem";

type FileIconProps = {
  item: number | string,
  type: number,
  metadata?: Metadata,
  thumbOptions?: { code?: string }
};

export default class FileIcon extends React.Component<FileIconProps> {
  ref: ?HTMLElement;

  constructor(props: FileIconProps) {
    super(props);

    const { item, type } = props;
    const { src, width, height } = getIconInfo(item, type, false);

    this.state = {
      src: src,
      iconSrc: src,
      width: width,
      height: height
    };
  }

  componentDidMount() {
    const { metadata, type, thumbOptions } = this.props;

    if (metadata && metadata.thumb) {
      HFN.thumbManager.setupThumb($(this.ref), metadata, type, thumbOptions);
    }
  }

  onError = () => {
    $(this.ref).prop('src', this.state.iconSrc);
  }

  render() {
    const { src, width, height } = this.state;

    return (
      <div className="iconwrap">
        <img ref={r => (this.ref = r)} src={src} width={width} height={height} loading="lazy" onError={this.onError} />
      </div>
    );
  }
}
