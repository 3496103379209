import { getUserSha256, rcookie } from './utils';

export const GA_FLAG = 1;
export const FBQ_FLAG = 2;

export function initTracking(trackingServices, initialCall, forceTrack) {
	let trackingSettings = rcookie('cookieconsent') || 0;

	const isEnabled = checkStatus(trackingSettings, 'enabled');
	const isDisabled = checkStatus(trackingSettings, 'disabled');
	const startService = invokeObjProp('onStart');
	const stopService = invokeObjProp('onDisabled');

	trackingServices.filter(isEnabled).forEach(service => {
		startService(service);
		if (typeof gtag === "function" && service.type === GA_FLAG) {
			gtag('consent', 'update', {
				'ad_user_data': 'granted',
				'ad_personalization': 'granted',
				'ad_storage': 'granted',
				'analytics_storage': 'granted'
			})
		}
	});
	trackingServices.filter(isDisabled).forEach(service => {
		if (typeof gtag === "function" && service.type === GA_FLAG && !initialCall) {
			gtag('consent', 'update', {
				'ad_user_data': 'denied',
				'ad_personalization': 'denied',
				'ad_storage': 'denied',
				'analytics_storage': 'denied'
			})
		}
		stopService(service);
	});

	function checkStatus(trackingSettings, status) {
		return function(service) {
			if (status === 'enabled') {
				return ((trackingSettings & service.type) === service.type) || forceTrack;
			} else {
				return ((trackingSettings & service.type) === 0 && !forceTrack);
			}
		}
	}

	function invokeObjProp(prop) {
		return function(obj) {
			obj[prop] && obj[prop]();
		}
	}
}

export const initAnalytics = () => {
	//console.log('init analytics', HFN.config.user.cryptosetup);
	console.log('<<<<<< INIT ANALYTICS');

	try {
		if (HFN.config.user.cryptosetup && !HFN.config.isDlink()) {
			$('script.ga4-script, iframe.mobius_tracking').remove();
			window.gtag = function(){};
			window.ga4 = function(){};
			window.dataLayer = [];
		}
	} catch(err) {};
	
	if (!$('script.ga4-script').length) {
		window.dataLayer = window.dataLayer || [];
		window.ga4 = function (props) {
			if (props.eventname) {
				props.event = props.eventname;
				delete props.eventname;
			}

			dataLayer.push(props)
		}

		window.gtag = function() {
			const data = arguments[2];
			let usersha256 = getUserSha256();

			if (data && data instanceof Object && usersha256) {
				data['user_id'] = usersha256;
			}

			dataLayer.push(arguments);
		};

		var ga4script = document.createElement('script');
		ga4script.classList.add("ga4-script")

		ga4script.onload = function() {
			let data = {};
			let usersha256 = getUserSha256();

			if (usersha256) {
				data = {
					'user_id': usersha256
				};
			}


			gtag('js', new Date());
			gtag('config', 'G-SDSBP59RE7', data);
		}
	
		ga4script.src = "https://www.googletagmanager.com/gtag/js?id=G-SDSBP59RE7";
		document.getElementsByTagName('head')[0].appendChild(ga4script);
	}
};

export const 	initTiktokTracking = () => {
	try {
		if (HFN.config.user.cryptosetup && !HFN.config.isDlink()) {
			$('script.tw-script, iframe.mobius_tracking').remove();
			// window.ttq = {track: function() {}};
			return;
		}
	} catch(err) {}

	try {
		!function (w, d, t) {
			w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

			ttq.load('C8A93CRSVD34VNSUFSC0');
			ttq.page();
		}(window, document, 'ttq');
	} catch(err) {
		console.log(err);
	}
};

export const 	initFacebook = () => {
	console.log('<<<<<< INIT FACEBOOK');
	try {
		if (HFN.config.user.cryptosetup && !HFN.config.isDlink()) {
			$('script.fb-script, iframe.mobius_tracking').remove();
			window.fbq = function() {};
			return;
		}
	} catch(err) {}

	if (!$('script.fb-script').length) {
		!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function()
		{n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}
		;if(!f._fbq)f._fbq=n;
		n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;t.className="fb-script";s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
		document,'script','//connect.facebook.net/en_US/fbevents.js');

		fbq('init', '280549965449042');
		fbq('track', "PageView");
	}
};

export const initTwitter = () => {
	try {
		if (HFN.config.user.cryptosetup && !HFN.config.isDlink()) {
			$('script.tw-script, iframe.mobius_tracking').remove();
			window.twq = function() {};
			return;
		}
	} catch(err) {}

	if (!$('script.tw-script').length) {
		console.log("TWITTER TRACKING LOADS HERE")
		// Twitter tracking
		!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
		},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
		u.className="tw-script",a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
		// Insert Twitter Pixel ID and Standard Event data below
		twq('init','o13cc');
		twq('track','PageView');
	}
};

export const setTracking = ({ initialCall, forceTrack }) => {
  window.dataLayer = window.dataLayer || [];
	window.gtag = function() {
		const data = arguments[2];
		let usersha256 = getUserSha256();
		
		if (data && data instanceof Object && usersha256) {
			data['user_id'] = usersha256;
		}

		dataLayer.push(arguments);
	};

  window.ga4 = function (props) {
    if (props.eventname) {
      props.event = props.eventname;
      delete props.eventname;
    }

    dataLayer.push(props)
  }
	if (initialCall) {
		gtag('consent', 'default', {
			'ad_user_data': 'denied',
			'ad_personalization': 'denied',
			'ad_storage': 'denied',
			'analytics_storage': 'denied'
		});
	}
  
  initTracking([
    {
      type: GA_FLAG,
      onStart: initAnalytics, 
      onDisabled: () => {
				window.ga4 = () => {};
      }
    }, 
    {
      type: FBQ_FLAG,
      onStart: () => { 
        initTiktokTracking();
        initFacebook();
        initTwitter();
      }, 
      onDisabled: () => {
				window.fbq = function() {};
				window.twq = function() {};
				// window.ttq = {track: function() {}};
			}
    }
  ], initialCall, forceTrack);
}