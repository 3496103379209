// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import VerificationCodeInput from "./VerificationCodeInput"; //done
import { __ } from "../../../lib/translate";
import VerificationCodeInputsError from "../MultiplatformComponents/Elements/VerificationCodeInputsError";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const VerificationCodeInputs = ({
  os = DEVICE_OS.Web,
  codeLength = 6,
  hasError = false,
  erorrMessage = __("invalid_auth_code", "Invalid code"),
  handleCodeState = () => { },
  allowLetters = false
}: {
  os: number,
  codeLength: number,
  hasError: boolean,
  erorrMessage: string,
  handleCodeState: () => void,
  allowLetters: boolean
}) => {
  let emptyCode = Array(codeLength).fill("");
  const [code, setCode] = useState(emptyCode);
  const chunk = Math.floor(codeLength / 2);

  useEffect(() => {
    let emptyCode = Array(codeLength).fill("");
    setCode(emptyCode);
  }, [codeLength]);

  const handleCode = (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => {
    const newCode = [...code];
    const remainingFields = codeLength - index;
    const newValue = value.length ? value.split("", remainingFields) : [""];
    const newValueSize = value.length ? value.length : 1;
    const target = ev.currentTarget;

    newCode.splice(index, newValueSize, ...newValue);
    setCode(newCode);

    if (!newCode.includes("")) {
      handleCodeState({ isCodeReady: true, code: newCode });
    } else {
      handleCodeState({ isCodeReady: false, code: newCode });
    }

    if (value.length && value.length < codeLength && index !== codeLength - 1) {
      (target.nextElementSibling || null).focus();
    }
    if (value.length === codeLength) {
      target.blur();
    }
  };
  const handleKey = (ev: React.KeyboardEvent<HTMLInputElement>, index) => {
    const target = ev.currentTarget;
    if (ev.key === "Backspace" && target.value === "" && index) {
      (target.previousElementSibling || null).focus();
    }
    else if (ev.key === "ArrowLeft") {
      const prevElement = target.previousElementSibling || null;
      if (prevElement) {
        prevElement.focus();
      }
    }
    else if (ev.key === "ArrowRight") {
      const nextElement = target.nextElementSibling || null;
      if (nextElement) {
        nextElement.focus();
      }
    }
    else if (!allowLetters && (ev.key === "e" || ev.key === "-" || ev.key === "+" || ev.key === ".")) {
      ev.preventDefault()
    }
  };

  return (
    <div>
      <VerificationCode
        className={hasError ? "verification-code-wrapper error" : "verification-code-wrapper"}
        handleCodeState={handleCodeState}
        chunk={chunk}
        codeLength={codeLength}
      >
        {code.map((char, index) => (
          <VerificationCodeInput
            key={index}
            handleCode={handleCode}
            handleKey={handleKey}
            char={char}
            index={index}
            maxLength={codeLength}
            os={os}
            chunk={chunk}
            allowLetters={allowLetters}
            className={index + 1 !== codeLength && codeLength / 2 - 1 !== index ? "two-fa-code-input" : undefined}
          />
        ))}
      </VerificationCode>
      <VerificationCodeInputsError os={os}>
        <VerificationCodeInputsError os={os}>
          <span>{hasError ? erorrMessage : ""}</span>
        </VerificationCodeInputsError>
      </VerificationCodeInputsError>
    </div>
  );
};

export default VerificationCodeInputs;

const VerificationCode = styled.div`
  display: ${({ codeLength }) => codeLength > 6 ? "flex" : "block"};
  &.error {
    & > input {
      outline: none;
      border-color: #fe5a5a;
    }
  }

  .two-fa-code-input {
    margin-right: ${({ codeLength }) => codeLength > 6 ? "6px" : "8px"};
  }
`;

// const ErrorMessage = styled.div`
//   font-size: 13px;
//   font-weight: 600;
//   color: #fe5a5a;
//   text-align: left;

//   grid-template-columns: repeat(${({ codeLength }) => codeLength}, ${({codeLength}) => codeLength > 6 ? "32px" : "42px"} [col-start]);
//   display: grid;
//   column-gap: ${({codeLength}) => codeLength > 6 ? "6px" : "8px"};;

//   & > span {
//     min-height: 16px;
//     display: grid;
//     grid-column-start: 1;
//     grid-column-end: ${({ codeLength }) => codeLength - 1};
//   }
// `;
