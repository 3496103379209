// @flow

import React from "react";
import styled from "styled-components";
import { __ } from "../lib/translate";

type UserNotLoggedProps = {
  onLoginRequest: () => void
};

const UserNotLogged = ({ onLoginRequest }: UserNotLoggedProps) => (
  <Wrap>
    <Sign onClick={onLoginRequest}>{__("signin", "Sign in")}</Sign>
  </Wrap>
);

const Wrap = styled.div`
  float: right;
  height: 60px;
  width: 147px;
`;

const Sign = styled.a`
  text-decoration: none;
  color: #17bed0;
  font-size: 14px;
  font-weight: 700;
  margin: 22px 0 0 37px;
  padding: 0 0 0 33px;
  border-left: 1px solid #ccc;
  cursor: pointer;
`;

export default UserNotLogged;
