import React from "react";
import { LoaderWrapper as WindowsLoaderWrapper } from "../WindowsStyles";
import { LoaderWrapper as WebLoaderWrapper } from "../WebStyles";
import { LoaderWrapper as MacLoaderWrapper } from "../MacStyles";
import { LoaderWrapper as LinuxLoaderWrapper } from "../LinuxStyles";
import { LoaderWrapper as IosLoaderWrapper } from "../iOSStyles";
import { LoaderWrapper as AndroidLoaderWrapper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const LoaderWrapper = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidLoaderWrapper {...rest} />
    case DEVICE_OS.iOS:
      return <IosLoaderWrapper {...rest} />
    case DEVICE_OS.Web:
      return <WebLoaderWrapper {...rest} />
    case DEVICE_OS.Win:
      return <WindowsLoaderWrapper {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacLoaderWrapper {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxLoaderWrapper {...rest} />
    default:
      return <WebLoaderWrapper {...rest} />
  }
};

export default LoaderWrapper;
