import React from "react";

import styled, { css, keyframes } from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../../lib/translate";
import Modal from "../../../components/Modal";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import Wrapper from "../MultiplatformComponents/Elements/Wrapper";

import BackIconTFA from "../../../../root/img/2fa-login/back.svg";
import { TFA_NOTIFICATION_FORM_ID, TFA_RECOVERY_FORM_ID, TFA_SMS_FORM_ID } from "../constants.signin";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import Button from "../MultiplatformComponents/Elements/Button";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import { DarkInfoIcon, LightInfoIcon } from "../DataCenterDropdown";

const TFACantReceiveCodeSupportUI = ({
  os = 4,
  isLoginModal,
  canResend,
  waitingTimeForSend,
  changeForm,
  toggleHelpSteps,
  toggleCanResend,
  theme = 'light',
}) => {
  return (
    <Wrapper os={os}>
      <Header>
        <Heading os={os}>{__("tfa_login_cant_receive_header", "Can't receive code?")}</Heading>
        <SubHeading os={os}>{__("tfa_login_cant_receive_subheader", "Try the following steps:")}</SubHeading>
      </Header>
      <StepsContainer className="steps-container" canResend={canResend} os={os}>
        {os === DEVICE_OS.Win &&
          <div className="tooltip-icon">
            {theme == "dark" ? <LightInfoIcon /> : <DarkInfoIcon />}
          </div>
        }
        <ol>
          <li>
            {__(
              "tfa_login_cant_receive_step1.1",
              "If you haven't received an SMS with the security code yet, wait a few minutes. Some operators may take up to 5 min to proceed your SMS. If you don't receive an SMS even after sufficient amount of time, try"
            ) + " "}
            <span
              className="send_link"
              onClick={() => {
                if (canResend) {
                  toggleHelpSteps();
                  changeForm(TFA_SMS_FORM_ID);
                  toggleCanResend();
                }
              }}
            >
              {__("tfa_login_cant_receive_step1.2", "resending the SMS")}
            </span>
            .{!canResend ? <Counter>({waitingTimeForSend})</Counter> : null}
          </li>
          <li>
            {__("tfa_login_cant_receive_step2.1", "If you have access to devices, where your pCloud account is logged, try") + " "}
            <span
              onClick={() => {
                toggleHelpSteps();
                changeForm(TFA_NOTIFICATION_FORM_ID);
              }}
            >
              {__("tfa_login_cant_receive_step2.2", "receiving the code as a notification.")}
            </span>
          </li>
          <li>
            {__("tfa_login_cant_receive_step3.1", "If you don't receive an SMS or don't have access to logged devices,") + " "}
            <span
              onClick={() => {
                toggleHelpSteps();
                changeForm(TFA_RECOVERY_FORM_ID);
              }}
            >
              {__("tfa_login_cant_receive_step3.2", "use one of your recovery codes")}
            </span>{" "}
            {__("tfa_login_cant_receive_step3.3", "to access pCloud.")}
          </li>
          <li>
            {__("tfa_login_cant_receive_step4.1", "If you don't have your recovery codes at hand,") + " "}
            <span
              onClick={() => window.open(URLSITE.indexOf('extension') ? "https://pcloud.com/request-help.html" : URLSITE + "request-help.html")}
            >
              {__("tfa_login_cant_receive_step4.2", "contact our support team")}
            </span>{" "}
            {__("tfa_login_cant_receive_step4.3", "to find a solution. You may be asked to provide some additional info to prove your identity.")}
          </li>
        </ol>
      </StepsContainer>
      {os !== DEVICE_OS.Android &&
        <ButtonLink className="cant-receive-code-back" os={os} style={{ marginTop: "15px" }} onClick={toggleHelpSteps}>{__("back")}</ButtonLink>
      }
      {os === DEVICE_OS.Android &&
        <Button os={os} onClick={toggleHelpSteps} style={{ maxWidth: "160px" }}>{__("back")}</Button>
      }
    </Wrapper>
  )
}

export default TFACantReceiveCodeSupportUI;

const fadein = keyframes`
  0% {
    opacity: 0;
    transform:scale(0.92, 0.92);
  }

  100% {
    opacity: 1;
    transform:scale(1, 1);
  }
`;

const Header = styled.div`
  text-align: left;
  width: 100%;

  @media (max-width: 460px) {
    text-align: left;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin: 12px;
  background-position: center;
  cursor: pointer;

  background-image: url(${require("../../../../root/img/updatedrive/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/updatedrive/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StepsContainer = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  box-sizing: border-box;
  text-align: left;
  margin-top: 38px;
  max-width: 600px;

  ${({ os }) => DEVICE_OS.Win === os && css`
    background-color: ${({ theme }) => theme.secondary};
    padding: 23px 20px 22px 57px;
    border: 1px solid ${({ theme }) => theme.borderPrimary};
    position: relative;
    border-radius: 4px;

    .tooltip-icon {
      position: absolute;
      top: 26px;
      left: 21px;
    }
  `}

  & ol {
    padding: 0px 0px 0px 15px;
    margin: 0px auto;
    list-style-position: inside;
  }
  & li {
    font-size: 14px;
    margin: 18px 0px;
    line-height: 1.4;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  & span {
    color: ${({ theme }) => theme.primary};
    cursor: pointer;

    ${({ os }) => DEVICE_OS.Win === os && css`
      text-decoration: underline;
      color: ${({ theme }) => theme.textPrimary};
    `}
  }
  & .send_link {
    color: ${({ theme, canResend }) => !canResend ? theme.textPrimary : theme.primary};
    cursor: ${props => (!props.canResend ? "not-allowed" : "pointer")};

    ${({ os }) => DEVICE_OS.Win === os && css`
      text-decoration: ${({ canResend }) => !canResend ? "none" : "underline"};
      color: ${({ theme }) => theme.textPrimary};
    `}
  }
`;

const Counter = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: red !important;
`;
