import React from "react";
import { InputButtonWrapper as WindowsInputButtonWrapper } from "../WindowsStyles";
import { InputButtonWrapper as WebInputButtonWrapper } from "../WebStyles";
import { InputButtonWrapper as MacInputButtonWrapper } from "../MacStyles";
import { InputButtonWrapper as LinuxInputButtonWrapper } from "../LinuxStyles";
import { InputButtonWrapper as IosInputButtonWrapper } from "../iOSStyles";
import { InputButtonWrapper as AndroidInputButtonWrapper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const InputButtonWrapper = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidInputButtonWrapper {...rest} />
    case DEVICE_OS.iOS:
      return <IosInputButtonWrapper {...rest} />
    case DEVICE_OS.Web:
      return <WebInputButtonWrapper {...rest} />
    case DEVICE_OS.Win:
      return <WindowsInputButtonWrapper {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacInputButtonWrapper {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxInputButtonWrapper {...rest} />
    default:
      return <WebInputButtonWrapper {...rest} />
  }
};

export default InputButtonWrapper;
