import React from "react";
import { VerificationCodeInputsError as WindowsVerificationCodeInputsError } from "../WindowsStyles";
import { VerificationCodeInputsError as WebVerificationCodeInputsError } from "../WebStyles";
import { VerificationCodeInputsError as MacVerificationCodeInputsError } from "../MacStyles";
import { VerificationCodeInputsError as LinuxVerificationCodeInputsError } from "../LinuxStyles";
import { VerificationCodeInputsError as IosVerificationCodeInputsError } from "../iOSStyles";
import { VerificationCodeInputsError as AndroidVerificationCodeInputsError } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const VerificationCodeInputsError = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidVerificationCodeInputsError {...rest} />
    case DEVICE_OS.iOS:
      return <IosVerificationCodeInputsError {...rest} />
    case DEVICE_OS.Web:
      return <WebVerificationCodeInputsError {...rest} />
    case DEVICE_OS.Win:
      return <WindowsVerificationCodeInputsError {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacVerificationCodeInputsError {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxVerificationCodeInputsError {...rest} />
    default:
      return <WebVerificationCodeInputsError {...rest} />
  }
};

export default VerificationCodeInputsError;
