import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
`;

export const FormWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.div`
  width: 100%;
  color: #000;
  font-size: 20px;
  text-align: left;
  display: block;
  font-weight: bold;
  margin-bottom: ${props => (props.subTitle ? "5px" : "0")};

  &.login-title, &.register-title {
    text-align: left;
  }

  &.webview {
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    margin: 20px 0 20px 0;

    &.webview {
      margin: 20px 0 0 0;
    }
  }
`;

export const SubHeading = styled.div`
  width: 100%;
  color: #999;
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  margin: 10px 0 5px 0;
  text-align: left;

  &.external {
    margin-bottom: 35px;
  }
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 24px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 12px auto;

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  .edit-email-icon {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 10px;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  line-height: 40px;
  height: 40px;
  padding: 0 10px 0 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  min-width: initial;
  width: 100%;
  outline: none;

  &:disabled {
    border: solid 1px #ebebeb;
    background-color: #f4f4f4;
    color: #888;
  }


  &[type="email"],
  &:read-only {
    padding-right: 35px !important;
  }

  &[type="password"] {
    padding-right: 35px !important;
  }
`;

export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 16px auto;
  margin-bottom: -14px;

  label {
    margin-right: 10px;
  }

  button {
    margin-left: 10px;
  }
`;

export const MessageBoxWarning = styled.div`
  padding: 19px;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #f6e2a6;
  background-color: #fff9e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const MessageBoxSuccess = styled.div`
  padding: 22px;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #a8e3a4;
  background-color: #e7f8e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const MessageBox = styled.div`
  padding: 22px;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #a2e5ec;
  background-color: #f7fdfe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const Message = styled.div`
  font-size: 13px;
  text-align: left;
  color: #000;
  width: 100%;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 235px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }
`;

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  svg {
    display: none;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 13px;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #f73c3c;
  width: 100%;

  strong, b {
    font-weight: bold;
  }
`;

// export const Button = styled.button`
//   font-family: inherit !important;
//   width: 100%;
//   height: 32px;
//   padding: 6px 10px;
//   border-radius: 4px;
//   background-color: #17bed0;
//   border: none;
//   font-size: 14px;
//   font-weight: 600;
//   text-align: center;
//   color: #fff;
//   cursor: pointer;

//   &:disabled {
//     background-color: #f3f2f1;
//     color: #a19f9d;
//     cursor: auto;
//   }
// `;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SocialButtonWrapper = styled.div`
  width: 100%;
`;

export const SocialButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: calc(50% - 6px);
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: none;
  border-radius: 8px;
  background-color: #f2f2f4;
  cursor: pointer;  
`;

export const ContinueWith = styled.div`
  font-size: 12px;
  text-align: center;
  color: #a19f9d;
  margin: 10px auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  padding: 0;
  text-align: center;
  background: transparent;
  height: auto;

  &:hover {
    background-color: transparent;
    color: #000;
  }
`;