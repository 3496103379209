import React from "react";

import FileIcon from "./FileIcon";
import { formatSize } from "../lib/utils";
import { ICONS, getFileIcon } from "../lib/icons";

const FileRow = ({ file, remove }) => (
  <div className="clearfix cwrap2">
    <div
      className="remove"
      title="Remove file"
      onClick={remove.bind(null, file)}
    />
    <div className="size">{formatSize(file.size)}</div>
    <div className="name cwrap2">
      <FileIcon item={getFileIcon(file)} type={ICONS.LIST} />
      {file.name}
    </div>
  </div>
);

export default FileRow;
