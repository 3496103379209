import React from "react";
import FacebookLogoSVG from "../../../../../root/img/svg/signin-with-facebook.svg"
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const FacebookLogo = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <FacebookLogoSVG width="24" height="24" {...rest} />
    case DEVICE_OS.iOS:
      return <FacebookLogoSVG width="28" height="28" {...rest} />
    case DEVICE_OS.Web:
      return <FacebookLogoSVG width="20" height="20" {...rest} />
    case DEVICE_OS.Win:
      return <FacebookLogoSVG width="20" height="20" {...rest} />
    case DEVICE_OS.MacOSX:
      return <FacebookLogoSVG width="20" height="20" {...rest} />
    case DEVICE_OS.Linux:
      return <FacebookLogoSVG width="20" height="20" {...rest} />
    default:
      return <FacebookLogoSVG width="20" height="20" {...rest} />
  }
};

export default FacebookLogo;
