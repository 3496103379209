//  @flow

export const LOAD_ACCOUNT_INFO = "LOAD_ACCOUNT_INFO";
export const CLEAR_ACCOUNT_INFO = "CLEAR_ACCOUNT_INFO";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const LOAD_BILLING_INFO = "LOAD_BILLING_INFO";
export const UPDATE_BILLING_INFO = "UPDATE_BILLING_INFO";
export const CLEAR_BILLING_INFO = "CLEAR_BILLING_INFO";
export const SET_PROCESSING_PROFILE = "SET_PROCESSING_PROFILE";
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_TEAMS = "LOAD_TEAMS";
export const CLEAR_USERS = "CLEAR_USERS";
export const CLEAR_TEAMS = "CLEAR_TEAMS";
export const EDIT_USER = "EDIT_USER";
