// @flow
// SHARE CONSTANTS
export const SHARES_LOAD = "SHARES_LOAD";
export const SHARES_UNLOAD = "SHARES_UNLOAD";
export const LIST_SHARES = "LIST_SHARES";
export const LOAD_FOLDER_SHARES = "LOAD_FOLDER_SHARES";
export const CLEAR_FOLDER_SHARES = "CLEAR_FOLDER_SHARES";
export const ADD_SHARE_TO_FOLDER = "ADD_SHARE_TO_FOLDER";
export const DELETE_SHARE_TO_FOLDER = "DELETE_SHARE_TO_FOLDER";
export const UPDATE_SHARE_TO_FOLDER = "UPDATE_SHARE_TO_FOLDER";
export const ACCEPTED_SHARE_TO_FOLDER = "ACCEPTED_SHARE_TO_FOLDER";
export const GET_SHARE_MESSAGE = "GET_SHARE_MESSAGE";
export const GET_SHARE_PERMISSION = "GET_SHARE_PERMISSION";
export const GET_SHARE_FOLDER_NAME = "GET_SHARE_FOLDER_NAME";
