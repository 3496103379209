import React, { useState } from "react";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";

import EmailLoginOption from "../LoginOptions/EmailLoginOption";
import InputInnerButton from "../LoginOptions/InputInnerButton";
import {
  APPLE_LOGIN_METHOD_ID,
  EMAIL_LOGIN_METHOD_ID,
  FACEBOOK_LOGIN_METHOD_ID,
  GOOGLE_LOGIN_METHOD_ID,
  LOGIN_FLOW_TYPE,
  LOGIN_SCREEN,
  REGISTER_FLOW_TYPE,
  SETUP_PASS_LOGIN_METHOD_ID,
  SIGNUP_STEP
} from "../constants.signin";
import * as FormStyle from "../../../components/FormSharedComponents"; // DONE

import NarrowArrow from "../../../../web-shared/images/svg/narrow-arrow.svg";

import Wrapper from "../MultiplatformComponents/Elements/Wrapper";
import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper";
import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import Form from "../MultiplatformComponents/Elements/Form";
import ContinueWith from "../MultiplatformComponents/Elements/ContinueWith";
import CheckBox from "../MultiplatformComponents/Elements/CheckBox";
import SigninOptionsWrapper from "../MultiplatformComponents/Elements/SigninOptionsWrapper";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import FacebookButton from "../LoginOptions/SocialLogins/FacebookButton";
import AppleButton from "../LoginOptions/SocialLogins/AppleButton";
import GoogleButton from "../LoginOptions/SocialLogins/GoogleButton";
import PasswordOption from "../LoginOptions/PasswordOption";

import { FORM_TEXTS } from "../texts.signin";
import Message from "./Message";
import { __ } from "../../../lib/translate";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import { boldConverter } from "../../../lib/componentifyConverters";
import { splitEmail } from "../utils.signin";
import ResponsiveEmail from "../../ResponsiveEmail";
import Button from "../MultiplatformComponents/Elements/Button";
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper";
import RevealPassword from "../MultiplatformComponents/Elements/RevealPassword";
import HidePassword from "../MultiplatformComponents/Elements/HidePassword";
import PcloudLogo from "../../../../web-shared/images/svg/pcloud-gradient-logo.svg";

const LoginUI = ({
  os = DEVICE_OS.Web,
  error = "",
  rememberme = false,
  isWebview = false,
  email,
  password,
  formFields = {
    preferredOption: [],
    secondaryOption: [],
    socialLoginsOptionsGroup: []
  },
  redirect_uris,
  loading = false,
  showTitle = true,
  title = "",
  themeMode = "light",
  language,
  requestId,
  state,
  receiveToken = () => { },
  getRequestId = () => { },
  getToken = () => { },
  onRememberMeChange = () => { },
  onPasswordChange = () => { },
  onSubmit = () => { },
  onForgotClick = () => { },
  onEmailChangeClick = () => { },
  setShowRegisterVerificationCode = () => { },
  forceValidateForm = false
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const PassIcon = showPassword ? HidePassword : RevealPassword;
  const onShowPass = () => setShowPassword((showPass) => !showPass);

  const renderRememnerMe = () => (
    <CheckBox
      type="checkbox"
      name="rememberme"
      size="small"
      // onChange={e => this.setInput("rememberme", !!e.target.checked)}
      onChange={onRememberMeChange}
      checked={rememberme}
      className="login-input-checkbox"
      os={os}
      text={__("lbl_remember_me", "Remember me")}
    />
  );

  const renderPasswordSection = () => {
    const disabledButton = password.length === 0;

    return (
      <PasswordSectionWrapper>
        <PasswordOption
          onChange={onPasswordChange}
          os={os}
          name="password"
          placeholder={__("login_or_signup_password_placeholder")}
          value={password}
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          error={error || forceValidateForm}
          className="login-input-password"
          autoFocus
        >
          {password.length !== 0 && <PassIcon themeMode={themeMode} os={os} onClick={onShowPass} className="login-input-password-icon" />}
          {os !== DEVICE_OS.Android &&
            <InputInnerButton os={os} loading={loading} onClick={onSubmit} disabled={disabledButton || loading} disabledColor="#ebedf0">
              <NarrowArrow />
            </InputInnerButton>}
        </PasswordOption>
        <SigninOptionsWrapper className="login-input-checkbox-wrapper" os={os}>
          {os === DEVICE_OS.Web ? renderRememnerMe() : <div />}
          <ButtonLink type="button" os={os} onClick={onForgotClick} className="forgotpass-button">
            {__("forgot_password", "Forgot Password")}
          </ButtonLink>
        </SigninOptionsWrapper>
        {os === DEVICE_OS.Android &&
          <ButtonWrapper os={os}>
            <Button className="login-button-submit" loading={loading} os={os} onClick={onSubmit} disabled={disabledButton} disabledColor="#ebedf0">{__("Login", "Log in")}</Button>
          </ButtonWrapper>}
      </PasswordSectionWrapper >
    );
  };

  const renderLoginOption = (loginmethod, preferred, optionsArray) => {
    if (loginmethod === EMAIL_LOGIN_METHOD_ID && !optionsArray.includes(SETUP_PASS_LOGIN_METHOD_ID)) {
      return renderPasswordSection();
    };

    if (loginmethod === SETUP_PASS_LOGIN_METHOD_ID) {
      return (
        <SetupPasswordWrapper>
          <ButtonLink type="button" os={os} onClick={setShowRegisterVerificationCode} className="forgotpass-button">
            {__("login_or_signup_setuppass_placeholder", "Setup password")}
          </ButtonLink>
        </SetupPasswordWrapper>
      )
    }

    if (loginmethod === FACEBOOK_LOGIN_METHOD_ID) {
      return (
        <FacebookButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          className="login-buttons-facebook"
          language={language}
          state={state}
        />
      )
    }

    if (loginmethod === APPLE_LOGIN_METHOD_ID) {
      return (
        <AppleButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          language={language}
        />
      )
    }

    if (loginmethod === GOOGLE_LOGIN_METHOD_ID) {
      return (
        <GoogleButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          className="login-buttons-google"
          language={language}
          state={state}
        />
      )
    }
  };

  const renderOption = (optionsArray = [], preferred) => {
    if (!optionsArray || !optionsArray.length) {
      return null;
    }

    return optionsArray.map(login => (
      renderLoginOption(login, preferred, optionsArray)
    ))
  };

  return (
    <FormWrapper os={os}>
      {os !== DEVICE_OS.Web &&
        <LogoWrapper>
          <PcloudLogo />
        </LogoWrapper>
      }
      {
        showTitle ? (
          <Heading os={os} className={isWebview ? "webview" : "login-title"}>
            {title || __("Log in to pCloud")}
          </Heading>
        ) : null
      }
      {
        email ? (
          <React.Fragment>
            <SubHeading os={os}>
              {reactStringReplace(__("login_or_signup_welcomeback"), /(%email%)/g, (match, i) => (
                <strong>
                  <ResponsiveEmail os={os} email={email} />
                </strong>
              ))}
            </SubHeading>
            <ButtonLink className="change-link" os={os} onClick={onEmailChangeClick} style={{ marginTop: "4px" }}>{__("Change", "Change")}</ButtonLink>
          </React.Fragment>
        ) : null
      }
      <Form os={os} onSubmit={onSubmit}>
        <Message error={error} os={os} />
        {
          formFields.preferredOption.length ? (
            <FlexBox>
              {renderOption(formFields.preferredOption, true)}
            </FlexBox>
          ) : null
        }
        {
          formFields.secondaryOption.length ? (
            <React.Fragment>
              {
                !formFields.secondaryOption.includes(SETUP_PASS_LOGIN_METHOD_ID) && (
                  <ContinueWith os={os} className="login-text-or">
                    {__("login_or_signup_orloginwith", "or log in with")}
                  </ContinueWith>
                )
              }
              <FlexBox>
                {renderOption(formFields.secondaryOption)}
              </FlexBox>
            </React.Fragment>
          ) : null
        }
        {
          formFields.socialLoginsOptionsGroup.length ? (
            <React.Fragment>
              <ContinueWith os={os} className="login-text-or">
                {__("login_or_signup_oruse", "or use")}
              </ContinueWith>
              <FlexBox>
                {renderOption(formFields.socialLoginsOptionsGroup)}
              </FlexBox>
            </React.Fragment>
          ) : null
        }
      </Form>
    </FormWrapper>
  )
};

export default LoginUI;

const FlexBox = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
`;

const PasswordSectionWrapper = styled.div`
  width: 100%;
`;

const SetupPasswordWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: center;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
