import React, { Component } from 'react';
import $ from "jquery";

export default class Tooltip extends Component {
  static defaultProps = {
    shouldRenderTooltip: true
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { shouldRenderTooltip, children } = this.props;

    return <span ref={ref => { this.reftooltip = ref; }}>{children}</span>;
  }

  componentDidMount() {
    const { shouldRenderTooltip } = this.props;
    console.log("-- MOUNT");

    if (shouldRenderTooltip) {
      this._attach();
    }
  }

  componentDidUpdate({ shouldRenderTooltip: prevShouldRenderTooltip}) {
    const { shouldRenderTooltip } = this.props;

    if (prevShouldRenderTooltip !== shouldRenderTooltip) {
      if (shouldRenderTooltip) {
        this._attach();
      } else {
        this._detach();
      }
    } 
  }

  componentWillUnmount() {
    const { shouldRenderTooltip } = this.props;
    console.log("-- UNMOUNT");
    if (shouldRenderTooltip) {
      this._detach();
    }
  }

  componentWillReceiveProps() {
  }

  _attach() {
    //alert(2);
    console.log('Tooltip attaching')
    $(this.reftooltip).children(0).tooltip({ container: document.body, title: this.props.title });
  }

  _detach() {
    //alert(3);
    console.log('Tooltip detaching')
    $(this.reftooltip).children(0).tooltip("destroy");
  }
}
