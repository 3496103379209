// @flow

import store from "./store";
import diffs from "../diffs";
import { abortAll } from "../XhrComponentUtils";
import type { Userinfo } from "../../types/user";
import type { UserState } from "./reducers/user";
import {
  modify,
  setUserContacts,
  setUserRelocationInfo,
  setUserSettingsInfo,
  setPaymentSource,
  setPaymentProvider
} from "./actions/user";
import contactsSubscriber from "../contactsSubscriber";
import apiMethod from "../../api/apiMethod";
import { rcookie } from "../../lib/utils";

import { parseSettingsInfo } from "./parseUser";

let currentToken = "";
let stopListenDiff;
let stopListenContacts;
let xhrs = [];

store.subscribe(() => {
  checkUser(currentUserState());
});

checkUser(currentUserState());

function userLogin() {
  let userContacts = [];
  let reloactionInfo = {};
  let userSettingsInfo = {};
  let paymentSource = {};
  let paymentProvider = {};

  const setContacts = () => {
    return apiMethod(
      "contactlist",
      { auth: currentToken },
      ({ contacts }) => {
        userContacts = contacts;
      },
      { forceFresh: true }
    );
  };

  const getReloactionStatus = () => {
    return apiMethod(
      "relocation/getstatus",
      { auth: currentToken },
      ({ statuscode = -1, canrelocate = true, hasfree = false }) => {
        reloactionInfo = {
          relocationStatus: statuscode,
          canRelocate: canrelocate,
          hasFree: hasfree
        };
        // update relocation status @www /hack/
        if (window.relocationst_callback) {
          window.relocationst_callback(statuscode);
        }
      },
      {
        errorCallback: ({ statuscode = -1, canrelocate = true, hasfree = false }) => {
          reloactionInfo = {
            relocationStatus: statuscode,
            canRelocate: canrelocate,
            hasFree: hasfree
          };
          // update relocation status @www /hack/
          if (window.relocationst_callback) {
            window.relocationst_callback(statuscode);
          }
        },
        forceFresh: true
      }
    );
  };

  const getUserSettingsInfo = () => {
    return apiMethod(
      "usersettingsinfo",
      { auth: currentToken },
      ret => {
        userSettingsInfo = parseSettingsInfo(ret);
      },
      {
        errorCallback: ({ error }) => {
          throw new Error(error);
        }
      }
    );
  };

  const getPaymentSource = () => {
    return apiMethod(
      "listpaymentsources",
      {},
      (res: any) => {
        if (res.result) {
          delete res.result;
        }
        paymentSource = res;
      },
      {
        errorCallback: (error) => {
          throw new Error(error);
        }
      }
    );
  };
  
  const getPaymentProvider = () => {
    const params = { };
  
    if (rcookie("forcecard")) {
      params.card = rcookie("forcecard");
    }
  
    if (rcookie("forcecountry")) {
      params.country = rcookie("forcecountry");
    }
  
    return apiMethod(
      "listpaymentmethods",
      params,
      (res: any) => {
        paymentProvider = res;
      },
      {
        errorCallback: (error) => {
          throw new Error(error);
        }
      }
    );
  };

  xhrs.push(
    setContacts(),
    getReloactionStatus(),
    getUserSettingsInfo(),
    getPaymentSource(),
    getPaymentProvider(),
  );

  Promise.all(xhrs).then(() => {
    delete paymentSource.result;
    delete paymentProvider.result;
    console.log("userState+++++++++++++++++ PromiseAll", {
      userContacts,
      reloactionInfo,
      userSettingsInfo,
      paymentSource,
      paymentProvider
    })
    store.dispatch(setUserContacts(userContacts));
    store.dispatch(setUserRelocationInfo(reloactionInfo));
    store.dispatch(setUserSettingsInfo(userSettingsInfo));
    store.dispatch(setPaymentSource(paymentSource));
    store.dispatch(setPaymentProvider(paymentProvider));
  });
}

function checkUser(userState: UserState) {
  if (userState.token != currentToken) {
    if (currentToken) {
      console.log("stop listen");
      currentToken = "";
      stopListenDiff();
      stopListenContacts();
      userLogout();
    }

    if (userState.logged) {
      console.log("go Listen");
      console.log("userState.logged", userState.logged);
      console.log("currentToken", currentToken);
      console.log("userState.token", userState.token);

      currentToken = userState.token;
      listenDiff();
      listenContacts();
      userLogin();
    }
  }
}

function listenDiff() {
  stopListenDiff = diffs.listen(currentToken, events => {
    const modifyEvent = events.last(ev => ev.event === "modifyuserinfo");

    if (modifyEvent) {
      store.dispatch(modify(modifyEvent.userinfo));
    }
  });
}

function listenContacts() {
  stopListenContacts = contactsSubscriber.listen(currentToken, event => {
    console.log("contactsSubscriber EVENT", event);
    // xhr = setContacts();
  });
}

function currentUserinfo(): Userinfo | false {
  const user = currentUserState();
  return user.logged ? user.userinfo : false;
}

function currentUserState(): UserState {
  return store.getState().user;
}

function userLogout() {
  if (xhrs.length) {
    abortAll(xhrs);
  }
  store.dispatch(setUserContacts([]));
  store.dispatch(setUserRelocationInfo({}));
}
