import React from "react";
import { Wrapper as WindowsSigninWraper } from "../WindowsStyles";
import { Wrapper as WebSigninWraper } from "../WebStyles";
import { Wrapper as MacSigninWraper } from "../MacStyles";
import { Wrapper as LinuxSigninWraper } from "../LinuxStyles";
import { Wrapper as IosSigninWraper } from "../iOSStyles";
import { Wrapper as AndroidSigninWraper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Wrapper = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidSigninWraper {...rest} />
    case DEVICE_OS.iOS:
      return <IosSigninWraper {...rest} />
    case DEVICE_OS.Web:
      return <WebSigninWraper {...rest} />
    case DEVICE_OS.Win:
      return <WindowsSigninWraper {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacSigninWraper {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxSigninWraper {...rest} />
    default:
      return <WebSigninWraper {...rest} />
  }
};

export default Wrapper;
