// @flow
import React, { Component } from "react";
import { hiDPI } from "polished";

import { ButtonSmall } from "./FacebookButton"; // DONE
import GL_Login from "../../../../lib/gl_login"; // DONE
import { DEVICE_OS, EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { __ } from "../../../../lib/translate"; // DONE
import apiMethod from "../../../../api/apiMethod"; // DONE

import SocialButton from "../../MultiplatformComponents/Elements/SocialButton"; // DONE
import GoogleLogo from "../../MultiplatformComponents/Elements/GoogleLogo" // DONE
import { LogoWrapper } from "../../MultiplatformComponents/CommonStyles";

const GoogleButton = props => {
  const {
    os = 4,
    size = "small",
    passpcloud = false,
    redirect_uris,
    getRequestId,
    getToken,
    receiveToken,
    language,
    requestId,
    state
  } = props;

  const onButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (redirect_uris) {
      let params = "";
      if (redirect_uris && os === DEVICE_OS.Web) {
        params = "is_extension";
      }
      if (passpcloud) {
        params = "passpcloud";
      }
      getRequestId(params)
        .then(({ request_id }) => {
          GL_Login.initGetToken(redirect_uris, request_id, passpcloud, os, language);
          getToken('gl_oauth2_token', request_id)
            .then(({ access_token }) => GL_Login.getTokenCallback(receiveToken(access_token, EXTERNAL_APPS.GOOGLE), redirect_uris, request_id, passpcloud));
        });
    } else {
      GL_Login.getTokenCallback(receiveTokenCb, null, requestId, passpcloud, os, language, { ...state, app: EXTERNAL_APPS.GOOGLE });
    }
  }

  const receiveTokenCb = token => {
    receiveToken(token, EXTERNAL_APPS.GOOGLE);
  }

  if (size === "big") {
    return (
      <SocialButton os={os} className="login-button-google" onClick={onButtonClick}>
        <LogoWrapper>
          <GoogleLogo os={os} />
        </LogoWrapper>
        <span>{__("sign_in_with_google", "Sign in with Google")}</span>
      </SocialButton>
    );
  };

  return (
    <SocialButton os={os} className="login-button-google" onClick={onButtonClick}>
      <GoogleLogo os={os} />
    </SocialButton>
  );
}

export default GoogleButton;
