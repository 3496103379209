// @flow

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { __ } from "../lib/translate";
import UserDropdown from "./UserDropdown";
import Notifications from "./Notifications";

import type { UserState } from "../lib/state/reducers/user";

type UserHeaderProps = {
  user: UserState,
  onLoginRequest: () => void
};

const UserHeader = ({ user: { logged }, onLoginRequest, ...restProps }) => (
  <Wrap className="user-header-wrapper">
    {logged ? (
      <LoggedWrap>
        <NotificationsWrap>
          <Notifications {...restProps} />
        </NotificationsWrap>
        <UserDropdown {...restProps} />
      </LoggedWrap>
    ) : (
      <LogoutWrap>
        <Sign className="signin-header-button" data-testid="signin-header-button" onClick={onLoginRequest}>{__("signin", "Sign in")}</Sign>
      </LogoutWrap>
    )}
  </Wrap>
);

export default connect(state => ({ user: state.user }))(UserHeader);

const Wrap = styled.div`
  width: 100%;
  height: 60px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LoggedWrap = styled.div`
  height: 60px;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }
`;

const NotificationsWrap = styled.div`
  width: 48px;
  height: 60px;
  margin: 0px 10px;

  & > div {
    margin-top: 6px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const UserDropdownWrap = styled.div`
  height: 60px;
  & > div {
    vertical-align: middle;
  }
`;

const LogoutWrap = styled.div``;

const Sign = styled.a`
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin-left: 30px;
  min-width: 82px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #17bed0;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;
