import $ from "jquery";
import { __ } from "./translate";
import { htmlentities } from "./utils";

export default function renderTemplate(template, vars, opts) {
  console.log('RTEMPLATE', template, vars)

  opts = $.extend({}, {
    escape: true
  }, opts);

  var tmpl  = $(template).html().trim().toString();
  var toStr = function (str) {
    return opts.escape ? htmlentities(str) : str;
  }

  // i18n part
  // its not allowed {{{{{variable}} some text}}} and {{{Some text {{varialbe}}}}}
  // fixed versions: {{{ {{variable}} some text}}} and {{{Some text {{varialbe}} }}}
  if (tmpl.match(/({{{{{|}}}}})/) !== null) {
    throw new Error("Invalid translation expression!!!\nIt is not allowed to have concacted "+
    "transaltion start and variable start, and variable end and translation end markers. They must be separated");
  }
  var re = /{{{(.+?)}}}/gmi;
  tmpl = tmpl.replace(re, function(a,b) {
    var key = b;
    var val = false;
    if (b.indexOf('|') != -1) {
      key = b.substring(0, b.indexOf('|'));
      val = b.substring(b.indexOf('|') + 1);
    }

    return __(key, val);
  });
  // /end i18n

  for (var n in vars) {
      while (tmpl.indexOf('{{foreach:') != -1) {
          var foreach = tmpl.match(/{{foreach::([^}]+)}}\s*([\s\S]+?)\s*{{\/foreach}}/i);
          var items = foreach[1], itemTemplate = foreach[2];
          var foreachHtml = '';
          for (var keys=0, itemsLength = vars[items].length; keys < itemsLength; keys++) {
            var row = itemTemplate;
            for (var key in vars[items][keys]) {
              if (vars[items][keys].hasOwnProperty(key)) {
                row = row.replace('{{item::'+key+'}}', toStr(vars[items][keys][key]));
              }
            }
            foreachHtml += row;
          }
          tmpl = tmpl.replace(foreach[0], foreachHtml);
      }
      for (var i = 0; i < 20; i++) {
        tmpl = tmpl.replace('{{'+n+'}}', toStr(vars[n]));
      }
  }

  return $(tmpl);
};
