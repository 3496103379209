//  @flow

import React, { Component } from "react";
import Signup from "./Signup";
import Signin from "./Signin";

type Props = {
  initialView: "login" | "register",
  subTitle: string,
  canSwitch: boolean,
  title: {},
  redirect_uris: object,
  isLoginModal: boolean,
  onSuccess: () => void,
  onForgotPassword: () => void,
};
type State = {
  view: "login" | "register"
};

export default class ModalSignin extends Component<Props, State> {
  static defaultProps = { initialView: "login", subTitle: "", title: {}, canSwitch: true };
  constructor(props: Props) {
    super(props);

    this.state = { view: props.initialView };
  }

  render() {
    const { initialView, canSwitch, title, isLoginModal = true, ...props } = this.props;
    const { view } = this.state;

    let toRegister = () => this.setState({ view: "register" });
    let toLogin = () => this.setState({ view: "login" });
    if (!canSwitch) {
      toLogin = toRegister = null;
    }

    return view === "login" ? (
      <Signin {...props} title={title.login} toRegister={toRegister} isLoginModal={isLoginModal} />
    ) : (
      <Signup {...props} title={title.register} toLogin={toLogin} isLoginModal={isLoginModal} />
    );
  }
}
