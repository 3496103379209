// @flow
import React, { Component } from "react";
import { hiDPI } from "polished";

import { ButtonSmall } from "./FacebookButton"; // DONE
import APPLELogin from "../../../lib/apple_login"; // DONE
import { EXTERNAL_APPS, APPLE_SDK } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { __ } from "../../../lib/translate"; // DONE
import { loadScript } from "../../../lib/utils"; // DONE
import SocialButton from "../MultiplatformComponents/Elements/SocialButton"; // DONE
import AppleLogo from "../../../../root/img/svg/signin-with-apple.svg" // DONE
import styled from "styled-components";

type Props = {
  os: number,
  size?: "small" | "big",
  receiveToken: (string, string) => void,
  getRequestId: () => void,
  getToken: () => void,
  redirect_uris: object,
  passpcloud: boolean
};

class AppleButton extends Component<Props, {}> {
  static defaultProps = {
    os: 4,
    size: "small",
    passpcloud: false
  };

  constructor(props: Props) {
    super(props);

    (this: any).onButtonClick = this.onButtonClick.bind(this);
    (this: any).onAuthSuccess = this.onAuthSuccess.bind(this);
    (this: any).onAuthError = this.onAuthError.bind(this);
  }

  componentDidMount() {
    loadScript(APPLE_SDK, () => {
      APPLELogin.init(true);
    });
  }

  onAuthSuccess({ authorization, user }: any) {
    const { receiveToken } = this.props;
    const token = authorization.id_token;
    console.log("RESPONSE>>>>>>>>>>>", { authorization, user });

    receiveToken(token, EXTERNAL_APPS.APPLE);

    if (user) {
      try {
        window.localStorage.setItem("apple_user", JSON.stringify(user));
      } catch (error) {}
    }
  }

  onAuthError(error: any) {
    console.log("ERROR>>>>>>>>>>>", error);
  }

  onButtonClick() {
    const { redirect_uris, getRequestId, getToken, receiveToken, passpcloud } = this.props;
    console.log("🚀 ~ file: AppleButton.js ~ line 57 ~ AppleButton ~ onButtonClick ~ passpcloud", passpcloud)

    if (redirect_uris) {
      let params = "";
      if (redirect_uris) {
        params = "is_extension";
      }
      if (passpcloud) {
        params = "passpcloud";
      }
      getRequestId(params)
        .then(({ request_id }) => {
          window.open(redirect_uris.apple + "?request_id=" + request_id, "apple-login", "width=550,height=450");
          getToken('apple_oauth2_token')
            .then(({ access_token }) => {
              receiveToken(access_token, EXTERNAL_APPS.APPLE);
            })
        });
    } else {
      const promise = APPLELogin.signIn();
      promise.then(this.onAuthSuccess, this.onAuthError);
    }
  }

  render() {
    const { size, os } = this.props;

    if (size === "big") {
      return <AppleBig className="login-button-apple" onClick={this.onButtonClick}>{__("sign_in_with_apple", "Sign in with Apple")}</AppleBig>
    };

    return (
      <SocialButton os={os} className="login-button-apple" onClick={this.onButtonClick}>
        <AppleLogo width="16.284px"/>
      </SocialButton>
    );
  }
}

export default AppleButton;

const AppleSmall = styled(ButtonSmall)`
  border: none !important;
  border-radius: 8px;
  background-color: #f2f2f4;
  margin: 0;
  background-image: url(${require("../../../../root/img/apple.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/apple@2x.png")});
  }
`;

const AppleBig = styled(AppleSmall)`
  width: 100% !important;
  border-radius: 7px;
  color: #fff !important;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.01);
  background-color: #111111;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 18px 22px;
  margin-top: 35px;

  background-image: url(${require("../../../../root/img/apple-white.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/apple-white@2x.png")});
  }
`;
