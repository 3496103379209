// @flow

import type {
  Userinfo,
  RelocationInfo,
  UserSettings,
  BillingInfo,
  PaymentsInfo
} from "../../../types/user";
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_MODIFY,
  USER_COMPLETE_JOURNEY,
  USER_SET_HAS_PASSWORD,
  USER_SET_PASSWORD,
  USER_DELETE_PASSWORD,
  USER_SET_PHONE_COUNTRY_CODE,
  USER_SET_PHONE_NUMBER,
  USER_DELETE_PHONE_NUMBER,
  USER_SET_VERIFIED_PHONE_NUMBER,
  USER_SET_ACTIVE_TWO_FACTOR_TYPE,
  USER_SET_COUNTRY,
  USER_SET_CONTACTS,
  USER_SET_REGION_ID,
  USER_SET_RELOCATION_INFO,
  USER_SET_SETTINGS_INFO,
  USER_UPDATE_BILLING_INFO,
  USER_UPDATE_PAYMENT_INFO,
  USER_UPDATE_RELOCATION_STATUS,
  USER_SET_PAYMENT_SOURCE,
  USER_SET_PAYMENT_PROVIDER
} from "../constants/user";

// ACTION TYPES
export type UserAction =
  | UserLoginAction
  | UserLogoutAction
  | UserClaimJourneyRewardAction
  | UserSetHasPasswordAction
  | UserSetPasswordAction
  | UserDeletePasswordAction
  | UserSetPhoneCountryCodeAction
  | UserSetPhoneNumberAction
  | UserDeletePhoneNumberAction
  | UserSetVerifiedPhoneNumberAction
  | UserSetTwoFactorActiveTypeAction
  | UserSetCountryAction
  | UserSetContactsAction
  | UserSetRegionIdAction
  | UserSetRelocationInfo
  | UserSetSettingsInfo
  | UserUpdateBillingInfo
  | UserUpdatePaymentInfo
  | UserSetPaymentSource
  | UserSetPaymentProvider
  | UserUpdateRelocationStatus;

type UserLoginAction = { type: typeof USER_LOGIN, token: string, userinfo: Userinfo };
type UserLogoutAction = { type: typeof USER_LOGOUT };
type UserModifyAction = { type: typeof USER_MODIFY, userinfo: Userinfo };
type UserClaimJourneyRewardAction = { type: typeof USER_COMPLETE_JOURNEY };
type UserSetHasPasswordAction = { type: typeof USER_SET_HAS_PASSWORD };
type UserSetPasswordAction = { type: typeof USER_SET_PASSWORD, password: string };
type UserDeletePasswordAction = { type: typeof USER_DELETE_PASSWORD };
type UserSetPhoneCountryCodeAction = {
  type: typeof USER_SET_PHONE_COUNTRY_CODE,
  countryCode: string
};
type UserSetPhoneNumberAction = { type: typeof USER_SET_PHONE_NUMBER, msisdn: string };
type UserDeletePhoneNumberAction = { type: typeof USER_DELETE_PHONE_NUMBER };
type UserSetVerifiedPhoneNumberAction = {
  type: typeof USER_SET_VERIFIED_PHONE_NUMBER,
  verifiedPhoneNumber: string
};
type UserSetTwoFactorActiveTypeAction = {
  type: typeof USER_SET_ACTIVE_TWO_FACTOR_TYPE,
  activeTfaType: number
};
type UserSetCountryAction = { type: typeof USER_SET_COUNTRY, userCountry: string };
type UserSetContactsAction = { type: typeof USER_SET_CONTACTS, userContacts: Array<any> };
type UserSetRegionIdAction = { type: typeof USER_SET_REGION_ID, userRegionId: number };
type UserSetRelocationInfo = {
  type: typeof USER_SET_RELOCATION_INFO,
  relocationInfo: RelocationInfo
};
type UserSetSettingsInfo = {
  type: typeof USER_SET_SETTINGS_INFO,
  settingsInfo: UserSettings
};
type UserUpdateBillingInfo = {
  type: typeof USER_UPDATE_BILLING_INFO,
  billingInfo: BillingInfo
};
type UserUpdatePaymentInfo = {
  type: typeof USER_UPDATE_PAYMENT_INFO,
  paymentInfo: PaymentsInfo
};
type UserUpdateRelocationStatus = {
  type: typeof USER_UPDATE_RELOCATION_STATUS,
  relocationStatus: number
};
type UserSetPaymentSource = {
  type: typeof USER_SET_PAYMENT_SOURCE,
  paymentSource: any
};
type UserSetPaymentProvider = {
  type: typeof USER_SET_PAYMENT_PROVIDER,
  paymentProvider: any
};

// ACTION CREATORS
export function login(userinfo: Userinfo, token: string): UserLoginAction {
  return { type: USER_LOGIN, token: token, userinfo: userinfo };
}

export function logout(): UserLogoutAction {
  return { type: USER_LOGOUT };
}

export function modify(userinfo: Userinfo): UserModifyAction {
  return { type: USER_MODIFY, userinfo: userinfo };
}

export function completeJourney(): UserClaimJourneyRewardAction {
  return { type: USER_COMPLETE_JOURNEY };
}

export function setHasPassword(): UserSetHasPasswordAction {
  return { type: USER_SET_HAS_PASSWORD };
}

export function setPassword(password: string): UserSetPasswordAction {
  return { type: USER_SET_PASSWORD, password };
}

export function deletePassword(): UserDeletePasswordAction {
  return { type: USER_DELETE_PASSWORD };
}

export function setPhoneCountryCode(countryCode: string): UserSetPhoneCountryCodeAction {
  return { type: USER_SET_PHONE_COUNTRY_CODE, countryCode };
}

export function setPhoneNumber(msisdn: string): UserSetPhoneNumberAction {
  return { type: USER_SET_PHONE_NUMBER, msisdn };
}

export function deletePhoneNumber(): UserDeletePhoneNumberAction {
  return { type: USER_DELETE_PHONE_NUMBER };
}

export function setVerifiedPhoneNumber(
  verifiedPhoneNumber: string
): UserSetVerifiedPhoneNumberAction {
  return { type: USER_SET_VERIFIED_PHONE_NUMBER, verifiedPhoneNumber };
}

export function setTwoFactorActiveType(activeTfaType: number): UserSetTwoFactorActiveTypeAction {
  return { type: USER_SET_ACTIVE_TWO_FACTOR_TYPE, activeTfaType };
}

export function setUserCountry(userCountry: string): UserSetCountryAction {
  return { type: USER_SET_COUNTRY, userCountry };
}

export function setUserContacts(userContacts: Array<any>): UserSetContactsAction {
  return { type: USER_SET_CONTACTS, userContacts };
}

export function setUserRegionId(userRegionId: number): UserSetRegionIdAction {
  return { type: USER_SET_REGION_ID, userRegionId };
}

export function setUserRelocationInfo(relocationInfo: RelocationInfo): UserSetRelocationInfo {
  return { type: USER_SET_RELOCATION_INFO, relocationInfo };
}

export function setUserSettingsInfo(settingsInfo: UserSettings): UserSetSettingsInfo {
  return { type: USER_SET_SETTINGS_INFO, settingsInfo };
}

export function updateUserBillingInfo(billingInfo: BillingInfo): UserUpdateBillingInfo {
  return { type: USER_UPDATE_BILLING_INFO, billingInfo };
}

export function updateUserPaymentInfo(paymentInfo: PaymentsInfo): UserUpdatePaymentInfo {
  return { type: USER_UPDATE_PAYMENT_INFO, paymentInfo };
}

export function setPaymentSource(paymentSource: any): UserSetPaymentSource {
  return { type: USER_SET_PAYMENT_SOURCE, paymentSource };
}

export function setPaymentProvider(paymentProvider: any): UserSetPaymentProvider {
  return { type: USER_SET_PAYMENT_PROVIDER, paymentProvider };
}

export function updateUserRelocationStatus(
  relocationStatus: RelocationStatus
): UserUpdateRelocationStatus {
  return { type: USER_UPDATE_RELOCATION_STATUS, relocationStatus };
}

export const userActionCreators = {
  login,
  logout,
  modify,
  completeJourney,
  setHasPassword,
  setPassword,
  deletePassword,
  setPhoneCountryCode,
  setPhoneNumber,
  deletePhoneNumber,
  setVerifiedPhoneNumber,
  setTwoFactorActiveType,
  setUserCountry,
  setUserContacts,
  setUserRegionId,
  setUserRelocationInfo,
  setUserSettingsInfo,
  updateUserRelocationStatus,
  setPaymentSource,
  setPaymentProvider,
  updateUserBillingInfo,
  updateUserPaymentInfo
};
