// @flow

import {
  NOTIFICATIONS_LOAD,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_SHOW,
  NOTIFICATIONS_RESET
} from "../constants/notifications";

import type { Notification } from "../../notifications/types";
import type { Action } from "../actions/types";

export type NotificationsState =
  | { loaded: false }
  | {
      loaded: true,
      notifications: Array<Notification>,
      showNotifications: Array<Notification>
    };

const initialState: NotificationsState = { loaded: false };

export function notificationsReducer(state: NotificationsState = initialState, action: Action) {
  switch (action.type) {
    case NOTIFICATIONS_LOAD:
      return { showNotifications: [], ...state, loaded: true, notifications: action.notifications };

    case NOTIFICATIONS_READ:
      if (state.loaded) {
        return { ...state, notifications: readNotifications(state.notifications) };
      }
      return state;

    case NOTIFICATIONS_SHOW:
      if (state.loaded) {
        return { ...state, showNotifications: [...state.notifications] };
      }

      return state;

    case NOTIFICATIONS_RESET:
      return initialState;
    default:
      return state;
  }
}

function readNotifications(notifications: Array<Notification>): Array<Notification> {
  return notifications.map((notification: Notification) => {
    const newNotification: any = { ...notification, isnew: false };
    return newNotification;
  });
}
