import styles from "../styles/dropdown.less";
import ReactTooltip from 'react-tooltip';
import { detectIsMobile } from "../lib/utils";


const dropDown = {
    N_TEXT: 0,
    N_HREF: 1,
    N_SUB:  2,
    N_EXPLN: 3,
    N_TARGET: 4,

    DIR_RIGHT: 1,
    DIR_LEFT: -1,
    DIR_CENTER: 0,

    DIR_DOWN: 1,
    DIR_UP: -1,

    defaultOpts: {
      mouseTrigger: false,
      direction: 1,
      vdirection: 1,
      childDirection: 1,
      eventTrigger: 'mouseover',
      eventClose: 'mouseout',
      position: 'absolute',
      holderClass: false,
      reverseDirections: false,
      showTip: true,
      buildCell: function (item, el) {
        return $('<a>')
          .attr('href', item[dropDown.N_HREF])
          .append(
            $('<li>').html( item[dropDown.N_TEXT] + ( item[dropDown.N_SUB] && 0 ? ' (sub)': '' ) )
          )
          .on('click', function (e) {
            dropDown.resetTo(0);
          })
          .appendTo(el);
      },
      buildHolder: function () { },
      onOpen: function () {},
      onClose: function () {}
    },

    stack: [],
    timeOut: null,

    triggerList: function (list, pos, opts) {
        opts = $.extend({}, this.defaultOpts, opts, { mouseTrigger: true });

        var el =
            $('<div class="contextholder"></div>', { width: '1px', height: '1px', position: 'absolute' })
                .css({
                    left: pos.x - 2,
                    top: pos.y - 2
                })
                .appendTo(document.body);

        opts.onClose = function (el) {
            el.unbind().remove();
        }.bind(null, el)

        el.data('mn', { list: list, opts: opts });
        dropDown.show(el, 0);
    },
    bindList: function (list, el, opts) {
      opts = $.extend({}, this.defaultOpts, opts);

      if (opts.reverseDirections) {
        opts.direction *= -1;
        opts.childDirection *= -1;
      }

      $(el)
          .data('mn', { list: list, opts: opts })
          .off('.ddown')
          .on(opts.eventTrigger + '.ddown', function (e) {
              // e.stopPropagation();

              console.log('the event');

              var sameElement = $(el).hasClass('opn');

              if (opts.eventClose == 'click' && dropDown.stack.length) {
                  $(document).off('.outorigin');
                  dropDown.close();
              }

              if (!sameElement) {
                  dropDown.show(this, 0);
                  if ($(".context-menu-active").length) {
                    //close context menu
                    $(".context-menu-active").contextMenu("hide");
                  }
              }
          })
    },
    unbindList: function (el) {
      /*
      if (this.stack.length) {
        if (dropDown.stack[0].parent[0] === el) {
          this.close();
        }
      }
      */

      $(el).removeData('mn').off('.ddown');
      $(document).off('.outorigin');
    },
    show: function (el, lvl) {
      const HALF_WIDTH_TIP = 3;
        if ($(el).hasClass('opn')) {
            console.log('stoping');
            this.stop();
            return;
        }

        this.resetTo(lvl);

        var dataObj = {
            lvl: lvl, // data id form the list arr/obj
            parent: $(el),
            el: $('<div class="mn-holder mnnew jsmin"></div>').append('<ul>')
        };
        var id = this.stack.push(dataObj) - 1;
        var top = $(el).offset().top;
        var left = $(el).offset().left;
        var opts = $(el).data('mn').opts;
        var dir = lvl && opts.childDirection || opts.direction;
        var vdir = opts.vdirection;
        var childDir = opts.childDirection;

        dataObj.el.appendTo('body');

        console.log('off', $(el).offset());

        if (lvl) {
          dataObj.el.addClass('sub');
        }

        opts.buildHolder(dataObj.el, lvl);

        console.log("DIR", lvl, opts);

        $(el).addClass("opn");

        dataObj.el.addClass('mnnew jsmn');

        if (opts.holderClass) {
          dataObj.el.addClass(opts.holderClass);
        }

        this.contents(id);

        if (childDir == dropDown.DIR_LEFT) {
          dataObj.el.addClass('leftdir');
        }
        if ((top + dataObj.el.outerHeight() + 5) > ($(window).scrollTop() + $(window).height()) && opts.vdirection == dropDown.DIR_DOWN) {
          vdir = dropDown.DIR_UP;
        }
        if ((left + dataObj.el.outerWidth()) > ($(window).scrollLeft() + $(window).width()) && opts.direction == dropDown.DIR_RIGHT) {
          dir = dropDown.DIR_LEFT;
        }
        if (left - $(window).scrollLeft() < dataObj.el.outerWidth() && opts.direction == dropDown.DIR_LEFT) {
          dir = dropDown.DIR_RIGHT;
        }
        if (left - $(window).scrollLeft() < dataObj.el.outerWidth() && opts.direction == dropDown.DIR_CENTER) {
          dir = dropDown.DIR_RIGHT;
        }
        console.log('left is', left);
        console.log('wi', dataObj.el.outerWidth());

        // offset accounting the height of the tip (top, bottom)
        // based on the height of the tip
        var tipOffset = 10;

        if (lvl === 0) {
          if (vdir === dropDown.DIR_UP) {
            //top -= $(el).outerHeight();
            top -= dataObj.el.outerHeight();

            if (opts.showTip) {
              top -= tipOffset;
            }
          } else {
            top += $(el).outerHeight();
            if (opts.showTip) {
              top += tipOffset;
            }
          }

          if (dir === dropDown.DIR_LEFT) {
            left -= $(this.stack[id].el).outerWidth() - $(el).outerWidth();
          }

          if (dir === dropDown.DIR_CENTER) {
            left -= $(this.stack[id].el).outerWidth() / 2 - $(el).outerWidth() / 2;
          }

          ReactTooltip.rebuild();
          console.log("SHOW!")
        } else {
          if (dir === dropDown.DIR_RIGHT) {
            left += $(el).outerWidth() - 2;
          } else {
            left -= $(this.stack[id].el).outerWidth() - 2;
          }

          // realign
          top -= 4;

          /*
          if (vdir === dropDown.DIR_UP && 0) {
            top += 4;
          } else {
            top -= 4;
          }
          */

          /*
            if ((top + dataObj.el.outerHeight() + 5) > ($(window).scrollTop() + $(window).height()) && opts.vdirection == dropDown.DIR_DOWN) {
          */

          var nScrollTop = opts.position === "fixed" && lvl === 0 ? 0 : $(window).scrollTop();

          console.log("--------");
          console.log(top);
          console.log(dataObj.el.outerHeight());
          console.log((top + dataObj.el.outerHeight() + 5));
          console.log($(window).scrollTop());
          console.log(nScrollTop);
          console.log((nScrollTop + $(window).height()));
          console.log("--------");

          if ((top + dataObj.el.outerHeight() + 5) > (nScrollTop + $(window).height())) {
            console.log("Ingo");
            //top -= (top + dataObj.el.outerHeight() + 5) - (nScrollTop + $(window).height());
            //top = ($(window).height() + nScrollTop) - dataObj.el.outerHeight();

            console.log("top #1", top);

            top -= ((top + dataObj.el.outerHeight() + 5) - ($(window).height() + nScrollTop));
            console.log("top #2", top);
          }
        }

        // embed tip
        if (lvl == 0 && opts.showTip) {
          var tip = $('<div class="tip"><div class="tip-inner"></div></div>').appendTo(dataObj.el);
          var side;
          var len;

          if (vdir == dropDown.DIR_UP) {
            dataObj.el.addClass('top');
          } else {
            dataObj.el.addClass('bottom');
          }

          if (dir == dropDown.DIR_CENTER) {
            tip.css("margin-left", "-" + tip.outerWidth() / 2 + "px");
            tip.css("left", "50%");
          } else {
            side = dir == dropDown.DIR_LEFT ? 'right' : 'left';
            len = $(el).outerWidth() / 2 - tip.outerWidth() / 2;
            if (opts.overwriteTip) {
              len = opts.overwriteTip.left || opts.overwriteTip.right;
            }

            if (detectIsMobile()) {
              len = left + HALF_WIDTH_TIP;
            }
  
            tip.css(side, len);
          }
        }

        console.log('off', $(el).offset());

        if (opts.position == 'fixed') {
          top -= $(window).scrollTop();
          left -= $(window).scrollLeft();
        }

        dataObj.el.css("top", top + "px").css("left", left + "px").css('position', opts.position);

        console.log('dataobj', dataObj.el);

        if (id === 0) {
          dataObj.el.css('opacity', 0).animate({opacity: 1}, 250);
        }

        if (lvl === 0) {
          setTimeout(() => { this.setupClose(el, opts, dataObj); }, 0);
        }

        opts.onOpen();
        this.stop();
    },

    setupClose: function(bindEl, opts, dataObj) {
      if (opts.eventClose == 'mouseout') {
          $(bindEl).on('mouseout.ddown', this.reset.bind(this)); // out of origin element

          dataObj.el.on('mouseover.ddown', this.stop.bind(this)); // out of origin -> in child
          dataObj.el.on('mouseout.ddown',  this.reset.bind(this)); // out of child menu element
      } else {
        $(document).off('.outorigin').on('click.outorigin', function (e) {
          console.log('click out origin', e.target);
          const target = $(e.target);
          for (let { el } of dropDown.stack) {
            console.log('look', el, el.find(target).length);

            if (el.find(target).length || el[0] === e.target) {
              return;
            }
          }

          console.log('look', $(bindEl), $(bindEl).find(target).length);
          if ($(bindEl).find(target).length || bindEl === e.target) {
            return;
          }

          $(document).off('.outorigin');
          dropDown.close();
        });
      }
    },

    contents: function (lvl) {
      console.log('stack data: ', this.stack[lvl]);

      var n = 0;
      var list = this.stack[lvl].parent.data('mn').list;
      var opts = this.stack[lvl].parent.data('mn').opts;
      var item;

      console.log('list', list);
      console.log('opts', opts);

      // mouseover for the whole list instead of per item
      // the submenus will stop propagation
      this.stack[lvl].el.on('mouseover', function(e) {
        dropDown.resetTo(lvl + 1);
      });

      for ( ; n < list.length; ++n) {
        item = opts.buildCell(list[n], this.stack[lvl].el.find('ul'));

        if (list[n][dropDown.N_SUB] && list[n][dropDown.N_SUB].length) {
          item
            .addClass('sub')
            .data('mn', { list: list[n][dropDown.N_SUB], opts: opts })
            .on('mouseover', function(e) {
              e.stopPropagation();

              dropDown.show(this, lvl + 1);
            });
        } else if (0) {
          item.on('mouseover', function(e) {
            dropDown.resetTo(lvl + 1);
          });
        }
      }
    },

    resetTo: function (i) {
        var n = this.stack.length - 1;
        for (; n >= i; --n) {
            if (n == 0)
                if ( this.stack[n].parent.data('mn') && this.stack[n].parent.data('mn').opts )
                    this.stack[n].parent.data('mn').opts.onClose();
            this.stack[n].el.remove();
            this.stack[n].parent.removeClass('opn');
         }
        this.stack = this.stack.slice(0, i);
    },
    stop: function () {
      console.log("stop");

      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = null;
    },
    reset: function () {
      console.log("reset");

      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(function () {
        dropDown.stop();
        dropDown.resetTo(0);
      }, 400);
    },
    close: function () {
        this.stop();
        this.resetTo(0);
        $(document).off('.outorigin');
    }
}

export default dropDown;
