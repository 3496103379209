// @flow

const config = {
  apiKey: "AIzaSyC-2RK0F3uqxaHObaOweT08kg3Jwz4YvBc",
  authDomain: "pcloud-f0c22.firebaseapp.com",
  messagingSenderId: "214464925930",
  projectId: "pcloud-f0c22",
  appId: "1:214464925930:android:1d49904a8abcbd2c"
};

export default config;
