import React from "react";
import { Button as WindowsButton } from "../WindowsStyles";
import { Button as MacButton } from "../MacStyles";
import { Button as LinuxButton } from "../LinuxStyles";
import { Button as IosButton } from "../iOSStyles";
import { Button as AndroidButton } from "../AndroidStyles";
import WebButton from "../../../../components/SubmitButton";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Button = ({ os, loading, children, className = "", ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return (
        <AndroidButton className={"multiplatform-butt android " + className} {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </AndroidButton>
      )
    case DEVICE_OS.iOS:
      return (
        <IosButton className={"multiplatform-butt ios " + className} {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </IosButton>
      )
    case DEVICE_OS.Web:
      return (
        <WebButton
          className={`${className} multiplatform-butt`}
          loading={loading}
          minHeight="48px"
          fontSize="16px"
          borderRadius="12px"
          children={children}
          {...rest}
        />
      )
    case DEVICE_OS.Win:
      return (
        <WindowsButton className={"multiplatform-butt windows " + className} {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </WindowsButton>
      )
    case DEVICE_OS.MacOSX:
      return (
        <MacButton className={"multiplatform-butt mac " + className} {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </MacButton>
      )
    case DEVICE_OS.Linux:
      return (
        <LinuxButton className={"multiplatform-butt linux " + className} {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </LinuxButton>
      )
    default:
      return <WebButton loading={loading} children={children} {...rest} />
  }
};

export default Button;
