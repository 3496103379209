// @flow

import React, { Component } from "react";
import styled from "styled-components";
import apiMethod from "../../../api/apiMethod"; // DONE
import apiConfig, { EU_API } from "../../../api/config"; // DONE

import FacebookButton from "./FacebookButton"; // DONE
import GoogleButton from "./GoogleButton"; // DONE
import AppleButton from "./AppleButton"; // DONE

type Props = {
  os: number,
  view?: "all" | "google" | "facebook" | "apple",
  receiveToken: string => void,
  redirect_uris: object,
  passpcloud: boolean,
  openUnavailableModal: () => void
};

class ButtonsWrapper extends Component<Props, {}> {
  static defaultProps = {
    os: 4,
    view: "all",
    passpcloud: false,
    openUnavailableModal: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = { request_id: "" };

    (this: any).getRequestId = this.getRequestId.bind(this);
    (this: any).getToken = this.getToken.bind(this);
  }

  getRequestId(params) {
    return new Promise((resolve, reject) => {
      apiMethod(
        "getrequestid",
        {},
        ({ request_id }) => {
          const requestId = request_id + params;

          this.setState({ request_id: requestId });
          resolve({ request_id: requestId });
        },
        {
          errorCallback: error => {
            reject(error);
          },
          apiServer: EU_API
        }
      );
    });
  }

  getToken(method) {
    return new Promise((resolve, reject) => {
      const { request_id } = this.state;

      apiMethod(
        method,
        { request_id },
        ({ access_token }) => {
          resolve({ access_token });
        },
        {
          errorCallback: error => {
            reject(error);
          },
          timeout: 0,
          apiServer: EU_API
        }
      );
    });
  }

  renderSwitch() {
    const { view, receiveToken, redirect_uris, passpcloud, os, language, openUnavailableModal } = this.props;

    switch (view) {
      case "all":
        return (
          <Wrapper className="login-buttons-wrapper">
            <AppleButton
              os={os}
              receiveToken={receiveToken}
              getRequestId={this.getRequestId}
              getToken={this.getToken}
              redirect_uris={redirect_uris}
              passpcloud={passpcloud}
              language={language}
            />
            {/* <FacebookButton
              os={os}
              receiveToken={receiveToken}
              getRequestId={this.getRequestId}
              getToken={this.getToken}
              redirect_uris={redirect_uris}
              passpcloud={passpcloud}
              className="login-buttons-facebook"
              openUnavailableModal={openUnavailableModal}
              language={language}
            /> */}
            <GoogleButton
              os={os}
              receiveToken={receiveToken}
              getRequestId={this.getRequestId}
              getToken={this.getToken}
              redirect_uris={redirect_uris}
              passpcloud={passpcloud}
              className="login-buttons-google"
              language={language}
            />
          </Wrapper>
        );
      case "google":
        return (
          <GoogleButton
            size="big"
            os={os}
            receiveToken={receiveToken}
            getRequestId={this.getRequestId}
            getToken={this.getToken}
            redirect_uris={redirect_uris}
            passpcloud={passpcloud}
            className="login-buttons-google"
            language={language}
          />
        );
      // case "facebook":
      //   return (
      //     <FacebookButton
      //       size="big"
      //       os={os}
      //       receiveToken={receiveToken}
      //       getRequestId={this.getRequestId}
      //       getToken={this.getToken}
      //       redirect_uris={redirect_uris}
      //       passpcloud={passpcloud}
      //       className="login-buttons-facebook"
      //       openUnavailableModal={openUnavailableModal}
      //       language={language}
      //     />
      //   );
      case "apple":
        return (
          <AppleButton
            size="big"
            os={os}
            receiveToken={receiveToken}
            getRequestId={this.getRequestId}
            getToken={this.getToken}
            redirect_uris={redirect_uris}
            passpcloud={passpcloud}
            className="login-buttons-apple"
            language={language}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return <React.Fragment>{this.renderSwitch()}</React.Fragment>;
  }
}

export default ButtonsWrapper;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div {
    cursor: pointer;
  }
`;
