import React from "react";
import { SocialButton as WebSocialButton } from "../WebStyles";
import { SocialButton as WindowsSocialButton } from "../WindowsStyles";
import { SocialButton as MacSocialButton } from "../MacStyles";
import { SocialButton as LinuxSocialButton } from "../LinuxStyles";
import { SocialButton as IosSocialButton } from "../iOSStyles";
import { SocialButton as AndroidSocialButton } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const SocialButton = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidSocialButton {...rest}/>
    case DEVICE_OS.iOS:
      return <IosSocialButton {...rest}/>
    case DEVICE_OS.Web:
      return <WebSocialButton {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsSocialButton {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacSocialButton {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxSocialButton {...rest}/>
    default:
      return <WebSocialButton {...rest}/>
  }
};

export default SocialButton;
