// @flow

import React, { useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import FileThumb from "../components/FileThumb";
import FileIcon from "../components/FileIcon";
import { ICONS } from "../lib/icons";
import popOver from "../lib/popOver";
import { boldConverter } from "../lib/componentifyConverters";
import ToLocalTime from "./ToLocalTime";

import type { Notification as NotificationType } from "../lib/notifications/types";

const Notification = ({
  notification: { iconid, notification, mtime, isnew, thumb, url }
}: {
  notification: NotificationType
}) => {
  const [isThumbFailed, setIsThumbFailed] = useState(false);
  return (
    <NotificationWrap href={url} onClick={() => { popOver.closeAll(); }}>
      {thumb && !isThumbFailed
        ? <FileThumb type={ICONS.NOTIFICATIONS} thumb={thumb} onError={() => { setIsThumbFailed(true) }} />
        : <FileIcon item={iconid} type={ICONS.NOTIFICATIONS} />}
      <NotificationBody>
        <Componentify text={notification} converters={[boldConverter]} />
        <NotificationDate>
          <ToLocalTime time={mtime} />
          {/* {formatDtTime(mtime)} */}
        </NotificationDate>
      </NotificationBody>
    </NotificationWrap>
  )
}

export default Notification;

const NotificationWrap = styled.a`
  min-height: 36px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 12px;
  padding: 10px 15px 10px 15px;
  background: ${props => (props.isnew ? "#e1f9fd" : "#fff")};
  cursor: pointer;
  display: block;
  color: #000;
  text-decoration: none;

  & > .iconwrap {
    float: left;
  }

  &.empty {
    text-align: center;
    line-height: 36px;
    height: 36px;
  }

  &:hover {
    background-color: #f3fdfe;
  }
`;

const NotificationBody = styled.div`
  margin: 0 0 0 46px;
  line-height: 16px;
  color: #474747;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  -ms-hyphens: auto;

  & b {
    font-weight: bold;
  }
`;

const NotificationDate = styled.span`
  display: block;
  color: #999;
  font-size: 11px;
  margin-top: 2px;
`;
