import React from "react";
import { __ } from "../../lib/translate";
import SubmitButton from "../SubmitButton";
import { ButtonWrapper } from "./MainComponents"

export const LogoutButton = ({ logoutUser }) => {
  return (
    <ButtonWrapper>
      <SubmitButton style={{width: "100%"}} onClick={logoutUser}>{__("viva_logout")}</SubmitButton>
    </ButtonWrapper>
  );
}
