// @flow
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { prepUrl } from "../../api/utils";
import { errorKeys } from "../../lib/errors";

import Button from "../ButtonDefault";
import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import * as Style from "./styledComponents";

import PreviewDocErrorIcon from "../../../root/img/svg/preview-document-error.svg";
import PasswordProtectedDocErrorIcon from "../../../root/img/svg/preview-password-protected-error.svg";

type Props = {
  documentName: string,
  candownload?: boolean,
  downloadmethod?: string,
  downloadparams?: object,
  passwordProtected?: boolean
};

const PreviewDocErrorModal = ({
  documentName,
  candownload,
  downloadmethod,
  downloadparams,
  passwordProtected
}: Props) => {
  const [isOpened, setIsOpened] = useState(true);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDownloadClick();
      }
    },
    [onDownloadClick]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]);

  const onCloseClick = () => {
    setIsOpened(false);
  };

  const onDownloadClick = useCallback(() => {
    apiMethod(
      downloadmethod,
      downloadparams,
      ret => {
        window.location = prepUrl(ret);
        setIsOpened(false);
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          throw new Error(error);
        }
      }
    );
  });

  const renderContent = () => {
    return (
      <React.Fragment>
        <Body>
          <Style.Header>{__("Preview Document", "Preview Document")}</Style.Header>
          <Style.Message>
            {passwordProtected ? <PasswordProtectedDocErrorIcon /> : <PreviewDocErrorIcon />}
            <Style.ElementBold style={{ padding: "8px 0 0 0" }}>{documentName}</Style.ElementBold>
            <Style.Title style={{ margin: "0 0 6px 0" }}>
              {passwordProtected
                ? __("cannot_preview_password", "This file is password-protected.")
                : __("cannot_prevew_file", "This file cannot be previewed.")}
            </Style.Title>
          </Style.Message>
        </Body>
        <Style.Footer>
          <Button style={{ marginRight: "5px" }} color="lightgray4" onClick={onCloseClick}>
            {__("Close")}
          </Button>
          {candownload ? (
            <Button style={{ marginLeft: "5px" }} color="cyan" onClick={onDownloadClick}>
              {__("Download File")}
            </Button>
          ) : null}
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal
      animate
      shouldCloseOnOverlayClick={false}
      onClose={() => setIsOpened(false)}
      opened={isOpened}
    >
      <OvalModalWrap>
        <CloseModalIcon onClick={() => setIsOpened(false)} />
        <Style.Container>{renderContent()}</Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default PreviewDocErrorModal;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
