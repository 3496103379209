const mimetypes = {
    "323": {
        "iconid": 0,
        "category": 0
    },
    "ez": {
        "iconid": 0,
        "category": 0
    },
    "anx": {
        "iconid": 0,
        "category": 0
    },
    "atom": {
        "iconid": 0,
        "category": 0
    },
    "atomcat": {
        "iconid": 0,
        "category": 0
    },
    "atomsrv": {
        "iconid": 0,
        "category": 0
    },
    "lin": {
        "iconid": 0,
        "category": 0
    },
    "cu": {
        "iconid": 0,
        "category": 0
    },
    "davmount": {
        "iconid": 0,
        "category": 0
    },
    "dcm": {
        "iconid": 0,
        "category": 0
    },
    "tsp": {
        "iconid": 0,
        "category": 0
    },
    "es": {
        "iconid": 0,
        "category": 0
    },
    "spl": {
        "iconid": 0,
        "category": 0
    },
    "hta": {
        "iconid": 0,
        "category": 0
    },
    "jar": {
        "iconid": 0,
        "category": 0
    },
    "ser": {
        "iconid": 0,
        "category": 0
    },
    "class": {
        "iconid": 0,
        "category": 0
    },
    "js": {
        "iconid": 0,
        "category": 0
    },
    "json": {
        "iconid": 0,
        "category": 0
    },
    "m3g": {
        "iconid": 0,
        "category": 0
    },
    "hqx": {
        "iconid": 0,
        "category": 0
    },
    "cpt": {
        "iconid": 1,
        "category": 1
    },
    "nb": {
        "iconid": 0,
        "category": 0
    },
    "nbp": {
        "iconid": 0,
        "category": 0
    },
    "mbox": {
        "iconid": 0,
        "category": 0
    },
    "mdb": {
        "iconid": 4,
        "category": 4
    },
    "doc": {
        "iconid": 6,
        "category": 4
    },
    "dot": {
        "iconid": 6,
        "category": 4
    },
    "mxf": {
        "iconid": 0,
        "category": 0
    },
    "bin": {
        "iconid": 0,
        "category": 0
    },
    "oda": {
        "iconid": 0,
        "category": 0
    },
    "ogx": {
        "iconid": 0,
        "category": 0
    },
    "one": {
        "iconid": 0,
        "category": 0
    },
    "onetoc2": {
        "iconid": 0,
        "category": 0
    },
    "onetmp": {
        "iconid": 0,
        "category": 0
    },
    "onepkg": {
        "iconid": 0,
        "category": 0
    },
    "pdf": {
        "iconid": 17,
        "category": 4
    },
    "pgp": {
        "iconid": 0,
        "category": 0
    },
    "key": {
        "iconid": 0,
        "category": 0
    },
    "sig": {
        "iconid": 0,
        "category": 0
    },
    "prf": {
        "iconid": 0,
        "category": 0
    },
    "ps": {
        "iconid": 0,
        "category": 0
    },
    "ai": {
        "iconid": 0,
        "category": 0
    },
    "eps": {
        "iconid": 0,
        "category": 0
    },
    "epsi": {
        "iconid": 0,
        "category": 0
    },
    "epsf": {
        "iconid": 0,
        "category": 0
    },
    "eps2": {
        "iconid": 0,
        "category": 0
    },
    "eps3": {
        "iconid": 0,
        "category": 0
    },
    "rar": {
        "iconid": 5,
        "category": 5
    },
    "rdf": {
        "iconid": 0,
        "category": 0
    },
    "rtf": {
        "iconid": 0,
        "category": 0
    },
    "stl": {
        "iconid": 0,
        "category": 0
    },
    "smi": {
        "iconid": 0,
        "category": 0
    },
    "smil": {
        "iconid": 0,
        "category": 0
    },
    "xhtml": {
        "iconid": 0,
        "category": 0
    },
    "xht": {
        "iconid": 0,
        "category": 0
    },
    "xml": {
        "iconid": 0,
        "category": 0
    },
    "xsl": {
        "iconid": 0,
        "category": 0
    },
    "xsd": {
        "iconid": 0,
        "category": 0
    },
    "xspf": {
        "iconid": 0,
        "category": 0
    },
    "zip": {
        "iconid": 5,
        "category": 5
    },
    "apk": {
        "iconid": 0,
        "category": 0
    },
    "cdy": {
        "iconid": 0,
        "category": 0
    },
    "kml": {
        "iconid": 0,
        "category": 0
    },
    "kmz": {
        "iconid": 0,
        "category": 0
    },
    "xul": {
        "iconid": 0,
        "category": 0
    },
    "xls": {
        "iconid": 8,
        "category": 4
    },
    "xlb": {
        "iconid": 8,
        "category": 4
    },
    "xlt": {
        "iconid": 8,
        "category": 4
    },
    "xlam": {
        "iconid": 8,
        "category": 4
    },
    "xlsb": {
        "iconid": 8,
        "category": 4
    },
    "xlsm": {
        "iconid": 8,
        "category": 4
    },
    "xltm": {
        "iconid": 8,
        "category": 4
    },
    "eot": {
        "iconid": 0,
        "category": 0
    },
    "thmx": {
        "iconid": 0,
        "category": 0
    },
    "cat": {
        "iconid": 0,
        "category": 0
    },
    "ppt": {
        "iconid": 7,
        "category": 4
    },
    "pps": {
        "iconid": 7,
        "category": 4
    },
    "ppam": {
        "iconid": 7,
        "category": 4
    },
    "pptm": {
        "iconid": 7,
        "category": 4
    },
    "sldm": {
        "iconid": 7,
        "category": 4
    },
    "ppsm": {
        "iconid": 7,
        "category": 4
    },
    "potm": {
        "iconid": 7,
        "category": 4
    },
    "docm": {
        "iconid": 6,
        "category": 4
    },
    "dotm": {
        "iconid": 6,
        "category": 4
    },
    "odc": {
        "iconid": 15,
        "category": 4
    },
    "odb": {
        "iconid": 4,
        "category": 4
    },
    "odf": {
        "iconid": 0,
        "category": 0
    },
    "odg": {
        "iconid": 0,
        "category": 0
    },
    "otg": {
        "iconid": 0,
        "category": 0
    },
    "odi": {
        "iconid": 1,
        "category": 1
    },
    "odp": {
        "iconid": 15,
        "category": 4
    },
    "otp": {
        "iconid": 15,
        "category": 4
    },
    "ods": {
        "iconid": 14,
        "category": 4
    },
    "ots": {
        "iconid": 14,
        "category": 4
    },
    "odt": {
        "iconid": 13,
        "category": 4
    },
    "odm": {
        "iconid": 13,
        "category": 4
    },
    "ott": {
        "iconid": 13,
        "category": 4
    },
    "oth": {
        "iconid": 13,
        "category": 4
    },
    "pptx": {
        "iconid": 7,
        "category": 4
    },
    "sldx": {
        "iconid": 7,
        "category": 4
    },
    "ppsx": {
        "iconid": 7,
        "category": 4
    },
    "potx": {
        "iconid": 7,
        "category": 4
    },
    "xlsx": {
        "iconid": 8,
        "category": 4
    },
    "xltx": {
        "iconid": 8,
        "category": 4
    },
    "docx": {
        "iconid": 6,
        "category": 4
    },
    "dotx": {
        "iconid": 6,
        "category": 4
    },
    "cod": {
        "iconid": 0,
        "category": 0
    },
    "mmf": {
        "iconid": 0,
        "category": 0
    },
    "sdc": {
        "iconid": 0,
        "category": 0
    },
    "sds": {
        "iconid": 0,
        "category": 0
    },
    "sda": {
        "iconid": 0,
        "category": 0
    },
    "sdd": {
        "iconid": 0,
        "category": 0
    },
    "sdf": {
        "iconid": 0,
        "category": 0
    },
    "sdw": {
        "iconid": 13,
        "category": 4
    },
    "sgl": {
        "iconid": 13,
        "category": 4
    },
    "sxc": {
        "iconid": 0,
        "category": 0
    },
    "stc": {
        "iconid": 0,
        "category": 0
    },
    "sxd": {
        "iconid": 0,
        "category": 0
    },
    "std": {
        "iconid": 0,
        "category": 0
    },
    "sxi": {
        "iconid": 0,
        "category": 0
    },
    "sti": {
        "iconid": 0,
        "category": 0
    },
    "sxm": {
        "iconid": 0,
        "category": 0
    },
    "sxw": {
        "iconid": 13,
        "category": 4
    },
    "sxg": {
        "iconid": 13,
        "category": 4
    },
    "stw": {
        "iconid": 13,
        "category": 4
    },
    "sis": {
        "iconid": 0,
        "category": 0
    },
    "cap": {
        "iconid": 0,
        "category": 0
    },
    "pcap": {
        "iconid": 0,
        "category": 0
    },
    "vsd": {
        "iconid": 0,
        "category": 0
    },
    "wbxml": {
        "iconid": 0,
        "category": 0
    },
    "wmlc": {
        "iconid": 0,
        "category": 0
    },
    "wmlsc": {
        "iconid": 0,
        "category": 0
    },
    "wpd": {
        "iconid": 0,
        "category": 0
    },
    "wp5": {
        "iconid": 0,
        "category": 0
    },
    "wk": {
        "iconid": 0,
        "category": 0
    },
    "7z": {
        "iconid": 5,
        "category": 5
    },
    "abw": {
        "iconid": 4,
        "category": 4
    },
    "dmg": {
        "iconid": 16,
        "category": 5
    },
    "bcpio": {
        "iconid": 0,
        "category": 0
    },
    "torrent": {
        "iconid": 0,
        "category": 0
    },
    "cab": {
        "iconid": 0,
        "category": 0
    },
    "cbr": {
        "iconid": 0,
        "category": 0
    },
    "cbz": {
        "iconid": 0,
        "category": 0
    },
    "cdf": {
        "iconid": 0,
        "category": 0
    },
    "cda": {
        "iconid": 0,
        "category": 0
    },
    "vcd": {
        "iconid": 0,
        "category": 0
    },
    "pgn": {
        "iconid": 0,
        "category": 0
    },
    "mph": {
        "iconid": 0,
        "category": 0
    },
    "cpio": {
        "iconid": 0,
        "category": 0
    },
    "csh": {
        "iconid": 0,
        "category": 0
    },
    "deb": {
        "iconid": 0,
        "category": 0
    },
    "udeb": {
        "iconid": 0,
        "category": 0
    },
    "dcr": {
        "iconid": 0,
        "category": 0
    },
    "dir": {
        "iconid": 0,
        "category": 0
    },
    "dxr": {
        "iconid": 0,
        "category": 0
    },
    "dms": {
        "iconid": 0,
        "category": 0
    },
    "wad": {
        "iconid": 0,
        "category": 0
    },
    "dvi": {
        "iconid": 0,
        "category": 0
    },
    "woff": {
        "iconid": 0,
        "category": 0
    },
    "mm": {
        "iconid": 0,
        "category": 0
    },
    "gan": {
        "iconid": 0,
        "category": 0
    },
    "gnumeric": {
        "iconid": 0,
        "category": 0
    },
    "sgf": {
        "iconid": 0,
        "category": 0
    },
    "gcf": {
        "iconid": 0,
        "category": 0
    },
    "gtar": {
        "iconid": 5,
        "category": 5
    },
    "tgz": {
        "iconid": 5,
        "category": 5
    },
    "taz": {
        "iconid": 5,
        "category": 5
    },
    "hdf": {
        "iconid": 0,
        "category": 0
    },
    "hwp": {
        "iconid": 0,
        "category": 0
    },
    "ica": {
        "iconid": 0,
        "category": 0
    },
    "info": {
        "iconid": 0,
        "category": 0
    },
    "ins": {
        "iconid": 0,
        "category": 0
    },
    "isp": {
        "iconid": 0,
        "category": 0
    },
    "iii": {
        "iconid": 0,
        "category": 0
    },
    "iso": {
        "iconid": 5,
        "category": 5
    },
    "jam": {
        "iconid": 0,
        "category": 0
    },
    "jnlp": {
        "iconid": 0,
        "category": 0
    },
    "jmz": {
        "iconid": 0,
        "category": 0
    },
    "chrt": {
        "iconid": 4,
        "category": 4
    },
    "kil": {
        "iconid": 0,
        "category": 0
    },
    "skp": {
        "iconid": 0,
        "category": 0
    },
    "skd": {
        "iconid": 0,
        "category": 0
    },
    "skt": {
        "iconid": 0,
        "category": 0
    },
    "skm": {
        "iconid": 0,
        "category": 0
    },
    "kpr": {
        "iconid": 4,
        "category": 4
    },
    "kpt": {
        "iconid": 4,
        "category": 4
    },
    "ksp": {
        "iconid": 4,
        "category": 4
    },
    "kwd": {
        "iconid": 4,
        "category": 4
    },
    "kwt": {
        "iconid": 4,
        "category": 4
    },
    "latex": {
        "iconid": 0,
        "category": 0
    },
    "lha": {
        "iconid": 5,
        "category": 5
    },
    "lyx": {
        "iconid": 0,
        "category": 0
    },
    "lzh": {
        "iconid": 0,
        "category": 0
    },
    "lzx": {
        "iconid": 5,
        "category": 5
    },
    "frm": {
        "iconid": 0,
        "category": 0
    },
    "maker": {
        "iconid": 0,
        "category": 0
    },
    "frame": {
        "iconid": 0,
        "category": 0
    },
    "fm": {
        "iconid": 0,
        "category": 0
    },
    "fb": {
        "iconid": 0,
        "category": 0
    },
    "book": {
        "iconid": 0,
        "category": 0
    },
    "fbdoc": {
        "iconid": 0,
        "category": 0
    },
    "md5": {
        "iconid": 0,
        "category": 0
    },
    "mif": {
        "iconid": 0,
        "category": 0
    },
    "m3u8": {
        "iconid": 0,
        "category": 0
    },
    "wmd": {
        "iconid": 0,
        "category": 0
    },
    "wmz": {
        "iconid": 0,
        "category": 0
    },
    "com": {
        "iconid": 16,
        "category": 0
    },
    "exe": {
        "iconid": 16,
        "category": 0
    },
    "bat": {
        "iconid": 16,
        "category": 0
    },
    "dll": {
        "iconid": 16,
        "category": 0
    },
    "msi": {
        "iconid": 0,
        "category": 0
    },
    "nc": {
        "iconid": 0,
        "category": 0
    },
    "pac": {
        "iconid": 0,
        "category": 0
    },
    "dat": {
        "iconid": 0,
        "category": 0
    },
    "nwc": {
        "iconid": 0,
        "category": 0
    },
    "o": {
        "iconid": 0,
        "category": 0
    },
    "oza": {
        "iconid": 0,
        "category": 0
    },
    "p7r": {
        "iconid": 0,
        "category": 0
    },
    "crl": {
        "iconid": 0,
        "category": 0
    },
    "pyc": {
        "iconid": 0,
        "category": 0
    },
    "pyo": {
        "iconid": 0,
        "category": 0
    },
    "qgs": {
        "iconid": 0,
        "category": 0
    },
    "shp": {
        "iconid": 0,
        "category": 0
    },
    "shx": {
        "iconid": 0,
        "category": 0
    },
    "qtl": {
        "iconid": 0,
        "category": 0
    },
    "rdp": {
        "iconid": 0,
        "category": 0
    },
    "rpm": {
        "iconid": 0,
        "category": 0
    },
    "rss": {
        "iconid": 0,
        "category": 0
    },
    "rb": {
        "iconid": 0,
        "category": 0
    },
    "sci": {
        "iconid": 0,
        "category": 0
    },
    "sce": {
        "iconid": 0,
        "category": 0
    },
    "xcos": {
        "iconid": 0,
        "category": 0
    },
    "sh": {
        "iconid": 0,
        "category": 0
    },
    "sha1": {
        "iconid": 0,
        "category": 0
    },
    "shar": {
        "iconid": 0,
        "category": 0
    },
    "swf": {
        "iconid": 0,
        "category": 0
    },
    "swfl": {
        "iconid": 0,
        "category": 0
    },
    "scr": {
        "iconid": 0,
        "category": 0
    },
    "sql": {
        "iconid": 0,
        "category": 0
    },
    "sit": {
        "iconid": 0,
        "category": 0
    },
    "sitx": {
        "iconid": 0,
        "category": 0
    },
    "sv4cpio": {
        "iconid": 0,
        "category": 0
    },
    "sv4crc": {
        "iconid": 0,
        "category": 0
    },
    "tar": {
        "iconid": 5,
        "category": 5
    },
    "tcl": {
        "iconid": 0,
        "category": 0
    },
    "gf": {
        "iconid": 0,
        "category": 0
    },
    "pk": {
        "iconid": 0,
        "category": 0
    },
    "texinfo": {
        "iconid": 0,
        "category": 0
    },
    "texi": {
        "iconid": 0,
        "category": 0
    },
    "t": {
        "iconid": 0,
        "category": 0
    },
    "tr": {
        "iconid": 0,
        "category": 0
    },
    "roff": {
        "iconid": 0,
        "category": 0
    },
    "man": {
        "iconid": 0,
        "category": 0
    },
    "me": {
        "iconid": 0,
        "category": 0
    },
    "ms": {
        "iconid": 0,
        "category": 0
    },
    "ustar": {
        "iconid": 0,
        "category": 0
    },
    "src": {
        "iconid": 0,
        "category": 0
    },
    "wz": {
        "iconid": 0,
        "category": 0
    },
    "crt": {
        "iconid": 0,
        "category": 0
    },
    "xcf": {
        "iconid": 0,
        "category": 0
    },
    "fig": {
        "iconid": 0,
        "category": 0
    },
    "xpi": {
        "iconid": 0,
        "category": 0
    },
    "amr": {
        "iconid": 3,
        "category": 3
    },
    "awb": {
        "iconid": 3,
        "category": 3
    },
    "axa": {
        "iconid": 3,
        "category": 3
    },
    "au": {
        "iconid": 3,
        "category": 3
    },
    "snd": {
        "iconid": 3,
        "category": 3
    },
    "csd": {
        "iconid": 3,
        "category": 3
    },
    "orc": {
        "iconid": 3,
        "category": 3
    },
    "sco": {
        "iconid": 3,
        "category": 3
    },
    "flac": {
        "iconid": 3,
        "category": 3
    },
    "mid": {
        "iconid": 3,
        "category": 3
    },
    "midi": {
        "iconid": 3,
        "category": 3
    },
    "kar": {
        "iconid": 3,
        "category": 3
    },
    "mpga": {
        "iconid": 3,
        "category": 3
    },
    "mpega": {
        "iconid": 3,
        "category": 3
    },
    "mp2": {
        "iconid": 3,
        "category": 3
    },
    "mp3": {
        "iconid": 3,
        "category": 3
    },
    "m4a": {
        "iconid": 3,
        "category": 3
    },
    "m3u": {
        "iconid": 3,
        "category": 3
    },
    "oga": {
        "iconid": 3,
        "category": 3
    },
    "ogg": {
        "iconid": 3,
        "category": 3
    },
    "spx": {
        "iconid": 3,
        "category": 3
    },
    "sid": {
        "iconid": 3,
        "category": 3
    },
    "aif": {
        "iconid": 3,
        "category": 3
    },
    "aiff": {
        "iconid": 3,
        "category": 3
    },
    "aifc": {
        "iconid": 3,
        "category": 3
    },
    "ape": {
        "iconid": 3,
        "category": 3
    },
    "gsm": {
        "iconid": 3,
        "category": 3
    },
    "wma": {
        "iconid": 3,
        "category": 3
    },
    "wax": {
        "iconid": 3,
        "category": 3
    },
    "ra": {
        "iconid": 3,
        "category": 3
    },
    "rm": {
        "iconid": 3,
        "category": 3
    },
    "ram": {
        "iconid": 3,
        "category": 3
    },
    "pls": {
        "iconid": 3,
        "category": 3
    },
    "sd2": {
        "iconid": 3,
        "category": 3
    },
    "wav": {
        "iconid": 3,
        "category": 3
    },
    "alc": {
        "iconid": 0,
        "category": 0
    },
    "cac": {
        "iconid": 0,
        "category": 0
    },
    "cache": {
        "iconid": 0,
        "category": 0
    },
    "csf": {
        "iconid": 0,
        "category": 0
    },
    "cbin": {
        "iconid": 0,
        "category": 0
    },
    "cascii": {
        "iconid": 0,
        "category": 0
    },
    "ctab": {
        "iconid": 0,
        "category": 0
    },
    "cdx": {
        "iconid": 0,
        "category": 0
    },
    "cer": {
        "iconid": 0,
        "category": 0
    },
    "c3d": {
        "iconid": 0,
        "category": 0
    },
    "chm": {
        "iconid": 0,
        "category": 0
    },
    "cif": {
        "iconid": 0,
        "category": 0
    },
    "cmdf": {
        "iconid": 0,
        "category": 0
    },
    "cml": {
        "iconid": 0,
        "category": 0
    },
    "cpa": {
        "iconid": 0,
        "category": 0
    },
    "bsd": {
        "iconid": 0,
        "category": 0
    },
    "csml": {
        "iconid": 0,
        "category": 0
    },
    "csm": {
        "iconid": 0,
        "category": 0
    },
    "ctx": {
        "iconid": 0,
        "category": 0
    },
    "cxf": {
        "iconid": 0,
        "category": 0
    },
    "cef": {
        "iconid": 0,
        "category": 0
    },
    "emb": {
        "iconid": 0,
        "category": 0
    },
    "embl": {
        "iconid": 0,
        "category": 0
    },
    "spc": {
        "iconid": 0,
        "category": 0
    },
    "inp": {
        "iconid": 0,
        "category": 0
    },
    "gam": {
        "iconid": 0,
        "category": 0
    },
    "gamin": {
        "iconid": 0,
        "category": 0
    },
    "fch": {
        "iconid": 0,
        "category": 0
    },
    "fchk": {
        "iconid": 0,
        "category": 0
    },
    "cub": {
        "iconid": 0,
        "category": 0
    },
    "gau": {
        "iconid": 0,
        "category": 0
    },
    "gjc": {
        "iconid": 0,
        "category": 0
    },
    "gjf": {
        "iconid": 0,
        "category": 0
    },
    "gal": {
        "iconid": 0,
        "category": 0
    },
    "gcg": {
        "iconid": 0,
        "category": 0
    },
    "gen": {
        "iconid": 0,
        "category": 0
    },
    "hin": {
        "iconid": 0,
        "category": 0
    },
    "istr": {
        "iconid": 0,
        "category": 0
    },
    "ist": {
        "iconid": 0,
        "category": 0
    },
    "jdx": {
        "iconid": 0,
        "category": 0
    },
    "dx": {
        "iconid": 0,
        "category": 0
    },
    "kin": {
        "iconid": 0,
        "category": 0
    },
    "mcm": {
        "iconid": 0,
        "category": 0
    },
    "mmd": {
        "iconid": 0,
        "category": 0
    },
    "mmod": {
        "iconid": 0,
        "category": 0
    },
    "mol": {
        "iconid": 0,
        "category": 0
    },
    "rd": {
        "iconid": 0,
        "category": 0
    },
    "rxn": {
        "iconid": 0,
        "category": 0
    },
    "sd": {
        "iconid": 0,
        "category": 0
    },
    "tgf": {
        "iconid": 0,
        "category": 0
    },
    "mcif": {
        "iconid": 0,
        "category": 0
    },
    "mol2": {
        "iconid": 0,
        "category": 0
    },
    "b": {
        "iconid": 0,
        "category": 0
    },
    "gpt": {
        "iconid": 0,
        "category": 0
    },
    "mop": {
        "iconid": 0,
        "category": 0
    },
    "mopcrt": {
        "iconid": 0,
        "category": 0
    },
    "mpc": {
        "iconid": 0,
        "category": 0
    },
    "zmt": {
        "iconid": 0,
        "category": 0
    },
    "moo": {
        "iconid": 0,
        "category": 0
    },
    "mvb": {
        "iconid": 0,
        "category": 0
    },
    "asn": {
        "iconid": 0,
        "category": 0
    },
    "prt": {
        "iconid": 0,
        "category": 0
    },
    "ent": {
        "iconid": 0,
        "category": 0
    },
    "val": {
        "iconid": 0,
        "category": 0
    },
    "aso": {
        "iconid": 0,
        "category": 0
    },
    "pdb": {
        "iconid": 0,
        "category": 0
    },
    "ros": {
        "iconid": 0,
        "category": 0
    },
    "sw": {
        "iconid": 0,
        "category": 0
    },
    "vms": {
        "iconid": 0,
        "category": 0
    },
    "vmd": {
        "iconid": 0,
        "category": 0
    },
    "xtel": {
        "iconid": 0,
        "category": 0
    },
    "xyz": {
        "iconid": 0,
        "category": 0
    },
    "gif": {
        "iconid": 1,
        "category": 1
    },
    "ief": {
        "iconid": 1,
        "category": 1
    },
    "jpeg": {
        "iconid": 1,
        "category": 1
    },
    "jpg": {
        "iconid": 1,
        "category": 1
    },
    "jpe": {
        "iconid": 1,
        "category": 1
    },
    "pcx": {
        "iconid": 1,
        "category": 1
    },
    "png": {
        "iconid": 1,
        "category": 1
    },
    "svg": {
        "iconid": 1,
        "category": 1
    },
    "svgz": {
        "iconid": 1,
        "category": 1
    },
    "tiff": {
        "iconid": 1,
        "category": 1
    },
    "tif": {
        "iconid": 1,
        "category": 1
    },
    "djvu": {
        "iconid": 1,
        "category": 1
    },
    "djv": {
        "iconid": 1,
        "category": 1
    },
    "ico": {
        "iconid": 1,
        "category": 1
    },
    "wbmp": {
        "iconid": 1,
        "category": 1
    },
    "cr2": {
        "iconid": 1,
        "category": 1
    },
    "crw": {
        "iconid": 1,
        "category": 1
    },
    "ras": {
        "iconid": 1,
        "category": 1
    },
    "cdr": {
        "iconid": 1,
        "category": 1
    },
    "pat": {
        "iconid": 1,
        "category": 1
    },
    "cdt": {
        "iconid": 1,
        "category": 1
    },
    "erf": {
        "iconid": 1,
        "category": 1
    },
    "art": {
        "iconid": 1,
        "category": 1
    },
    "jng": {
        "iconid": 1,
        "category": 1
    },
    "bmp": {
        "iconid": 1,
        "category": 1
    },
    "nef": {
        "iconid": 1,
        "category": 1
    },
    "orf": {
        "iconid": 1,
        "category": 1
    },
    "psd": {
        "iconid": 1,
        "category": 1
    },
    "pnm": {
        "iconid": 1,
        "category": 1
    },
    "pbm": {
        "iconid": 1,
        "category": 1
    },
    "pgm": {
        "iconid": 1,
        "category": 1
    },
    "ppm": {
        "iconid": 1,
        "category": 1
    },
    "rgb": {
        "iconid": 1,
        "category": 1
    },
    "xbm": {
        "iconid": 1,
        "category": 1
    },
    "xpm": {
        "iconid": 1,
        "category": 1
    },
    "xwd": {
        "iconid": 1,
        "category": 1
    },
    "eml": {
        "iconid": 0,
        "category": 0
    },
    "igs": {
        "iconid": 0,
        "category": 0
    },
    "iges": {
        "iconid": 0,
        "category": 0
    },
    "msh": {
        "iconid": 0,
        "category": 0
    },
    "mesh": {
        "iconid": 0,
        "category": 0
    },
    "silo": {
        "iconid": 0,
        "category": 0
    },
    "wrl": {
        "iconid": 0,
        "category": 0
    },
    "vrml": {
        "iconid": 0,
        "category": 0
    },
    "x3dv": {
        "iconid": 0,
        "category": 0
    },
    "x3d": {
        "iconid": 0,
        "category": 0
    },
    "x3db": {
        "iconid": 0,
        "category": 0
    },
    "appcache": {
        "iconid": 0,
        "category": 0
    },
    "ics": {
        "iconid": 0,
        "category": 0
    },
    "icz": {
        "iconid": 0,
        "category": 0
    },
    "css": {
        "iconid": 0,
        "category": 0
    },
    "csv": {
        "iconid": 4,
        "category": 4
    },
    "html": {
        "iconid": 18,
        "category": 0
    },
    "htm": {
        "iconid": 18,
        "category": 0
    },
    "shtml": {
        "iconid": 18,
        "category": 0
    },
    "uls": {
        "iconid": 0,
        "category": 0
    },
    "mml": {
        "iconid": 0,
        "category": 0
    },
    "asc": {
        "iconid": 4,
        "category": 4
    },
    "txt": {
        "iconid": 4,
        "category": 4
    },
    "text": {
        "iconid": 4,
        "category": 4
    },
    "pot": {
        "iconid": 4,
        "category": 4
    },
    "brf": {
        "iconid": 4,
        "category": 4
    },
    "srt": {
        "iconid": 4,
        "category": 4
    },
    "rtx": {
        "iconid": 0,
        "category": 0
    },
    "sct": {
        "iconid": 0,
        "category": 0
    },
    "wsc": {
        "iconid": 0,
        "category": 0
    },
    "tm": {
        "iconid": 0,
        "category": 0
    },
    "tsv": {
        "iconid": 0,
        "category": 0
    },
    "jad": {
        "iconid": 0,
        "category": 0
    },
    "wml": {
        "iconid": 0,
        "category": 0
    },
    "wmls": {
        "iconid": 0,
        "category": 0
    },
    "bib": {
        "iconid": 0,
        "category": 0
    },
    "boo": {
        "iconid": 0,
        "category": 0
    },
    "h": {
        "iconid": 0,
        "category": 0
    },
    "htc": {
        "iconid": 0,
        "category": 0
    },
    "c": {
        "iconid": 0,
        "category": 0
    },
    "d": {
        "iconid": 0,
        "category": 0
    },
    "diff": {
        "iconid": 0,
        "category": 0
    },
    "patch": {
        "iconid": 0,
        "category": 0
    },
    "hs": {
        "iconid": 0,
        "category": 0
    },
    "java": {
        "iconid": 0,
        "category": 0
    },
    "ly": {
        "iconid": 0,
        "category": 0
    },
    "lhs": {
        "iconid": 0,
        "category": 0
    },
    "moc": {
        "iconid": 0,
        "category": 0
    },
    "p": {
        "iconid": 0,
        "category": 0
    },
    "pas": {
        "iconid": 0,
        "category": 0
    },
    "gcd": {
        "iconid": 0,
        "category": 0
    },
    "pl": {
        "iconid": 0,
        "category": 0
    },
    "pm": {
        "iconid": 0,
        "category": 0
    },
    "py": {
        "iconid": 0,
        "category": 0
    },
    "scala": {
        "iconid": 0,
        "category": 0
    },
    "etx": {
        "iconid": 0,
        "category": 0
    },
    "sfv": {
        "iconid": 0,
        "category": 0
    },
    "tk": {
        "iconid": 0,
        "category": 0
    },
    "tex": {
        "iconid": 0,
        "category": 0
    },
    "ltx": {
        "iconid": 0,
        "category": 0
    },
    "sty": {
        "iconid": 0,
        "category": 0
    },
    "cls": {
        "iconid": 0,
        "category": 0
    },
    "vcs": {
        "iconid": 0,
        "category": 0
    },
    "vcf": {
        "iconid": 0,
        "category": 0
    },
    "3gp": {
        "iconid": 2,
        "category": 2
    },
    "axv": {
        "iconid": 2,
        "category": 2
    },
    "dl": {
        "iconid": 2,
        "category": 2
    },
    "dif": {
        "iconid": 2,
        "category": 2
    },
    "dv": {
        "iconid": 2,
        "category": 2
    },
    "fli": {
        "iconid": 2,
        "category": 2
    },
    "gl": {
        "iconid": 2,
        "category": 2
    },
    "mpeg": {
        "iconid": 2,
        "category": 2
    },
    "mpg": {
        "iconid": 2,
        "category": 2
    },
    "mpe": {
        "iconid": 2,
        "category": 2
    },
    "ts": {
        "iconid": 2,
        "category": 2
    },
    "mp4": {
        "iconid": 2,
        "category": 2
    },
    "qt": {
        "iconid": 2,
        "category": 2
    },
    "mov": {
        "iconid": 2,
        "category": 2
    },
    "ogv": {
        "iconid": 2,
        "category": 2
    },
    "webm": {
        "iconid": 2,
        "category": 2
    },
    "mxu": {
        "iconid": 2,
        "category": 2
    },
    "flv": {
        "iconid": 2,
        "category": 2
    },
    "lsf": {
        "iconid": 2,
        "category": 2
    },
    "lsx": {
        "iconid": 2,
        "category": 2
    },
    "mng": {
        "iconid": 2,
        "category": 2
    },
    "asf": {
        "iconid": 2,
        "category": 2
    },
    "asx": {
        "iconid": 2,
        "category": 2
    },
    "wm": {
        "iconid": 2,
        "category": 2
    },
    "wmv": {
        "iconid": 2,
        "category": 2
    },
    "wmx": {
        "iconid": 2,
        "category": 2
    },
    "wvx": {
        "iconid": 2,
        "category": 2
    },
    "avi": {
        "iconid": 2,
        "category": 2
    },
    "movie": {
        "iconid": 2,
        "category": 2
    },
    "mpv": {
        "iconid": 2,
        "category": 2
    },
    "mkv": {
        "iconid": 2,
        "category": 2
    },
    "ice": {
        "iconid": 0,
        "category": 0
    },
    "sisx": {
        "iconid": 0,
        "category": 0
    },
    "vrm": {
        "iconid": 0,
        "category": 0
    },
    "caf": {
        "iconid": 3,
        "category": 3
    },
    "gz": {
        "iconid": 5,
        "category": 5
    },
    "gzip": {
        "iconid": 5,
        "category": 5
    },
    "bz2": {
        "iconid": 5,
        "category": 5
    },
    "lz": {
        "iconid": 5,
        "category": 5
    },
    "lzma": {
        "iconid": 5,
        "category": 5
    },
    "lzo": {
        "iconid": 5,
        "category": 5
    },
    "z": {
        "iconid": 5,
        "category": 5
    },
    "xz": {
        "iconid": 5,
        "category": 5
    },
    "ace": {
        "iconid": 5,
        "category": 5
    },
    "arj": {
        "iconid": 5,
        "category": 5
    },
    "zoo": {
        "iconid": 0,
        "category": 0
    },
    "pages": {
        "iconid": 10,
        "category": 4
    },
    "keynote": {
        "iconid": 11,
        "category": 4
    },
    "numbers": {
        "iconid": 12,
        "category": 4
    }
};

export default mimetypes;
