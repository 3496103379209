import {
  APPLE_LOGIN_METHOD_ID,
  EMAIL_LOGIN_METHOD_ID,
  FACEBOOK_LOGIN_METHOD_ID,
  GOOGLE_LOGIN_METHOD_ID,
  SETUP_PASS_LOGIN_METHOD_ID
} from "./constants.signin";

export const getLoginOptionsArray = (loginOptions) => {
  const newLoginOptions = {
    preferredOption: [],
    secondaryOption: [],
    socialLoginsOptionsGroup: [],
  };

  loginOptions.forEach(({ type, preferred }) => {
    if (preferred) {
      newLoginOptions.preferredOption.push(type);
    } else if (type === EMAIL_LOGIN_METHOD_ID || type === SETUP_PASS_LOGIN_METHOD_ID) {
      newLoginOptions.secondaryOption.push(type);
    } else if (type === APPLE_LOGIN_METHOD_ID || type === GOOGLE_LOGIN_METHOD_ID || type === FACEBOOK_LOGIN_METHOD_ID) {
      newLoginOptions.socialLoginsOptionsGroup.push(type);
    }
  });

  return newLoginOptions;
};

export const splitEmail = (email = "") => {
  if (!email || typeof email !== "string") {
    return [];
  };

  return email.split("@");
}