import React from "react";
import { SocialButtonWrapper as WebSocialButtonWrapper } from "../WebStyles";
import { SocialButtonWrapper as WindowsSocialButtonWrapper } from "../WindowsStyles";
import { SocialButtonWrapper as MacSocialButtonWrapper } from "../MacStyles";
import { SocialButtonWrapper as LinuxSocialButtonWrapper } from "../LinuxStyles";
import { SocialButtonWrapper as IosSocialButtonWrapper } from "../iOSStyles";
import { SocialButtonWrapper as AndroidSocialButtonWrapper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const SocialButtonWrapper = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidSocialButtonWrapper {...rest}/>
    case DEVICE_OS.iOS:
      return <IosSocialButtonWrapper {...rest}/>
    case DEVICE_OS.Web:
      return <WebSocialButtonWrapper {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsSocialButtonWrapper {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacSocialButtonWrapper {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxSocialButtonWrapper {...rest}/>
    default:
      return <WebSocialButtonWrapper {...rest}/>
  }
};

export default SocialButtonWrapper;
