import PropTypes from "prop-types";
import React, { Component } from "react";
import dropDown from "../lib/dropDown";
import { isInternalUrl } from "../lib/utils";
import styles from "../styles/dropdown.less";

export const DIR_LEFT = dropDown.DIR_LEFT;
export const DIR_RIGHT = dropDown.DIR_RIGHT;
export const DIR_UP = dropDown.DIR_UP;
export const DIR_DOWN = dropDown.DIR_DOWN;
export const DIR_CENTER = dropDown.DIR_CENTER;

export default class Dropdown extends Component {
	static propTypes = {
		children: PropTypes.any.isRequired,
		list: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequred,
				href: PropTypes.string.isRequred,
				id: PropTypes.any.isRequred,
				submenu: PropTypes.array,
				target: PropTypes.string
			})
		).isRequired,
		onSelect: PropTypes.func.isRequired,
		prepareHolder: PropTypes.func,
		prepareCell: PropTypes.func,
		direction: PropTypes.oneOf([dropDown.DIR_LEFT, dropDown.DIR_RIGHT, dropDown.DIR_CENTER]),
		verticalDirection: PropTypes.oneOf([dropDown.DIR_UP, dropDown.DIR_DOWN]),
		eventTrigger: PropTypes.oneOf(["click", "mouseenter"]),
		eventClose: PropTypes.oneOf(["click", "mouseout"]),
		position: PropTypes.oneOf(["absolute", "fixed"]),
		onOpen: PropTypes.func,
		reverseDirections: PropTypes.bool,
		showTip: PropTypes.bool,
		holderClass: PropTypes.string,
		overwriteTip: PropTypes.shape({
			right: PropTypes.string,
			left: PropTypes.string,
			top: PropTypes.string,
			bottom: PropTypes.string
		}),
		forceClose: PropTypes.bool
	};

	static defaultProps = {
		direction: dropDown.DIR_LEFT,
		verticalDirection: dropDown.DIR_DOWN,
		eventTrigger: "click",
		eventClose: "click",
		position: "fixed",
		reverseDirections: false,
		list: [],
		prepareHolder: id => id,
		prepareCell: id => id,
		onSelect: () => {},
		forceClose: false,
	};

	constructor(props) {
		super(props);
	}

	_normalizeList(list) {
		return list.map(item => [
			item.title,
			item.href,
			item.submenu && item.submenu.length
				? this._normalizeList(item.submenu)
				: [],
			item.extra ? item.extra : null,
			item.target ? item.target : null
		]);
	}

	_denormalizeItem(item) {
		return {
			title: item[0],
			href: item[1],
			submenu: item[2],
			extra: item[3] || {},
			target: item[4]
		};
	}

	_setup() {
		const props = this.props;

		dropDown.bindList(this._normalizeList(props.list), this.ref, {
			direction: props.direction,
			vdirection: props.verticalDirection,
			childDirection: props.childDirection,
			eventTrigger: props.eventTrigger,
			eventClose: props.eventClose,
			position: props.position,
			showTip: props.showTip,
			holderClass: props.holderClass,
			overwriteTip: props.overwriteTip || false,
			buildHolder: props.prepareHolder,
			onOpen: props.onOpen,
			buildCell: (arrItem, el) => {
				const item = this._denormalizeItem(arrItem);

				let cell = $("<a>")
					.attr("href", "javascript:void(0);")
					.addClass(
						"extra" in item && "border" in item.extra
							? "border-" + item.extra.border
							: ""
					)
					.append($("<li>").append(item.title))
					.on("click", e => {
						e.preventDefault();
						e.stopPropagation();

						props.onSelect(item);
						dropDown.resetTo(0);
					})
					.appendTo(el);

				console.log("denormalized item", item);
				if (item.href) {
					cell.attr("href", item.href).off("click").on("click", (e) => {
						e.stopPropagation();

						if (item.target && item.target !== '_blank') {
							props.onSelect(item);
						}
						dropDown.resetTo(0);
					});

					if (!isInternalUrl(item.href)) {
						cell.attr("target", "_blank");
					}

					if (item.target) {
						cell.attr("target", item.target);
					}
				}

				cell = props.prepareCell(cell, item);
				return cell;
			}
		});
	}

	_tear() {
		//console.log("_tearing", this.ref);
		dropDown.unbindList(this.ref);
	}

	componentDidMount() {
		this._setup();
	}

	componentWillUnmount() {
		this._tear();
		dropDown.close();
	}

	componentDidUpdate(prevProps) {
		const { forceClose: nextForceClose } = this.props;
		const { forceClose: prevForceClose } = prevProps;

		console.log(";;;", "props", prevProps);
		console.log(this.props);

		if (nextForceClose !== prevForceClose & nextForceClose) {
			dropDown.close();
		} 

		// this._tear();
		this._setup();
	}

	render() {
		return (
			<div className="dropdown-holder" ref={ref => (this.ref = ref)}>
				{this.props.children}
			</div>
		);
	}
}
