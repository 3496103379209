//  @flow
import {
  LOAD_ACCOUNT_INFO,
  CLEAR_ACCOUNT_INFO,
  UPDATE_COMPANY_INFO,
  LOAD_BILLING_INFO,
  UPDATE_BILLING_INFO,
  CLEAR_BILLING_INFO,
  SET_PROCESSING_PROFILE,
  LOAD_USERS,
  LOAD_TEAMS,
  CLEAR_USERS,
  CLEAR_TEAMS,
  EDIT_USER,
} from "../constants/business";

export type BusinessAction =
  | LoadAccountInfo
  | ClearAccountInfo
  | UpdateCompanyInfo
  | LoadBillingInfo
  | UpdateBillingInfo
  | ClearBillingInfo
  | SetProcessingProfile
  | LoadUsers
  | LoadTeams
  | ClearUsers
  | ClearTeams
  | EditUser;

type LoadAccountInfo = { type: typeof LOAD_ACCOUNT_INFO, accountInfo: any };
type ClearAccountInfo = { type: typeof CLEAR_ACCOUNT_INFO };
type UpdateCompanyInfo = { type: typeof UPDATE_COMPANY_INFO, companyInfo: any };
type LoadBillingInfo = { type: typeof LOAD_BILLING_INFO, billingInfo: any };
type UpdateBillingInfo = { type: typeof UPDATE_BILLING_INFO, billingInfo: any };
type ClearBillingInfo = { type: typeof CLEAR_BILLING_INFO };
type SetProcessingProfile = { type: typeof SET_PROCESSING_PROFILE };
type LoadUsers = { type: typeof LOAD_USERS, users: Array<any> };
type LoadTeams = { type: typeof LOAD_TEAMS, teams: Array<any> };
type ClearUsers = { type: typeof CLEAR_USERS };
type ClearTeams = { type: typeof CLEAR_TEAMS };
type EditUser = { type: typeof EDIT_USER, user: any };

export function loadAccountInfo(accountInfo: Array<any>): LoadAccountInfo {
  return { type: LOAD_ACCOUNT_INFO, accountInfo: accountInfo };
}

export function clearAccountInfo(): ClearAccountInfo {
  return { type: CLEAR_ACCOUNT_INFO };
}

export function updateCompanyInfo(companyInfo: any): UpdateCompanyInfo {
  return { type: UPDATE_COMPANY_INFO, companyInfo: companyInfo };
}

export function loadBillingInfo(billingInfo: Array<any>): LoadBillingInfo {
  return { type: LOAD_BILLING_INFO, billingInfo: billingInfo };
}

export function updateBillingInfo(billingInfo: Array<any>): UpdateBillingInfo {
  return { type: UPDATE_BILLING_INFO, billingInfo: billingInfo };
}

export function clearBillingInfo(): ClearBillingInfo {
  return { type: CLEAR_BILLING_INFO };
}

export function setProcessingProfile(): SetProcessingProfile {
  return { type: SET_PROCESSING_PROFILE };
}

export function loadUsers(users: Array<any>): LoadUsers {
  return { type: LOAD_USERS, users: users };
}

export function loadTeams(teams: Array<any>): LoadTeams {
  return { type: LOAD_TEAMS, teams: teams };
}

export function clearUsers(): ClearUsers {
  return { type: CLEAR_USERS };
}

export function clearTeams(): ClearTeams {
  return { type: CLEAR_TEAMS };
}

export function editUser(user: any) {
  return { type: EDIT_USER, user: user };
}

export const businessActionCreators = {
  loadAccountInfo,
  clearAccountInfo,
  updateCompanyInfo,
  loadBillingInfo,
  updateBillingInfo,
  clearBillingInfo,
  setProcessingProfile,
  loadUsers,
  loadTeams,
  clearUsers,
  clearTeams,
  editUser,
};
