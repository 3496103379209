// @flow

import React, { useState } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../components/Modal"; // DONE
import * as FormStyle from "../../components/FormSharedComponents"; // DONE
import { BackButton } from "../TwoFactorAuth/SharedComponents"; // DONE
import apiMethod from "../../api/apiMethod"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { validateEmail } from "../../lib/utils"; // DONE

import SubHeading from "./MultiplatformComponents/Elements/SubHeading"; // DONE
import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE
import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper"; // DONE
import Input from "./MultiplatformComponents/Elements/Input"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import Wrapper from "./MultiplatformComponents/Elements/Wrapper"; // DONE
import MessageBox from "./MultiplatformComponents/Elements/MessageBox"; // DONE
import Message from "./MultiplatformComponents/Elements/Message"; // DONE

const ForgotPassModal = ({ open = false, isModal = false, mail = "", passpcloud = false, onClose = () => {}, os = 4 }) => {
  const [email, setEmail] = useState(mail);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
      setError("");
      setEmail("");
      setShowSuccessMsg(false);
    }
  };

  const onEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onResetPass();
    }
  };

  const onResetPass = (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }
    const params = { mail: email };

    if (passpcloud) {
      params.passpcloud = passpcloud;
    }

    if (!validateEmail(email)) {
      setError(__("invalid_email", "Invalid Email"));
      return;
    }

    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "lostpassword",
      params,
      res => {
        setShowSuccessMsg(true);
        setIsLoading(false);
      },
      {
        errorCallback: ({ error }) => {
          setIsLoading(false);
          HFN.message(
            __("something_went_wrong_refresh_and_try_again", "Something went wrong. Please reload the page and try again"),
            "error"
          );
          throw new Error(error);
        }
      }
    );
  };

  const renderSuccessMsg = () => {
    return (
      <SuccessMessageWrapper>
        <MessageBox os={os}>
          <Message>
            {__("forgot_pass_succ_msg", "Check your email for further instructions.")}
          </Message>
        </MessageBox>
      </SuccessMessageWrapper>
    );
  };

  const renderBody = () => {
    return (
      <React.Fragment>
        {error ? (
          <FormStyle.ErrorMessage>{error}</FormStyle.ErrorMessage>
        ) : (
          <SubHeading os={os}>{__("forgot_pass_enter_mail", "Enter your email to reset your password:")}</SubHeading>
        )}
        <Form os={os}>
          <div>
            <InputWrapper os={os} osSubmit={onResetPass}>
              <Input
                os={os}
                type="email"
                autoComplete="email"
                name="email"
                placeholder={__("form_email", "Email")}
                onChange={onEmailChange}
                value={email}
                autoFocus
                onKeyDown={onEnter}
              />
            </InputWrapper>
          </div>
          <ButtonWrapper os={os}>
            <Button type="submit" os={os} disabled={!email} loading={isLoading} onClick={onResetPass} minWidth="100%" disabledColor="#dddddd">
              {__("Reset")}
            </Button>
          </ButtonWrapper>
        </Form>
      </React.Fragment>
    );
  };

  const renderWrapper = () => {
    return (
      <FormWrapper os={os} isModal={isModal}>
        {isModal ? <BackButton onClick={onClose}>{__("Back")}</BackButton> : null}
        <Heading os={os}>{__("Reset Password")}</Heading>
        {showSuccessMsg ? renderSuccessMsg() : renderBody()}
      </FormWrapper>
    );
  };

  const renderModal = () => {
    return (
      <Modal animate onClose={handleClose} opened={open}>
        <ForgotPassModalWrap className="forgot-pass-madal-wrap">
          <OvalModalCloseV2 onClick={handleClose} />
          <Wrapper os={os}>
            {renderWrapper()}
          </Wrapper>
        </ForgotPassModalWrap>
      </Modal>
    );
  };

  return isModal ? renderWrapper() : renderModal();
};

export default ForgotPassModal;

const SuccessMessageWrapper = styled.div`
  margin-top: 20px;
`;

const ForgotPassModalWrap = styled(OvalModalWrap)`
  padding: 20px;
  min-width: 320px;
`;
