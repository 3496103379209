import styled from "styled-components";
import { fadein } from "../AuthenticationStepsUI/TFALoggedDevicesModalUI"

export const lightTheme = {
  background: '#fafafa',
  primary: '#17bed0',
};

export const darkTheme = {
  background: '#191c1d',
  primary: '#48d8eb',
};

export const Wrapper = styled.div`
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }

  .tfa-button {
    width: 54px;
    flex-shrink: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: ${({ fixedPosition }) => fixedPosition ? "fixed" : "absolute"};
  border-radius: 18px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: inherit;
  z-index: 1002;
`;

export const FormWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.div`
  width: 100%;
  color: #000;
  font-size: 23px;
  text-align: center;
  display: block;
  font-weight: 500;
  margin-bottom: ${props => (props.subTitle ? "5px" : "0")};

  &.webview {
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    margin: 20px 0 20px 0;

    &.webview {
      margin: 20px 0 0 0;
    }
  }
`;

export const SubHeading = styled.div`
  width: 100%;
  color: #000;
  font-size: 17px;
  text-align: center;
  font-weight: normal;
  margin: 5px 0;
  line-height: 1.35;

  &.external {
    margin-bottom: 35px;
  }
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 34px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 16px auto;

  & > input {
    border-color: ${({ error }) => error ? "#fe574e" : "#cdd0d4"};
  }

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  .edit-email-icon {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 10px;
    cursor: pointer;
  }

  .login-input-password-icon {
    right: 65px;
  }

  .register-input-password-icon {
    right: 15px;
  }
`;

export const Input = styled.input`
  line-height: 46px;
  height: 46px;
  padding: 0 15px 0 15px;
  border: solid 1px #cdd0d4;
  background-color: #fff;
  font-size: 14px;
  border-radius: 12px;
  box-sizing: border-box;
  min-width: initial;
  width: 100%;
  outline: none;

  &:disabled {
    border: solid 1px #ebebeb;
    background-color: #f4f4f4;
    color: #888;
  }


  &[type="email"],
  &:read-only {
    padding-right: 53px !important;
  }

  &[type="password"] {
    padding-right: 53px !important;
  }
`;

export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding-bottom: 15px;
  margin-top: 10px;

  &.login-input-checkbox-wrapper {
    border-bottom: 1px solid #eeeeee;
  }

  &.terms-wrapper {
    border-bottom: none;
  }

  label {
    margin-right: 10px;
  }

  button {
    margin-left: 10px;
  }
`;

export const MessageBoxWarning = styled.div`
  padding: 19px;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #f6e2a6;
  background-color: #fff9e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const MessageBoxSuccess = styled.div`
  padding: 22px;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #a8e3a4;
  background-color: #e7f8e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const MessageBox = styled.div`
  padding: 22px;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border: solid 1px #a2e5ec;
  background-color: #f7fdfe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const Message = styled.div`
  font-size: 13px;
  text-align: center;
  color: #000;
  width: 100%;
  line-height: 20px;
  margin-bottom: 45px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 235px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }
`;

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 9px;

  svg {
    display: none;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 13px;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #fe574e;
  width: 100%;
  visibility: ${({ error }) => error ? "visible" : "hidden" };
  min-height: 17px;
  margin-top: -17px;

  strong, b {
    font-weight: bold;
  }
`;

// export const Button = styled.button`
//   font-family: inherit !important;
//   width: 100%;
//   height: 32px;
//   padding: 6px 10px;
//   border-radius: 4px;
//   background-color: #17bed0;
//   border: none;
//   font-size: 14px;
//   font-weight: 600;
//   text-align: center;
//   color: #fff;
//   cursor: pointer;

//   &:disabled {
//     background-color: #f3f2f1;
//     color: #a19f9d;
//     cursor: auto;
//   }
// `;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SocialButtonWrapper = styled.div`
  width: 100%;
`;

export const SocialButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  /* line-height: 46px; */
  text-align: center;
  border: solid 1px #dadcdf;
  border-radius: 12px;
  background-color: #fff;
  cursor: pointer;  
`;

export const ContinueWith = styled.div`
  font-size: 13px;
  text-align: center;
  color: #b2b4b8;
  margin: 16px auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  padding: 0;
  text-align: center;
  background: transparent;
  height: auto;

  &:hover {
    background-color: transparent;
    color: #000;
  }
`;

export const SecurityCodeStyledInput = styled.input`
  width: ${({ maxLength }) => {
    const singleInputMargin = maxLength > 6 ? 6 : 8;
    const separationSpace = maxLength > 6 ? 16 : 18;
    const margins = (maxLength - 2) * singleInputMargin;

    return "calc(((100% - " + (separationSpace + margins) + "px) / " + maxLength + "))"
  }};
  height: 46px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px #cdd0d4;
  line-height: 1;
  font-size: ${({ maxLength }) => maxLength > 6 ? "21px" : "26px"} ;
  text-align: center;
  transition: all 150ms ease-in-out;

  &:nth-child(${({ chunk }) => chunk}) {
    margin-right: ${({maxLength}) => maxLength > 6 ? "14px" : "16px"};
  }

  &:focus {
    outline: none;
    border-color: #7c7c7c;
    transform: scale(1.02);
  }
`;

export const VerificationCodeInputsError = styled.div``;

export const InputButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 46px;
`;

export const ModalWindow = styled.div`
  padding: 48px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 456px;
  min-width: 270px;
  border-radius: 8px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: 460px) {
    padding: 12px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
`;

export const PassIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 11px;
`;

export const DeviceContainer = styled.div`
  border: solid 1px #cdd0d4;
  background: #fff;
  border-radius: 12px;
  min-height: 10px;
  margin: 25px 0px;
  margin-bottom: 0px;
  color: #000;
  font-size: 13px;
  text-align: left;
  padding: 12px;

  @media (max-width: 460px) {
    margin: 10px 0px;
    margin-bottom: 0px;
    padding: 8px;
    background: none;
    border: none;
    color: black;
  }
`;