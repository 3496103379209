// @flow
// WARNING: Do not use HFN in this component

import React, { Component } from "react";

import { __ } from "../../lib/translate"; // DONE
import { loadScript, setcookie } from "../../lib/utils"; // DONE
import Registration from "./Registration"; // DONE
import ExternalRegister from "./SocialLogins/ExternalRegister"; // DONE
import TwoFactorAuthLogin from "./TwoFactorAuthLogin"; // DONE
import ButtonsWrapper from "./SocialLogins/ButtonsWrapper"; // DONE
import * as FormStyle from "../../components/FormSharedComponents"; // DONE
import { RestrictedAccessWarning, RestrictedAccessWarningModal } from "./RestrictedAccessWarning"; // DONE
import SocialLoginUnavailableModal from "./SocialLogins/SocialLoginUnavailableModal"; // DONE

import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper";  // DONE
import Heading from "./MultiplatformComponents/Elements/Heading";  // DONE
import SubHeading from "./MultiplatformComponents/Elements/SubHeading";  // DONE
import Form from "./MultiplatformComponents/Elements/Form";  // DONE
import Wrapper from "./MultiplatformComponents/Elements/Wrapper";  // DONE
import Footer from "./MultiplatformComponents/Elements/Footer";  // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink";  // DONE
import ContinueWith from "./MultiplatformComponents/Elements/ContinueWith";  // DONE
import SocialButtonWrapper from "./MultiplatformComponents/Elements/SocialButtonWrapper";  // DONE
import LoginCreatePassword from "./LoginCreatePassword"; // DONE
import RegisterVerificationCode from "./RegisterVerificationCode"; // DONE
import LoginVerificationCode from "./LoginVerificationCode"; // DONE
import { DEVICE_OS, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { apiMethod } from "../../api";

type Props = {
  subTitle: string,
  title: string,
  theme: string,
  language: string,
  isLoginModal: boolean,
  showFooter?: boolean,
  footerText?: string,
  redirect_uris: object,
  onFooterClick?: () => void,
  footerComponent?: () => void,
  onSuccess: () => void,
  onError: () => void,
  toLogin: any => void,
  promoCode?: string,
  isRegisterPage: Boolean,
  os: number
  // noFbCheckbox: boolean
};

type State = {
  token: string,
  email: string,
  externalToken: string,
  externalApp: string,
  tfatype: string,
  verifyToken: boolean,
  hasdevices: boolean,
  showTFALoginForm: boolean,
  showRestrictionWarning: boolean,
  usegrecaptcha: boolean,
  loader: boolean
};

export default class Signup extends Component<Props, State> {
  static defaultProps = {
    toLogin: null,
    subTitle: "",
    title: "signup",
    showTitle: true,
    theme: "",
    showFooter: false,
    footerText: "",
    footerComponent: null,
    promoCode: "",
    os: 4,
    onFooterClick: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      token: "",
      externalToken: "",
      externalApp: "",
      tfatype: "",
      verifyToken: false,
      hasdevices: false,
      showTFALoginForm: false,
      showRestrictionWarning: false,
      showRegisterVerificationCode: false,
      showLoginVerificationCode: false,
      showFacebookUnavailableModal: false,
      loader: true,
      usegrecaptcha: false
    };

    (this: any).setTFALoginInfo = this.setTFALoginInfo.bind(this);
    (this: any).setVerifyInfo = this.setVerifyInfo.bind(this);
    (this: any).closeWarning = this.closeWarning.bind(this);
    (this: any).receiveToken = this.receiveToken.bind(this);
    (this: any).toLoginClick = this.toLoginClick.bind(this);

    (this: any).setShowRegisterVerificationCode = this.setShowRegisterVerificationCode.bind(this);
    (this: any).setShowLoginVerificationCode = this.setShowLoginVerificationCode.bind(this);
    (this: any).setShowCreatePassword = this.setShowCreatePassword.bind(this);
    (this: any).resetView = this.resetView.bind(this);
    (this: any).onSendCodeClick = this.onSendCodeClick.bind(this);
    (this: any).showFacebookUnavailableModal = this.showFacebookUnavailableModal.bind(this);
    (this: any).closeFacebookUnavailableModal = this.closeFacebookUnavailableModal.bind(this);
    (this: any).onFacebookUnavailableContinue = this.onFacebookUnavailableContinue.bind(this);
  }

  componentDidMount() {
    const { os } = this.props;
    apiMethod("user/preparelogin", {os}, ({ result, usegrecaptcha }) => {
      if (result === 0) {
        if (usegrecaptcha) {
          if (typeof grecaptcha === "undefined" ) {
            try {
              loadScript("https://www.google.com/recaptcha/api.js?render=6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR", () => {
                this.setState({ usegrecaptcha: !!usegrecaptcha, loader: false });
              }, { dontcache: true }); 
            } catch (error) {
              console.error("Error loading recaptcha script:", error);
              this.setState({ loader: false });
            }
          } else {
            const recaptchaBadge = document.querySelector(".grecaptcha-badge");

            if (recaptchaBadge) {
              recaptchaBadge.style.visibility = "visible";
            }
          }
        }
        
        this.setState({ usegrecaptcha: !!usegrecaptcha, loader: false });
      } else {
        this.setState({ loader: false });
      }
    }, {
      errorCallback: (error) => {
        console.error("Error user/preparelogin:", error);
        this.setState({ loader: false });
      },
      onTimeout: () => {
        this.setState({ loader: false });
      }
    })
  }

  setTFALoginInfo(token: string, hasdevices: boolean, tfatype: string) {
    this.setState({
      token: token,
      tfatype: tfatype,
      hasdevices: hasdevices,
      showTFALoginForm: true,
      verificationCode: "",
      showRegisterVerificationCode: false,
      showCreatePassword: false,
      resendVerificationCode: false,
      locationId: EU_LOCATIONID
    });
  }

  setVerifyInfo(verifyToken: boolean, email: string) {
    this.setState({ showRestrictionWarning: true, verifyToken, email });
  }

  receiveToken(token: string, externalApp: string) {
    this.setState({ externalToken: token, externalApp });
  }

  closeWarning() {
    this.setState({ showRestrictionWarning: false, externalToken: "", externalApp: "" });
  }

  toLoginClick(e: any) {
    const { toLogin } = this.props;

    e.preventDefault();
    toLogin(e);
  }

  setLocationId(id) {
    this.setState({ locationId: id });
  }

  setShowRegisterVerificationCode(show = false, email = "", code = "") {
    this.setState({ showRegisterVerificationCode: show, email, verificationCode: code });
  }

  setShowLoginVerificationCode(show = false, email = "", code = "") {
    this.setState({ showLoginVerificationCode: show, email, verificationCode: code });
  }

  setShowCreatePassword(show = false) {
    this.setState({ showCreatePassword: show });
  }

  showFacebookUnavailableModal() {
    this.setState({
      showFacebookUnavailableModal: true,
    })
  }

  closeFacebookUnavailableModal() {
    this.setState({
      showFacebookUnavailableModal: false,
    })
  }

  onFacebookUnavailableContinue() {
    setcookie("logininfo", "");
    this.setState({
      loginWith: "",
      showFacebookUnavailableModal: false
    })
  }

  resetView() {
    this.setState({
      email: "",
      verificationCode: "",
      showRegisterVerificationCode: false,
      showLoginVerificationCode: false,
      showCreatePassword: false
    });
  }

  onSendCodeClick() {
    this.setState({
      verificationCode: "",
      showCreatePassword: false,
      showRegisterVerificationCode: true,
      resendVerificationCode: true
    });
  }

  renderExternalFlow() {
    const { passpcloud, theme, onSuccess, language, promoCode, os } = this.props;
    const { externalToken, externalApp } = this.state;

    return (
      <ExternalRegister
        os={os}
        passpcloud={passpcloud}
        token={externalToken}
        language={language}
        externalApp={externalApp}
        onSuccess={onSuccess}
        setTFALoginInfo={this.setTFALoginInfo}
        setVerifyInfo={this.setVerifyInfo}
        setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
        setShowLoginVerificationCode={this.setShowLoginVerificationCode}
        onBackClick={() => this.setState({ externalToken: "", externalApp: "" })}
        promoCode={promoCode}
        theme={theme}
      />
    );
  }

  renderFooter() {
    const { footerComponent: CustomFooter, showFooter, footerText, onFooterClick, toLogin, os } = this.props;

    return (
      <React.Fragment>
        {showFooter ? <CustomFooter footerText={footerText} onFooterClick={onFooterClick} /> : null}
        {toLogin ? (
          <Footer os={os} className="register-footer-text">
            <ButtonLink os={os} className="reegister-footer-button" onClick={this.toLoginClick}>
              {__("already_have_account", "Already have an account?") + " " + __("signin")}
            </ButtonLink>
          </Footer>
        ) : null}
      </React.Fragment>
    );
  }

  renderRegistrationFlow() {
    const {
      toLogin,
      subTitle,
      showTitle,
      title,
      isWebview,
      isLoginModal,
      redirect_uris,
      isRegisterPage,
      passpcloud,
      os,
      ...props
    } = this.props;
    const { loader, showFacebookUnavailableModal, usegrecaptcha } = this.state;
    const translateTitle = title ? __(title) : __("signup");

    const showModalContentInsideForm = os !== DEVICE_OS.Web || (os === DEVICE_OS.Web && isLoginModal);

    if (showModalContentInsideForm && showFacebookUnavailableModal) {
      return (
        <SocialLoginUnavailableModal
          os={os}
          isModal={showModalContentInsideForm}
          onClose={this.closeFacebookUnavailableModal}
          onContinue={this.onFacebookUnavailableContinue}
          open={showFacebookUnavailableModal}
        />
      )
    }

    return (
      <React.Fragment>
        {loader ? (
          <FormStyle.LoaderWrapper>
            <FormStyle.Loader />
          </FormStyle.LoaderWrapper>
        ) : null}

        <FormWrapper os={os}>
          {showTitle ? (
            <Heading os={os} className={isWebview ? "webview" : "register-title"}>
              {translateTitle}
            </Heading>
          ) : null}
          {subTitle ? (
            <SubHeading os={os} className="register-subtitle">
              {subTitle}
            </SubHeading>
          ) : null}
          <Registration usegrecaptcha={usegrecaptcha} isWebview={isWebview} os={os} passpcloud={passpcloud} {...props} />
          <ContinueWith os={os} className="register-text-or">
            {__("or_continue", "or continue with")}
          </ContinueWith>
          <SocialButtonWrapper os={os}>
            <ButtonsWrapper os={os} receiveToken={this.receiveToken} redirect_uris={redirect_uris} passpcloud={passpcloud} openUnavailableModal={this.showFacebookUnavailableModal}/>
          </SocialButtonWrapper>
        </FormWrapper>
        {this.renderFooter()}
        { !showModalContentInsideForm ? (
          <SocialLoginUnavailableModal
            isModal={showModalContentInsideForm}
            os={os}
            open={showFacebookUnavailableModal}
            onClose={this.closeFacebookUnavailableModal}
            onContinue={this.onFacebookUnavailableContinue}
          />
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    const { theme, isLoginModal, os, language, onSuccess, isWebview } = this.props;
    const {
      token,
      email,
      externalToken,
      tfatype,
      showTFALoginForm,
      hasdevices,
      showRestrictionWarning,
      locationId,
      verifyToken,
      showRegisterVerificationCode,
      showLoginVerificationCode,
      verificationCode,
      showCreatePassword,
      resendVerificationCode,
      showFacebookUnavailableModal
    } = this.state;

    if (showRegisterVerificationCode) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <RegisterVerificationCode
            os={os}
            email={email}
            language={language}
            resend={resendVerificationCode}
            setShowCreatePassword={this.setShowCreatePassword}
            setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
            setLocationId={this.setLocationId}
          />
        </Wrapper>
      );
    }

    if (showLoginVerificationCode) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <LoginVerificationCode
            os={os}
            email={email}
            language={language}
            resend={resendVerificationCode}
            setShowCreatePassword={this.setShowCreatePassword}
            setShowLoginVerificationCode={this.setShowLoginVerificationCode}
            setLocationId={this.setLocationId}
          />
        </Wrapper>
      );
    }

    if (showCreatePassword) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <LoginCreatePassword
            locationId={locationId}
            os={os}
            email={email}
            isWebview={isWebview}
            verificationCode={verificationCode}
            resetView={this.resetView}
            onSendCodeClick={this.onSendCodeClick}
            onSuccess={onSuccess}
          />
        </Wrapper>
      );
    }

    if (showTFALoginForm) {
      return <TwoFactorAuthLogin hasLoggedDevices={hasdevices} token={token} tfatype={tfatype} {...this.props} />;
    }

    if (isLoginModal && showRestrictionWarning) {
      return <RestrictedAccessWarning verifyToken={verifyToken} email={email} />;
    }

    return (
      <Wrapper os={os} className="responsive-wrap">
        {showRestrictionWarning ? (
          <RestrictedAccessWarningModal
            verifyToken={verifyToken}
            email={email}
            show={showRestrictionWarning}
            onClose={this.closeWarning}
          />
        ) : null}
        {externalToken ? this.renderExternalFlow() : this.renderRegistrationFlow()}
      </Wrapper>
    );
  }
}
