// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import FB_Login from "../../../../lib/fb_login"; // DONE
import { DEVICE_OS, EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { __ } from "../../../../lib/translate"; // DONE
import apiMethod from "../../../../api/apiMethod"; // DONE
import Popup from "../../../../lib/Popup"; // DONE

import SocialButton from "../../MultiplatformComponents/Elements/SocialButton"; // DONE
import FacebookLogo from "../../MultiplatformComponents/Elements/FacebookLogo" // DONE
import { LogoWrapper } from "../../MultiplatformComponents/CommonStyles";

const FacebookButton = props => {
  const {
    os = 4,
    size = "small",
    passpcloud = false,
    openUnavailableModal = () => {},
    redirect_uris,
    getRequestId,
    requestId,
    getToken,
    receiveToken,
    language,
    state
  } = props;

  const onButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (redirect_uris) {
      let params = "";

      if (redirect_uris && os === DEVICE_OS.Web) {
        params = "is_extension";
      }
      
      if (passpcloud) {
        params = "passpcloud";
      }

      getRequestId(params)
        .then(({ request_id }) => {
          FB_Login.initGetToken(redirect_uris, request_id, passpcloud, os);
          getToken('fb_oauth2_token', request_id)
            .then(({ access_token }) => FB_Login.getTokenCallback(receiveToken(access_token, EXTERNAL_APPS.FACEBOOK), redirect_uris, request_id, passpcloud));
        });
    } else {
      FB_Login.getTokenCallback(receiveTokenCb, null, requestId, passpcloud, os, language, { ...state, app: EXTERNAL_APPS.FACEBOOK });
    }
    // openUnavailableModal();
  }

  const receiveTokenCb = token => {
    receiveToken(token, EXTERNAL_APPS.FACEBOOK);
  }

  if (size === "big") {
    return (
      <SocialButton os={os} className="login-button-facebook" onClick={onButtonClick}>
        <LogoWrapper>
          <FacebookLogo os={os} />
        </LogoWrapper>
        <span>{__("continue_with_facebook", "Continue with Facebook")}</span>
      </SocialButton>
    );
  };

  return (
    <SocialButton os={os} className="login-button-facebook" onClick={onButtonClick}>
      <FacebookLogo os={os} />
    </SocialButton>
  );
}

export default FacebookButton;
