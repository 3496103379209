// @flow

import type { LinkData, UploadSettings } from "../reducers/sharedLinksSettings";
import { parseLinkData } from "../../../containers/ShareLinkSettings/utils";
import {
  LOAD_LINK_DATA,
  SET_UPLOAD_SETTINGS,
  SET_DOWNLOAD_SETTING,
  SET_HAS_PASSWORD,
  SET_EXPIRES,
  SET_EMAILS_WITH_UPLOAD_ACCESS,
  SET_SHORT_LINK_URL
} from "../constants/sharedLinksSettings";

export type SharesAction =
  | LoadSharedLinkData
  | SetUploadSettings
  | SetDownloadSettings
  | SetHasPassword
  | SetExpires
  | SetEmailsWithUploadAccess
  | SetShortLinkUrl;

type LoadSharedLinkData = { type: typeof LOAD_LINK_DATA, linkData: {} };
type SetUploadSettings = { type: typeof SET_UPLOAD_SETTINGS, settings: UploadSettings };
type SetDownloadSettings = { type: typeof SET_DOWNLOAD_SETTING, canDownload: boolean };
type SetHasPassword = { type: typeof SET_HAS_PASSWORD, payload: boolean };
type SetExpires = { type: typeof SET_EXPIRES, payload: boolean };
type SetEmailsWithUploadAccess = {
  type: typeof SET_EMAILS_WITH_UPLOAD_ACCESS,
  emails: Array<string>
};
type SetShortLinkUrl = {
  type: typeof SET_SHORT_LINK_URL,
  payload: boolean
};

export function loadSharedLinkData(linkData: LinkData): LoadSharedLinkData {
  return { type: LOAD_LINK_DATA, linkData: parseLinkData(linkData) };
}

export function setUploadSettings(settings: UploadSettings): SetUploadSettings {
  return { type: SET_UPLOAD_SETTINGS, settings };
}

export function setDownloadSettings(canDownload: Boolean): SetDownloadSettings {
  return { type: SET_DOWNLOAD_SETTING, canDownload };
}

export function setHasPassword(payload: boolean): SetHasPassword {
  return { type: SET_HAS_PASSWORD, payload };
}

export function setExpires(payload: boolean): SetExpires {
  return { type: SET_EXPIRES, payload };
}

export function setEmailsWithUploadAccess(emails: Array<string>): SetEmailsWithUploadAccess {
  return { type: SET_EMAILS_WITH_UPLOAD_ACCESS, emails };
}

export function setShortLinkUrl(payload: boolean): SetShortLinkUrl {
  return { type: SET_SHORT_LINK_URL, payload };
}

export const sharedLinkActionCreators = {
  loadSharedLinkData,
  setUploadSettings,
  setDownloadSettings,
  setHasPassword,
  setExpires,
  setEmailsWithUploadAccess,
  setShortLinkUrl
};
