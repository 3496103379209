import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

const NotificationsBell = styled.div`
  width: 40px;
  height: 48px;
  background: url(${require("../../root/img/header/bell.png")}) no-repeat center center;
  background-size: 16px 19px;
  cursor: pointer;
  position: relative;

  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/bell@2x.png")});
  }

  &:hover,
  &.opn,
  .popover-opened & {
    background-image: url(${require("../../root/img/header/bell-active.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/header/bell-active@2x.png")});
    }
  }

  &:hover,
  &.opn {
    background-image: url(${require("../../root/img/header/bell-active@2x.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/header/bell-active@2x.png")});
    }
  }
`;

export const NotificationsBellLight = styled.div`
  width: 40px;
  height: 48px;
  background: url(${require("../../root/img/header/bell-white.png")}) no-repeat center center;
  background-size: 16px 19px;
  cursor: pointer;
  opacity: 0.5;

  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/bell-white@2x.png")});
  }

  &:hover,
  &.opn,
  .popover-opened & {
    background-image: url(${require("../../root/img/header/bell-white.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/header/bell-white@2x.png")});
    }
  }

  &:hover,
  &.opn {
    background-image: url(${require("../../root/img/header/bell-white@2x.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/header/bell-white@2x.png")});
    }
  }
`;

export const NotificationContainer = styled.div`
  position: relative;
  width: 40px;
  height: 48px;
`;

export default NotificationsBell;
