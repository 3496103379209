import React, { useState } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";

import { __ } from "../../../lib/translate";
import { boldConverter, spanClassTagConverter } from "../../../../web-shared/componentifyConverters";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper";
import Form from "../MultiplatformComponents/Elements/Form";
import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import InputWrapper from "../MultiplatformComponents/Elements/InputWrapper";
import Input from "../MultiplatformComponents/Elements/Input";
import Button from "../MultiplatformComponents/Elements/Button";
import Footer from "../MultiplatformComponents/Elements/Footer";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper";
import MessageBox from "../MultiplatformComponents/Elements/MessageBox";
import Message from "../MultiplatformComponents/Elements/Message";
import SystemMessage from "./Message";
import CheckEmailIcon from "../../../../root/img/svg/check-email.svg";
import CheckEmailIconiOS from "../../../../root/img/svg/check-email-ios.svg";
import CheckEmailIconAndroid from "../../../../root/img/svg/check-email-android.svg";
import VerificationCodeInputs from "../MultiplatformComponents/VerificationCodeInputs";
import InputInnerButton from "../LoginOptions/InputInnerButton";
import NarrowArrow from "../../../../web-shared/images/svg/narrow-arrow.svg";
import { ButtonWithIcon } from "../../../components/ButtonDefault";
import ResponsiveEmail from "../../ResponsiveEmail";
import PcloudLogo from "../../../../web-shared/images/svg/pcloud-gradient-logo.svg"

const LoginVerificationCodeUI = ({
  os = DEVICE_OS.Web,
  isWebview = false,
  email = "",
  verificationCode,
  error = "",
  disabled = false,
  loading = false,
  canResendCode = true,
  waitingTimeForSend = 0,
  setDisabledForm = () => { },
  onVerificationCodeChange = () => { },
  onResendCodeClick = () => { },
  onSubmit = () => { },
}) => {
  const [code, setCode] = useState([]);

  return (
    <React.Fragment>
      <FormWrapper os={os}>
        {os !== DEVICE_OS.Web &&
          <LogoWrapper>
            <PcloudLogo />
          </LogoWrapper>
        }
        <React.Fragment>
          <Heading os={os} className={isWebview ? "webview" : "login-title"}>
            {__("2FA_force_title", "Verify login attempt")}
          </Heading>
          <SubHeading os={os}>{__("2FA_force_text", "To confirm your pCloud account's security, please enter the verification code sent to your email.")}</SubHeading>
        </React.Fragment>
        <Form os={os} onSubmit={onSubmit}>
          <SystemMessage os={os} error={error} />
          <SecurityCodeWrapper addMargin={os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android}>
            <VerificationCodeInputs
              os={os}
              codeLength={8}
              hasError={false}
              erorrMessage={""}
              handleCodeState={({ isCodeReady, code }) => {
                const arrOfCharsToString = code.join("");
                setDisabledForm(!isCodeReady)
                setCode(code);
                onVerificationCodeChange(arrOfCharsToString)
              }}
            />
            {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android &&
              <Button
                os={os}
                minHeight="48px"
                onClick={onSubmit}
                loading={loading}
                disabled={disabled}
                disabledColor="#ebedf0"
                style={{ marginTop: "0px" }}
                className="tfa-button"
              >
                <NarrowArrow />
              </Button>
            }
          </SecurityCodeWrapper>
          {(os === DEVICE_OS.Android || os === DEVICE_OS.iOS) &&
            <Button
              os={os}
              onClick={onSubmit}
              loading={loading}
              disabled={disabled}
              disabledColor="#ebedf0" >
              {__("continue", "Continue")}
            </Button>
          }
        </Form>
      </FormWrapper>
      <Footer os={os}>
        <ButtonLink disabled={!canResendCode} os={os} type="button" onClick={onResendCodeClick}>
          {`${__("verification_code_resend_button_text", "Didn't receive the code?")} ${__(
            "verification_code_resend_button",
            "Send again"
          )}`}
          {!canResendCode ? (
            <Counter>({waitingTimeForSend})</Counter>
          ) : null}
        </ButtonLink>
      </Footer>
    </React.Fragment >
  );
};

export default LoginVerificationCodeUI;

const SecurityCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: inherit;
  /* gap: 6px; */
  .verification-code-wrapper {
    ${({ addMargin }) => addMargin && "margin-right: 6px;"}
  }
`;

const Counter = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: red !important;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
