// @flow

import type { Action } from "../actions/types";
import {
    ONBOARDING_ADD_ITEM,
    ONBOARDING_REMOVE_ITEM
} from "../constants/onboarding";

export type OnboardingState = {
  queue: Array<any>,
};

const initialState = {
  queue: [],
};

export function onboardingReducer(
  state: OnboardingState = initialState,
  action: Action
): OnboardingState {
  switch (action.type) {
    case ONBOARDING_ADD_ITEM:
      return {
        ...state,
        queue: [...state.queue, action.id]
      };
    case ONBOARDING_REMOVE_ITEM:
      return {
        ...state,
        queue: state.queue.filter(item => item !== action.id)
      };
    default:
      return state;
  }
}
