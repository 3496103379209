// @flow

import apiMethod from "./apiMethod";

export const getBillingInfoCall = (onSuccess = () => {}, onError = () => {}) => {
  return apiMethod(
    "account_billinginfo",
    {},
    onSuccess,
    {
      errorCallback: onError,
      forceFresh: true,
      showErrorMessage: false
    }
  );
};

export const getAccountInfoCall = (onSuccess = () => {}, onError = () => {}) => {
  return apiMethod(
    "account_info",
    {},
    onSuccess,
    { 
      errorCallback: onError,
      forceFresh: true
    }
  );
};

export const activateGracePeriod = (params, onSuccess = () => {}, onError = () => {}) => {
  return apiMethod(
    "account_activategraceperiod",
    params,
    onSuccess,
    {
      errorCallback: onError,
      forceFresh: true
    }
  );
};
