import React from "react";
import styled from "styled-components";

const ResponsiveEmail = ({
  email = ""
}) => {
  if (!email || typeof email !== "string") {
    return null;
  };

  const emailPartsArray = email.split("@");

  if (emailPartsArray.length !== 2) {
    return null;
  }

  return (
    <ResponsiveEmailWrapepr>
      <EmailName>{emailPartsArray[0]}</EmailName>
      <span>@</span>
      <EmailDOmain>{emailPartsArray[1]}</EmailDOmain>
    </ResponsiveEmailWrapepr>
  )
}

export default ResponsiveEmail;

const ResponsiveEmailWrapepr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
`;

const EmailDOmain = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`;