// @flow
// WARNING: Do not use HFN in this component

import React, { Component } from "react";

import { __ } from "../../lib/translate"; // DONE
import { loadScript, rcookie, setcookie } from "../../lib/utils"; // DONE
import Login from "./Login"; // DONE
import RegisterVerificationCode from "./RegisterVerificationCode";
import LoginVerificationCode from "./LoginVerificationCode"; // DONE
import LoginCreatePassword from "./LoginCreatePassword"; // DONE
import ExternalRegister from "./SocialLogins/ExternalRegister"; // DONE
import TwoFactorAuthLogin from "./TwoFactorAuthLogin"; // DONE
import ButtonsWrapper from "./SocialLogins/ButtonsWrapper"; // DONE
import ForgotPassModal from "./ForgotPassModal"; // DONE
import * as FormStyle from "../../components/FormSharedComponents"; // DONE
import { RestrictedAccessWarning, RestrictedAccessWarningModal } from "./RestrictedAccessWarning"; // DONE

import { DEVICE_OS, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import Wrapper from "./MultiplatformComponents/Elements/Wrapper"; // DONE
import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE
import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import SubHeading from "./MultiplatformComponents/Elements/SubHeading"; // DONE
import Footer from "./MultiplatformComponents/Elements/Footer"; // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink"; // DONE
import ContinueWith from "./MultiplatformComponents/Elements/ContinueWith"; // DONE
import SocialButtonWrapper from "./MultiplatformComponents/Elements/SocialButtonWrapper"; // DONE
import SocialLoginUnavailableModal from "./SocialLogins/SocialLoginUnavailableModal"; // DONE
import GracePeriodBisiness from "./GracePeriodBisiness"; // DONE
import { myUrl } from "@pcloud/web-utilities/dist/config"; // DONE
import { apiMethod } from "../../api";
import { getLoginOptionsArray } from "../UserAuthentication2/utils.signin";
import { SETUP_PASS_LOGIN_METHOD_ID, VERIFICATION_CODE_METHOD_ID } from "../UserAuthentication2/constants.signin";
import { setGlobalLocationById, setGlobalLocations } from "@pcloud/web-utilities/dist/api/utils.api";

type Props = {
  title: string,
  showTitle?: boolean,
  email: string,
  theme: string,
  subTitle: string,
  isLoginModal: boolean,
  redirect_uris: object,
  isWebview: boolean,
  passpcloud: boolean,
  language: string,
  onSuccess: () => void,
  toRegister: null | (any => void),
  os: number
};

type State = {
  token: string,
  tfatype: string,
  email: string,
  externalToken: string,
  externalApp: string,
  loginWith: string,
  showTFALoginForm: boolean,
  showRestrictionWarning: boolean,
  hasdevices: boolean,
  verifyToken: boolean,
  rememberme: boolean,
  isLoginPage: boolean,
  showRegisterVerificationCode: boolean,
  usegrecaptcha: boolean,
  loader: boolean
};

export default class Signin extends Component<Props, State> {
  static defaultProps = {
    title: "signin",
    email: "",
    showTitle: true,
    theme: "",
    subTitle: "",
    isLoginModal: false,
    toRegister: null,
    isWebview: false,
    passpcloud: false,
    os: 4,
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      locationId: EU_LOCATIONID,
      token: "",
      tfatype: "",
      email: props.email || "",
      externalToken: "",
      externalApp: "",
      loginWith: rcookie("logininfo") || "",
      showTFALoginForm: false,
      showRestrictionWarning: false,
      hasdevices: false,
      verifyToken: false,
      rememberme: false,
      showForgotPass: false,
      loader: true,
      verificationCode: "",
      showRegisterVerificationCode: false,
      showLoginVerificationCode: false,
      showPasswordInput: false,
      showCreatePassword: false,
      showFacebookUnavailableModal: false,
      resendVerificationCode: false,
      showGracePeriodModal: false,
      baRestrictedAuth: false,
      gracePeriodActivated: false,
      usegrecaptcha: false,
    };

    (this: any).setTFALoginInfo = this.setTFALoginInfo.bind(this);
    (this: any).getRememberMe = this.getRememberMe.bind(this);
    (this: any).setVerifyInfo = this.setVerifyInfo.bind(this);
    (this: any).closeWarning = this.closeWarning.bind(this);
    (this: any).receiveToken = this.receiveToken.bind(this);
    (this: any).toRegisterCLick = this.toRegisterCLick.bind(this);
    (this: any).signinDiffAccauntClick = this.signinDiffAccauntClick.bind(this);
    (this: any).onForgotPassword = this.onForgotPassword.bind(this);
    (this: any).onBackForgotPassClick = this.onBackForgotPassClick.bind(this);
    (this: any).setShowPasswordInput = this.setShowPasswordInput.bind(this);
    (this: any).setShowRegisterVerificationCode = this.setShowRegisterVerificationCode.bind(this);
    (this: any).setShowLoginVerificationCode = this.setShowLoginVerificationCode.bind(this);
    (this: any).setShowCreatePassword = this.setShowCreatePassword.bind(this);
    (this: any).resetView = this.resetView.bind(this);
    (this: any).onSendCodeClick = this.onSendCodeClick.bind(this);
    (this: any).setLocationId = this.setLocationId.bind(this);
    (this: any).showFacebookUnavailableModal = this.showFacebookUnavailableModal.bind(this);
    (this: any).closeFacebookUnavailableModal = this.closeFacebookUnavailableModal.bind(this);
    (this: any).onFacebookUnavailableContinue = this.onFacebookUnavailableContinue.bind(this);
    (this: any).onShowGraceModal = this.onShowGraceModal.bind(this);
    (this: any).closeShowGraceModal = this.closeShowGraceModal.bind(this);
    (this: any).gracePeriodActivated = this.gracePeriodActivated.bind(this);
    (this: any).onLoginCodeVerifaction = this.onLoginCodeVerifaction.bind(this);
  }

  componentDidMount() {
    const { os } = this.props;
    apiMethod("user/preparelogin", {os}, ({ result, usegrecaptcha }) => {
      if (result === 0) {
        if (usegrecaptcha) {
          if (typeof grecaptcha === "undefined" ) {
            try {
              loadScript("https://www.google.com/recaptcha/api.js?render=6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR", () => {
                this.setState({ usegrecaptcha: !!usegrecaptcha, loader: false });
              }, { dontcache: true }); 
            } catch (error) {
              console.error("Error loading recaptcha script:", error);
              this.setState({ loader: false });
            }
          } else {
            const recaptchaBadge = document.querySelector(".grecaptcha-badge");

            if (recaptchaBadge) {
              recaptchaBadge.style.visibility = "visible";
            }
          }
        }
        
        this.setState({ usegrecaptcha: !!usegrecaptcha, loader: false });
      } else {
        this.setState({ loader: false });
      }
    }, {
      errorCallback: (error) => {
        console.error("Error user/preparelogin:", error);
        this.setState({ loader: false });
      },
      onTimeout: () => {
        this.setState({ loader: false });
      }
    })
  }

  getRememberMe(rememberme: boolean) {
    this.setState({ rememberme: rememberme });
  }

  setVerifyInfo(verifyToken: boolean, email: string) {
    this.setState({ showRestrictionWarning: true, verifyToken, email });
  }

  setTFALoginInfo(token: string, hasdevices: boolean, tfatype: string, logininfo?: string) {
    this.setState({
      token: token,
      showTFALoginForm: true,
      hasdevices: hasdevices,
      tfatype: tfatype,
      loginWith: logininfo || ""
    });
  }

  setLocationId(id) {
    this.setState({ locationId: id });
  }

  closeWarning() {
    this.setState({ showRestrictionWarning: false, externalToken: "", externalApp: "" });
  }

  receiveToken(token: string, externalApp: string) {
    this.setState({ externalToken: token, externalApp });
  }

  toRegisterCLick(e: any) {
    const { toRegister } = this.props;

    e.preventDefault();
    toRegister(e);
  }

  signinDiffAccauntClick() {
    setcookie("logininfo", "");
    this.setState({ loginWith: "" });
  }

  onForgotPassword() {
    this.setState({ showForgotPass: true });
  }

  onShowGraceModal(auth) {
    console.log("on login clicked @GracePeriodModal", auth);
    this.setState({ showGracePeriodModal: true, baRestrictedAuth: auth });
  }

  closeShowGraceModal() {
    const { gracePeriodActivated } = this.state;
    this.setState({ showGracePeriodModal: false });

    if (gracePeriodActivated) {
      //retry login
      document.dispatchEvent(new Event("grace-period-activated"));
    }
  }

  gracePeriodActivated() {
    this.setState({ gracePeriodActivated: true });
  }

  showFacebookUnavailableModal() {
    this.setState({
      showFacebookUnavailableModal: true
    });
  }

  closeFacebookUnavailableModal() {
    this.setState({
      showFacebookUnavailableModal: false
    });
  }

  onFacebookUnavailableContinue() {
    setcookie("logininfo", "");
    this.setState({
      loginWith: "",
      showFacebookUnavailableModal: false
    });
  }

  onLoginCodeVerifaction(_verificationCode) {
    const {
      language,
      os
    } = this.props;

    const { email } = this.state;

    const params = {
      email,
      language,
      os,
      getlogins: 1
    };

    if (_verificationCode) {
      params.emailtfacode = _verificationCode;
    }

    apiMethod(
      "user/preparelogin",
      params,
      ({ result, logins, ...rest }) => {
        if (result === 0) {
          const loginOptions = getLoginOptionsArray(logins);

          if (loginOptions?.preferredOption?.length && loginOptions?.preferredOption?.includes(SETUP_PASS_LOGIN_METHOD_ID)) {
            this.setShowRegisterVerificationCode(true, email, _verificationCode);
          } else if (loginOptions?.preferredOption?.length && loginOptions?.preferredOption?.includes(VERIFICATION_CODE_METHOD_ID)) {
            this.setShowLoginVerificationCode(true, email, _verificationCode);
          } else {
            this.setShowPasswordInput(true, _verificationCode);
          }
        }
      },
      {
        errorCallback: ({ result, location = {} }) => {
          if (result === 2360 || result === 2321) {
            if (location.id) {
              setGlobalLocationById(location.id);
              this.setLocationId(location.id);
              setGlobalLocations([location]);
              this.onLoginCodeVerifaction();
              return;
            }
          }

          this.setState({ isLogging: false });
        }
      }
    );
  }

  renderExternalFlow() {
    const { passpcloud, theme, onSuccess, language, os } = this.props;
    const { externalToken, externalApp } = this.state;

    return (
      <React.Fragment>
        <ExternalRegister
          os={os}
          passpcloud={passpcloud}
          language={language}
          token={externalToken}
          externalApp={externalApp}
          onSuccess={onSuccess}
          theme={theme}
          setTFALoginInfo={this.setTFALoginInfo}
          setVerifyInfo={this.setVerifyInfo}
          setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
          setShowLoginVerificationCode={this.setShowLoginVerificationCode}
          onBackClick={() => this.setState({ externalToken: "", externalApp: "" })}
        />
      </React.Fragment>
    );
  }

  renderContinueWith(view) {
    const { title, showTitle, redirect_uris, isWebview, passpcloud, os } = this.props;

    return (
      <React.Fragment>
        <FormWrapper os={os}>
          {showTitle ? (
            <Heading os={os} className={isWebview ? "webview" : "login-title"}>
              {__(title)}
            </Heading>
          ) : null}
          <Form os={os}>
            <ButtonsWrapper
              os={os}
              view={view}
              receiveToken={this.receiveToken}
              redirect_uris={redirect_uris}
              passpcloud={passpcloud}
              openUnavailableModal={this.showFacebookUnavailableModal}
            />
          </Form>
        </FormWrapper>
        <Footer os={os}>
          <ButtonLink os={os} type="button" onClick={this.signinDiffAccauntClick} className="login-use-different-account">
            {__("sign_in_account", "Sign in with a different account")}
          </ButtonLink>
        </Footer>
      </React.Fragment>
    );
  }

  onBackForgotPassClick() {
    this.setState({ showForgotPass: false });
  }

  setShowPasswordInput(show = false, code = "") {
    this.setState({ showRegisterVerificationCode: false, showLoginVerificationCode: false, showPasswordInput: show, verificationCode: code });
  }

  setShowRegisterVerificationCode(show = false, email = "", code = "") {
    this.setState({ showRegisterVerificationCode: show, showLoginVerificationCode: false, email, verificationCode: code });
  }

  setShowLoginVerificationCode(show = false, email = "", code = "") {
    this.setState({ showRegisterVerificationCode: false, showLoginVerificationCode: show, email, verificationCode: code });
  }

  setShowCreatePassword(show = false) {
    this.setState({ showCreatePassword: show });
  }

  resetView() {
    this.setState({
      email: "",
      verificationCode: "",
      showRegisterVerificationCode: false,
      showLoginVerificationCode: false,
      showCreatePassword: false
    });
  }

  onSendCodeClick() {
    this.setState({
      verificationCode: "",
      showCreatePassword: false,
      showRegisterVerificationCode: true,
      resendVerificationCode: true
    });
  }

  renderLoginFlow() {
    const {
      language,
      toRegister,
      isLoginModal,
      redirect_uris,
      isLoginPage,
      passpcloud,
      showTitle,
      subTitle,
      isWebview,
      title,
      os,
      onSuccess,
      ...props
    } = this.props;
    const {
      locationId,
      email,
      loginWith,
      showForgotPass,
      loader,
      verificationCode,
      showPasswordInput,
      showFacebookUnavailableModal,
      showCreatePassword,
      showGracePeriodModal,
      baRestrictedAuth,
      usegrecaptcha,
    } = this.state;

    const showInsideForm = os !== DEVICE_OS.Web || (os === DEVICE_OS.Web && isLoginModal);

    if (showInsideForm && showForgotPass) {
      return (
        <ForgotPassModal
          os={os}
          isModal={showInsideForm}
          passpcloud={passpcloud}
          onClose={this.onBackForgotPassClick}
        />
      );
    }

    if (showInsideForm && showFacebookUnavailableModal) {
      return (
        <SocialLoginUnavailableModal
          os={os}
          isModal={showInsideForm}
          onClose={this.closeFacebookUnavailableModal}
          onContinue={this.onFacebookUnavailableContinue}
          open={showFacebookUnavailableModal}
        />
      );
    }

    if (showInsideForm && showGracePeriodModal) {
      console.log("inside form ", showInsideForm, showGracePeriodModal);
      return (
        <GracePeriodBisiness
          os={os}
          isModal={showInsideForm}
          onClose={this.closeShowGraceModal}
          open={showGracePeriodModal}
          auth={baRestrictedAuth}
          gracePeriodActivated={this.gracePeriodActivated}
        />
      );
    }

    return (
      <React.Fragment>
        {loginWith ? (
          this.renderContinueWith(loginWith.toLowerCase())
        ) : (
          <React.Fragment>
            <FormWrapper os={os}>
              {showTitle ? (
                <Heading os={os} subTitle={subTitle} className={isWebview ? "webview" : "login-title"}>
                  {__(title)}
                </Heading>
              ) : null}
              {subTitle ? (
                <SubHeading od={os} className="login-subtitle">
                  {subTitle}
                </SubHeading>
              ) : null}
              {/* <Form className="form" os={os}> */}
              <Login
                {...props}
                os={os}
                passpcloud={passpcloud}
                language={language}
                email={email}
                locationId={locationId}
                showPasswordInput={showPasswordInput}
                verificationCode={verificationCode}
                setShowPasswordInput={this.setShowPasswordInput}
                setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
                setShowLoginVerificationCode={this.setShowLoginVerificationCode}
                showForgotPass={this.onForgotPassword}
                setTFALoginInfo={this.setTFALoginInfo}
                setVerifyInfo={this.setVerifyInfo}
                getRememberMe={this.getRememberMe}
                setLocationId={this.setLocationId}
                onSuccess={onSuccess}
                showGracePeriodModal={false}
                showInsideForm={showInsideForm}
                showGracePeriodModalCb={this.onShowGraceModal}
                usegrecaptcha={usegrecaptcha}
              />
              <ContinueWith os={os} className="login-text-or">
                {__("or_continue", "or continue with")}
              </ContinueWith>
              <SocialButtonWrapper os={os}>
                <ButtonsWrapper
                  os={os}
                  receiveToken={this.receiveToken}
                  redirect_uris={redirect_uris}
                  passpcloud={passpcloud}
                  openUnavailableModal={this.showFacebookUnavailableModal}
                />
              </SocialButtonWrapper>
              {/* </Form> */}
            </FormWrapper>
            {toRegister ? (
              <Footer>
                <ButtonLink type="button" os={os} onClick={this.toRegisterCLick} className="login-footer-button">
                  {__("dont_have_account", "Don't have an account?") + " " + __("signup", "Sign up")}
                </ButtonLink>
              </Footer>
            ) : null}
          </React.Fragment>
        )}
        {loader ? (
          <FormStyle.LoaderWrapper>
            <FormStyle.Loader />
          </FormStyle.LoaderWrapper>
        ) : null}
        {!showInsideForm ? (
          <ForgotPassModal
            os={os}
            open={showForgotPass}
            isModal={showInsideForm}
            passpcloud={passpcloud}
            onClose={this.onBackForgotPassClick}
          />
        ) : null}
        {!showInsideForm ? (
          <SocialLoginUnavailableModal
            isModal={showInsideForm}
            os={os}
            open={showFacebookUnavailableModal}
            onClose={this.closeFacebookUnavailableModal}
            onContinue={this.onFacebookUnavailableContinue}
          />
        ) : null}
        {!showInsideForm ? (
          <GracePeriodBisiness
            os={os}
            isModal={showInsideForm}
            onClose={this.closeShowGraceModal}
            auth={baRestrictedAuth}
            open={showGracePeriodModal}
            gracePeriodActivated={this.gracePeriodActivated}
          />
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    const { theme, isLoginModal, os, onSuccess, language, ...props } = this.props;
    const {
      token,
      loginWith,
      externalToken,
      showRestrictionWarning,
      tfatype,
      verifyToken,
      email,
      showTFALoginForm,
      hasdevices,
      rememberme,
      showRegisterVerificationCode,
      showLoginVerificationCode,
      verificationCode,
      showCreatePassword,
      resendVerificationCode,
      locationId,
      showFacebookUnavailableModal
    } = this.state;
    console.log("🚀 ~ file: Signin.js:371 ~ Signin ~ render ~ email", email);

    if (isLoginModal && showRestrictionWarning) {
      return <RestrictedAccessWarning verifyToken={verifyToken} email={email} />;
    }

    if (showTFALoginForm) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <TwoFactorAuthLogin
            {...this.props}
            os={os}
            token={token}
            logininfo={loginWith}
            hasLoggedDevices={hasdevices}
            rememberme={rememberme}
            tfatype={tfatype}
          />
        </Wrapper>
      );
    }

    if (showRegisterVerificationCode) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <RegisterVerificationCode
            os={os}
            email={email}
            language={language}
            resend={resendVerificationCode}
            setShowCreatePassword={this.setShowCreatePassword}
            setShowRegisterVerificationCode={this.setShowRegisterVerificationCode}
            setLocationId={this.setLocationId}
          />
        </Wrapper>
      );
    }

    if (showLoginVerificationCode) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <LoginVerificationCode
            os={os}
            email={email}
            language={language}
            resend={resendVerificationCode}
            setShowPasswordInput={this.setShowPasswordInput}
            setShowLoginVerificationCode={this.setShowLoginVerificationCode}
            setLocationId={this.setLocationId}
            onLoginCodeVerifaction={this.onLoginCodeVerifaction}
          />
        </Wrapper>
      );
    }

    if (showCreatePassword) {
      return (
        <Wrapper os={os} className="responsive-wrap">
          <LoginCreatePassword
            {...props}
            locationId={locationId}
            os={os}
            email={email}
            verificationCode={verificationCode}
            resetView={this.resetView}
            onSendCodeClick={this.onSendCodeClick}
            onSuccess={onSuccess}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper os={os} className="responsive-wrap">
        {showRestrictionWarning ? (
          <RestrictedAccessWarningModal
            verifyToken={verifyToken}
            email={email}
            show={showRestrictionWarning}
            onClose={this.closeWarning}
          />
        ) : null}
        {externalToken ? this.renderExternalFlow() : this.renderLoginFlow()}
      </Wrapper>
    );
  }
}
