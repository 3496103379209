import PropTypes from 'prop-types';
import React from "react";
import { __ } from "../lib/translate";

const UploadButton = ({ active, onButtonClick, errorText }) => {
  let classes = [ "ul-uploadbutton" ];
  if (active) {
    classes.push("active");
  }

  let tooltip = { "data-toggle": "remove-tooltip" };
  if (errorText) {
    tooltip.title = errorText;
    tooltip["data-toggle"] = "tooltip";
    tooltip["data-container"] = ".upload-link-wrap";
  }

  return (
    <div className={classes.join(" ")}>
      <div onClick={onButtonClick} {...tooltip}>
        {__("ul_upload", "UPLOAD").toUpperCase()}
      </div>
    </div>
  );
};

UploadButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  errorText: PropTypes.string
};

export default UploadButton;
