// @flowstore

import { userActionCreators as user } from "./user";
import { notificationsActionCreators as notifications } from "./notifications";
import { publiclinkActionCreators as publiclink } from "./publiclink";
import { sharesActionCreators as shares } from "./shares";
import { businessActionCreators as business } from "./business";
import { audioPlayerActionCreators as audioPlayer } from "./audioPlayer";
import { sharedLinkActionCreators as sharedLink } from "./sharedLinksSettings";
import { fileRequestSettingsActionCreators as fileRequestSettings } from "./fileRequestSettings";
import { onboardingActionCreators as onboarding } from "./onboarding";
import { galleryPreviewActionCreators as galleryPreview } from "./galleryPreview";

export default {
  user,
  notifications,
  publiclink,
  shares,
  business,
  audioPlayer,
  sharedLink,
  fileRequestSettings,
  onboarding,
  galleryPreview
};
