import React from 'react';

import Scrollbar from './Scrollbar';
import { fileListSize, formatSize } from '../lib/utils';

import FileRow from './FileRow';

const FilesList = (props) => {
	const { files, removeFile, removeAllFiles } = props;
	const scrollHeight = Math.min(files.length * 41, 246);
	let filesList;

	filesList = <div></div>;
	if (files.length) {
		filesList = <div className="ul-files-list clearfix">
			<div className="heading clearfix">
				<div className="name">{files.length} files added</div>

				<div className="remove" onClick={removeAllFiles}></div>
				<div className="size">{formatSize(fileListSize(files))}</div>
			</div>
			<Scrollbar className="files" style={{ height: scrollHeight }}>
				{files.map((file, i) =>
						<FileRow key={i} file={file} remove={removeFile} />)}
			</Scrollbar>
		</div>;
	}

	return (
		<div>
			{filesList}
		</div>
	);
}

export default FilesList;
