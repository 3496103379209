import React from "react";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import ErrorIconAndroid from "../../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "../MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "../MultiplatformComponents/Elements/ErrorMessage"; // DONE
import Notification from "../MultiplatformComponents/Notification";

const Message = ({
  error = "",
  os = DEVICE_OS.Web
}) => {
  const errorMessageIcons = {
    [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24" />,
    [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24" />,
    [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24" />
  };

  if (((os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && error) || os === DEVICE_OS.Web) {
    return (
      <ErrorBox os={os}>
        <div className="icon-wrapper">{errorMessageIcons[os]}</div>
        <ErrorMessage error={error} className="login-error-message" os={os}>
          {error}
        </ErrorMessage>
      </ErrorBox>
    );
  }

  return <Notification os={os} />;
};

export default Message;
