// @flow

import {
  NOTIFICATIONS_LOAD,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_SHOW,
  NOTIFICATIONS_RESET
} from "../constants/notifications";
import type { ThunkAction } from "../types";
import type { Notification } from "../../notifications/types";
import apiMethod from "../../../api/apiMethod";

// ACTION TYPES
export type NotificationAction =
  | LoadNotificationsAction
  | ReadNotificationsAction
  | ShowNotificationsAction
  | ResetNotificationsAction;

type LoadNotificationsAction = {
  type: typeof NOTIFICATIONS_LOAD,
  notifications: Array<Notification>
};
type ReadNotificationsAction = { type: typeof NOTIFICATIONS_READ };
type ShowNotificationsAction = { type: typeof NOTIFICATIONS_SHOW };
type ResetNotificationsAction = { type: typeof NOTIFICATIONS_RESET };

// ACTION CREATORS
export function load(notifications: Array<Notification>): LoadNotificationsAction {
  return { type: NOTIFICATIONS_LOAD, notifications: notifications };
}

export function read(): ReadNotificationsAction {
  return { type: NOTIFICATIONS_READ };
}

export function readAsync(): ThunkAction {
  return (dispatch, getState) => {
    const user = getState().user;
    const notifications = getState().notifications;
    const lastNotificationId =
      notifications.loaded && notifications.notifications.length
        ? notifications.notifications[0].notificationid
        : false;
    if (user.logged && notifications.loaded) {
      apiMethod("readnotifications", { auth: user.token, notificationid: lastNotificationId }, () =>
        dispatch(read())
      );
    } else {
      dispatch(read());
    }
  };
}

export function reset(): ResetNotificationsAction {
  return { type: NOTIFICATIONS_RESET };
}

export function show(): ShowNotificationsAction {
  return { type: NOTIFICATIONS_SHOW };
}

export const notificationsActionCreators = { load, read, show, reset };
