import React from "react";
import styled from "styled-components";
import { __ } from "../../lib/translate";
import { FormWrap, FormContent, Title, ButtonWrapper, Input, InputWrapper, InputPrefix } from "./MainComponents";
import SubmitButton from "../SubmitButton";
import { containOnlyNumbers, hasLengthBetween, startsWith } from "../../lib/validators"; 

export const PhoneNumberStep = ({ handleChange, value, error, submit, loadingState }) => {
  const validateOnChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\s+/g, '').replace(/^(((\+)|0{1,2})359)|0?/, "");
    if ((containOnlyNumbers(value) || startsWith(value, ['+'])) && hasLengthBetween(value, 0, 9)) {
      handleChange(event.target.value);
    }
  }

  const onEnter = (event) => {
    if (event.keyCode == 13 ) {
      submit();
    }
  }

  const onPaste = (event) => {
    event.preventDefault();
  }

  return (
    <FormWrap>
      <Title error={error}>{error ? error : __("vivacom_phone_number")}</Title>
      <FormContent>
          <InputWrapper borderRadius={0} error={error}>
            <InputPrefix unselectable="on" error={error}>+359</InputPrefix>
            <Input onChange={validateOnChange} type="text" placeholder={__("vivacom_number_placeholder")} value={value} align="center" onPaste={onPaste} onKeyDown={onEnter} autoFocus/>
          </InputWrapper>
          <ButtonWrapper>
            <SubmitButton onClick={submit} loading={loadingState} style={{width: "100%"}}>{__("vivacom_submit")}</SubmitButton>
          </ButtonWrapper>
      </FormContent>
    </FormWrap>
  );
}
