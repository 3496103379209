export function arraySort(array, field, m) {
  array.sort(function(a, b) {

    if (typeof a[field] === "boolean") {
      return ((a[field] ? 1 : 0) - (b[field] ? 1 : 0)) * m;
    }

    if (!isNaN(parseInt(a[field]))) {
      return (a[field] - b[field]) * m;
    }

    return a[field].localeCompare(b[field]) * m;
  });

  return array;
}

export function rowClassName({ index }) {
  if (index < 0) {
    return "header-row";
  } else {
    return index % 2 === 0 ? "row even-row" : "row odd-row";
  }
}
