// @flow

import type { GalleryOptionsData } from "../reducers/galleryPreview";
import {
  FETCH_MEDIA_SUCCESS,
  DELETE_MEDIA_ITEM,
  SET_CAN_DOWNLOAD,
  // LOAD_GALLERY_DATA,
  SET_GALLERY_OPTIONS,
  SET_HAS_CODE,
  SET_HAS_PASSWORD
} from "../constants/galleryPreview";

export type GallerySettingsAction =
  // | LoadGalleryImageData
  MediaItemUrl |
  SetOptionsSettings;

// type LoadGalleryImageData = { type: typeof LOAD_GALLERY_DATA, linkData: Array<any> };
type MediaItemUrl = { type: typeof FETCH_MEDIA_SUCCESS, mediaItem: object };
type SetOptionsSettings = { type: typeof SET_GALLERY_OPTIONS, optsData: GalleryOptionsData };

// export const loadGalleryData = (data: Array<any>): LoadGalleryImageData => {
//   return { type: LOAD_GALLERY_DATA, linkData: data };
// };

export const fetchMediaSuccess = (mediaItem: object): MediaItemUrl => {
  return { type: FETCH_MEDIA_SUCCESS, payload: mediaItem };
};

export const deleteMediaItem = (id: string): string => {
  return { type: DELETE_MEDIA_ITEM, payload: id };
};

export const setGalleryOptions = (optsData: GalleryOptionsData): SetOptionsSettings => {
  return { type: SET_GALLERY_OPTIONS, payload: optsData };
};

export const setHasPassword = (linkpassword: string): SetOptionsSettings => {
  return { type: SET_HAS_PASSWORD, payload: linkpassword };
};

export const setHasCode = (code: string): SetOptionsSettings => {
  return { type: SET_HAS_CODE, payload: code };
};
export const setCanDownload = (candownload: boolean): SetOptionsSettings => {
  return { type: SET_CAN_DOWNLOAD, payload: candownload };
};

export const galleryPreviewActionCreators = {
  // loadGalleryData,
  fetchMediaSuccess,
  deleteMediaItem,
  setGalleryOptions,
  setHasPassword,
  setHasCode,
  setCanDownload
};
