// @flow
// WARNING: Do not use HFN in this component

import React, { useState } from "react";
import apiMethod from "../../api/apiMethod"; // DONE
import * as FormStyles from "../../components/FormSharedComponents"; // DONE
import { __ } from "../../lib/translate";
import { getDeviceID, validateEmail, validatePassword, rcookie, setcookie } from "../../lib/utils"; // DONE
import { DEVICE_OS, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import Notification, { notify } from "./MultiplatformComponents/Notification";

import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE
import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper"; // DONE
import Input from "./MultiplatformComponents/Elements/Input"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import Footer from "./MultiplatformComponents/Elements/Footer"; // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import MessageBox from "./MultiplatformComponents/Elements/MessageBox"; // DONE
import Message from "./MultiplatformComponents/Elements/Message"; // DONE
import CreatePassIcon from "../../../root/img/svg/create-pass.svg"; // DONE
import SigninOptionsWrapper from "./MultiplatformComponents/Elements/SigninOptionsWrapper"; // DONE
import CheckBox from "./MultiplatformComponents/Elements/CheckBox"; // DONE
import CreatePassIconiOS from "../../../root/img/svg/create-pass-ios.svg"; // DONE
import CreatePassIconAndroid from "../../../root/img/svg/create-pass-android.svg"; // DONE
import ErrorIconAndroid from "../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "./MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "./MultiplatformComponents/Elements/ErrorMessage"; // DONE
import errors from "../../lib/errors"; // DONE

const LoginCreatePassword = ({
  isWebview = false,
  email = "",
  os = DEVICE_OS.Web,
  osversion = "",
  appversion = "",
  appversioncode = "",
  device = "",
  clientid = "",
  locationId = EU_LOCATIONID,
  verificationCode = "",
  onSuccess = () => {},
  resetView = () => {},
  onSendCodeClick = () => {}
}) => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [wrongCodeError, setWrongCodeError] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const onPassChange = e => {
    setPassword(e.target.value);
    setWrongCodeError(false);
  };

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message);
    } else if (os !== DEVICE_OS.Web) {
      notify(message, type, { os });
    }
  };

  const iconByOs = {
    [DEVICE_OS.iOS]: <CreatePassIconiOS />,
    [DEVICE_OS.Android]: <CreatePassIconAndroid />,
    [DEVICE_OS.Web]: <CreatePassIcon />,
    [DEVICE_OS.Win]: <CreatePassIcon />,
    [DEVICE_OS.Linux]: <CreatePassIcon />,
    [DEVICE_OS.MacOSX]: <CreatePassIcon />
  };

  const errorMessageIcons = {
    [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24" />,
    [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24" />,
    [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24" />
  };

  const onContinueClick = e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    let error = validatePassword(password);

    if (!validateEmail(email)) {
      error = __("invalid_email", "Invalid Email");
    }

    if (error) {
      setError(error);
      return;
    }

    let _params = {
      email: email,
      code: verificationCode.trim(),
      password: password
    };

    if (os === DEVICE_OS.Web) {
      _params = {
        ..._params,
        _t: $.now,
        logout: 1,
        getlastsubscription: 1,
        promoinfo: 1,
        deviceid: getDeviceID(),
        os: os,
        osversion: "0.0.0"
      };

      if (rcookie("restrictedauth")) {
        _params.restrictedauth = rcookie("restrictedauth");
        setcookie("restrictedauth", "", -1);
      }  
    } else {
      if (os) {
        _params.os = os;
      }

      if (osversion) {
        _params.osversion = osversion;
      }

      if (appversion) {
        _params.appversion = appversion;
      }

      if (appversioncode) {
        _params.appversioncode = appversioncode;
      }

      if (device) {
        _params.device = device;
      }

      if (clientid) {
        _params.clientid = clientid;
      }
    }

    setIsLoading(true);

    apiMethod(
      "user/setuppassbycode",
      _params,
      response => {
        const { result, ...userinfo } = response;

        if (result === 0) {
          onSuccess({
            action: "login",
            userinfo: userinfo,
            rememberme: rememberMe,
            locationid: locationId
          });
        }
      },
      {
        errorCallback: ({ result }) => {
          console.log(result, "result");
          setIsLoading(false);
          if (result === 2012) {
            setWrongCodeError(true);
            showMessage(__("login_verification_code_expired", "Sign-in failed. Your verification code has expired."), "error");
          }

          if (errors[result]) {
            showMessage(errors[result], "error");
          } else {
            showMessage(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );

    // Errors:
    // 1130="Please provide 'email'."
    // 1022="Please provide 'code'."
    // 2012="Invalid 'code' provided."
    // 2035="Password can not contain only consecutive characters."
  };
  const PassIcon = showPass ? FormStyles.ShowPasswordIcon : FormStyles.HidePasswordIcon;

  const renderMessage = () => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && error) {
      return (
        <ErrorBox os={os}>
          <div className="icon-wrapper">{errorMessageIcons[os]}</div>
          <ErrorMessage className="login-error-message" os={os}>
            {error}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    if (os === DEVICE_OS.Web) {
      return null;
    }

    return <Notification os={os} />;
  };

  return (
    <React.Fragment>
      <FormWrapper os={os}>
        <Heading os={os} className={isWebview ? "webview" : "login-title"}>
          {__("create_password_title", "Create a password for pCloud")}
        </Heading>
        <Form os={os} onSubmit={onContinueClick}>
          <MessageBox os={os}>
            <div className="icon-wrapper">{iconByOs[os]}</div>
            <Message os={os}>{__("create_password_message", "You'll use this password to access your files in pCloud")}</Message>
          </MessageBox>
          {renderMessage()}
          <div>
            <InputWrapper os={os}>
              <Input os={os} type="email" value={email} readOnly disabled />
            </InputWrapper>
            <InputWrapper os={os}>
              <Input
                os={os}
                autoComplete="new-password"
                type={showPass ? "text" : "password"}
                value={password}
                onChange={onPassChange}
                placeholder={__("Enter new password", "Enter new password")}
                autoFocus
              />
              {password.length ? <PassIcon onClick={() => setShowPass(!showPass)} /> : null}
            </InputWrapper>
          </div>
          <SigninOptionsWrapper os={os} className="regsitration-terms">
            {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android ? (
              <CheckBox
                size="small"
                os={os}
                checked={rememberMe}
                onChange={e => setRememberMe(e.target.checked)}
                text={__("remember me", "Remember me")}
              />
            ) : (
              <div />
            )}
          </SigninOptionsWrapper>
          <ButtonWrapper os={os}>
            <Button os={os} onClick={onContinueClick}>
              {__("signin", "Sign in")}
            </Button>
          </ButtonWrapper>
        </Form>
      </FormWrapper>
      <Footer os={os}>
        {wrongCodeError ? (
          <ButtonLink os={os} onClick={onSendCodeClick} type="button">
            {__("login_verification_code_send_again", "Send code again")}
          </ButtonLink>
        ) : (
          <ButtonLink os={os} onClick={resetView} type="button">
            {__("sign_in_account", "Sign in with a different account")}
          </ButtonLink>
        )}
      </Footer>
    </React.Fragment>
  );
};

export default LoginCreatePassword;
