// @flow

import subscriptions from "./subscriptions";

let authToken = false;
let isRunning = false;
let listeners = [];

// used for both pollins and reading
let readXhr = null;

const contactsInstance = {
  handleEvents: handleEvents,
  getParams: () => {
    return {
      auth: authToken
    };
  }
};

function handleEvents (ret) {
  console.log("New event: ", ret);
  
  if (ret.from) {
    processEvents(ret.from);
  }

  subscriptions.setState('contacts', 'ready');
}

function processEvents(result) {
  listeners.forEach(listener => listener(result));
}


function listen(token: string, callback: () => void) {
  if (authToken !== token) {
    authToken = token;
  }

  listeners.push(callback);

  if (!isRunning) {
    start();
  }

  return () => {
    listeners.splice(listeners.indexOf(callback));

    if (!listeners.length) {
      stop();
    }
  };
}

function start() {
  if (isRunning) {
    stop();
  }

  isRunning = true;
  subscriptions.add('contacts', contactsInstance);
  subscriptions.setState('contacts', 'ready');

}

function stop() {
  if (readXhr) {
    readXhr.abort();
  }

  isRunning = false;
  subscriptions.remove('contacts');
}

export default { listen };
