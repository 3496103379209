import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../../lib/translate";
import Modal from "../../../components/Modal";

import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import Wrapper from "../MultiplatformComponents/Elements/Wrapper";
import ModalWindow from "../MultiplatformComponents/Elements/ModalWindow"
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import { BodyText } from "../MultiplatformComponents/iOSStyles";
import { IosModalClose } from "../MultiplatformComponents/iOSStyles";
import { AndroidModalClose } from "../MultiplatformComponents/AndroidStyles";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import DeviceContainer from "../MultiplatformComponents/Elements/DeviceContainer";
import { css } from 'styled-components'
const TFALoggedDevicesModalUI = ({
  os = 4,
  devices,
  isLoginModal,
  toggleLoggedDevices
}) => {
  const alignLeftText = os === DEVICE_OS.Android || os === DEVICE_OS.Win ? { textAlign: "left" } : undefined
  const deviceRows = devices && devices.length > 0 ? devices.map((device, index) => (
    <DeviceRow key={device.name + index}>
      {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android ? deviceIcons[device.type] : null}
      {os === DEVICE_OS.iOS ? <BodyTextDeviceTitle>{device.name}</BodyTextDeviceTitle> : <DeviceTitle os={os} style={alignLeftText}>{device.name}</DeviceTitle>}
    </DeviceRow>
  )) : __("tfa_login_error_2301", "You don't have any devices with logged pCloud account.");

  return isLoginModal ? (
    <div>
      <Wrapper os={os} style={{ padding: "0px", margin: "0px" }}>
        <Header os={os}>
          {os === DEVICE_OS.iOS ? <SubHeading os={os} style={{ margin: '0px', fontWeight: "bold" }}>{__("tfa_login_logged_devices_header", "Logged devices")}</SubHeading> :
            <Heading os={os} style={alignLeftText} isModal>{__("tfa_login_logged_devices_header", "Logged devices")}</Heading>
          }
          {os === DEVICE_OS.iOS ? <BodyText>{__("tfa_login_logged_devices_subheader", "You are currently logged in pCloud on the following devices or web browsers:")}</BodyText> :
            <SubHeading os={os} style={alignLeftText} isModal>{__("tfa_login_logged_devices_subheader", "You are currently logged in pCloud on the following devices or web browsers:")}</SubHeading>
          }
        </Header>
        {(os === DEVICE_OS.iOS || os === DEVICE_OS.Android) ? <React.Fragment>{deviceRows} </React.Fragment> :
          <DeviceContainer className="device-container" os={os}>{deviceRows}</DeviceContainer>
        }
      </Wrapper>
      <ModalBackButton className="back-button" onClick={toggleLoggedDevices}>{__("back")}</ModalBackButton>
    </div>
  ) : (
    <Modal opened onClose={toggleLoggedDevices} fullSize={true}>
      <ModalWindow os={os}>
        <Header os={os}>
          {os === DEVICE_OS.iOS ? <SubHeading os={os} style={{ margin: '0px', fontWeight: "bold" }}>{__("tfa_login_logged_devices_header", "Logged devices")}</SubHeading> :
            <Heading os={os} style={alignLeftText} isModal>{__("tfa_login_logged_devices_header", "Logged devices")}</Heading>
          }
          {os === DEVICE_OS.iOS ? <BodyText os={os}>{__("tfa_login_logged_devices_subheader", "You are currently logged in pCloud on the following devices or web browsers:")}</BodyText> :
            <SubHeading os={os} style={alignLeftText} isModal>{__("tfa_login_logged_devices_subheader", "You are currently logged in pCloud on the following devices or web browsers:")}</SubHeading>
          }
        </Header>
        {(os === DEVICE_OS.iOS || os === DEVICE_OS.Android) ? <React.Fragment>{deviceRows} </React.Fragment> :
          <DeviceContainer className="device-container" os={os}>{deviceRows}</DeviceContainer>
        }
        {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android && <CloseButton className="back-button" onClick={toggleLoggedDevices} />}
        {os === DEVICE_OS.iOS && <IosModalClose onClick={toggleLoggedDevices}>
          <ButtonLink os={os}>{__("Close")}</ButtonLink>
        </IosModalClose>}
        {os === DEVICE_OS.Android && <AndroidModalClose onClick={toggleLoggedDevices}>
          <ButtonLink os={os} className="modal-link">{__("Close")}</ButtonLink>
        </AndroidModalClose>}
      </ModalWindow>
    </Modal>
  );
};

export default TFALoggedDevicesModalUI;

export const fadein = keyframes`
  0% {
    opacity: 0;
    transform:scale(0.92, 0.92);
  }

  100% {
    opacity: 1;
    transform:scale(1, 1);
  }
`;

const Header = styled.div`
  text-align: left;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;

  @media (max-width: 460px) {
    text-align: left;
    ${({ os }) => os !== DEVICE_OS.iOS && css`
      padding: 0;
    `}
  }
`;


const DeviceRow = styled.div`
  margin: 12px 0px;
  text-align: left;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const DeviceIcon = styled.div`
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-position: center;
`;

const UnknownDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../../root/img/2fa-login/unknown.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/2fa-login/unknown@2x.png")});
  }
`;

const AndroidDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../../root/img/2fa-login/mobile-other.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/2fa-login/mobile-other@2x.png")});
  }
`;

const IosDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../../root/img/2fa-login/mobile-apple.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/2fa-login/mobile-apple@2x.png")});
  }
`;

const WebDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../../root/img/2fa-login/web.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/2fa-login/web@2x.png")});
  }
`;

const DesktopDeviceIcon = styled(DeviceIcon)`
  background-image: url(${require("../../../../root/img/2fa-login/desktop-other.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/2fa-login/desktop-other@2x.png")});
  }
`;

const DeviceTitle = styled(SubHeading)`
  vertical-align: middle;
  font-weight: bold;
  margin-top: 0px;
`;

const BodyTextDeviceTitle = styled(BodyText)`
  vertical-align: middle;
  font-weight: bold;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin: 12px;
  background-position: center;
  cursor: pointer;

  background-image: url(${require("../../../../root/img/updatedrive/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/updatedrive/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

const BackButton = styled.div`
  margin-top: 28px;
  cursor: pointer;
  color: #17bed0;
  font-size: 13px;
`;

const ModalBackButton = styled(BackButton)`
  top: 25px;
  left: 30px;
`;

const deviceIcons = {
  0: <UnknownDeviceIcon />,
  1: <AndroidDeviceIcon />,
  2: <IosDeviceIcon />,
  3: <WebDeviceIcon />,
  4: <DesktopDeviceIcon />
};
