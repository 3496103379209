import React from "react";
import { FormWrapper as WindowsFormWrapper } from "../WindowsStyles";
import { FormWrapper as WebFormWrapper } from "../WebStyles";
import { FormWrapper as MacFormWrapper } from "../MacStyles";
import { FormWrapper as LinuxWrapper } from "../LinuxStyles";
import { FormWrapper as IosWrapper } from "../iOSStyles";
import { FormWrapper as AndroidWrapper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const FormWrapper = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidWrapper {...rest} />
    case DEVICE_OS.iOS:
      return <IosWrapper {...rest} />
    case DEVICE_OS.Web:
      return <WebFormWrapper {...rest} />
    case DEVICE_OS.Win:
      return <WindowsFormWrapper {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacFormWrapper {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxWrapper {...rest} />
    default:
      return <WebFormWrapper {...rest} />
  }
};

export default FormWrapper;
