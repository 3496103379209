import React from 'react';
import classNames from "classnames"

const UploadWrap = ({ className, children }) => {

	return (
		<div className={classNames("ul-files", className)}>
			{children}
		</div>
	);
}

export default UploadWrap;
