import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const DownloadStatsNoStats = styled.div`
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  color: #a7a7a7;
  padding: 20px;
  text-align: center;
  font-size: 13px;
  line-height: 20px;

  & > a {
    color: #1bc7cf;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default DownloadStatsNoStats;
