// @flow

export const getUserInfo = (share: any, users: Array<any>) => {
  if (!share.touserid) {
    return null;
  }

  return users.find(user => user.id === share.touserid);
};

export const getTeamInfo = (share: any, teams: Array<any>) => {
  if (typeof share.toteamid === "undefined") {
    return null;
  }
  return teams.find(team => team.id === share.toteamid);
};
