import React, { useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";
import reactStringReplace from "react-string-replace";

import EmailLoginOption from "../LoginOptions/EmailLoginOption";
import InputInnerButton from "../LoginOptions/InputInnerButton";
import {
  APPLE_LOGIN_METHOD_ID,
  EMAIL_LOGIN_METHOD_ID,
  FACEBOOK_LOGIN_METHOD_ID,
  GOOGLE_LOGIN_METHOD_ID,
  LOGIN_FLOW_TYPE,
  LOGIN_STEP,
  REGISTER_FLOW_TYPE,
  SETUP_PASS_LOGIN_METHOD_ID,
  SIGNUP_SCREEN
} from "../constants.signin";
import * as FormStyle from "../../../components/FormSharedComponents"; // DONE

import Wrapper from "../MultiplatformComponents/Elements/Wrapper";
import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper";
import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import Form from "../MultiplatformComponents/Elements/Form";
import ContinueWith from "../MultiplatformComponents/Elements/ContinueWith";
import CheckBox from "../MultiplatformComponents/Elements/CheckBox";
import SigninOptionsWrapper from "../MultiplatformComponents/Elements/SigninOptionsWrapper";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import FacebookButton from "../LoginOptions/SocialLogins/FacebookButton";
import AppleButton from "../LoginOptions/SocialLogins/AppleButton";
import GoogleButton from "../LoginOptions/SocialLogins/GoogleButton";
import PasswordOption from "../LoginOptions/PasswordOption";

import { FORM_TEXTS } from "../texts.signin";
import Message from "./Message";
import { __ } from "../../../lib/translate";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import { boldConverter, linkConverter, linkConverterV2 } from "../../../../web-shared/componentifyConverters";
import DataCenterDropdown from "../DataCenterDropdown";
import InputWrapper from "../MultiplatformComponents/Elements/InputWrapper";
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper";
import RevealPassword from "../MultiplatformComponents/Elements/RevealPassword";
import HidePassword from "../MultiplatformComponents/Elements/HidePassword";
import Button from "../MultiplatformComponents/Elements/Button";
import ResponsiveEmail from "../../ResponsiveEmail";
import PcloudLogo from "../../../../web-shared/images/svg/pcloud-gradient-logo.svg"

const SignupUI = ({
  os = DEVICE_OS.Web,
  error = "",
  terms = false,
  isWebview = false,
  email,
  password,
  formFields = {},
  locations,
  disabled,
  loading,
  redirect_uris,
  showTitle = true,
  title = "",
  themeMode = "light",
  receiveToken = () => { },
  getRequestId = () => { },
  getToken = () => { },
  onTermsChange = () => { },
  onPasswordChange = () => { },
  onSubmit = () => { },
  onEmailChangeClick = () => { },
  forceValidateForm = false,
  language,
  requestId,
  state
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const PassIcon = showPassword ? HidePassword : RevealPassword;
  console.log("email", email);
  const onShowPass = () => setShowPassword((showPass) => !showPass);

  const renderPasswordSection = () => {
    const isMobileOrMacOSX = os === DEVICE_OS.iOS || os === DEVICE_OS.Android || os === DEVICE_OS.MacOSX;
    const pcloudTermsKey = isMobileOrMacOSX
      ? __(
        "i_accept_pcloud_terms_mobile",
        "I Accept pCloud's https://www.pcloud.com/policy/terms_conditions/[Terms & Conditions], https://www.pcloud.com/policy/privacy_policy/[Privacy Policy] and https://www.pcloud.com/policy/intellectual_privacy/[Intellectual Property Policy]'"
      )
      : __(
        "i_accept_pcloud_terms",
        "I Accept pCloud's https://www.pcloud.com/terms_and_conditions.html[Terms & Conditions], https://www.pcloud.com/privacy_policy.html[Privacy Policy] and https://www.pcloud.com/int_pr_policy.html[Intellectual Property Policy]"
      );

    return (
      <React.Fragment>
        <PasswordOption
          onChange={onPasswordChange}
          os={os}
          name="password"
          placeholder={__("login_or_signup_setuppass_placeholder")}
          value={password}
          autoComplete="new-passwor"
          type={showPassword ? "text" : "password"}
          className="register-input-password"
          error={error || forceValidateForm}
          autoFocus
        >
          {password.length !== 0 && <PassIcon themeMode={themeMode} os={os} onClick={onShowPass} className="register-input-password-icon sign-in" />}
        </PasswordOption>
        <InputWrapper os={os}>
          <DataCenterDropdown os={os} locations={locations} />
        </InputWrapper>
        <SigninOptionsWrapper os={os} className="terms-wrapper">
          <CheckBox
            os={os}
            name="terms"
            size="small"
            checked={terms}
            onChange={onTermsChange}
            className="register-terms-checkbox"
          >
            <FormStyle.Terms className="regsitration-terms" onClick={onTermsChange}>
              <Componentify
                className="terms-text"
                text={__(pcloudTermsKey)}
                converters={[boldConverter, linkConverter, linkConverterV2]}
              />
            </FormStyle.Terms>
          </CheckBox>
        </SigninOptionsWrapper>
        <ButtonWrapper os={os}>
          <Button
            os={os}
            type="submit"
            // active={!isRegistering && !isRegistered}
            disabled={disabled}
            loading={loading}
            onClick={onSubmit}
            className="register-button-submit"
            disabledColor="#ebedf0"
          >
            {__("create_account", "Create Account")}
          </Button>
        </ButtonWrapper>
      </React.Fragment>
    );
  }

  const renderLoginOption = (loginmethod, preferred) => {
    if (loginmethod === EMAIL_LOGIN_METHOD_ID) {
      return renderPasswordSection();
    };

    if (loginmethod === SETUP_PASS_LOGIN_METHOD_ID) {
      return null;
    }

    if (loginmethod === FACEBOOK_LOGIN_METHOD_ID) {
      return (
        <FacebookButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          className="login-buttons-facebook"
          language={language}
          state={state}
        />
      )
    }

    if (loginmethod === APPLE_LOGIN_METHOD_ID) {
      return (
        <AppleButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          language={language}
        />
      )
    }

    if (loginmethod === GOOGLE_LOGIN_METHOD_ID) {
      return (
        <GoogleButton
          os={os}
          size={preferred ? "big" : "small"}
          receiveToken={receiveToken}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          redirect_uris={redirect_uris}
          className="login-buttons-google"
          language={language}
          state={state}
        />
      )
    }
  };

  const renderOption = (optionsArray = [], preferred) => {
    if (!optionsArray || !optionsArray.length) {
      return null;
    }

    return optionsArray.map(login => (
      renderLoginOption(login, preferred)
    ))
  };

  return (
    <Wrapper os={os} className="responsive-wrap">
      <FormWrapper os={os}>
        {os !== DEVICE_OS.Web &&
          <LogoWrapper>
            <PcloudLogo />
          </LogoWrapper>
        }
        {
          showTitle ? (
            <Heading os={os} className={isWebview ? "webview" : "login-title"}>
              {title || __("Sign up to pCloud")}
            </Heading>
          ) : null
        }
        {
          email ? (
            <React.Fragment>
              <SubHeading os={os}>
                {reactStringReplace(__("login_or_signup_continuewith"), /(%email%)/g, (match, i) => (
                  <strong>
                    <ResponsiveEmail os={os} email={email} />
                  </strong>
                ))}
              </SubHeading>
              <ButtonLink className="change-link" os={os} onClick={onEmailChangeClick}>{__("Change")}</ButtonLink>
            </React.Fragment>
          ) : null
        }
        <Form os={os} onSubmit={onSubmit}>
          <Message error={error} os={os} />
          {
            formFields.preferredOption.length ? (
              renderOption(formFields.preferredOption, true)
            ) : null
          }
          {
            formFields.secondaryOption.length ? (
              <React.Fragment>
                <ContinueWith os={os} className="login-text-or">
                  {__("login_or_signup_orloginwith", "or log in with")}
                </ContinueWith>
                {renderOption(formFields.secondaryOption)}
              </React.Fragment>
            ) : null
          }
          {
            formFields.socialLoginsOptionsGroup.length ? (
              <React.Fragment>
                <ContinueWith os={os} className="login-text-or">
                  {__("login_or_signup_oruse", "or use")}
                </ContinueWith>
                <FlexBox>
                  {renderOption(formFields.socialLoginsOptionsGroup)}
                </FlexBox>
              </React.Fragment>
            ) : null
          }
        </Form>
      </FormWrapper>
    </Wrapper>
  )
};

export default SignupUI;

const FlexBox = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
