import apiMethod from "../api/apiMethod";
import subscriptions from "../lib/subscriptions";

let authToken = false;
let listeners = [];
let isRunning = false;
let lastDiffId = -1;
let xhr;

const diffInstance = {
  getParams: function() {
    return {
      diffid: lastDiffId,
      iconformat: "id",
      difflimit: 500,
      auth: authToken
    };
  },
  handleEvents: function(ret) {
    lastDiffId = ret.diffid;

    processDiffEvents(ret.entries);
    setTimeout(
      subscriptions.setState.bind(subscriptions, "diff", "ready"),
      ret.entries.length
    );
  }
};

function processDiffEvents(entries) {
  listeners.forEach(listener => listener(entries));
}

function listen(token, callback) {

  if (authToken !== token) {
    authToken = token;
  }

  listeners.push(callback);
  start();

  return () => {
    listeners.splice(listeners.indexOf(callback));

    console.log("removed diff listener", `#${listeners.length}`);

    if (!listeners.length) {
      stop();
    }
  };
}

function start() {
  if (isRunning) {
    return;
  }

  isRunning = true;
  subscriptions.add("diff", diffInstance);

  xhr = apiMethod("diff", { last: 0, auth: authToken }, function(ret) {
    lastDiffId = ret.diffid;

    subscriptions.setState("diff", "ready");

    xhr = false;
  }, { errorCallback: () => {} });
}

function stop() {
  if (xhr) {
    xhr.abort();
  }

  lastDiffId = -1;
  subscriptions.remove("diff");
  isRunning = false;
}

export default { listen };
