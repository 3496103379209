import React from "react";
import { ButtonWrapper as WindowsButtonWrapper } from "../WindowsStyles";
import { ButtonWrapper as WebButtonWrapper } from "../WebStyles";
import { ButtonWrapper as MacButtonWrapper } from "../MacStyles";
import { ButtonWrapper as LinuxButtonWrapper } from "../LinuxStyles";
import { ButtonWrapper as IosButtonWrapper } from "../iOSStyles";
import { ButtonWrapper as AndroidButtonWrapper } from "../AndroidStyles";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ButtonWrapper = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidButtonWrapper {...rest} />
    case DEVICE_OS.iOS:
      return <IosButtonWrapper {...rest} />
    case DEVICE_OS.Web:
      return <WebButtonWrapper {...rest} />
    case DEVICE_OS.Win:
      return <WindowsButtonWrapper {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacButtonWrapper {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxButtonWrapper {...rest} />
    default:
      return <WebButtonWrapper {...rest} />
  }
};

export default ButtonWrapper;
