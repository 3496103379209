import {__} from "./translate";
import renderTemplate from "./renderTemplate";
import $ from "jquery";

const templates = {
  success: [
    '<div class="messageBox successBox">',
        '<img src="/ZCDNURL/img/alertb-ok.png" width="20" height="20">{{message}}',
    '</div>'
  ].join("\n"),

  error: [
    '<div class="messageBox errorBox">',
        '<img src="/ZCDNURL/img/alertb-no.png" width="20" height="20">{{message}}{{message}}',
    '</div>'
  ].join("\n"),

  info: '<div class="messageBox successBox">{{message}}</div>'
}

export default function message(message, type, escape, translate, displayForOverwrite) {
  if (type == undefined) {
    type = 'success';
  }

  if (escape == undefined) {
    escape = true;
  }

  if (translate == undefined) {
    translate = true;
  }

  if (translate) {
    message = __(message);
  }

  var div = renderTemplate(templates[type], { message: __(message, message) }, { escape: escape });
  var displayFor = type == 'error' ? 5000 : 6000;
  var closef;

  if (displayForOverwrite) {
    displayFor = displayForOverwrite;
  }

  if ($('.messagecnt').length) {
    $('.messagecnt').remove();
  }

  div.addClass('messagecnt');

  var to = setTimeout(closef = function (div) {
    div.fadeOut(100, function () { $(this).remove(); });
  }.bind(null, div), displayFor);

  div.on('mouseover', function () {
    if (to) {
      console.log('clear timeout');
      clearTimeout(to);
    }
  }).on('mouseleave', function () {
    console.log('resetting timeout')
    to = setTimeout(function () {
      div.fadeOut(1000, function () { $(this).remove(); });
    }, 3000);
  }).on('click', closef);

  div.appendTo(document.body);
  div.css({
    position: 'fixed',
    top: '37px',
    left: '50%',
    marginLeft: div.outerWidth() / 2 * -1
  });

}
