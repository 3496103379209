// @flow
// WARNING: Do not use HFN in this component

import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import login from "../../api/login";
import { __ } from "../../lib/translate";
import { validateEmail, setcookie, rcookie, validatePassword, hrefSearch, isValidUrl } from "../../lib/utils";
import { DEVICE_OS, EU_LOCATIONID, LOGIN_THEMES } from "@pcloud/web-utilities/dist/config/constants";
import * as FormStyle from "../../components/FormSharedComponents";
import { setGlobalLocations, setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api";
import { sendCJTokenOnLogin } from "../../../web-shared/cjAffiliate";
import apiMethod from "../../api/apiMethod";

import LoginUI from "./AuthenticationStepsUI/LoginUI";
import { notify } from "./MultiplatformComponents/Notification";

const Login = (props) => {
  const {
    language = "en",
    locationId = EU_LOCATIONID,
    os = DEVICE_OS.Web,
    setShowRegisterVerificationCode = () => {},
    onLoginBegin = () => {},
    showForgotPass = () => {},
    onSuccess = () => {},
    setRememberMe = () => {},
    rememberme = false,
    setTFALoginInfo = () => {},
    setVerifyInfo = () => {},
    setLocationId = () => {},
    showInsideForm = false,
    showGracePeriodModalCb = baRestrictedAuth => {},
    email,
    formFields,
    isWebview,
    osversion = "0.0.0",
    appversion,
    appversioncode,
    device,
    deviceid,
    onEmailChangeClick = () => {},
    themeMode = "light",
    usegrecaptcha,
    verificationCode,
    requestId,
    state
  } = props;
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);
  const [openGracePeriodModal, setOpenGracePeriodModal] = useState(false);
  const [passpcloud, setPasspcloud] = useState(false);
  const [redirectToBillingPage, setRedirectToBillingPage] = useState(false);

  useEffect(() => {
    return () => {
      document.removeEventListener("grace-period-activated", gracePeriodActivatedEventListener);
    }
  }, [])

  const onPasswordChange = (e) => {
    const value = e.target.value;

    setPassword(value);
  }

  const onForgotClick = () => {
    if (showForgotPass && typeof showForgotPass === "function") {
      showForgotPass();
    }
  }

  const onRememberMeChange = () => setRememberMe(!rememberme);

  const onCloseForgotPass = () => {
    setOpenForgotPassModal(false);
  }

  const handlePasswordIconClick = () => {
    setShowPassword(!showPassword)
  }

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message);
    } else {
      notify(message, type, { os });
    }
  }

  const canLoginStatus = cb => {
    if (isLogging) {
      return;
    }

    let error = validatePassword(password) || "";

    if (!validateEmail(email)) {
      error = __("invalid_email", "Invalid Email");
    }

    if (error) {
      showMessage(error, "error");
    } else {
      setError("");
      cb();
    }
  }

  const onLoginClick = e => {
    e.preventDefault();

    if (!password.length || !email.length || isLogging || isLogged) {
      return;
    }
    setIsLogging(true);

    canLoginStatus((cb) => {
      if (typeof grecaptcha !== "undefined" && usegrecaptcha) {
        console.log("grecaptcha init");
        grecaptcha.ready(function() {
          grecaptcha.execute('6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR', {action: 'submit'}).then((gresponsetoken) => {
            console.log("grecaptcha ready");
            onLogin(false, false, null, gresponsetoken)
          });
        });
      } else {
        onLogin();
      }
    });
  };

  const gracePeriodActivatedEventListener = (e) => {
    if (e && e.detail === "GO_TO_BILLING") {
      onLogin(false, true);
    } else {
      onLogin();
    }
  };

  const onLogin = (retry = false, redirectToBilling = false, _locationId, gresponsetoken = null) => {
    if (retry) {
      setGlobalLocationById(EU_LOCATIONID);
    } else if (_locationId) {
      setGlobalLocationById(_locationId);
      setLocationId(_locationId);
    } else {
      setGlobalLocationById(locationId);
    }

    getApiServerCall();

    if (onLoginBegin && typeof onLoginBegin === "function") {
      onLoginBegin();
    }

    document.removeEventListener("grace-period-activated", gracePeriodActivatedEventListener);
    document.addEventListener("grace-period-activated", gracePeriodActivatedEventListener);

    login(email, password, rememberme, {
      os,
      language,
      osversion,
      appversion,
      appversioncode,
      device,
      deviceid,
      passpcloud,
      gresponsetoken,
      emailtfacode: verificationCode,
      successCallback: userinfo => {
        const { auth } = userinfo;
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");
        const billingURL = redirectToBilling ? window.location.protocol + "//" + window.location.host + "/" + "#page=b_billing&authtoken=" + auth + "&locationid=" + (_locationId || locationId) : null;

        setIsLogged(true);
        setcookie("email", email);
        sendCJTokenOnLogin(apiMethod, auth);
        onSuccess({
          action: "login",
          userinfo,
          rememberme,
          locationid: _locationId || locationId,
          redirecturl: hrefSearch("redirecturl"),
          redirectUrlOnNewWindow: redirectToBilling ? billingURL : false
        });

        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = "hidden";
        }
      },
      errorCallback: ret => {
        const { result, token, hasdevices, tfatype, verifytoken, email, error, location = {}, auth = "" } = ret;
        
        if (result == 2297) {
          setTFALoginInfo(token, hasdevices, tfatype, email);
          setIsLogging(false);
        } else if (result == 2306) {
          setVerifyInfo(verifytoken, email);
          setIsLogging(false);
        } else if (result === 2321) {
          setGlobalLocations([location]);
          setLocationId(location.id);
          onLogin(false, false, location.id, gresponsetoken);
        } else if (result === 2330) {
          setError(__(
            "error_2330",
            "Access to your account has been temporarily stopped, due to your move to a new data region."
          ));
          setPassword("");
          setIsLogging(false);
          showMessage(
            __("error_2330", "Access to your account has been temporarily stopped, due to your move to a new data region."),
            "error"
          );
        } else if (result === 2205) {
          //open ba owner grace modal
          if (auth) {
            if (showGracePeriodModalCb && typeof showGracePeriodModalCb === "function") {
              showGracePeriodModalCb(auth);
            };

            setIsLogging(false);
            setOpenGracePeriodModal(true);
          } else {
            setPassword("");
            setIsLogging(false);
            showMessage(error, "error");
          }
        } else {
          setPassword("");
          setIsLogging(false);
          showMessage(error, "error");
        }
      },
      onTimeout: () => {
        setPassword("");
        setIsLogging(false);
        showMessage(__("error_connection_timed_out"), "error");
      }
    });
  }

  return (
    <LoginUI
      email={email}
      password={password}
      onPasswordChange={onPasswordChange}
      error={error}
      rememberme={rememberme}
      isWebview={isWebview}
      formFields={formFields}
      onRememberMeChange={onRememberMeChange}
      onSubmit={onLoginClick}
      onForgotClick={onForgotClick}
      os={os}
      onEmailChangeClick={onEmailChangeClick}
      loading={isLogging}
      themeMode={themeMode}
      {...props}
    />
  )
}

export default Login;
