import $ from "jquery";
import browser from './browser';

require('../styles/popup.less');

const Popup = {
  cache: {},
  config: {
    ajax: false,
    hasClose: true,
    closeBtnSelector: false,
    className: "",
    fadeSpeed: 250,
    overlay: true,
    innerlay: "",
    cssPosition: "fixed",
    onEscClose: true,
    clickClose: true,
    business: false,
    size: false,
    overridePosition: false,
    hasTitle: true
  },
  instanceConfig: {},
  overlay: null,
  wrapper: null,
  loading: false,
  init: function() {
    if (this.overlay) this.close(false, true);

    this.overlay = $("<div></div>")
      .addClass("g-overlay popup")
      .hide()
      .appendTo(this.instanceConfig.modalContainer || document.body);

    var self = this;

    if (this.instanceConfig.clickClose) {
      this.overlay.on("click", function(e) {
        if ($(e.target).hasClass("overlay")) {
          self.overlay.fadeOut(250, function() {
            self.close();
          });
        }
      });
    }

    this.wrapper = $("<div>")
      .addClass("g-modal")
      .append(
        $('<div class="g-modal-inner"></div>').append(
          '<div class="g-modal-content"></div>'
        )
      )
      .appendTo(this.overlay)
      .hide();

    if (this.instanceConfig.customClass) {
      this.wrapper.addClass(this.instanceConfig.customClass);
    }

    $("<a>", { href: "javascript: void(0);", class: "g-modal-close" })
      .click(function() {
        Popup.close();
      })
      .appendTo(this.wrapper);

    this.loaded = true;
  },
  resize: function() {
    this.wrapper.animate(
      {
        height: this.wrapper.find(".g-modal-content").outerHeight(),
        width: this.wrapper.find(".g-modal-content").outerWidth()
      },
      function() {
        Popup.setPosition();
      }
    );
  },
  setTitle: function(title) {
    this.wrapper.find(".g-modal-title").text(i18n.get(title));
  },
  open: function(cont, config) {

    if (this.isOpen()) {
      this.close(Popup.open.bind(this, cont, config), true);
      return;
    }

    config = this.instanceConfig = $.extend({}, this.config, config);

    this.init();
    this.overlay.show();

    this.wrapper.find(".g-modal-inner .g-modal-title").remove();
    if (config.title) {
      var title = config.title;
      if (!config.dont_translate_title) title = __(title);
      if (!config.dont_escape_title) title = htmlentities(title);

      this.wrapper
        .find(".g-modal-inner")
        .prepend(
          $('<div class="g-modal-title"></div>').append(
            $('<div class="g-modal-title-text cwrap2"></div>')
              .html(title)
              .attr("title", strip_tags(title))
          )
        );

        if (config.subtitle) {
          this.wrapper.find(".g-modal-title-text").append($("<span class='subtitle'></span>").css({"margin-left": "5px"})).find(".subtitle").text(config.subtitle);
        }
    }

    if (config.business) {
      this.wrapper.addClass("businessmodal");
    } else {
      this.wrapper.removeClass("businessmodal");
    }

    if (config.className) {
      this.wrapper.addClass(config.className);
      this.overlay.addClass("g-overlay-" + config.className)
    }

    if (!config.hasTitle) {
      this.wrapper.addClass("no-title");
    } else {
      this.wrapper.removeClass("no-title");
    }

    if (config.onEscClose) {
      $(window).off("keyup.popup").on("keyup.popup", function(e) {
        if (e.keyCode === 27) {
          Popup.close();
        }
      });
    }

    $(window).on('resize.popup', function(e) {
      Popup.setPosition();
    });

    $(".modal .g-modal-close").show();

    if (config.hasClose) {
      $(".g-modal-close").show();
    } else {
      $(".g-modal-close").hide();
    }

    if (config.ajax) {
      this.contents("");
      this.loading();

      if (this.cache[cont]) this.show(Popup.cache[cont]);
      else {
        $.get(cont, {}, function(data) {
          Popup.cache[cont] = data;
          Popup.show(data);
        });
      }
    } else if (
      typeof cont == "string" &&
        (cont.substring(0, 1) == "#" || cont.substring(0, 1) == ".")
    ) {
      this.show($(cont).clone().css("display", ""), config.fadeSpeed);
    } else {
      this.show(cont, config.fadeSpeed);
    }
  },
  show: function(cont, fadeSpeed) {
    this.contents(cont);

    console.log('in show');
    console.log(this.instanceConfig);

    if (this.instanceConfig.onDomReady) {
      this.instanceConfig.onDomReady(this.wrapper);
    }

    if (typeof this.instanceConfig.setPosition == "function")
      this.instanceConfig.setPosition();
    else
      this.setPosition();

    if (this.instanceConfig.closeBtnSelector) {
      $(this.instanceConfig.closeBtnSelector).click(function() {
        Popup.close();
      });
    }

    this.wrapper.fadeIn(250);
    $("html").addClass("g-modal-open");
  },
  setPosition: function() {
    var size = [ this.wrapper.outerWidth(), this.wrapper.outerHeight() ];

    /*
        this.wrapper.css({
      		//marginTop:  -1 * size[1] / 2,
      		marginLeft: -1 * size[0] / 2,
      		// width: size[0],
      		// height: size[1],
            position: this.instanceConfig.cssPosition
        });
		*/
    if (browser.mobile) {
      var mtop = Math.max(
        ($(window).outerHeight() - this.wrapper.outerHeight()) / 2,
        10
      );

      this.wrapper.css({ margin: "0px" });
    }

    if (this.instanceConfig.overridePosition)
      this.wrapper.css(this.instanceConfig.overridePosition);
  },
  animateTo: function(width, height) {
    var self = this;

    this.wrapper.css("position", "fixed");
    this.wrapper.animate(
      {
        width: width,
        //marginLeft: -1 * (width / 2) + 'px',
        //position: 'fixed'
        height: height
        /*marginTop:  -1 * (height  / 2) + 'px',*/
      },
      500,
      function() {
        //self.wrapper.css('position', 'fixed');
      }
    );
  },
  prepAnimate: function() {
    if (this.wrapper != null) {
      this.wrapper.css({
        width: this.wrapper.width(),
        height: this.wrapper.height()
      });
    }
  },
  hideClose: function() {
    if (this.wrapper != null) this.wrapper.find(".g-modal-close").hide();
  },
  showClose: function() {
    this.wrapper.find(".g-modal-close").show();
  },
  hideModalCont: function() {
    if (this.wrapper != null) {
      this.wrapper.find(".g-modal-inner").hide();
    }
  },
  showModalCont: function() {
    if (this.wrapper != null) {
      this.wrapper.find(".g-modal-inner").show();
    }
  },
  startLoading: function(opts) {
    if (this.wrapper == null) return false;

    this.stopLoading(true);

    opts = $.extend(
      {},
      {
        processExplain: i18n.get("Loading ..."),
        processStopText: i18n.get("Stop"),
        stopCallback: false,
        fast: false
      },
      opts
    );

    this.prepAnimate();
    this.hideModalCont();
    this.hideClose();
    this.wrapper.append(
      '<div class="processing"><div class="information"></div></div>'
    );

    if (opts.stopCallback) {
      this.wrapper
        .find(".information")
        .text(opts.processExplain)
        .after(
          $('<div class="stopprocess"></div>').append(
            $('<div class="button smallbut stopbut"></div>')
              .append(
                '<img src="/ZCDNURL/img/stop-process.png" width="9" height="9"> ' +
                  opts.processStopText
              )
              .on("click", opts.stopCallback)
          )
        );
    } else if (opts.processExplain) {
      this.wrapper.find(".information").text(opts.processExplain);
    }

    var ml = this.wrapper.find(".processing"),
      size = [ ml.outerWidth(), ml.outerHeight() ],
      self = this;

    this.loading = true;

    if (opts.now) {
      this.wrapper.css({
        width: size[0],
        //marginLeft: -1 * size[0] / 2
        height: [ size[1] ]
      });
      ml.show();
    } else {
      this.wrapper.animate(
        {
          width: size[0],
          //marginLeft: -1 * size[0] / 2
          height: [ size[1] ]
        },
        450,
        function() {
          ml.show();
        }
      );
    }
  },
  updateExplain: function(explain) {
    this.wrapper.find(".information").text(explain);
  },
  isOpen: function() {
    return this.wrapper != null;
  },
  stopLoading: function(clearOnly) {
    if (this.wrapper == null) return this;

    this.wrapper.stop(true, true);
    this.wrapper.find(".processing").remove();

    //this.wrapper.find('.loading').remove();
    //this.wrapper.find('.loadcenter').remove();
    if (clearOnly) {
      this.wrapper.css({ width: "", height: "" });
      this.wrapper.find(".g-modal-inner").show();
      this.showClose();
      return this;
    }

    var mcnt = this.wrapper.find(".g-modal-inner"),
      size = [ mcnt.outerWidth(), mcnt.outerHeight() ],
      self = this;
    //this.wrapper.find('.g-modal-content').show();
    console.log("Starting Animation");

    if (size[0] && size[1]) {
      this.wrapper.animate(
        {
          width: size[0],
          //marginLeft: -1 * size[0] / 2
          height: [ size[1] ]
        },
        450,
        function() {
          mcnt.show();
          self.showClose();

          self.loading = false;
          if (self.onFinishLoad) {
            self.onFinishLoad();
            self.onFinishLoad = null;
          }

          self.wrapper.css("height", "");
        }
      );
    } else {
      this.wrapper.css({ width: "", height: "" });
      this.showClose();

      this.loading = false;
      if (this.onFinishLoad) {
        this.onFinishLoad();
        this.onFinishLoad = null;
      }
    }

    return this;
  },
  onLoad: function(callback) {
    this.onFinishLoad = callback;
  },
  contents: function(cont) {
    if (cont) {
      this.wrapper.find(".g-modal-content").html(cont);

      if (typeof cont === 'object' && 'find' in cont && 'tooltip' in $()) {
        cont.find("[data-toggle=tooltip][data-auto=true]").tooltip();
      }
    } else {
      if (!cont) this.wrapper.find(".g-modal-content").empty();
    }
  },
  close: function(callback, fast) {
    if (!fast) {
      fast = true;
    }

    if (this.wrapper == null) {
      return;
    }

    this.onFinishLoad = null;

    if (this.instanceConfig && this.instanceConfig.closeCallback) {
      this.instanceConfig.closeCallback();
    }
    this.stopLoading(true);
    this.wrapper.css({ width: "", height: "" });
    this.wrapper.find(".g-modal-content").empty();
    this.wrapper.find(".g-modal-title").remove();
    this.wrapper.hide();

    this.wrapper.remove();
    this.wrapper = null;

    // clear grid selection 
    if (typeof daGrid !== 'undefined' && daGrid !== null) {
      daGrid.clearSelection && daGrid.clearSelection();
    }
    var self = this,
      onDone = function() {
        if (self.overlay) {
          self.overlay.stop().remove();
          self.overlay = null;
        }

        $(self).trigger("mclose");
        if (callback) callback();
      };

    if (fast) {
      onDone();
    } else if (this.overlay) {
      this.overlay.fadeOut(250, function() {
        onDone();
      });
    } else onDone();

    this.instanceConfig = {};
    $(window).off(".popup");
    $("html").removeClass("g-modal-open");
  },
  setOpt: function(key, val) {
    this.instanceConfig[key] = val;
  }
};

export default Popup;
