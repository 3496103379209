// @flow

import { userReducer as user } from "./user";
import { notificationsReducer as notifications } from "./notifications";
import { publicLinkReducer as publiclink } from "./publiclink";
import { sharesReducer as shares } from "./shares";
import { businessReducer as business } from "./business";
import { sharedLinkReducer as sharedLink } from "./sharedLinksSettings";
import { fileRequestSettingsReducer as fileRequestSettings } from "./fileRequestSettings";
import { audioPlayerReducer as audioPlayer } from "./audioPlayer";
import { onboardingReducer as onboarding } from "./onboarding";
import { galleryPreviewReducer as galleryPreview } from "./galleryPreview";

export default {
  user,
  notifications,
  publiclink,
  shares,
  business,
  sharedLink,
  fileRequestSettings,
  audioPlayer,
  onboarding,
  galleryPreview
};
