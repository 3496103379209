// @flow

import React, { Component } from "react";
import { createRoot } from 'react-dom/client';
import componentMount from '../lib/componentMount';
import Popup from "../lib/Popup";
import $ from "jquery";

type OpenModalOptions = {
  title: string,
  closeCallback?: () => void,
  onDomReady? : () => void
}

function OpenModal(render: any, popupOptions: OpenModalOptions) {
  const wrap = $('<div />')[0];
  const root = createRoot(wrap);
  let unmount;

  popupOptions.onDomReady = () => {
    root.render(render);
    unmount = () => root.unmount();
  }

  popupOptions.closeCallback = () => {
    unmount && unmount();
  };

  Popup.open(wrap, popupOptions);

  return () => {
    Popup.close();
  }
}

export default OpenModal;
