import styled from "styled-components";
import { fadein } from "../AuthenticationStepsUI/TFALoggedDevicesModalUI"

export const lightTheme = {
  background: '#ffffff',
  primary: '#17bed0',
  secondary: '#a1a1a1',
  textPrimary: '#000000',
  disabled: '#f2f2f4',
  backgroundDisabled: "#f4f4f4",
  borderColorDisabled: "rgba(161, 161, 161, 0.45)",
};

export const darkTheme = {
  background: '#1c1c1e',
  primary: '#17bed0',
  secondary: '#656569',
  textPrimary: '#ffffff',
  disabled: '#2c2c2e',
  backgroundDisabled: "#333",
  borderColorDisabled: "#444",
};

export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 2.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  font-family: "SF UI Display", sans-serif;
  background-color: ${({ theme }) => theme.background};

  .change-link {
    color: ${({ theme }) => theme.primary};
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.background} inset;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: ${({ fixedPosition }) => fixedPosition ? "fixed" : "absolute"};
  border-radius: 18px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  z-index: 1002;
`;

export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  font-family: "SF UI Display", sans-serif;
`;

export const SubHeading = styled.div`
  font-size: 1.063rem;
  text-align: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.textPrimary};
  width: 100%;
  font-family: "SF UI Display", sans-serif;
`;

export const BodyText = styled.p`
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  margin: 12.3px auto 0 auto;
  font-family: "SF UI Display", sans-serif;
`

export const MessageBox = styled.div`
  padding: 1.625rem 1.5rem;
  border-radius: 17px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.07);
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25rem;

  .icon-wrapper {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #17bed0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MessageBoxWarning = styled(MessageBox)`
  border: none;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.07);
  background-color: #f8eccf;
  margin-top: 2.375rem;
  flex-direction: column;
  gap: 1.242rem;
  margin-bottom: 0;

  .icon-wrapper {
    background-color: transparent;
  }
`;

export const MessageBoxSuccess = styled(MessageBoxWarning)`
  background-color: #dff8e2;
`;

export const Message = styled.div`
  font-size: 1rem;
  letter-spacing: 0.06rem;
  text-align: left;
  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 20px;
  margin-left: 1.438rem;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 225px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }

  &.centered {
    text-align: center;
    margin-left: 0;
  }
`;

export const ErrorBox = styled.div`
  padding: 17px 22px;
  border-radius: 17px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #ff3b30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .icon-wrapper {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  svg {
    flex-shrink: 0
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 1rem;
  line-height: 1.24;
  letter-spacing: 0.96px;
  text-align: left;
  color: #fff;
  flex-grow: 1;
  width: 100%;

  strong, b {
    font-weight: bold;
  }
`;

export const Form = styled.form`
  margin-top: 2rem;
  width: 100%;
  max-width: 340px;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 2.063rem auto 1rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    cursor: pointer;
  }

  .register-input-password-icon {
    right: 16px;
  }

  .login-input-password-icon {
    right: 60px
  }

  .login-input-password {
      padding-right: 85px !important;
  }

  .register-input-password {
      padding-right: 45px !important;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 49.3px;
  box-sizing: border-box;
  padding: 0.875rem 1rem 0.875rem 1rem;
  border-radius: 10.3px;
  border: solid 1px ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.background};
  font-family: inherit !important;
  font-size: 1.125rem;
  text-align: left;
  color: ${({ theme }) => theme.textPrimary};
  outline: none;
  letter-spacing: 0.011rem;

  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }

  &:disabled {
    border: solid 1px ${({ theme }) => theme.borderColorDisabled};
    color: #9f9f9f;
    background-color: ${({ theme }) => theme.backgroundDisabled};
  }

  &[type="email"],
  &:read-only {
    padding-right: 3.25rem !important;
  }
`;

export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.331rem;
  margin: 1.375rem 0;
  margin-bottom: -1rem;

  label {
    margin-right: 0.625rem;
    
    a {
      text-decoration: none !important;
      color: #17bed0;
    }
  }

  button {
    margin-left: 0.625rem;
  }

  &.login-input-checkbox-wrapper {
    border-bottom: 1px solid ${({ theme }) => theme.disabled};
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  position: relative;

  .register-button-submit {
    margin-top: 20px;
  }
`;

export const GeneralButton = styled.button`
  font-family: inherit !important;
  width: 100%;
  padding: 0.375rem 0.625rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  position: relative;
  color: #fff;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: #b2b2b2;
    cursor: auto;
  }
`;

export const Button = styled(GeneralButton)`
  height: 49.3px;
  margin-top: 40px;

  &.grace-period-button {
    margin-top: 0px;
  }
`;

export const InputButton = styled(GeneralButton)`
  height: 37.3px;
`;

export const ButtonLink = styled.button`
  font-size: ${({ fontSize }) => fontSize ? fontSize : "1rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({ color }) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
`;

export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`

export const SecurityCodeStyledInput = styled.input`
  width: ${({ maxLength }) => {
    const singleInputMargin = maxLength > 6 ? 6 : 8;
    const separationSpace = maxLength > 6 ? 16 : 18;
    const margins = (maxLength - 2) * singleInputMargin;

    return "calc(((100% - " + (separationSpace + margins) + "px) / " + maxLength + "))"
  }};
  height: 40px;
  box-sizing: border-box;
  border-radius: 10.3px;
  border: solid 1px ${({ theme }) => theme.secondary};
  line-height: 1;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.textPrimary};

  &:nth-child(${({ chunk }) => chunk}) {
    margin-right: ${({ maxLength }) => maxLength > 6 ? "16px" : "18px"};
  }

  &:focus {
    outline: none;
    border-color: #7c7c7c;
  }

  -webkit-appearance: none;  
  margin: 0;  
  padding: 0;  
`;

export const VerificationCodeInputsError = styled.div``;

export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 340px;
`;

export const SocialButton = styled.div`
  width: 100%;
  height: 49.3px;
  box-sizing: border-box;
  border-radius: 10.3px;
  background-color: ${({ theme }) => theme.disabled};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8rem;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  letter-spacing: 0.54px;
`;

export const ContinueWith = styled.div`
  font-size: 1rem;
  line-height: 1.206rem;
  text-align: center;
  color: #b2b2b2;
  margin: 1.25rem auto 1.413rem auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 28px;
  text-align: center;

  & button {
    font-size: 1.063rem;
  }
`;

export const InputButtonWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 37.3px;
  height: 37.3px !important;
`;

export const ModalWindow = styled.div`
  padding: 22.3px 0px 12.3px 0px;
  box-sizing: border-box;
  position: relative;
  background: ${({ theme }) => theme.background};
  max-width: 366px;
  border-radius: 14px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  margin: 0 10px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.background} inset;
  }
`;

export const IosModalClose = styled.div`
  border-top: 1px solid ${({ theme }) => theme.textPrimary}66;
  padding-top: 11.3px;

  button {
    font-family: "SF UI Display", sans-serif;
  }
`

export const PassIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 11px;
`;
