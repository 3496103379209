import React from "react";
import styled from "styled-components";

const AndroidCheckBox = ({
  children,
  checked = false,
  text = "",
  id = "ios-checkbox",
  onChange = () => { },
  ...rest
}) => {
  return (
    <Label className="container" for={id}>
      <Input id={id} type="checkbox" onChange={onChange} checked={checked} {...rest} />
      <Checkmark className="checkmark"></Checkmark>
      {text ? <TextWrapper>{text}</TextWrapper> : null}
      {children}
    </Label>
  )
};

export default AndroidCheckBox;

const Label = styled.label`
  display: flex;
  position: relative;
  padding-left: 0px;
  padding-bottom: 0px;
  line-height: 20px;
  margin-right: 0px;
  font-size: inherit;
  user-select: none;
  color: ${({ theme }) => theme.primary};
  font-weight: normal;
  cursor: pointer;
  background-color: ${({ theme }) => theme.background};

  .regsitration-terms {
    margin-left: 1rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textPrimary};
  }

  input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.primary};
    border: none;

    &:after {
      display: block;
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const TextWrapper = styled.div`
  margin-left: 0.5rem;
  font-size: 0.875rem;
`;

const Checkmark = styled.span`
  position: relative;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  top: 0px;
  left: 0px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  border: solid 1px ${({ theme }) => theme.secondary};

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid ${({ theme }) => theme.background};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
