// @flow

import type { FileRequestData } from "../reducers/fileRequestSettings";
import {
  LOAD_FILE_REQUEST_DATA,
  SET_MESSAGE_SETTINGS,
  SET_LIMIT_SETTINGS,
  SET_EXPIRES_SETTINGS
} from "../constants/fileRequestSettings";

export type FileRequestSettingsAction =
  | LoadFileRequestData
  | SetMessageSettings
  | SetLimitSettings
  | SetExpiresSettings;

type LoadFileRequestData = { type: typeof LOAD_FILE_REQUEST_DATA, linkData: FileRequestData };
type SetMessageSettings = { type: typeof SET_MESSAGE_SETTINGS, comment: string };
type SetLimitSettings = { type: typeof SET_LIMIT_SETTINGS, maxspace: number };
type SetExpiresSettings = { type: typeof SET_EXPIRES_SETTINGS, expires: string };

export const loadFileRequestData = (data: FileRequestData): LoadFileRequestData => {
  return { type: LOAD_FILE_REQUEST_DATA, linkData: data };
};

export const setMessageSettings = (comment: string): SetMessageSettings => {
  return { type: SET_MESSAGE_SETTINGS, comment };
};

export const setLimitSettings = (maxspace: string): SetLimitSettings => {
  return { type: SET_LIMIT_SETTINGS, maxspace };
};

export const setExpiresSettings = (expires: boolean): SetExpiresSettings => {
  return { type: SET_EXPIRES_SETTINGS, expires };
};

export const fileRequestSettingsActionCreators = {
  loadFileRequestData,
  setMessageSettings,
  setLimitSettings,
  setExpiresSettings
};
