// @flow
import {
  PUBLIC_LINK_INIT,
  PUBLIC_LINK_DISPOSE,
  PUBLIC_LINK_OPEN_FOLDER
} from "../constants/publiclink";
import type { Metadata } from "../../../types/filesystem";

export type PublicLinkAction =
  | PublicLinkInitAction
  | PublicLinkOpenFolderAction
  | PublicLinkDisposeAction;

type PublicLinkInitAction = {
  type: typeof PUBLIC_LINK_INIT,
  metadata: Metadata
};
type PublicLinkOpenFolderAction = {
  type: typeof PUBLIC_LINK_OPEN_FOLDER,
  folderid: number
};
type PublicLinkDisposeAction = { type: typeof PUBLIC_LINK_DISPOSE };

export function load(metadata: Metadata): PublicLinkInitAction {
  return { type: PUBLIC_LINK_INIT, metadata: metadata };
}

export function openFolder(folderid: number): PublicLinkOpenFolderAction {
  return { type: PUBLIC_LINK_OPEN_FOLDER, folderid: folderid };
}

export function dispose(): PublicLinkDisposeAction {
  return { type: PUBLIC_LINK_DISPOSE };
}

export const publiclinkActionCreators = { load, openFolder, dispose };
