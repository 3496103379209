import styled from "styled-components";
import { fadein } from "../AuthenticationStepsUI/TFALoggedDevicesModalUI"

export const lightTheme = {
  background: '#f8f8f8',
  primary: '#17bed0',
  textPrimary: "#1b1b1b",
  disabled: "#cfcfcf",
  buttonDisabledText: "#ffffff",
  socialButtonBackground: "#ffffff",
  border: "rgba(0, 0, 0, 0.07)",
  inputBackground: "#ffffff",
  separatingLine: "#ebebeb",
  modalBackground: "#ffffff",
  backgroundDisabled: "#fff",
  borderColorDisabled: "rgba(0, 0, 0, 0.08)",
};

export const darkTheme = {
  background: '#262526',
  primary: '#17bed0',
  textPrimary: "#ffffff",
  disabled: "#5d5c5c",
  buttonDisabledText: "#ffffff4D",
  socialButtonBackground: "#3e3c3c",
  border: "rgba(255, 255, 255, 0.07)",
  inputBackground: "#313031",
  separatingLine: "#424142",
  modalBackground: "#252224",
  backgroundDisabled: "#333",
  borderColorDisabled: "#444",
};

export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  font-family: "SF Pro Text", sans-serif;
  background-color: ${({ theme }) => theme.background};

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.inputBackground} inset;
  }

  input::-ms-clear, input::-ms-reveal {
    display: none;
  }
`;
//
export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tfa-button {
    width: 32px;
    border-radius: 5px;
    flex-shrink: 0;
  }
`;
//
export const Heading = styled.div`
  font-size: 1.375rem;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  letter-spacing: -0.44px;
  margin-bottom: 4px;
  font-family: "SF Pro Text", sans-serif;
`;
//
export const SubHeading = styled.div`
  font-size: 1rem;
  line-height: 1.25;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  font-family: "SF Pro Text", sans-serif;
  width: 100%;
`;
//
export const MessageBox = styled.div`
  margin-top: -1.5rem;
  margin-bottom: 1.875rem;

  svg {
    display: none;
  }
`;
//
export const MessageBoxWarning = styled(MessageBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.242rem;
  border-radius: 5px;
  background-color: #f8eccf;
  margin-top: 1.875rem;
  padding: 1rem;

  .icon-wrapper {
    margin-right: 1rem;

    svg {
      display: block;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: ${({ fixedPosition }) => fixedPosition ? "fixed" : "absolute"};
  border-radius: 18px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  z-index: 1002;
`;

//
export const MessageBoxSuccess = styled(MessageBoxWarning)`
`;
//
export const Message = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 20px;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 360px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }
`;
//
export const Form = styled.form`
  margin-top: 2.875rem;
  width: 100%;
  max-width: 340px;
  position: relative;
`;
//
export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 0.75rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .register-input-password-icon {
    right: 12px;
  }

  .login-input-password-icon {
    right: 48px
  }

  .login-input-password {
    padding-right: 70px !important;
  }

  .register-input-password {
    padding-right: 35px !important;
  }
`;
//
export const Input = styled.input`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding: 0.375rem 0.625rem;
  border-radius: 5px;
  box-shadow: 0 1.5px 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.inputBackground};
  font-family: inherit !important;
  font-size: 0.875rem;
  text-align: left;
  color: ${({ theme }) => theme.textPrimary};
  outline: none;

  &:disabled {
    /* opacity: 0.5; */
    box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.06);
    border: solid 1px ${({ theme }) => theme.borderColorDisabled};
    color: #9f9f9f;
    background-color: ${({ theme }) => theme.backgroundDisabled};
  }

  &[type="email"],
  &:read-only {
    padding-right: 35px !important;
  }

  &[type="password"] {
    padding-right: 35px !important;
  }
`;
//
export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.813rem;
  margin: 1rem 0;
  margin-bottom: 0;
  padding-bottom: 1rem;

  label {
    margin-right: 0.625rem;
  }

  button {
    margin-left: 0.625rem;
  }

  &.regsitration-terms,
  &.login-input-checkbox-wrapper {
    border-bottom: 1px solid ${({ theme }) => theme.separatingLine};
  }
`;
//
export const ButtonWrapper = styled.div`
  width: 100%;
  /* margin-top: 2rem; */
  position: relative;
`;
//
export const Button = styled.button`
  font-family: inherit !important;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.07);
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: relative;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.buttonDisabledText};
    border: ${({ theme }) => theme.border};
    cursor: auto;
  }
`;

export const InputButton = styled.button`
  width: 28px;
  height: 28px;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 5px;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.buttonDisabledText};
    border: ${({ theme }) => theme.border};
  }
`;

//
export const ButtonLink = styled.button`
  font-size: ${({ fontSize }) => fontSize ? fontSize : "0.813rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({ color }) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
`;
//
export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`
//
export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 340px;
`;
//
export const SecurityCodeStyledInput = styled.input`
  width: ${({ maxLength }) => {
    const singleInputMargin = maxLength > 6 ? 6 : 8;
    const separationSpace = maxLength > 6 ? 16 : 18;
    const margins = (maxLength - 2) * singleInputMargin;

    return "calc(((100% - " + (separationSpace + margins) + "px) / " + maxLength + "))"
  }};
  max-width: 28px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.border};
  line-height: 1;
  font-size: 1.125rem;
  text-align: center;
  font-weight: normal;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.textPrimary};

  &:nth-child(${({ chunk }) => chunk}) {
    margin-right: ${({maxLength}) => maxLength > 6 ? "16px" : "18px"};
  }

  &:focus {
    outline: none;
    border: solid 3px rgba(23, 190, 208, 0.5);
  }
`;
//
export const VerificationCodeInputsError = styled.div``;
//
export const SocialButton = styled.div`
  width: 100%;
  height: 32px;
  padding: 0.375rem;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.socialButtonBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: ${({ theme }) => theme.textPrimary};
`;

export const ContinueWith = styled.div`
  font-size: 0.8125rem;
  text-align: center;
  color: #616161;
  margin: 0.75rem auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 2.75rem;
  text-align: center;
`;

export const InputButtonWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: -1px;
  width: 32px;
  height: 32px;
`;

export const ModalWindow = styled.div`
  padding: 36px 30px 31px 30px;
  box-sizing: border-box;
  position: relative;
  background: ${({ theme }) => theme.modalBackground};
  max-width: 380px;
  border-radius: 16px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  margin: 0 10px;
  border: 1px solid ${({ theme }) => theme.border};

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.inputBackground} inset;
  }
`;

export const PassIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 11px;
`;


export const DeviceContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.border};
  background: ${({ theme }) => theme.modalBackground};
  border-radius: 12px;
  min-height: 10px;
  margin: 25px 0px;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 13px;
  text-align: left;
  padding: 12px;

  @media (max-width: 460px) {
    margin: 10px 0px;
    margin-bottom: 0px;
    padding: 8px;
    background: none;
    border: none;
    color: black;
  }
`;