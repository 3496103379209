import React from "react";
import { InputWrapper as WebInputWrapper } from "../WebStyles";
import { InputWrapper as WindowsInputWrapper } from "../WindowsStyles";
import { InputWrapper as MacInputWrapper } from "../MacStyles";
import { InputWrapper as LinuxInputWrapper } from "../LinuxStyles";
import { InputWrapper as IosInputWrapper } from "../iOSStyles";
import { InputWrapper as AndroidInputWrapper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const InputWrapper = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidInputWrapper {...rest}/>
    case DEVICE_OS.iOS:
      return <IosInputWrapper {...rest}/>
    case DEVICE_OS.Web:
      return <WebInputWrapper {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsInputWrapper {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacInputWrapper {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxInputWrapper {...rest}/>
    default:
      return <WebInputWrapper {...rest}/>
  }
};

export default InputWrapper;
