// @flow
import {
  SHARES_LOAD,
  SHARES_UNLOAD,
  UPDATE_SHARE_TO_FOLDER,
  LIST_SHARES,
  LOAD_FOLDER_SHARES,
  CLEAR_FOLDER_SHARES,
  ADD_SHARE_TO_FOLDER,
  DELETE_SHARE_TO_FOLDER,
  ACCEPTED_SHARE_TO_FOLDER,
  GET_SHARE_MESSAGE,
  GET_SHARE_PERMISSION,
  GET_SHARE_FOLDER_NAME
} from "../constants/shares";
import type { Action } from "../actions/types";

export type SharesState = {
  loaded: boolean,
  shareditems?: number,
  listShares: any,
  folderShares: Array<any>,
  shareMessage: string,
  sharePermissionId: number,
  shareFolderName: string
};

const initialState: SharesState = {
  loaded: false,
  listShares: { shares: [], requests: [] },
  shareditems: 0,
  folderShares: [],
  shareMessage: "",
  sharePermissionId: 0,
  shareFolderName: ""
};

const deleteShare = (state: SharesState, share) => {
  const { folderShares } = state;
  const { shareid, sharerequestid } = share;

  if (shareid) {
    return folderShares.filter(share => share.shareid !== shareid);
  } else if (sharerequestid) {
    return folderShares.filter(share => share.sharerequestid !== sharerequestid);
  } else {
    return folderShares;
  }
};

const updateShare = (state, share) => {
  const { folderShares } = state;
  const { shareid, sharerequestid } = share;

  if (shareid) {
    const filteredShares = folderShares.filter(share => share.shareid !== shareid);
    return [...filteredShares, share];
  } else if (sharerequestid) {
    const filteredShares = folderShares.filter(share => share.sharerequestid !== sharerequestid);
    return [...filteredShares, share];
  } else {
    return folderShares;
  }
};

const acceptedShare = (state, share) => {
  const { folderShares } = state;
  const { sharerequestid } = share;

  if (sharerequestid) {
    const filteredShares = folderShares.filter(share => share.sharerequestid !== sharerequestid);
    delete share.sharerequestid;
    return [...filteredShares, share];
  } else {
    return folderShares;
  }
};

export function sharesReducer(state: SharesState = initialState, action: Action): SharesState {
  switch (action.type) {
    case SHARES_LOAD:
      return { ...state, loaded: true, shareditems: action.shareditems };
    case SHARES_UNLOAD:
      return { ...state, loaded: false, shareditems: 0 };
    case LIST_SHARES:
      return { ...state, listShares: action.shares };
    case LOAD_FOLDER_SHARES:
      return { ...state, folderShares: action.shares };
    case CLEAR_FOLDER_SHARES:
      return { ...state, folderShares: [] };
    case ADD_SHARE_TO_FOLDER:
      return { ...state, folderShares: [...state.folderShares, action.share] };
    case DELETE_SHARE_TO_FOLDER:
      return { ...state, folderShares: deleteShare(state, action.share) };
    case UPDATE_SHARE_TO_FOLDER:
      return { ...state, folderShares: updateShare(state, action.share) };
    case ACCEPTED_SHARE_TO_FOLDER:
      return { ...state, folderShares: acceptedShare(state, action.share) };
    case GET_SHARE_MESSAGE:
      return { ...state, shareMessage: action.message };
    case GET_SHARE_PERMISSION:
      return { ...state, sharePermissionId: action.permissionId };
    case GET_SHARE_FOLDER_NAME:
      return { ...state, shareFolderName: action.shareFolderName };
    default:
      return state;
  }
}
