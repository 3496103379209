// @flow
import {
  FETCH_MEDIA_SUCCESS,
  DELETE_MEDIA_ITEM,
  // LOAD_GALLERY_DATA,
  SET_GALLERY_OPTIONS,
  SET_HAS_PASSWORD,
  SET_HAS_CODE,
  SET_CAN_DOWNLOAD
} from "../constants/galleryPreview";
import type { Action } from "../actions/types";


export type GalleryOptionsData = {
  code: string,
  candownload: boolean,
  linkpassword: string,
};

export type GalleryOptsState = {
  // linkData: array,
  mediaItems: object,
  optsData: GalleryOptionsData
};

const initialState: GalleryOptsState = {
  // linkData: [],
  mediaItems: {},
  optsData: {
    code: "",
    candownload: false,
    linkpassword: "",
  }
};

const modifyMedia = (state, id) => {
  const { mediaItems } = state;
  delete mediaItems[id];

  return { ...mediaItems };
};

export const galleryPreviewReducer = (
  state: GalleryOptsState = initialState,
  action: Action
): GalleryOptsState => {
  const { type, payload } = action;
  console.log("payload ====", payload, state, type)

  switch (type) {
    case FETCH_MEDIA_SUCCESS:
      return { ...state, mediaItems: { ...state.mediaItems, [payload.id]: payload } };
    case DELETE_MEDIA_ITEM:
      return { ...state, mediaItems: modifyMedia(state, payload) };
    // case LOAD_GALLERY_DATA:
    //   return { ...state, linkData: { ...state.linkData, ...linkData } };
    case SET_GALLERY_OPTIONS:
      return { ...state, optsData: { ...state.optsData, ...payload } };
    case SET_HAS_PASSWORD:
      return { ...state, optsData: { ...state.optsData, linkpassword: payload } };
    case SET_HAS_CODE:
      return { ...state, optsData: { ...state.optsData, code: payload } };
    case SET_CAN_DOWNLOAD:
      return { ...state, optsData: { ...state.optsData, candownload: payload } };
    default:
      return state;
  }
};
