import React from "react";
import AppleLogoSVG from "../../../../../root/img/svg/signin-with-apple.svg"
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import styled from 'styled-components'

const AppleLogo = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <StyledAppleSVG width="19.5" height="24" {...rest} />
    case DEVICE_OS.iOS:
      return <StyledAppleSVG width="21.16" height="28" {...rest} />
    case DEVICE_OS.Web:
      return <StyledAppleSVG width="16.284" height="20" {...rest} />
    case DEVICE_OS.Win:
      return <StyledAppleSVG width="16.284" height="20" {...rest} />
    case DEVICE_OS.MacOSX:
      return <StyledAppleSVG width="16.284" height="20" {...rest} />
    case DEVICE_OS.Linux:
      return <StyledAppleSVG width="16.284" height="20" {...rest} />
    default:
      return <StyledAppleSVG width="16.284" height="20" {...rest} />
  }
};

const StyledAppleSVG = styled(AppleLogoSVG)`
  fill: ${({ theme }) => theme.textPrimary};
`

export default AppleLogo;
