// @flow

import { hiDPI } from "polished";
import invariant from "invariant";

import { retinaSrc } from "../utils";

export default function retina(filename: string, retinaFilename: string, backgroundSize: string) {
  invariant(filename, "Supply filename to retina() as first argument.");
  invariant(retinaFilename, "Supply filename to retina() as second argument.");
  invariant(backgroundSize, "Supply backgroundSize to retina() as third argument.");

  return {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: backgroundSize,
    backgroundImage: `url(${filename})`,
    [hiDPI()]: {
      backgroundImage: `url(${retinaFilename})`,
      backgroundSize,
    },
  };
}
