// @flow

import * as React from "react";
import { createRoot } from 'react-dom/client';

const usedReactRoots = {};

const getExistingOrNewRoot = (element: HTMLElement) => {
  //  Existing ID or create new unique ID.
  const reactRootId = element.dataset.reactRootId || Math.random().toString(16).slice(2);
  let root = usedReactRoots[reactRootId] || null;
  
  if (root === null) {
    // Save the generated new ID to the element to be reused if we change the props and call this again.
    element.dataset.reactRootId = reactRootId;
    root = createRoot(element);
  }

  // Cache the root.
  usedReactRoots[reactRootId] = root;

  return {reactRootId , root};
}

const componentMount = (render: (props: {}) => React.Node) => (element: HTMLElement, props: {}) => {
  if (!element) {
    return null;
  }

  const {reactRootId, root} = getExistingOrNewRoot(element);
  root.render(render(props));

  return () => {
    root.unmount();
    delete usedReactRoots[reactRootId];
  };
};

export function componentRender(element: HTMLElement, render: React.Element<any>) {
  const {reactRootId, root} = getExistingOrNewRoot(element);
  root.render(render);

  return () => {
    root.unmount();
    delete usedReactRoots[reactRootId];
  };
}

export default componentMount;
