import React from "react";
import { ModalWindow as WebModalWindow } from "../WebStyles";
import { ModalWindow as WindowsModalWindow } from "../WindowsStyles";
import { ModalWindow as MacModalWindow } from "../MacStyles";
import { ModalWindow as LinuxModalWindow } from "../LinuxStyles";
import { ModalWindow as IosModalWindow } from "../iOSStyles";
import { ModalWindow as AndroidModalWindow } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ModalWindow = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidModalWindow {...rest} />
    case DEVICE_OS.iOS:
      return <IosModalWindow {...rest} />
    case DEVICE_OS.Web:
      return <WebModalWindow {...rest} />
    case DEVICE_OS.Win:
      return <WindowsModalWindow {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacModalWindow {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxModalWindow {...rest} />
    default:
      return <WebModalWindow {...rest} />
  }
};

export default ModalWindow;
