// @flow

import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import Componentify from "react-componentify";

import {
  brTagConverter,
  boldConverter,
  linkConverter,
  linkConverterV2,
  linkConverterSelf,
  spanClassTagConverter
} from "../lib/componentifyConverters";
import { TimerTemplate } from "../../web-shared/components/TimerTemplate";
import { ArrowIcon } from "../../web-shared/components/ButtonDefault";
import TimerContainer from "../containers/TimerContainer";

type Props = {
  title: string,
  titlecolor: string,
  description: string,
  descriptioncolor: string,
  btntext: string,
  btntextcolor: string,
  btnlink: string,
  btnbackground: string,
  disclaimer: string,
  disclaimercolor: string,
  img: string,
  img2x: string,
  img2xSize: string,
  imgSize: string,
  backgroundcolor: string,
  leftpartbackgroundcolor: string,
  label: string,
  lefttitletext: string,
  lefttitletextcolor: string,
  channelid: number | string,
  timerStartTime?: string | number | boolean,
  timerEndTime?: string | number | boolean,
  timertextcolor?: string | boolean,
  timerbackgroundcolor?: string | boolean,
  useLocalTime?: boolean,
  sendAnalyticsEvent: () => void,
  closeModal: () => void
};

class PromoHorizontal1 extends React.Component<Props, {}> {
  static defaultProps = {
    timerStartTime: false,
    timerEndTime: false,
    useLocalTime: false
  };

  constructor(props: Props) {
    super(props);
  }

  onButtonClick = () => {
    const { label, channelid, sendAnalyticsEvent, closeModal, openNewWindow } = this.props;

    sendAnalyticsEvent({
      category: "Promo modal button",
      action: "click",
      label: label,
      os: 4
    }, {
      channelid: channelid
    });
    // if (typeof gaAll == 'function') {
    //   gaAll("event", "button-promo-offer-modal-" + channelid, "click");
    // }
    if (typeof ga4 == 'function') {
      ga4({
        eventname: "select_promotion",
        ecommerce: {
          items: [{
            promotion_name: label,
            creative_slot: "modal",
            item_id: channelid
          }]
        }
      })
    }

    closeModal();

    // window.open(promoLink, "_blank");
  };

  getTimerData = () => {
    const { timerStartTime, timerEndTime, useLocalTime } = this.props;

    if (!timerStartTime || !timerEndTime) {
      return false;
    }

    const formattedStart = timerStartTime.replace(" ", "T");
    const formattedEnd = timerEndTime.replace(" ", "T");

    const timer = {
      start: new Date(formattedStart).getTime(),
      end: new Date(formattedEnd).getTime()
    };

    if (!useLocalTime) {
      const offset = new Date().getTimezoneOffset()*60*1000;
      timer.start += offset * -1;
      timer.end += offset * -1;
    }

    return timer;
  };

  constructURL = () => {
    const { btnlink, label, channelid } = this.props;
    const link = new URL(btnlink);
    
    link.searchParams.delete("channelid");
    link.searchParams.delete("label");
    link.searchParams.append("channelid", channelid);
    link.searchParams.append("label", label);

    return link.href;
  }

  renderTimer = () => {
    const { timertextcolor, timerbackgroundcolor, lefttitletextcolor } = this.props;
    const timerData = this.getTimerData();
    const timeNow = new Date().getTime();
    
    if (!timerData || timerData.start > timeNow || timerData.end < timeNow) {
      return null;
    }

    return (
      <TimerWrapper>
        <HurryUpText color={lefttitletextcolor}>{__("timer_hurryup")}</HurryUpText>
        <TimerContainer
          timeExpire={timerData.end}
          timeExpireCallback={() => {}}
          // showLabels={false}
          renderTimer={TimerTemplate}
          textColor={timertextcolor || lefttitletextcolor || "fff"}
          timerColor={timerbackgroundcolor}
          size="big"
        ></TimerContainer>
      </TimerWrapper>
    );
  };

  render() {
    const {
      title,
      titlecolor,
      description,
      descriptioncolor,
      imgSize,
      btntext,
      btntextcolor,
      btnbackground,
      disclaimer,
      disclaimercolor,
      img,
      img2x,
      backgroundcolor,
      leftpartbackgroundcolor,
      lefttitletext,
      lefttitletextcolor
    } = this.props;
    const imgsrc = window.devicePixelRatio > 1 ? img2x : img;

    const imgWidth = imgSize.split("x")[0];
    const imgHeight = imgSize.split("x")[1];
    const converters = [brTagConverter, boldConverter, linkConverterV2, linkConverterSelf, spanClassTagConverter];
    const promoLink = this.constructURL();

    return (
      <Wrapper backgroundColor={backgroundcolor} className="promo-modal-wrapper">
        <Title color={titlecolor}>
          <Componentify
            text={title}
            converters={converters}
          />
        </Title>
        <Description color={descriptioncolor}>
          <Componentify
            text={description}
            converters={converters}
          />
        </Description>
        <BoxContainer>
          <Box bgcolor={leftpartbackgroundcolor}>
            <DiscountText color={lefttitletextcolor}>
              <Componentify
                text={lefttitletext}
                converters={converters}
              />
            </DiscountText>
            {this.renderTimer()}
            <Button
              href={promoLink}//"javascript:;"
              color={btntextcolor}
              background={btnbackground}
              onClick={this.onButtonClick}
              target="_blank"
            >
              {btntext}
              <ArrowIcon rotation="right" color={btnbackground}> <i /> </ArrowIcon>
            </Button>
            {disclaimer ? (
              <Disclaimer color={disclaimercolor}>{disclaimer}</Disclaimer>
            ) : null}
          </Box>
          <Box>
            <Banner src={imgsrc} width={imgWidth} height={imgHeight} />
          </Box>
        </BoxContainer>
      </Wrapper>
    );
  }
}

export default PromoHorizontal1;

const Wrapper = styled.div`
  text-align: center;
  max-width: 720px;
  z-index: 0;
  position: relative;
  background-color: ${({backgroundColor}) => backgroundColor ? "#" + backgroundColor : "transparent"};
  padding: 10px;
  box-sizing: border-box;
  min-height: 500px;
  border-radius: 17px;
`;

const Title = styled.div`
  display: inline-block;
  width: 100%;
  /* margin: 16px 0px; */
  margin: 16px 0px 6px 0px;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -0.3px;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#222")};
`;

const Banner = styled.img`
  @media (max-width: 430px) {
    width: 100% !important;
    height: auto !important;
  }
`;

const Description = styled.div`
  display: inline-block;
  /* margin: 16px auto; */
  margin: 0px auto;
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#222")};
  padding: 0px 30px;
  box-sizing: border-box;
`;

const Button = styled.a`
  display: inline-block;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  padding: 8px 17px;
  margin: 29px auto 15px auto;
  border-radius: 22px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.05);
  background-color: ${props =>
    props.background ? "#" + props.background : "#17bed0"};
  font-size: 17px;
  font-weight: bold;
  line-height: 27px;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#fff")};
  cursor: pointer;
`;

const DiscountText = styled.div`
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -1.33px;
  text-align: center;
  color: ${props => props.color ? "#" + props.color : "#000000"};
  text-transform: capitalize;
  width: 100%;
`;

const Disclaimer = styled.div`
  display: inline-block;
  margin: 10px auto;
  width: 100%;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.18;
  text-align: center;
  color: ${props => (props.color ? "#" + props.color : "#868686")};
  width: 100%;
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const Box = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  margin: 0px 5px;
  min-height: 373px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:first-of-type {
    margin-left: 0px;
    padding: 30px;
  }

  &:last-of-type {
    margin-right: 0px;
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: ${({bgcolor}) => bgcolor ? "#" + bgcolor : "transparent"};
    z-index: -1;
  }
`;

const HurryUpText = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.33px;
  text-align: center;
  color: ${({color}) => color ? "#" + color : "#0e0e1e"};
  margin-bottom: 10px;
  width: 100%;
`;

const TimerWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
`;