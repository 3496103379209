// @flow

import API_ERRORS, { ERROR_MESSAGE } from '@pcloud/web-utilities/dist/api/errors';
import { myUrl } from "@pcloud/web-utilities/dist/config";
import apiMethod from "./apiMethod";
import Popup from "../lib/Popup";
import legacyMessage from "../lib/legacyMessage";
import { setcookie } from "../lib/utils";

const extraOptions = {
  errorCallback: errorCallback
};

export default function apiMethodMy(method: string, params: {}, cb: () => void, options: {}) {
  return apiMethod(method, params, cb, { ...options, ...extraOptions });
}

function errorCallback(data) {
  Popup.close();

  if (ERROR_MESSAGE[data.result] != undefined)
    legacyMessage(ERROR_MESSAGE[data.result], 'error')
  else if (data.result.toString().charAt(0) == 3)
    console.log('Ignored Error', data);
  else if (data.result == API_ERRORS.LOGIN_REQUIRED || data.result == API_ERRORS.LOGIN_FAILED) {
    legacyMessage("Session Expired. Please login.", 'error');
    setcookie("pcauth", "", -1);
    setcookie("locationid", "", -1);
    location.href = myUrl("#page=login");
    return;
  } else {
    console.log('Uncatched Error: ');
    console.log(data);
    legacyMessage(data.error, 'error');
  }
}
