// @flow
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_MODIFY,
  USER_COMPLETE_JOURNEY,
  USER_SET_HAS_PASSWORD,
  USER_DELETE_PASSWORD,
  USER_DELETE_PHONE_NUMBER,
  USER_SET_ACTIVE_TWO_FACTOR_TYPE,
  USER_SET_COUNTRY,
  USER_SET_PASSWORD,
  USER_SET_PHONE_COUNTRY_CODE,
  USER_SET_PHONE_NUMBER,
  USER_SET_VERIFIED_PHONE_NUMBER,
  USER_SET_CONTACTS,
  USER_SET_REGION_ID,
  USER_SET_RELOCATION_INFO,
  USER_SET_SETTINGS_INFO,
  USER_UPDATE_BILLING_INFO,
  USER_UPDATE_PAYMENT_INFO,
  USER_UPDATE_RELOCATION_STATUS,
  USER_SET_PAYMENT_SOURCE,
  USER_SET_PAYMENT_PROVIDER
} from "../constants/user";

import type { Userinfo, UserSettings, UserContacts, RelocationInfo } from "../../../types/user";
import type { Action } from "../actions/types";

export type UserState =
  | { logged: false }
  | {
      logged: true,
      token: string,
      userinfo: Userinfo,
      userSettings: UserSettings,
      userContacts: UserContacts,
      userRegionId: number,
      relocationInfo: RelocationInfo,
      paymentSource: any,
      paymentProvider: any
    };

const initialState = {
  logged: false,
  userinfo: {},
  userSettings: {
    billingInfo: [],
    payments: [],
    twoFacAuth: {},
    security: {
      activeTfaType: null
    }
  },
  userContacts: [],
  userRegionId: 2,
  relocationInfo: {},
  paymentSource: {},
  paymentProvider: {}
};

export function userReducer(state: UserState = initialState, action: Action) {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, logged: true, userinfo: action.userinfo, token: action.token };
    case USER_LOGOUT:
      return initialState;
    case USER_MODIFY:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, ...action.userinfo }
        };
      }

      return state;
    /*
    case USER_MODIFY_SUBSCRIPTIONS:
      if (state.logged) {
        return { ...state, userinfo: { ...state.userinfo, subscriptions: action.subscriptions } };
      }
    */
    case USER_COMPLETE_JOURNEY:
      if (state.logged) {
        return {
          ...state,
          userinfo: {
            ...state.userinfo,
            journey: { ...state.userinfo.journey, claimed: true }
          }
        };
      }

      return state;

    case USER_SET_HAS_PASSWORD:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, haspassword: true }
        };
      }

      return state;

    case USER_SET_PASSWORD:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, password: action.password }
        };
      }

      return state;

    case USER_DELETE_PASSWORD:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, password: "" }
        };
      }

      return state;

    case USER_SET_PHONE_COUNTRY_CODE:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, countryCode: action.countryCode }
        };
      }

      return state;

    case USER_SET_PHONE_NUMBER:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, msisdn: action.msisdn }
        };
      }

      return state;

    case USER_DELETE_PHONE_NUMBER:
      if (state.logged) {
        return {
          ...state,
          userinfo: { ...state.userinfo, msisdn: "" }
        };
      }

      return state;

    case USER_SET_VERIFIED_PHONE_NUMBER:
      if (state.logged) {
        return {
          ...state,
          userinfo: {
            ...state.userinfo,
            verifiedPhoneNumber: action.verifiedPhoneNumber || state.userinfo.msisdn
          }
        };
      }

      return state;

    case USER_SET_COUNTRY:
      if (state.logged) {
        return {
          ...state,
          userinfo: {
            ...state.userinfo,
            userCountry: action.userCountry
          }
        };
      }

      return state;

    case USER_SET_ACTIVE_TWO_FACTOR_TYPE:
      if (state.logged) {
        return {
          ...state,
          userSettings: {
            ...state.userSettings,
            security: {
              ...state.userSettings.security,
              activeTfaType: action.activeTfaType
            }
          }
        };
      }

      return state;

    case USER_SET_CONTACTS:
      if (state.logged) {
        return {
          ...state,
          userContacts: action.userContacts
        };
      }

      return state;

    case USER_SET_REGION_ID:
      return { ...state, userRegionId: action.userRegionId };

    case USER_SET_RELOCATION_INFO:
      return { ...state, relocationInfo: action.relocationInfo };

    case USER_SET_SETTINGS_INFO:
      return { ...state, userSettings: { ...state.userSettings, ...action.settingsInfo } };
    case USER_UPDATE_BILLING_INFO:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          billingInfo: action.billingInfo
        }
      };

    case USER_UPDATE_PAYMENT_INFO:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          payments: action.paymentInfo
        }
      };

    case USER_UPDATE_RELOCATION_STATUS:
      return {
        ...state,
        relocationInfo: { ...state.relocationInfo, relocationStatus: action.relocationStatus }
      };
    case USER_SET_PAYMENT_SOURCE:
      return { ...state, paymentSource: action.paymentSource };
    case USER_SET_PAYMENT_PROVIDER:
      return { ...state, paymentProvider: action.paymentProvider };

    default:
      return state;
  }
}
