import styled from "styled-components";

export const ButtonLink = styled.button`
  font-size: ${({fontSize}) => fontSize ? fontSize : "1rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({color}) => color ? color : "#17bed0"};
  padding: 0px;
  cursor: pointer;

  &:disabled {
    color: #999;
    cursor: auto;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  // fixes wrong alignment
  display: flex;
`;
