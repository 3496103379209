import React from "react";
import { InputButton as WindowsButton } from "../WindowsStyles";
import { InputButton as MacButton } from "../MacStyles";
import { InputButton as LinuxButton } from "../LinuxStyles";
import { InputButton as IosButton } from "../iOSStyles";
import { InputButton as AndroidButton } from "../AndroidStyles";
import WebButton from "../../../../components/SubmitButton";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const InputButton = ({ os, loading, children, className = "", ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return (
        <AndroidButton className="multiplatform-butt android" {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </AndroidButton>
      )
    case DEVICE_OS.iOS:
      return (
        <IosButton className="multiplatform-butt ios" {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </IosButton>
      )
    case DEVICE_OS.Web:
      return <WebButton className={`${className} multiplatform-butt`}loading={loading} children={children} {...rest}/>
    case DEVICE_OS.Win:
      return (
        <WindowsButton className="multiplatform-butt windows" {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </WindowsButton>
      )
    case DEVICE_OS.MacOSX:
      return (
        <MacButton className="multiplatform-butt mac" {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </MacButton>
      )
    case DEVICE_OS.Linux:
      return (
        <LinuxButton className="multiplatform-butt linux" {...rest}>
          {loading ? <div className="loading-image animate"></div> : children}
        </LinuxButton>
      )
    default:
      return <WebButton loading={loading} children={children} {...rest}/>
  }
};

export default InputButton;
