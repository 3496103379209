
let dragPrepared = false;
let dragTimer = null;
let options = {
  onDragOver: () => {
    console.log('drag over');
  },
  onDragEnter: () => {
    console.log('drag enter');
  },
  onDragLeave: () => {
    console.log('drag leave');
  },
  onDragOverTarget: () => {
    console.log('drag over target');
  },
  onDragLeaveTarget: () => {
    console.log('drag leave target');
  }
}

function _isDraggingFiles(e) {
	var dt = e.dataTransfer;
	var hasfiles = false;
	var hastextplain = false;
	var hasMozFile = false;

	//console.log(dt);

	if (dt.types && dt.types.length) {
		var n;
		for (n in dt.types) {
			if (dt.types.hasOwnProperty(n)) {
				if (dt.types[n].toLowerCase() == 'files') {
					hasfiles = true;
				} else if (['text/plain', 'text'].indexOf(dt.types[n].toLowerCase()) != -1) {
					hastextplain = true;
				}
				else if ('application/x-moz-file' == dt.types[n].toLowerCase()) {
					hasMozFile = true;
				}
			}
		}
	}

	return hasfiles && (!hastextplain || hasMozFile);
}

function _dragOver(e) {
  e.preventDefault();

  if (_isDraggingFiles(e)) {
    options.onDragOver();
    clearTimeout(dragTimer);
  }
}

function _dragEnter(e) {
  e.preventDefault();

  if (!_isDraggingFiles(e)) {
    return;
  }

  options.onDragEnter();
}

function initDragDropTarget(inOptions) {
  if (inOptions.target == undefined) {
    console.trace();
    console.error('`target` is required param for drag and drop.');
    console.log(inOptions);
    return;
  }

  Object.assign(options, inOptions);
  _prepDrag();

  return () => _unprepDrag();
}

var dragTimeout;

function _dragLeave(e) {
  e.preventDefault();

  if (dragTimeout) {
    clearTimeout(dragTimeout);
    dragTimeout = null;
  }

  dragTimeout = setTimeout(() => {
    dragTimeout = null;
    options.onDragLeave();
  }, 100);
}

function _drop(e) {
  e.preventDefault();

  clearTimeout(dragTimeout);
  options.onDragLeave();
}

function _prepDrag() {
	if (!window.FormData || dragPrepared) {
		return;
	}

	dragPrepared = true;
  document.addEventListener('dragenter', _dragEnter, false);
  document.addEventListener('dragleave', _dragLeave, false);
  document.addEventListener('dragover', _dragOver, false);
  document.addEventListener('drop', _drop, false);
}

function _unprepDrag() {
  dragPrepared = false;

  document.removeEventListener('dragenter', _dragEnter, false);
  document.removeEventListener('dragleave', _dragLeave, false);
  document.removeEventListener('dragover', _dragOver, false);
  document.removeEventListener('drop', _drop, false);
}

export default initDragDropTarget;
