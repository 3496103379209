import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const directionsMap = {
  top: "bottom",
  bottom: "top",
  left: "right",
  right: "left"
};

const Arrow = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border: ${props => props.size}px solid transparent;
  border-${props => directionsMap[props.direction]}-color: ${props => props.color};
`;

Arrow.defaultProps = {
  direction: "top",
  color: "#000",
  size: 6
};

Arrow.propTypes = {
  color: PropTypes.any.isRequired,
  direction: PropTypes.oneOf(Object.keys(directionsMap)).isRequired,
  size: PropTypes.number.isRequired
};

export default Arrow;

const BorderedArrow = ({ size, borderColor, ...rest }) => (
  <Arrow {...rest} size={size + 1} color={borderColor} style={{ position: "relative" }}>
    <Arrow
      size={size}
      {...rest}
      style={{ position: "absolute", top: -1 * (size - 1), left: -1 * size }}
    />
  </Arrow>
);

BorderedArrow.defaultProps = {
  borderColor: "#fff",
  direction: "top",
  color: "#000",
  size: 6
};

export { BorderedArrow };
