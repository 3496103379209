import React from "react";
import { Message as WebMessage } from "../WebStyles";
import { Message as WindowsMessage } from "../WindowsStyles";
import { Message as MacMessage } from "../MacStyles";
import { Message as LinuxMessage } from "../LinuxStyles";
import { Message as IosMessage } from "../iOSStyles";
import { Message as AndroidMessage } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Message = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidMessage {...rest}/>
    case DEVICE_OS.iOS:
      return <IosMessage {...rest}/>
    case DEVICE_OS.Web:
      return <WebMessage {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsMessage {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacMessage {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxMessage {...rest}/>
    default:
      return <WebMessage {...rest}/>
  }
};

export default Message;
