import React from "react";
import { ErrorBox as WebErrorBox } from "../WebStyles";
// import { ErrorBox as WindowsErrorBox } from "../WindowsStyles";
// import { ErrorBox as MacErrorBox } from "../MacStyles";
// import { ErrorBox as LinuxErrorBox } from "../LinuxStyles";
import { ErrorBox as IosErrorBox } from "../iOSStyles";
import { ErrorBox as AndroidErrorBox } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ErrorBox = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidErrorBox {...rest}/>
    case DEVICE_OS.iOS:
      return <IosErrorBox {...rest}/>
    case DEVICE_OS.Web:
      return <WebErrorBox {...rest}/>
    // case DEVICE_OS.Win:
    //   return <WindowsErrorBox {...rest}/>
    // case DEVICE_OS.MacOSX:
    //   return <MacErrorBox {...rest}/>
    // case DEVICE_OS.Linux:
    //   return <LinuxErrorBox {...rest}/>
    default:
      return <WebErrorBox {...rest}/>
  }
};

export default ErrorBox;
