import PropTypes from "prop-types";
import React from "react";
import { formatSize } from "../lib/utils";
import { TString } from "./intl";
import ToLocalDate from "./ToLocalDate";

const Limits = props => {
  const { maxspace, expire } = props;

  let classes = ["ul-limits", "clearfix"];
  let linkExpiration;
  let linkSpacelimit;

  linkExpiration = (
    <div>
      <TString id="ul_link_expire_on">Link Expires:</TString>{" "}
      <b>
        <ToLocalDate time={expire} />
      </b>
    </div>
  );
  if (!expire) {
    linkExpiration = <div />;
  }

  linkSpacelimit = <div />;
  if (maxspace) {
    linkSpacelimit = (
      <div>
        <TString id="ul_availablespace" text="Available space:" /> <b>{formatSize(maxspace)}</b>
      </div>
    );
  }

  if (!maxspace && !expire) {
    classes.push("empty");
  }

  return (
    <div className={classes.join(" ")}>
      {linkSpacelimit}
      {linkExpiration}
    </div>
  );
};

Limits.propTypes = {
  expire: PropTypes.number.isRequired,
  maxspace: PropTypes.number.isRequired
};

Limits.defaultProps = {
  expire: 0,
  maxspace: 0
};

export default Limits;
