import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../../lib/translate";
import { Wrapper, Message, Icon } from "./MainComponents"

export const Warning = ({ warningMessage }) => {
  return (
    <Wrapper>
      <Content>
        <WarningIcon/>
        <WarningMessage>{warningMessage}</WarningMessage>
      </Content>
    </Wrapper>
  )
}

const Content = styled.div`
  background-color: #fffbea;
  border: 1px solid #f7f3e3;
  text-align: center;
  padding: 27px;
`

const WarningIcon = styled(Icon)`
  width: 38px;
  height: 34px;
  background-image: url(${require("../../../root/img/vivacom/warning.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/vivacom/warning@2x.png")});
  }
`

const WarningMessage = styled(Message)`
  opacity: 0.65;
`
