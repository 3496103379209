const uniqueNum = {
	incr: -1,
	get: function (prefix) {
		if (this.incr == undefined)
			this.init();
		return (prefix ? prefix + '-' : '') + (++this.incr);
	}
}

if (uniqueNum.incr === -1) {
  uniqueNum.incr = Math.round(Math.random() * 1000);
}

export default uniqueNum;
