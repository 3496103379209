import React from "react";
import { ButtonLink as CommonButtonLink } from "../CommonStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ButtonLink = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <CommonButtonLink {...rest} />
    case DEVICE_OS.iOS:
      return <CommonButtonLink {...rest} />
    case DEVICE_OS.Web:
      return <CommonButtonLink fontSize="13px" {...rest} />
    case DEVICE_OS.Win:
      return <CommonButtonLink fontSize="0.75rem" {...rest} />
    case DEVICE_OS.MacOSX:
      return <CommonButtonLink fontSize="0.75rem" {...rest} />
    case DEVICE_OS.Linux:
      return <CommonButtonLink fontSize="0.75rem" {...rest} />
    default:
      return <CommonButtonLink {...rest} />
  }
};

export default ButtonLink;
