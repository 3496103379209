// @flow

import store from "./store";
import notificationSubscriber from "../notifications/notificationSubscriber";
import apiMethod from "../../api/apiMethod";

import type { Userinfo } from "../../types/user";
import type { UserState } from "./reducers/user";
import type { NotificationsState } from "./reducers/notifications";
import { load, reset } from "./actions/notifications";

let currentToken = "";
let stopListen;

store.subscribe(() => {
  checkUser(currentUserState());
});

checkUser(currentUserState());

function checkUser(userState: UserState) {
  if (userState.token != currentToken) {
    if (currentToken) {
      console.log("stop listen");
      currentToken = "";
      stopListen();
    }

    if (userState.logged) {
      console.log("go Listen");
      currentToken = userState.token;
      listen();
    }
  }
}

function listen() {
  const stopSubscriber = notificationSubscriber.listen(currentToken, notifications => {
    console.log("GOT NOTIF", notifications);
    store.dispatch(load(notifications));
  });

  stopListen = () => {
    stopSubscriber();
    setTimeout(() => store.dispatch(reset()), 0);
  };
}

function currentNotificationsState(): NotificationsState {
  return store.getState().notifications;
}

function currentUserinfo(): Userinfo | false {
  const user = currentUserState();
  return user.logged ? user.userinfo : false;
}

function currentUserState(): UserState {
  return store.getState().user;
}
