import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: #000000;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export default Link;
