// @flow

import React, { Component } from "react";
import { createRoot } from 'react-dom/client';
import { connect } from "react-redux";
import styled from "styled-components";
import $ from "jquery";

import Dropdown, { DIR_LEFT } from "../components/Dropdown";
import UserNotLogged from "./UserNotLogged";
import UserDropdownPlanSummary from "../components/UserDropdownPlanSummary";
import UserAvatar from "../components/UserAvatar";
import Arrow from "../components/Arrow";
import languageIcons from "../components/jquery/languages";
import { getLanguages } from "../lib/pCloudIntl";
import { disableSelection } from "../styles/utils";
import { URLMY, URLSITE } from "@pcloud/web-utilities/dist/config";
import { __ } from "../lib/translate";

import type { Userinfo, Language } from "../types/user";
import type { UserState } from "../lib/state/reducers/user";

const SETTINGS = 1;
const LANGUAGE = 2;

function prepareCell({ userinfo, language }) {
  return (cell, item) => { };
}

const handleClick = (props, state) => item => { };

const DropdownHolder = styled.div`${disableSelection()} cursor: pointer;`;

const DropdownArrow = styled(Arrow)`
  vertical-align: middle;
  margin: 5px 0 0 5px;
`;

type UserDropdownProps = {
  userinfo: Userinfo,
  logged: boolean,
  language: ?Language,
  showStats: boolean,
  onChangeLanguage: string => void,
  onLogoutRequest: () => void,
  onLoginRequest: () => void,
  onOpenStats: () => void,
  onUnverifiedStatusClick: () => void
};

type UserDropdownState = {
  usedquota: number
};

class UserDropdown extends Component<UserDropdownProps, UserDropdownState> {
  preparedObject: HTMLDivElement;
  root;
  stopListen: ?() => void;

  static defaultProps = {
    language: "en",
    showStats: false,
    onChangeLanguage: () => {},
    onLogoutRequest: () => {},
    onLoginRequest: () => {},
    onOpenStats: () => {}
  };

  constructor(props) {
    super(props);

    this.preparedObject = document.createElement("div");
    this.root = createRoot(this.preparedObject);

    (this: any)._prepareObject = this._prepareObject.bind(this);
    (this: any)._prepareList = this._prepareList.bind(this);
    (this: any)._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    this._updateDropdownPlanSummary();
  }

  componentDidUpdate() {
    this._updateDropdownPlanSummary();
  }

  componentWillUnmount() {
    this._removeDropdownSummary();
  }

  _updateDropdownPlanSummary() {
    const { logged, userinfo, ...rest } = this.props;

    this.root.render(logged ? <UserDropdownPlanSummary userinfo={userinfo} {...rest} /> : <div />);
  }

  _removeDropdownSummary() {
    this.root.unmount();
  }

  _prepareObject(obj, level) {
    if (level === 0) {
      obj.find("ul").prepend(this.preparedObject);
    }

    return obj;
  }

  _prepareList() {
    const langMenu = {
      title: __("Language"),
      href: false,
      extra: { id: "language" },
      submenu: getLanguages().map(({ code, title }) => ({
        title: __(title),
        extra: { id: code, type: "lang" }
      }))
    };

    const { userinfo, logged, showStats, showBetaLink } = this.props;

    const settingObj = {
      title: __("Settings"),
      href: URLMY + "#page=settings",
      extra: { id: "en" },
      submenu: [
        { title: __("Account"), href: URLMY + "#page=settings&settings=tab-account" },
        //{ title: "Download Links", href: "#page=settings&settings=tab-apps" },
        { title: __("Linked Accounts"), href: URLMY + "#page=settings&settings=tab-apps" },
        { title: __("Security"), href: URLMY + "#page=settings&settings=tab-security" },
        { title: "Encryption", href: URLMY + "#page=settings&settings=tab-crypto" },
        { title: __("Notifications"), href: URLMY + "#page=settings&settings=tab-notifications" }
      ]
    };

    // add Playment history to Settings submenu
    if (!userinfo.business) {
      settingObj.submenu.push({ title: __("payments_history", "Payment History"), href: URLMY + "#page=settings&settings=tab-finance" });
    }
    // Add Speed test to Settings submenu
    settingObj.submenu.push({ title: __("speed_test_setting", "Speed Test"), href: URLMY + "#page=settings&settings=tab-speedtest" });

    const listLogged = [
      settingObj,
      langMenu,
      { title: __("Help"), href: URLSITE + "help/general-help-center/" },
      { title: __("Contact"), href: URLSITE + "company/contactus.html" },
    ];

    if (showBetaLink) {
      listLogged.push({
        title: __("explore_bew_UI_label", "Explore the new UI"),
        href: false,
        extra: { id: "exploreBeta" }
      });
    }

    listLogged.push({
      title: __("Logout"),
      href: false,
      extra: { id: "logout", border: "top" }
    })

    // add Statistics to dropdown menu
    if (showStats) {
      listLogged.unshift({
        title: __("dl_statistics", "Statistics"),
        href: false,
        extra: { id: "stats" }
      });
    }

    const listUnlogged = [
      { title: __("Login"), extra: { id: "login" } },
      langMenu,
      { title: __("Help"), href: URLSITE + "help/general-help-center/" },
      { title: __("Contact"), href: URLSITE + "company/contactus.html" }
    ];

    return logged ? listLogged : listUnlogged;
  }

  _handleClick(item) {
    const { onChangeLanguage, onLogoutRequest, onLoginRequest, onOpenStats } = this.props;

    console.log("CLICK", this.props, item);

    if (item.extra.id === "exploreBeta" && typeof gtag === "function") {
      location.href = "https://my.pcloud.com/";
      gtag("event", "navigation_click", {
        action: "explore new design",
      })
    }

    if (item.href) {
      //location.href = item.href;
    } else if (item.extra.id === "login") {
      onLoginRequest();
    } else if (item.extra.type === "lang") {
      onChangeLanguage(item.extra.id);
    } else if (item.extra.id === "logout") {
      onLogoutRequest();
    } else if (item.extra.id === "stats") {
      onOpenStats();
    } else {
      console.log("unhandled", item);
    }
  }

  render() {
    const { logged, userinfo, onLoginRequest, europeUI } = this.props;
    const euBackgroundColor = europeUI ? "rgba(255, 255, 255, 0.5)" : "#8e8e95";

    if (!logged) {
      return <UserNotLogged onLoginRequest={onLoginRequest} />;
    }

    return (
      <Dropdown
        {...this.props}
        direction={DIR_LEFT}
        childDirection={DIR_LEFT}
        list={this._prepareList()}
        onSelect={this._handleClick}
        prepareHolder={this._prepareObject}
      >
        <DropdownHolder>
          {userinfo && <UserAvatar userinfo={userinfo} europeUI={europeUI}/>}
          {!userinfo && <UserAvatar loading={true} europeUI={europeUI}/>}
          <DropdownArrow size={5} direction="bottom" color={euBackgroundColor}/>
        </DropdownHolder>
      </Dropdown>
    );
  }
}

export default connect(({ user }) => {
  const { logged, userinfo = {} } = user;
  const { language } = userinfo;

  return {
    logged,
    userinfo,
    language,
  }
})(UserDropdown);

function isBusiness(userinfo) {
  return !!userinfo.account;
}

function cryptoSettings(userinfo) {
  var canCryptoSettings;

  canCryptoSettings = userinfo.cryptosetup || new Date(userinfo.cryptoexpires) > new Date();
  if (this.isBusiness(userinfo) && !userinfo.account.permissions.crypto_access) {
    canCryptoSettings = false;
  }

  return canCryptoSettings;
}
