// @flow
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { __ } from "../lib/translate";

type Props = {
  seconds: string,
  minutes: string,
  hours: string,
  textColor: string,
  timerColor: string
};

const PlainTimerTemplate = ({
  seconds,
  minutes,
  hours,
  textColor,
  timerColor
}: Props) => {
  return (
    <React.Fragment>
      <Title textColor={textColor}>{__("limited_time_deal")}</Title>
      <CounterCont timerColor={timerColor}>
        <span>
          <ValueWrapper>
            <Value timerColor={timerColor}>{hours}</Value>
          </ValueWrapper>
        </span>
        <i>:</i>
        <span>
          <ValueWrapper>
            <Value timerColor={timerColor}>{minutes}</Value>
          </ValueWrapper>
        </span>
        <i>:</i>
        <span>
          <ValueWrapper>
            <Value timerColor={timerColor}>{seconds}</Value>
          </ValueWrapper>
        </span>
      </CounterCont>
    </React.Fragment>
  );
};

const Title = styled.div`
  color: ${props => (props.textColor ? "#" + props.textColor : "#ffffff")};
  margin-top: 5px;
  font-size: 15px;
  margin-bottom: 8px;
`;

const CounterCont = styled.div`
  display: flex;
  justify-content: center;

  i {
    /* line-height: 47px; */
    margin: 0px 10px;
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => (props.timerColor ? "#" + props.timerColor : "#d9d9d9")};

    @media (max-width: 350px) {
      margin: 0px 6px;
    }
  }
`;

const ValueWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Value = styled.span`
  color: #fff;
  font-size: 40px;
  letter-spacing: -0.47px;
  z-index: 1;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: ${props => (props.timerColor ? "#" + props.timerColor : "#ffffff")};
`;

export default PlainTimerTemplate;
