import Componentify from "react-componentify";

export const boldConverter = {
  component: "span",
  props: {
    style: { fontWeight: "bold" }
  },
  regex: /\<b\>(.+?)\<\/b\>/g,
  innerText: matches => matches[1]
};

export const strongConverter = {
  component: "span",
  props: {
    style: { fontWeight: "bold" }
  },
  regex: /\<strong\>(.+?)\<\/strong\>/g,
  innerText: matches => matches[1]
};

export const underlineConverter = {
  component: "span",
  props: {
    style: { textDecoration: "underline" }
  },
  regex: /\<u\>(.+?)\<\/u\>/g,
  innerText: matches => matches[1]
};

export const brTagConverter = {
  regex: /<br\/>/g,
  component: "br"
};

export const spanClassTagConverter = {
  regex: /\<span class='(.*?)'>(.+?)\<\/span\>/g,
  component: "span",
  props: ([_, classElement]) => {
    return { className: classElement }
  },
  innerText: matches => matches[2] || matches[1]
};

export const styledSpanTagConverter = {
  regex: /\<span\>(.+?)\<\/span\>/g,
  props: {
    style: { color: "#17bed0" }
  },
  component: "span",
  innerText: matches => matches[1]
};

// for one or more links
// We strongly advise changing it. https://my.pcloud.com/#page=settings&settings=tab-crypto[Change Crypto Pass]
export const linkConverter = {
  regex: /((?:https?:\/\/)?(?:[\da-z\.-]+)\.(?:[a-z\.]{2,6})(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)*\/?)(?:\[(.+?)\])?/g,
  component: "a",
  props: ([_, url]) => {
    return { href: url, target: "_blank" };
  },
  innerText: matches => matches[2] || matches[1]
};

export const linkConverterSelf = {
  regex: /((?:https?:\/\/)?(?:[\da-z\.-]+)\.(?:[a-z\.]{2,6})(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)*\/?)(?:\[(.+?)\])?/g,
  component: "a",
  props: ([_, url]) => {
    return { href: url, target: "_self" };
  },
  innerText: matches => matches[2] || matches[1]
};

// An <link %update%|update for pCloud Crypto is available!> Collaborate with other pCloud Business
export const linkConverterV2 = {
  regex: /\<link (.+?)\|([^>]+)\>/g,
  component: "a",
  props: ([_, url]) => { return { href: url } },
  innerText: matches => matches[2]
};

export const divClassTagConverter = {
  regex: /\<div class='(.*?)'>(.+?)\<\/div\>/g,
  component: "div",
  props: ([_, classElement]) => {
    return { className: classElement }
  },
  innerText: matches => matches[2] || matches[1]
};