import PropTypes from "prop-types";
import React from "react";
import InputText from "./InputText";

const InputEmail = (props) => (
  <InputText {...props} type="email" autoCapitalize="none" autoCorrect="off" spellCheck="false" />
);

/*
  return (
    <input
      className="text"
      type="email"
      autoCapitalize="none"
      autoCorrect="off"
      spellCheck="false"
      placeholder={placeholder}
      name={name}
      value={value}
      {...rest}
    />
  );
  */
//};

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string.isRequired
};

InputEmail.defaultProps = {
  value: ""
};

export default InputEmail;
