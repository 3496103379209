// @flow
// WARNING: Do not use HFN in this component

import React, { Component } from "react";
import styled from "styled-components";

import login from "../../api/login"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { validateEmail, setcookie, rcookie, validatePassword } from "../../lib/utils"; // DONE
import { DEVICE_OS, EU_LOCATIONID, LOGIN_THEMES } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import * as FormStyle from "../../components/FormSharedComponents"; // DONE
import { setGlobalLocations, setGlobalLocationById, getApiServerCall } from "@pcloud/web-utilities/dist/api/utils.api"; // DONE
import { sendCJTokenOnLogin } from "../../../web-shared/cjAffiliate"; // DONE
import apiMethod from "../../api/apiMethod"; // DONE

import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper"; // DONE
import Input from "./MultiplatformComponents/Elements/Input"; // DONE
import SigninOptionsWrapper from "./MultiplatformComponents/Elements/SigninOptionsWrapper"; // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink"; // DONE
import CheckBox from "./MultiplatformComponents/Elements/CheckBox"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import Notification, { notify } from "./MultiplatformComponents/Notification"; // DONE
import EditEmailIconiOS from "../../../root/img/svg/edit_email_ios.svg"; // DONE
import EditEmailIconWeb from "../../../root/img/svg/edit_email.svg"; // DONE
import EditEmailIconWin from "../../../root/img/svg/edit_email_win.svg"; // DONE
import EditEmailIconMac from "../../../root/img/svg/edit_email_mac.svg"; // DONE
import EditEmailIconAndroid from "../../../root/img/svg/edit_email_android.svg"; // DONE
import ErrorIconAndroid from "../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "./MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "./MultiplatformComponents/Elements/ErrorMessage"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE
import Modal, { ModalWrap } from "../../components/Modal"; // DONE
import { CloseButton } from "../TwoFactorAuth/SharedComponents"; // DONE
import { Container, Title } from "../../components/Modals/styledComponents"; // DONE
import { getLoginOptionsArray } from "../UserAuthentication2/utils.signin";
import { SETUP_PASS_LOGIN_METHOD_ID, VERIFICATION_CODE_METHOD_ID } from "../UserAuthentication2/constants.signin";

require("../../styles/forms.less"); // DONE

type Props = {
  locationId: number,
  language: string,
  os: number,
  email: string,
  theme?: {},
  passpcloud: boolean,
  verificationCode: string,
  onLoginBegin: () => void,
  setShowRegisterVerificationCode: () => void,
  setShowLoginVerificationCode: () => void,
  showPasswordInput: boolean,
  setShowPasswordInput: () => void,
  onForgotPassword: () => void,
  onSuccess: ({ action: string, userinfo: string, rememberme: boolean }) => void,
  getRememberMe: boolean => void,
  setTFALoginInfo: (token: string, hasdevices: boolean, tfatype: number, logininfo?: string) => void,
  setVerifyInfo: (verifytoken: boolean, email: string) => void,
  setLocationId: (id: number) => void,
  showGracePeriodModalCb: (baRestrictedAuth: string) => void,
  usegrecaptcha: boolean
};

type State = {
  email: string,
  password: string,
  error: string,
  rememberme: boolean,
  isLogging: boolean,
  isLogged: boolean,
  showPassword: boolean
};

export default class Login extends Component<Props, State> {
  static defaultProps = {
    language: "en",
    locationId: EU_LOCATIONID,
    os: DEVICE_OS.Web,
    theme: {},
    showPasswordInput: false,
    setShowRegisterVerificationCode: () => {},
    setShowLoginVerificationCode: () => {},
    onLoginBegin: () => {},
    showForgotPass: () => {},
    onSuccess: () => {},
    getRememberMe: () => {},
    setTFALoginInfo: () => {},
    setVerifyInfo: () => {},
    setLocationId: () => {},
    showInsideForm: false,
    showGracePeriodModalCb: baRestrictedAuth => {},
    verificationCode: ""
  };

  constructor(props: Props) {
    super(props);
    const { os } = this.props;

    this.state = {
      email: props.email || rcookie("email") || "",
      password: "",
      rememberme: os === DEVICE_OS.Web ? true : false,
      isLogging: false,
      isLogged: false,
      showPassword: false,
      error: "",
      openForgotPassModal: false,
      passpcloud: false,
      redirectToBillingPage: false,
    };

    (this: any).onEnter = (this: any).onEnter.bind(this);
    (this: any).onLogin = (this: any).onLogin.bind(this);
    (this: any).submitContinue = (this: any).submitContinue.bind(this);
    (this: any).onContinueClick = (this: any).onContinueClick.bind(this);
    (this: any).onForgotClick = (this: any).onForgotClick.bind(this);
    (this: any).handlePasswordIconClick = (this: any).handlePasswordIconClick.bind(this);
    (this: any).onLoginClick = (this: any).onLoginClick.bind(this);
    (this: any).onCloseForgotPass = (this: any).onCloseForgotPass.bind(this);
    (this: any).onForgotClick = (this: any).onForgotClick.bind(this);
    (this: any).showMessage = (this: any).showMessage.bind(this);
  }

  componentDidMount() {
    document.addEventListener("grace-period-activated", (e) => {
      if (e && e.detail === "GO_TO_BILLING") {
        this.onLogin({retry: false, redirectToBilling: true});
      } else {
        this.onLogin({});
      }
    })
  }

  onEnter(e: any) {
    const { showPasswordInput } = this.props;

    e.stopPropagation();
    if (e.keyCode === 13) {
      if (showPasswordInput) {
        this.onLoginClick(e);
      } else {
        this.onContinueClick(e);
      }
    }
  }

  setInput(name: string, value: string) {
    this.setState({ [name]: value });
  }

  onForgotClick() {
    const { showForgotPass } = this.props;
    showForgotPass();
  }

  onCloseForgotPass() {
    this.setState({ openForgotPassModal: false });
  }

  handlePasswordIconClick() {
    this.setState(prevState => {
      return { showPassword: !prevState.showPassword };
    });
  }

  submitContinue(e: any) {
    const {
      language,
      setShowRegisterVerificationCode,
      setShowLoginVerificationCode,
      setShowPasswordInput,
      locationId,
      setLocationId,
      os
    } = this.props;

    const { email, isLogging } = this.state;

    this.setState({ isLogging: true });

    apiMethod(
      "user/preparelogin",
      {
        email,
        language,
        os,
        getlogins: 1,
      },
      ({ result, logins, ...rest }) => {
        if (result === 0) {
          this.setState({ isLogging: false });
          const loginOptions = getLoginOptionsArray(logins);

          if (loginOptions?.preferredOption?.length && loginOptions?.preferredOption?.includes(SETUP_PASS_LOGIN_METHOD_ID)) {
            setShowRegisterVerificationCode(true, email);
          } else if (loginOptions?.preferredOption?.length && loginOptions?.preferredOption?.includes(VERIFICATION_CODE_METHOD_ID)) {
            setShowLoginVerificationCode(true, email);
          } else {
            setShowPasswordInput(true);
          }
        }
      },
      {
        errorCallback: ({ result, location = {} }) => {
          if (result === 2360 || result === 2321) {
            if (location.id) {
              setGlobalLocationById(location.id);
              setLocationId(location.id);
              setGlobalLocations([location]);
              this.submitContinue();
              return;
            }
          }

          this.setState({ isLogging: false });
        }
      }
    );
    // Errors:
    // 1130="Please provide 'email'."
    // 2360="The activation code is provided by email."
  }

  showMessage(message, type) {
    const { os } = this.props;

    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      this.setState({ error: message });
    } else {
      notify(message, type, { os });
    }
  }

  canLoginStatus(cb: () => void) {
    const { email, password, isLogging } = this.state;
    const { showPasswordInput } = this.props;

    if (isLogging) {
      return;
    }

    let error = (showPasswordInput && validatePassword(password)) || "";
    if (!validateEmail(email)) {
      error = __("invalid_email", "Invalid Email");
    }

    if (error) {
      this.showMessage(error, "error");
    } else {
      this.setState({ error: "" });
      cb();
    }
  }

  onContinueClick(e: any) {
    e.preventDefault();
    const { email, isLogging, isLogged } = this.state;

    if (!email.length || isLogging || isLogged) {
      return;
    }

    this.canLoginStatus(this.submitContinue);
  }

  onLoginClick(e: any) {
    e.preventDefault();
    const { password, email, isLogging, isLogged } = this.state;
    const { usegrecaptcha } = this.props;

    if (!password.length || !email.length || isLogging || isLogged) {
      return;
    }

    const self = this;
    this.canLoginStatus(() => {
      if (typeof grecaptcha !== "undefined" && usegrecaptcha) {
        console.log("grecaptcha init");
        grecaptcha.ready(function() {
          grecaptcha.execute('6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR', {action: 'submit'}).then((token) => {
            console.log("grecaptcha ready");
            self.onLogin({ gresponsetoken: token })
          });
        });
      } else {
        self.onLogin({});
      }
    });
  }

  onLogin({ retry = false, redirectToBilling = false, gresponsetoken = false } = {}) {
    const {
      language,
      locationId,
      getRememberMe,
      onLoginBegin,
      onSuccess,
      setTFALoginInfo,
      setVerifyInfo,
      os,
      osversion,
      appversion,
      appversioncode,
      device,
      deviceid,
      passpcloud,
      setLocationId,
      verificationCode
    } = this.props;
    const { email, password, rememberme, openGracePeriodModal } = this.state;

    getRememberMe(rememberme);

    if (retry) {
      setGlobalLocationById(EU_LOCATIONID);
    } else {
      setGlobalLocationById(locationId);
    }

    getApiServerCall();

    this.setState({ isLogging: true });

    if (onLoginBegin) {
      onLoginBegin();
    }

    login(email, password, rememberme, {
      os: os,
      language: language,
      osversion: osversion,
      appversion: appversion,
      appversioncode: appversioncode,
      device: device,
      deviceid: deviceid,
      passpcloud: passpcloud,
      gresponsetoken: gresponsetoken,
      emailtfacode: verificationCode,
      successCallback: userIfno => {
        const { auth } = userIfno;
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");

        this.setState({ isLogged: true });
        setcookie("email", email);
        sendCJTokenOnLogin(apiMethod, auth);
        onSuccess({
          action: "login",
          userinfo: userIfno,
          rememberme: rememberme,
          locationid: locationId
        });

        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = "hidden";
        }

        if (redirectToBilling) {
          /** go to billing */
          const baseUrl = window.location.protocol + "//" + window.location.host + "/" ;
          window.open(baseUrl + "#page=b_billing&authtoken=" + auth + "&locationid=" + locationId);
        }
      },
      errorCallback: ret => {
        const { result, token, hasdevices, tfatype, verifytoken, email, error, location = {}, auth = "" } = ret;
        console.log("ERORRR CALLBACK LOGIN BA", ret);
        if (result == 2297) {
          setTFALoginInfo(token, hasdevices, tfatype, email);
          this.setState({ isLogging: false });
        } else if (result == 2306) {
          setVerifyInfo(verifytoken, email);
          this.setState({ isLogging: false });
        } else if (result === 2321) {
          setGlobalLocations([location]);
          setLocationId(location.id);
          this.onLogin({ gresponsetoken });
        } else if (result === 2330) {
          this.setState({
            error: __(
              "error_2330",
              "Access to your account has been temporarily stopped, due to your move to a new data region."
            ),
            password: "",
            isLogging: false
          });
          this.showMessage(
            __("error_2330", "Access to your account has been temporarily stopped, due to your move to a new data region."),
            "error"
          );
        } else if (result === 2205) {
          //open ba owner grace modal
          if (auth) {
            const { showGracePeriodModalCb } = this.props;
            showGracePeriodModalCb(auth);
            this.setState({ openGracePeriodModal: true, isLogging: false });
          } else {
            this.setState({ password: "", isLogging: false });
            this.showMessage(error, "error");
          }
        } else {
          this.setState({ password: "", isLogging: false });
          this.showMessage(error, "error");
        }
      },
      onTimeout: () => {
        this.setState({ password: "", isLogging: false });
        this.showMessage(__("error_connection_timed_out"), "error");
      }
    });
  }

  renderRememnerMe() {
    const { rememberme } = this.state;
    const { os } = this.props;

    return (
      <CheckBox
        type="checkbox"
        name="rememberme"
        size="small"
        onChange={e => this.setInput("rememberme", !!e.target.checked)}
        checked={rememberme}
        className="login-input-checkbox"
        os={os}
        text={__("lbl_remember_me", "Remember me")}
      />
    );
  }

  renderMessage() {
    const { error } = this.state;
    const { os } = this.props;
    const errorMessageIcons = {
      [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24" />,
      [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24" />,
      [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24" />
    };
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && error) {
      return (
        <ErrorBox os={os}>
          <div className="icon-wrapper">{errorMessageIcons[os]}</div>
          <ErrorMessage className="login-error-message" os={os}>
            {error}
          </ErrorMessage>
        </ErrorBox>
      );
    }

    return <Notification os={os} />;
  }

  render() {
    const { theme, os, showPasswordInput, setShowPasswordInput, showInsideForm } = this.props;
    const {
      email,
      password,
      rememberme,
      isLogged,
      isLogging,
      showPassword,
      openForgotPassModal,
      openGracePeriodModal
    } = this.state;
    const PassIcon = showPassword ? FormStyle.ShowPasswordIcon : FormStyle.HidePasswordIcon;
    const themeStyle = theme && LOGIN_THEMES[theme] ? LOGIN_THEMES[theme] : {};

    const editEmailIcons = {
      [DEVICE_OS.Android]: <EditEmailIconAndroid className="edit-email-icon" onClick={() => setShowPasswordInput(false)} />,
      [DEVICE_OS.iOS]: <EditEmailIconiOS className="edit-email-icon" onClick={() => setShowPasswordInput(false)} />,
      [DEVICE_OS.Web]: <EditEmailIconWeb className="edit-email-icon" onClick={() => setShowPasswordInput(false)} />,
      [DEVICE_OS.Win]: <EditEmailIconWin className="edit-email-icon" onClick={() => setShowPasswordInput(false)} />,
      [DEVICE_OS.MacOSX]: <EditEmailIconMac className="edit-email-icon" onClick={() => setShowPasswordInput(false)} />,
      [DEVICE_OS.Linux]: <EditEmailIconWin className="edit-email-icon" onClick={() => setShowPasswordInput(false)} />
    };

    return (
      <Form className="form" os={os} onSubmit={showPasswordInput ? this.onContinueClick : this.onLoginClick}>
        {this.renderMessage()}
        <div>
          <InputWrapper os={os} className="input-wrapper">
            <Input
              os={os}
              type="email"
              name="email"
              autoComplete="email"
              autocapitalize="off"
              placeholder={__("form_email", "Email")}
              value={email}
              onChange={e => this.setInput("email", e.target.value)}
              onKeyDown={this.onEnter}
              disabled={showPasswordInput && email}
              style={showPasswordInput && email ? { paddingRight: "30px" } : {}}
              // className="login-input-email text"
            />
            {showPasswordInput ? editEmailIcons[os] : null}
          </InputWrapper>
          {showPasswordInput ? (
            <InputWrapper os={os} className="input-wrapper login-input-password-wrapper">
              <Input
                os={os}
                name="password"
                placeholder={__("form_password", "Password")}
                value={password}
                onChange={e => this.setInput("password", e.target.value)}
                onKeyDown={this.onEnter}
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                autoFocus
                // className="login-input-password text"
              />
              <PassIcon onClick={this.handlePasswordIconClick} className="login-input-password-icon" />
            </InputWrapper>
          ) : null}
        </div>
        {showPasswordInput ? (
          <React.Fragment>
            <SigninOptionsWrapper className="login-input-checkbox-wrapper" os={os}>
              {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android ? this.renderRememnerMe() : <div />}
              <ButtonLink type="button" os={os} onClick={this.onForgotClick} className="forgotpass-button">
                {__("forgot_password", "Forgot Password")}
              </ButtonLink>
            </SigninOptionsWrapper>
            <ButtonWrapper os={os}>
              <Button
                os={os}
                disabled={!password.length || !email.length}
                active={!isLogging && !isLogged}
                loading={isLogging}
                onClick={this.onLoginClick}
                className="login-button-submit"
              >
                {__("Login", "Login")}
              </Button>
            </ButtonWrapper>
          </React.Fragment>
        ) : (
          <ButtonWrapper os={os}>
            <Button
              os={os}
              disabled={!email.length}
              active={!isLogging && !isLogged}
              loading={isLogging}
              onClick={this.onContinueClick}
              className="login-button-submit"
            >
              {__("Continue", "Continue")}
            </Button>
          </ButtonWrapper>
        )}
      </Form>
    );
  }
}

export const RememberMe = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

const IconWrapper = styled.div`
  /* position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 10px;
  cursor: pointer; */
`;
