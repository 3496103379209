// @flow

import Button, { ButtonCentered, ButtonWithIcon } from "../../web-shared/components/ButtonDefault";

export default Button;
export { ButtonCentered, ButtonWithIcon };

// export type ButtonProps = {
//   loading?: boolean,
//   disabled?: boolean,
//   color?:
//     | "cyan"
//     | "lightCyan"
//     | "red"
//     | "redCaution"
//     | "lightCaution"
//     | "black"
//     | "green"
//     | "darkGreen"
//     | "gray"
//     | "light"
//     | "lightCyan"
//     | "lightgray1"
//     | "lightgray2"
//     | "orange",
//   size?: "big" | "medium" | "small" | "xs",
//   href?: string,
//   borderRadius?: string,
//   minWidth?: string,
//   children: any,
//   borderRadius?: number,
//   inline?: boolean,
//   type?: "button" | "submit",
//   fontWeight?: string
// };

// class Button extends Component<ButtonProps> {
//   static defaultProps = {
//     href: "javascript:;",
//     size: "big",
//     color: "cyan",
//     active: true,
//     disabled: false,
//     loading: false,
//     textSize: "normal"
//   };

//   render() {
//     const { children, type, ...rest } = this.props;
//     console.log({...rest})
//     return (
//       <ButtonWrap {...rest}>
//         <span>{children}</span>
//         {rest.loading && <Loading />}
//         { type == "submit" ? <input type="submit" style={{display: "none"}} /> : null }
//       </ButtonWrap>
//     );
//   }
// }

// const ButtonCentered = (props: ButtonProps) => (
//   <ButtonCenteredWrap>
//     <Button {...props} inline />
//   </ButtonCenteredWrap>
// );

// export { ButtonCentered };
// export default Button;

// const ButtonWrap = styled.a`
//   height: ${props => heightSize[props.size]}px;
//   line-height: ${props => heightSize[props.size]}px;
//   border-radius: ${props => props.borderRadius ? props.borderRadius : 5}px;
//   color: ${props => colors[props.color].text};
//   font-family: "Roboto", sans-serif;
//   text-decoration: none;
//   text-align: center;
//   cursor: ${props => (props.disabled ? "default" : "pointer")};
//   font-weight: ${({fontWeight}) => fontWeight || 600 };
//   font-size: ${props => textSize[props.textSize]}px;
//   position: relative;
//   padding: 0;
//   width: auto;
//   min-width: ${props => props.minWidth ? props.minWidth : "auto"};
//   padding: 0 20px;
//   margin: 0 auto;
//   display: inline-block;
//   box-sizing: border-box;
//   border: ${props => colors[props.color].border};
//   opacity: ${props => (props.disabled ? 0.5 : 1)};
//   & > span {
//     visibility: ${props => (props.loading ? "hidden" : "visible")};
//   }
//   background: ${props => colors[props.color].background};
//   transition: all 200ms ease-in-out;
//   ${props => buttonSize(props)};
// `;

// const ButtonCenteredWrap = styled.div`
//   text-align: center;
// `;

// const Loading = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 20px;
//   height: 20px;
//   margin: -10px 0 0 -10px;
//   -webkit-animation: spin 0.5s linear infinite;
//   animation: spin 0.5s linear infinite;
//   background: url(${require("../../root/img/loading_button.png")}) no-repeat 50%;
//   background-size: 20px 20px;
// `;

// function buttonSize(props: ButtonProps) {
//   return props.inline
//     ? {
//         display: "inline-block",
//         margin: "0 auto"
//       }
//     : {
//         display: "block",
//         width: "100%"
//       };
// }

// export const colors = {
//   cyan: {
//     background: "#17bed0",
//     border: "none",
//     text: "#fff"
//   },
//   lightCyan: {
//     background: "#fff",
//     border: "1px solid #aae7ec",
//     text: "#17bed0"
//   },
//   red: {
//     background: "#ff4242",
//     border: "none",
//     text: "#fff"
//   },
//   redCaution: {
//     background: "#f12e2e",
//     border: "none",
//     text: "#fff"
//   },
//   lightCaution: {
//     background: "#fff",
//     border: "1px solid #ffb9b9",
//     text: "#f12e2e"
//   },
//   redReset: {
//     background: "#fc354c",
//     text: "#fff",
//   },
//   black: {
//     background: "#343536",
//     border: "none",
//     text: "#fff"
//   },
//   green: {
//     background: "#86c600",
//     border: "none",
//     text: "#fff"
//   },
//   darkGreen: {
//     background: "#61C100",
//     border: "none",
//     text: "#fff"
//   },
//   gray: {
//     background: "#999999",
//     border: "none",
//     text: "#fff"
//   },
//   lightgray1: {
//     background: "#d5d5d5",
//     border: "none",
//     text: "#fff"
//   },
//   lightgray2: {
//     background: "#f2f2f2",
//     border: "1px solid #eeeeee",
//     text: "#000"
//   },
//   lightgray3: {
//     background: "#e9e9e9",
//     border: "1px solid #e9e9e9",
//     text: "#555555"
//   },
//   light: {
//     background: "#fff",
//     border: "1px solid #eeeeee",
//     text: "#000"
//   },
//   orange: {
//     background: "#ff9326",
//     border: "none",
//     text: "#fff"
//   },
//   transparent: {
//     background: "transparent",
//     border: "none",
//     text: "#fff"
//   }
// };

// const heightSize = {
//   big: 40,
//   medium: 34,
//   xm: 26,
//   small: 20
// };

// const textSize = {
//   normal: 13,
//   small: 11
// };
