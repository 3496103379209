import React from "react";

import RevealPassAndroid from "../../../../../root/img/svg/reveal-password/android/no-eye-white.svg";
import RevealPassiOS from "../../../../../root/img/svg/reveal-password/ios/no-eye.svg";
import RevealPassMac from "../../../../../root/img/svg/reveal-password/mac/no-eye.svg";
import RevealPassWeb from "../../../../../root/img/svg/reveal-password/web/no-eye-white.svg";
import RevealPassWin from "../../../../../root/img/svg/reveal-password/win/no-eye-white.svg";
import { PassIconWrapper as LinuxPassIconWrapper } from "../LinuxStyles";
import { PassIconWrapper as IosPassIconWrapper } from "../iOSStyles";
import { PassIconWrapper as AndroidPassIconWrapper } from "../AndroidStyles";
import { PassIconWrapper as WebPassIconWrapper } from "../WebStyles";
import { PassIconWrapper as MacPassIconWrapper } from "../MacStyles";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ReavealPassword = ({ os, themeMode, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Android:
      return (
        <AndroidPassIconWrapper {...rest} >
          <RevealPassAndroid fill={themeMode === "dark" ? "#bfc8ca" : "#3f484a"} width="20px" height="18px"/>
        </AndroidPassIconWrapper>
      )
    case DEVICE_OS.iOS:
      return (
        <IosPassIconWrapper {...rest} >
          <RevealPassiOS fill={themeMode === "dark" ? "#7f7f7f" : "#7f7f7f"} width="24px" height="15px"/>
        </IosPassIconWrapper>
      )
    case DEVICE_OS.Web:
      return (
        <WebPassIconWrapper {...rest} >
          <RevealPassWeb fill={themeMode === "dark" ? "#cdd0d4" : "#656668"} width="16px" height="16px"/>
        </WebPassIconWrapper>
      )
    case DEVICE_OS.Win:
      return (
        <LinuxPassIconWrapper {...rest} >
          <RevealPassWin fill={themeMode === "dark" ? "#cdd0d4" : "#212121"} width="12px" height="12px"/>
        </LinuxPassIconWrapper>
      )
    case DEVICE_OS.MacOSX:
      return (
        <MacPassIconWrapper {...rest} >
          <RevealPassMac fill={themeMode === "dark" ? "#cfcfcf" : "#cfcfcf"} width="16px" height="10px"/>
        </MacPassIconWrapper>
      )
    case DEVICE_OS.Linux:
      return (
        <LinuxPassIconWrapper {...rest} >
          <RevealPassWin fill={themeMode === "dark" ? "#cdd0d4" : "#212121"} width="12px" height="12px"/>
        </LinuxPassIconWrapper>
      )
    default:
      return (
        <WebPassIconWrapper {...rest} >
          <RevealPassWeb fill={themeMode === "dark" ? "#cdd0d4" : "#656668"} width="14px" height="16px"/>
        </WebPassIconWrapper>
      )  }
};

export default ReavealPassword;
