// WARNING: Do not use HFN in this component

import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import Modal from "../../components/Modal"; // DONE
import apiMethod from "../../api/apiMethod"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { LOGIN_THEMES, DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { URLSITE } from "@pcloud/web-utilities/dist/config"; // DONE

import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "./MultiplatformComponents/Elements/Form"; // DONE
import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import SubHeading from "./MultiplatformComponents/Elements/SubHeading"; // DONE
import SigninOptionsWrapper from "./MultiplatformComponents/Elements/SigninOptionsWrapper"; // DONE
import CheckBox from "./MultiplatformComponents/Elements/CheckBox"; // DONE
import Input from "./MultiplatformComponents/Elements/Input"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import Footer from "./MultiplatformComponents/Elements/Footer"; // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink"; // DONE
import MessageBox from "./MultiplatformComponents/Elements/MessageBox"; // DONE
import Message from "./MultiplatformComponents/Elements/Message"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import Notification, { notify } from "./MultiplatformComponents/Notification"; // DONE
import TfaCodeIcon from "../../../root/img/svg/tfa-code.svg"; // DONE
import TfaCodeiOSIcon from "../../../root/img/svg/tfa-code-ios.svg"; // DONE
import TfaCodeAndroidIcon from "../../../root/img/svg/tfa-code-android.svg"; // DONE
import ErrorIconAndroid from "../../../root/img/svg/error-android.svg"; // DONE
import ErrorIconiOS from "../../../root/img/svg/error-ios.svg"; // DONE
import ErrorBox from "./MultiplatformComponents/Elements/ErrorBox"; // DONE
import ErrorMessage from "./MultiplatformComponents/Elements/ErrorMessage"; // DONE

export class TwoFactAuthLoginCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formID: 0,
      validCode: false
    };

    this.submitCode = this.submitCode.bind(this);
    this.codeChange = this.codeChange.bind(this);
    this.toggleSafeDevice = this.toggleSafeDevice.bind(this);
    this.disableInput = this.disableInput.bind(this);
    this.validateCode = this.validateCode.bind(this);
    this.submitCodeOnEnter = this.submitCodeOnEnter.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.textInputRef = null;
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  componentDidMount() {
    const { formID, sendNotification, sendSMS } = this.props;
    if (formID == 1) {
      sendNotification();
    }
    if (formID == 2) {
      sendSMS();
      this.focusTextInput();
    }
    // console.log("ref", this.nameInput);
    // this.nameInput.focus();
  }

  codeChange(e) {
    const code = e.target.value;

    this.props.updateCode(code);
  }

  validateCode(e) {
    const code = e.target.value;
    const { formID } = this.props;
    const codeLengths = {
      "1": "6",
      "2": "6",
      "3": "8",
      "4": "6",
    };
    if (codeLengths[formID] == code.length) {
      this.setState({ validCode: true });
    } else {
      this.setState({ validCode: false });
    }
  }

  toggleSafeDevice() {
    this.props.updateIsSafeDevice();
  }

  disableInput(e) {
    if (this.props.sessionTimedOut || e.keyCode == 32) {
      e.preventDefault();
    }
  }

  submitCode(e) {
    e.preventDefault();
    const showError = this.props.error.showError;

    if (this.state.validCode && !showError) {
      this.props.tfaLogin();
    }
  }

  submitCodeOnEnter(e) {
    e.preventDefault();
    const showError = this.props.error.showError;

    if (!showError && this.state.validCode && e.keyCode == 13) {
      this.props.tfaLogin();
    }
  }

  focusTextInput() {
    this.textInputRef.focus();
  }

  renderMessage() {
    const { error, os } = this.props;

    const showWrongCode = error.wrongCode && error.showError;
    const showError = error.showError;

    const errorMessageIcons = {
      [DEVICE_OS.Web]: <ErrorIconAndroid width="28" height="24"/>,
      [DEVICE_OS.iOS]: <ErrorIconiOS width="26" height="24"/>,
      [DEVICE_OS.Android]: <ErrorIconAndroid width="28" height="24"/>,
    }
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && showError) {
      return (
        <ErrorBox os={os}>
          <div className="icon-wrapper">{errorMessageIcons[os]}</div>
          <ErrorMessage className="login-error-message" os={os}>{error.errorMessage}</ErrorMessage>
        </ErrorBox>
      )
    }

    return <Notification os={os} />;
  }

  render() {
    const {
      formID,
      userPhoneNumber,
      code,
      error,
      hide,
      requestFinished,
      sessionTimedOut,
      isSafeDevice,
      showLoading,
      toggleLoggedDevices,
      toggleHelpSteps,
      changeForm,
      toggleCanResend,
      canResend,
      waitingTimeForSend,
      theme,
      os
    } = this.props;

    const showWrongCode = error.wrongCode && error.showError;
    const showError = error.showError;

    const iconByOs = {
      [DEVICE_OS.iOS]: <TfaCodeiOSIcon/>,
      [DEVICE_OS.Android]: <TfaCodeAndroidIcon/>,
      [DEVICE_OS.Web]: <TfaCodeIcon/>,
      [DEVICE_OS.Win]: <TfaCodeIcon/>,
      [DEVICE_OS.Linux]: <TfaCodeIcon/>,
      [DEVICE_OS.MacOSX]: <TfaCodeIcon/>
    };

    const themeStyle = theme && LOGIN_THEMES[theme] ? LOGIN_THEMES[theme] : {};

    const forms = {
      "1": {
        form: "Notification form",
        title: __("tfa_login_notification_header", "Enter security code"),
        intructions: (
          <div>
            <span>{__("tfa_login_notification_subheader1.1", "We've sent you the code as a pCloud notification on your") + " "}</span>
            <ButtonLink type="button" os={os} style={{ fontSize: "inherit" }} className="tfa-link" onClick={toggleLoggedDevices}>
              {__("tfa_login_notification_subheader1.2", "logged devices.")}
            </ButtonLink>
          </div>
        ),
        codeLength: 6,
        anotherOption: __("tfa_login_notification_another_option", "Send code via SMS"),
        helperFunction: () => {
          if (canResend) {
            changeForm(2);
            toggleCanResend();
            this.focusTextInput();
          }
        }
      },
      "2": {
        form: "SMS form",
        title: __("tfa_login_sms_header", "Code sent"),
        intructions: userPhoneNumber ? (
          <div>
            <span style={{marginRight: "4px"}}>{__("tfa_login_sms_subheader", "We've sent you the code to number")}</span>
            <b>{userPhoneNumber}</b>
          </div>
        ) : (
          <div>
            <span>{__("tfa_login_sms_subheader", "We've sent you the code to number")}</span>
            <UnsentPhoneNumber/>
          </div>
        ),
        codeLength: 6,
        anotherOption: __("tfa_login_sms_another_option", "Can't receive code?"),
        helperFunction: () => toggleHelpSteps()
      },
      "3": {
        form: "Recovery code form",
        title: __("tfa_login_recovery_header", "Recovery code"),
        intructions: __("tfa_login_recovery_subheader", "Provide any of your one-time recovery codes, which you haven't used yet"),
        codeLength: 8,
        anotherOption: __("tfa_login_recovery_another_option", "Contact support team"),
        helperFunction: () => window.open(URLSITE.indexOf('extension') ? "https://pcloud.com/request-help.html" : URLSITE + "request-help.html")
      },
      "4": {
        form: "Google authenticator form",
        title: __("tfa_login_authenticator_header", "Enter security code"),
        intructions: __("tfa_login_authenticator_subheader", "Enter the security code generated by your authenticator app"),
        codeLength: 6,
        anotherOption: __("tfa_login_authenticator_another_option", "Use recovery code"),
        helperFunction: () => {
          changeForm(3);
          this.focusTextInput();
        }
      }
    };

    const helperFunction = forms[formID].helperFunction;

    // if (this.nameInput && !showError) {
    //   this.nameInput.focus();
    // } else if (this.nameInput && showError) {
    //   this.nameInput.blur();
    // }

    return (
      hide ? null : (
        <React.Fragment>
          {requestFinished ? null : <LoaderWrapper/>}
          <FormWrapper os={os}>
            <Heading className="login-title" os={os} style={themeStyle.text}>{forms[formID].title}</Heading>
            <Form os={os} showWrongCode={showWrongCode} onSubmit={this.submitCode}>
              <MessageBox os={os}>
                <div className="icon-wrapper">
                  {iconByOs[os]}
                </div>
                <Message os={os}>{forms[formID].intructions}</Message>
              </MessageBox>
              {this.renderMessage()}
              <Input
                os={os}
                type="text"
                value={code}
                onChange={e => {
                  this.codeChange(e);
                  this.validateCode(e);
                }}
                placeholder={__("tfa_login_login_enter_code", "Enter code:")}
                autoFocus
                maxLength={forms[formID].codeLength}
                onKeyDown={this.disableInput}
                disabled={sessionTimedOut}
                ref={node => this.textInputRef = node}
              />
              <SigninOptionsWrapper os={os} className="regsitration-terms">
                <CheckBox 
                  os={os}
                  checked={isSafeDevice}
                  onChange={this.toggleSafeDevice}
                  disabled={sessionTimedOut}
                  text={__("tfa_login_form_dont_ask", "Don't ask for a code in the next 30 days")}
                  style={themeStyle.checkboxLabel}
                  size="small"
                />
              </SigninOptionsWrapper>
              <ButtonWrapper os={os}>
                {this.props.sessionTimedOut? (
                  <Button
                    os={os}
                    type="button"
                    color="cyan"
                    size="big"
                    onClick={()=>{location.reload()}}
                    style={{width: "100%"}}
                    className="twofactauth-button"
                  >
                    {__("tfa_login_tryagain", "Try again")}
                  </Button>
                ) : (
                  <Button
                    os={os}
                    type="button"
                    loading={showLoading && this.state.validCode}
                    disabled={!this.state.validCode}
                    color="cyan"
                    size="big"
                    onClick={this.submitCode}
                    style={{width: "100%"}}
                    className="twofactauth-button"
                  >
                    {__("Continue")}
                  </Button>
                )}
              </ButtonWrapper>
            </Form>
          </FormWrapper>
          <Footer>
            <ButtonLink type="button" disabled={formID == 1 && !canResend} onClick={helperFunction} className="twofactauth-link">
              {forms[formID].anotherOption}
              {formID == 1 && !canResend ? (
                <Counter>({waitingTimeForSend})</Counter>
              ) : null}
            </ButtonLink>
          </Footer>
        </React.Fragment>
      )
    );
  }
}

TwoFactAuthLoginCodeForm.propTypes = {
  isSafeDevice: PropTypes.bool,
  hide: PropTypes.bool,
  requestFinished: PropTypes.bool,
  sessionTimedOut: PropTypes.bool,
  showLoading: PropTypes.bool,
  formID: PropTypes.number.isRequired,
  code: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  error: PropTypes.shape({
    errorMessage: PropTypes.string,
    wrongCode: PropTypes.bool,
    showError: PropTypes.bool
  }),
  updateCode: PropTypes.func,
  updateIsSafeDevice: PropTypes.func,
  toggleLoggedDevices: PropTypes.func.isRequired,
  tfaLogin: PropTypes.func.isRequired,
  sendSMS: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired
};

const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

const Wrapper = styled.div`
  width: 100%;
  display: ${props => (props.hide ? "none" : "block")};


  .twofactauth-button {
    font-family: "Roboto", sans-serif !important;
  }
`;


// const Title = styled.div`
//   text-align: center;
//   font-size: 17px;
//   font-weight: 600;
//   margin: 5px;
// `;

// const Instructions = styled.div`
//   text-align: center;
//   font-size: 13px;
//   font-weight: 400;
//   color: #888;
//   & span {
//     color: #17bed0;
//     cursor: pointer;
//   }
//   & b {
//     color: #222;
//     display: inline-block;
//     margin-top: 6px;
//   }
// `;

// const Form = styled.div`
//   padding: 8px 0px 35px 0px;
//   text-align: center;
//   max-width: 280px;
//   margin: 0 auto;
//   & > input {
//     width: 100%;
//     text-align: center;
//     color: #444;
//     letter-spacing: 4px;
//     border: ${props =>
//       props.showWrongCode ? "1px solid #ED1C24" : "1px solid #aaa"};
//     outline: none;
//     padding: 8px;
//     border-radius: 4px;
//     box-sizing: border-box;
//     ${props =>
//       props.showWrongCode
//         ? `animation-name: ${shake};
//         animation-duration: 700ms;
//         animation-delay: 200ms;
//         animation-timing-function: ease-in-out;
//         animation-direction: alternate;
//         animation-fill-mode: forwards;`
//         : ""};
//     & ::-webkit-input-placeholder {
//       letter-spacing: normal;
//       text-align: left;
//     }
//     & ::-moz-placeholder {
//       letter-spacing: normal;
//       text-align: left;
//     }
//     & :-ms-input-placeholder {
//       letter-spacing: normal;
//       text-align: left;
//     }
//     & :-moz-placeholder {
//       letter-spacing: normal;
//       text-align: left;
//     }
//     @media (max-width: 460px) {
//       font-size: 14px;
//       padding: 10px;
//     }
//   }
//   @media (max-width: 460px) {
//     max-width: none;
//   }
// `;

const Helper = styled.div`
  color: ${({forbidden}) => forbidden ? "#999999" : "#17bed0"};
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  cursor: ${({forbidden}) => forbidden ? "not-allowed" : "pointer"};
  display: inline-block;
`;

const SafeDeviceCheck = styled.div`
  margin-top: 12px;
  text-align: left;
  & label {
    color: #888;
    font-weight: 400;
    display: inline;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ span {
        background-color: #17bed0;
        border: none;
        &:after {
          display: block;
        }
      }
    }
    &:hover input:not(:checked) ~ span {
      background-color: #eee;
    }
    & span {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #fafafa;
      border: 1px solid #bbb;
      border-radius: 3px;
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 3px;
        width: 3px;
        height: 6px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;

// const ErrorMessage = styled.div`
//   font-size: 11px;
//   color: #ed1c24;
//   font-weight: 600;
//   text-align: center;
//   margin: 6px;
//   visibility: ${props => (props.showError ? "visible" : "hidden")};
// `;

// const ButtonWrapper = styled.div`
//   width: 180px;
//   margin: 0 auto;
//   @media (max-width: 460px) {
//     width: 100%;
//   }
// `;

const dotsLoading = keyframes`
  0%  {box-shadow: 15px 0 #17bed0, -15px 0 #0002;background: #17bed0 }
  33% {box-shadow: 15px 0 #17bed0, -15px 0 #0002;background: #0002}
  66% {box-shadow: 15px 0 #0002,-15px 0 #17bed0; background: #0002}
  100%{box-shadow: 15px 0 #0002,-15px 0 #17bed0; background: #17bed0 }
`

const UnsentPhoneNumber = styled.div`
  margin-left: 25px;
  display: inline-block;
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: ${dotsLoading} 1s infinite ease-in-out forwards;
`;

const spin = keyframes`
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  &:after {
    content: "";
    display: inline-block;
    animation-name: ${spin};
    animation-duration: 600ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    margin: -8px 0 0 -24px;
    z-index: 100;
    background: url(${require("../../../root/img/loading_blue.png")}) no-repeat 0 0
      transparent;
    background-size: cover;
  }
`;

const Counter = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: red !important;
`;
