import React from "react";
import { DeviceContainer as WebDeviceContainer } from "../WebStyles";
import { DeviceContainer as WindowsDeviceContainer } from "../WindowsStyles";
import { DeviceContainer as MacDeviceContainer } from "../MacStyles";
import { DeviceContainer as LinuxDeviceContainer } from "../LinuxStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const DeviceContainer = ({ os, ...rest }) => {
  switch (os) {
    case DEVICE_OS.Web:
      return <WebDeviceContainer {...rest} />
    case DEVICE_OS.Win:
      return <WindowsDeviceContainer {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacDeviceContainer {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxDeviceContainer {...rest} />
    default:
      return <WebDeviceContainer {...rest} />
  }
};

export default DeviceContainer;
