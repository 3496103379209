import styled from "styled-components";
import { fadein } from "../AuthenticationStepsUI/TFALoggedDevicesModalUI"

export const lightTheme = {
  background: '#f9f9f9',
  primary: '#17bed0',
  secondary: '#ffffff',
  textPrimary: "#1b1b1b",
  disabled: "#999999",
  separatingLine: "#e5e5e5"
};

export const darkTheme = {
  background: '#f9f9f9',
  primary: '#17bed0',
  secondary: '#ffffff',
  textPrimary: "#1b1b1b",
  disabled: "#999999",
  separatingLine: "#e5e5e5"
};

export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.background};
  margin: 0;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  font-family: "Ubuntu", sans-serif;

  .change-link {
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: underline;
  }

  .cant-receive-code-back {
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: underline;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.secondary} inset;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: ${({ fixedPosition }) => fixedPosition ? "fixed" : "absolute"};
  border-radius: 18px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  z-index: 1002;
`;

export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tfa-button {
    width: 32px;
    flex-shrink: 0;
  }
`;
//
export const Heading = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  font-family: "Ubuntu", sans-serif;
`;
//
export const SubHeading = styled.div`
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 4px;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  font-family: "Ubuntu", sans-serif;
  width: 100%;

  .tfa-link {
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: underline;
  }
`;
//
export const MessageBox = styled.div`
  margin-top: -1.5rem;
  margin-bottom: 1.875rem;

  svg {
    display: none;
  }
`;
//
export const MessageBoxWarning = styled(MessageBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.242rem;
  border-radius: 5px;
  background-color: #fff5d2;
  margin-top: 1.875rem;
  padding: 1rem;

  .icon-wrapper {
    margin-right: 1rem;

    svg {
      display: block;
    }
  }
`;
//
export const MessageBoxSuccess = styled(MessageBoxWarning)`
`;
//
export const Message = styled.div`
  font-size: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 20px;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    max-width: 360px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }
`;
//
export const Form = styled.form`
  margin-top: 1.875rem;
  width: 100%;
  max-width: 350px;
  position: relative;
`;
//
export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 0.75rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
  }

  .register-input-password-icon {
    right: 12px;
  }

  .login-input-password-icon {
    right: 48px
  }

  .login-input-password {
    padding-right: 70px !important;
  }

  .register-input-password {
    padding-right: 35px !important;
  }
`;
//
export const Input = styled.input`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding: 0.375rem 0.625rem;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.disabled};
  background-color: ${({ theme }) => theme.secondary};
  font-family: inherit !important;
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.textPrimary};
  outline: none;

  &:disabled {
    background-color: #edebe9;
    border-color: #cccccc;
    color: #bbb;
  }

  &[type="email"],
  &:read-only {
    padding-right: 40px !important;
  }
`;
//
export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-top: 16px;

  label {
    margin-right: 0.625rem;

    a {
      text-decoration: underline !important;
      color: ${({ theme }) => theme.textPrimary};
    }
  }

  button {
    margin-left: 0.625rem;
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: underline;
  }

  &.login-input-checkbox-wrapper {
    padding-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.separatingLine};
  }
`;
//
export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 13px;
  position: relative;
`;
//
export const Button = styled.button`
  font-family: inherit !important;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  position: relative;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: #fff;
    cursor: auto;
  }
`;

export const InputButton = styled(Button)`
  height: 24px;
  padding: 0;
  border-radius: 2px;
`;

//
export const ButtonLink = styled.button`
  font-size: ${({ fontSize }) => fontSize ? fontSize : "0.813rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({ color }) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
`;
//
export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`

export const SecurityCodeStyledInput = styled.input`
  width: ${({ maxLength }) => {
    const singleInputMargin = maxLength > 6 ? 6 : 8;
    const separationSpace = maxLength > 6 ? 16 : 18;
    const margins = (maxLength - 2) * singleInputMargin;

    return "calc(((100% - " + (separationSpace + margins) + "px) / " + maxLength + "))"
  }};
  max-width: 28px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.disabled};
  line-height: 21px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.textPrimary};

  &:nth-child(${({ chunk }) => chunk}) {
    margin-right: ${({maxLength}) => maxLength > 6 ? "16px" : "18px"};
  }

  &:focus {
    outline: none;
  }
`;

//
export const VerificationCodeInputsError = styled.div``;
//
export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 350px;
`;
//
export const SocialButton = styled.div`
  width: 100%;
  height: 32px;
  padding: 0.375rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.disabled};
  background-color: ${({ theme }) => theme.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

export const ContinueWith = styled.div`
  font-size: 13px;
  text-align: center;
  color: #616161;
  margin: 12px auto 14px auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 0.625rem;
  text-align: center;

  button {
    font-size: 14px;
    color: ${({ theme }) => theme.textPrimary};
    text-decoration: underline;
  }
`;

export const InputButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 32px;
  height: 24px;
`;

export const ModalWindow = styled.div`
  padding: 27px 30px 31px 30px;
  box-sizing: border-box;
  position: relative;
  background: ${({ theme }) => theme.secondary};
  max-width: 380px;
  border-radius: 8px;
  animation-name: ${fadein};
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  margin: 0 10px;
  border: 1px solid ${({ theme }) => theme.disabled};

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.secondary} inset;
  }
`;

export const PassIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: -9px;
  top: 50%;
  right: 11px;
`;

export const DeviceContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.disabled};
  background: ${({ theme }) => theme.secondary};
  border-radius: 12px;
  min-height: 10px;
  margin: 25px 0px;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 13px;
  text-align: left;
  padding: 12px;

  @media (max-width: 460px) {
    margin: 10px 0px;
    margin-bottom: 0px;
    padding: 8px;
    background: none;
    border: none;
    color: black;
  }
`;