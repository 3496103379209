import styled from "styled-components";
//
export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu", sans-serif;
  position: relative;
`;
//
export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
//
export const Heading = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #000;
`;
//
export const SubHeading = styled.div`
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 0.6rem;
  text-align: center;
  color: #201f1e;
`;
//
export const MessageBox = styled.div`
  margin-top: -1.5rem;
  margin-bottom: 1.875rem;

  svg {
    display: none;
  }
`;
//
export const MessageBoxWarning = styled(MessageBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.242rem;
  border-radius: 5px;
  background-color: #fff5d2;
  margin-top: 1.875rem;
  padding: 1rem;

  .icon-wrapper {
    margin-right: 1rem;

    svg {
      display: block;
    }
  }
`;
//
export const MessageBoxSuccess = styled(MessageBoxWarning)`
`;
//
export const Message = styled.div`
  font-size: 1rem;
  text-align: center;
  color: #201f1e;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    max-width: 360px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }
`;
//
export const Form = styled.form`
  margin-top: 1.875rem;
  width: 100%;
  max-width: 350px;
  position: relative;
`;
//
export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 0.75rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
  }
`;
//
export const Input = styled.input`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding: 0.375rem 0.625rem;
  border-radius: 2px;
  border: solid 1px #999999;
  background-color: #fff;
  font-family: inherit !important;
  font-size: 0.875rem;
  text-align: left;
  color: #000;
  outline: none;

  &:disabled {
    background-color: #edebe9;
    border-color: #cccccc;
    color: #bbb;
  }

  &[type="email"],
  &:read-only {
    padding-right: 35px !important;
  }

  &[type="password"] {
    padding-right: 35px !important;
  }
`;
//
export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  margin: 1rem auto;
  margin-bottom: -1rem;

  label {
    margin-right: 0.625rem;
  }

  button {
    margin-left: 0.625rem;
  }
`;
//
export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  position: relative;
`;
//
export const Button = styled.button`
  font-family: inherit !important;
  width: 100%;
  height: 32px;
  padding: 0.375rem 0.625rem;
  border-radius: 2px;
  background-color: #17bed0;
  border: none;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  cursor: pointer;

  &:disabled {
    background-color: #999999;
    color: #fff;
    cursor: auto;
  }
`;
//
export const ButtonLink = styled.button`
  font-size: ${({fontSize}) => fontSize ? fontSize : "0.813rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({color}) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
`;
//
export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`
//
export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 350px;
`;
//
export const SocialButton = styled.div`
  width: 110px;
  height: 32px;
  padding: 0.375rem;
  box-sizing: border-box;
  border-radius: 2px;
  border: solid 1px #999999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ContinueWith = styled.div`
  font-size: 0.75rem;
  text-align: center;
  color: #a19f9d;
  margin: 0.625rem auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 0.625rem;
  text-align: center;
`;