import React from "react";
import { Footer as WindowsFooter } from "../WindowsStyles";
import { Footer as WebFooter } from "../WebStyles";
import { Footer as MacFooter } from "../MacStyles";
import { Footer as LinuxFooter } from "../LinuxStyles";
import { Footer as IosFooter } from "../iOSStyles";
import { Footer as AndroidFooter } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const Footer = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidFooter {...rest} />
    case DEVICE_OS.iOS:
      return <IosFooter {...rest} />
    case DEVICE_OS.Web:
      return <WebFooter {...rest} />
    case DEVICE_OS.Win:
      return <WindowsFooter {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacFooter {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxFooter {...rest} />
    default:
      return <WebFooter {...rest} />
  }
};

export default Footer;
