import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { setGlobalLocationById, setGlobalLocations } from "@pcloud/web-utilities/dist/api/utils.api";
import { __ } from "../../lib/translate";
import { isFacebookBrowser } from "../../lib/utils";
import { DEVICE_OS, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import {
  LOGIN_FLOW_TYPE,
  REGISTER_FLOW_TYPE,
  GOOGLE_LOGIN_METHOD_ID,
  APPLE_LOGIN_METHOD_ID,
  FACEBOOK_LOGIN_METHOD_ID,
  EMAIL_LOGIN_METHOD_ID,
  SETUP_PASS_LOGIN_METHOD_ID,
  VERIFICATION_CODE_METHOD_ID,
} from "./constants.signin";
import { prepareLoginMethod } from "./methods.signin";
import { getLoginOptionsArray } from "./utils.signin";

import Wrapper from "./MultiplatformComponents/Elements/Wrapper";
import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper";
import Form from "./MultiplatformComponents/Elements/Form";
import Heading from "./MultiplatformComponents/Elements/Heading";
import SubHeading from "./MultiplatformComponents/Elements/SubHeading";
import InputWrapper from "./MultiplatformComponents/Elements/InputWrapper";
import Input from "./MultiplatformComponents/Elements/Input";
import FacebookButton from "./LoginOptions/SocialLogins/FacebookButton";
import AppleButton from "./LoginOptions/SocialLogins/AppleButton";
import GoogleButton from "./LoginOptions/SocialLogins/GoogleButton";
import ContinueWith from "./MultiplatformComponents/Elements/ContinueWith";
import { ButtonWithIcon } from "../../components/ButtonDefault";
import Button from "./MultiplatformComponents/Elements/Button";

import EmailLoginOption from "./LoginOptions/EmailLoginOption";
import InputInnerButton from "./LoginOptions/InputInnerButton";
import LoginOrSignupUI from "./AuthenticationStepsUI/LoginOrSignupUI";
import { loadScript, rcookie, validateEmail } from "../../../web-shared/utils";
import { notify } from "./MultiplatformComponents/Notification";
import { ThemeProvider } from 'styled-components'
import apiMethod from "../../api/apiMethod";
import { EU_API } from "../../api/config";
import ExternalRegister from "./LoginOptions/SocialLogins/ExternalRegister";
import { RestrictedAccessWarningModal } from "./RestrictedAccessWarning";
import LoginCreatePassword from "./LoginCreatePassword";
import RegisterVerificationCode from "./RegisterVerificationCode";
import LoginVerificationCode from "./LoginVerificationCode";
import Login from "./Login";
import GracePeriodBisiness from "./GracePeriodBisiness";
import ForgotPassModal from "./ForgotPassModal";
import Registration from "./Registration";
import TwoFactorAuthLogin from "./TwoFactorAuthLogin";
import Loader from "../../components/Loader";

import { lightTheme as AndroidLightTheme, darkTheme as AndroidDarkTheme } from './MultiplatformComponents/AndroidStyles';
import { lightTheme as IosLightTheme, darkTheme as IosDarkTheme } from './MultiplatformComponents/iOSStyles';
import { lightTheme as WebLightTheme, darkTheme as WebDarkTheme } from './MultiplatformComponents/WebStyles';
import { lightTheme as WindowsLightTheme, darkTheme as WindowsDarkTheme } from './MultiplatformComponents/WindowsStyles';
import { lightTheme as MacLightTheme, darkTheme as MacDarkTheme } from './MultiplatformComponents/MacStyles';
import { lightTheme as LinuxLightTheme, darkTheme as LinuxDarkTheme } from './MultiplatformComponents/LinuxStyles';

const AUTH_THEME = {
  [DEVICE_OS.Android]: { dark: AndroidDarkTheme, light: AndroidLightTheme },
  [DEVICE_OS.iOS]: { dark: IosDarkTheme, light: IosLightTheme },
  [DEVICE_OS.Web]: { dark: WebDarkTheme, light: WebLightTheme },
  [DEVICE_OS.Win]: { dark: WindowsDarkTheme, light: WindowsLightTheme },
  [DEVICE_OS.MacOSX]: { dark: MacDarkTheme, light: MacLightTheme },
  [DEVICE_OS.Linux]: { dark: LinuxDarkTheme, light: LinuxLightTheme },
}

const LoginOrSignup = (props) => {
  const {
    email: externalemail = "",
    showTitle = true,
    theme = "light",
    subTitle = "",
    isLoginModal = false,
    isWebview = false,
    passpcloud = false,
    os = 4,
    googleLoginAvailable: glLoginAvailableURL = true,
    facebookLoginAvailable: fbLoginAvailableURL = true,
    appleLoginAvailable: appleLoginAvailableURL = true,
    language = "en",
    onSuccess = () => { },
    onPrepareLoginOrSignupFormFinished = () => { },
    skipPrepareLoginOrSignupFormLoading = false,
    showInsideForm = false,
    requestId: requestIdProp = "",
    stateForSocialLoginRedirect = {},
    socialLoginAccessToken = "",
    externalApp: externalAppProp = "",
  } = props;

  const [email, setEmail] = useState(externalemail);
  const [error, setError] = useState("");
  const [formLoading, setFormLoading] = useState(!skipPrepareLoginOrSignupFormLoading);
  const [locationId, setLocationId] = useState(EU_LOCATIONID);
  const [formFields, setFormFields] = useState({
    preferredOption: [],
    secondaryOption: [],
    socialLoginsOptionsGroup: []
  });
  const [flowType, setFlowType] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [authenticationStep, setAuthenticationStep] = useState();
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestID] = useState(requestIdProp);
  const [externalToken, setExternalToken] = useState(socialLoginAccessToken);
  const [externalApp, setExternalApp] = useState(externalAppProp);

  const [token, setToken] = useState("");
  const [hasdevices, setHasdevices] = useState(false);
  const [tfatype, setTfaType] = useState("");
  const [loginWith, setLoginWith] = useState(rcookie("logininfo") || "");
  const [showTFALoginForm, setShowTFALoginForm] = useState(false);
  const [showRestrictionWarning, setShowRestrictionWarning] = useState(false);
  const [verifyToken, setVerifyToken] = useState(false);
  const [showRegisterVerificationCode, setShowRegisterVerificationCode] = useState(false);
  const [showLoginVerificationCode, setShowLoginVerificationCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const [resendVerificationCode, setResendVerificationCode] = useState(false);
  const [rememberme, setRememberMe] = useState(true);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [showGracePeriodModal, setShowGracePeriodModal] = useState(false);
  const [baRestrictedAuth, setBaRestrictedAuth] = useState(false);
  const [gracePeriodActivated, setGracePeriodActivated] = useState(false);
  const [googleLoginAvailable, setGoogleLoginAvailable] = useState(true);
  const [facebookLoginAvailable, setFacebookLoginAvailable] = useState(true);
  const [appleLoginAvailable, setAppleLoginAvailable] = useState(true);
  const [usegrecaptcha, setUsegrecaptcha] = useState(false);

  useEffect(() => {
    const socialLoginsAvailableStatus = getSocialLoginsAvailableStatus();
    setGoogleLoginAvailable(socialLoginsAvailableStatus.googleLoginAvailable);
    setFacebookLoginAvailable(socialLoginsAvailableStatus.facebookLoginAvailable);
    setAppleLoginAvailable(socialLoginsAvailableStatus.appleLoginAvailable);
    setGlobalLocationById(EU_LOCATIONID);
    prepareLogin("", socialLoginsAvailableStatus, skipPrepareLoginOrSignupFormLoading);
  }, []);

  useEffect(() => {
    if (formFields?.preferredOption?.length && formFields?.preferredOption?.includes(SETUP_PASS_LOGIN_METHOD_ID)) {
      setShowRegisterVerificationCode(true);
      setShowLoginVerificationCode(false);
    } else if (formFields?.preferredOption?.length && formFields?.preferredOption?.includes(VERIFICATION_CODE_METHOD_ID)) {
      setShowLoginVerificationCode(true);
      setShowRegisterVerificationCode(false);
    } else {
      setShowRegisterVerificationCode(false);
      setShowLoginVerificationCode(false);
    }
  }, [formFields]);

  const prepareLogin = (
    _email,
    socialLoginsAvailableStatus = {
      googleLoginAvailable,
      appleLoginAvailable,
      facebookLoginAvailable
    },
    skipLoading = false,
    _verificationCode
  ) => {
    if (!skipLoading) {
      setLoading(true);
    }
    
    const params = {
      os,
      language,
      cannotusegooglelogin: !socialLoginsAvailableStatus.googleLoginAvailable,
      cannotuseapplelogin: !socialLoginsAvailableStatus.appleLoginAvailable,
      cannotusefacebooklogin: !socialLoginsAvailableStatus.facebookLoginAvailable,
      getlogins: 1
    };

    if (_email) {
      params.email = _email.trim();
    };

    if (_verificationCode) {
      params.emailtfacode = _verificationCode;
    }

    prepareLoginMethod(params)
      .then(({ result, flowtype, logins, usegrecaptcha: apiUsegrecaptcha }) => {
        if (result === 0) {
          const newLoginOptions = getLoginOptionsArray(logins);

          if (apiUsegrecaptcha) {
            setUsegrecaptcha(true);

            if (typeof grecaptcha === "undefined" ) {
              try {
                loadScript(`https://www.google.com/recaptcha/api.js?hl=${language}&render=6Ld3iconAAAAAMeIhfk_3jTdIPSNX_OcSY6QlvZR`, () => {}, { dontcache: true }); 
              } catch (error) {
                console.error("Error loading recaptcha script:", error);
              }
            } else {
              const recaptchaBadge = document.querySelector(".grecaptcha-badge");
  
              if (recaptchaBadge) {
                recaptchaBadge.style.visibility = "visible";
              }
            }
          }

          if (_email && flowtype) {
            setFlowType(flowtype);
          }

          setFormFields(newLoginOptions);
        }
        setFormLoading(false);
        setLoading(false);
        onPrepareLoginOrSignupFormFinished();
      })
      .catch(({ error, result, location = {} }) => {
        if (result === 2360 || result === 2321) {
          if (location.id) {
            setGlobalLocations([location]);
            setGlobalLocationById(location.id);
            setLocationId(location.id);
            prepareLogin(_email);
            return;
          }
        } else {
          setFormLoading(false);
          setLoading(false);
          showMessage(error, "error");
          onPrepareLoginOrSignupFormFinished();
        }
      });
  }

  const getToken = (method, request_id) => (
    new Promise((resolve, reject) => {
      apiMethod(
        method,
        { request_id },
        ({ access_token }) => {
          resolve({ access_token });
        },
        {
          errorCallback: error => {
            reject(error);
          },
          timeout: 0,
          apiServer: EU_API
        }
      );
    })
  )

  const getRequestId = params => (
    new Promise((resolve, reject) => {
      apiMethod(
        "getrequestid",
        {},
        ({ request_id }) => {
          const requestId = request_id + params;

          setRequestID(requestId);
          resolve({ request_id: requestId });
        },
        {
          errorCallback: error => {
            reject(error);
          },
          apiServer: EU_API
        }
      );
    })
  )
  
  const getSocialLoginsAvailableStatus = () => {
    const socialLoginsAvailableStatus = {
      googleLoginAvailable: true,
      facebookLoginAvailable: true,
      appleLoginAvailable: true
    };

    if (isFacebookBrowser()) {
      socialLoginsAvailableStatus.googleLoginAvailable = false;
      socialLoginsAvailableStatus.facebookLoginAvailable = false;
      socialLoginsAvailableStatus.appleLoginAvailable = false;
    } else {
      if (!glLoginAvailableURL) {
        socialLoginsAvailableStatus.googleLoginAvailable = false;
      }
  
      if (!fbLoginAvailableURL) {
        socialLoginsAvailableStatus.facebookLoginAvailable = false;
      }
  
      if (!appleLoginAvailableURL) {
        socialLoginsAvailableStatus.appleLoginAvailable = false;
      }
    }

    return socialLoginsAvailableStatus;
  }

  const setTFALoginInfo = (token, hasdevices, tfatype, logininfo) => {
    setToken(token);
    setHasdevices(hasdevices);
    setTfaType(tfatype);
    setLoginWith(logininfo || "")
    setShowTFALoginForm(true);
    setVerificationCode("");
    setShowRegisterVerificationCode(false);
    setShowLoginVerificationCode(false);
    setShowCreatePassword(false);
    setResendVerificationCode(false);
    setLocationId(EU_LOCATIONID);
    setExternalToken("");
  };

  const setVerifyInfo = (token, email) => {
    setShowRestrictionWarning(true);
    setVerifyToken(token);
    setEmail(email);
  };

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message);
    } else {
      notify(message, type, { os });
    }
  }

  const onEmailChange = ({ target }) => {
    setEmail(target.value);
  }

  const onEmailChangeClick = (e) => {
    e.preventDefault();
    setFlowType(null);
    setFormFields({
      preferredOption: [],
      secondaryOption: [],
      socialLoginsOptionsGroup: []
    });
    setLoading(false);
    setFormLoading(true);
    prepareLogin("");
    setShowCreatePassword(false);
    setShowRegisterVerificationCode(false);
    // reset location id if user from the previous login try is registered in US,
    setGlobalLocationById(EU_LOCATIONID);
    setVerificationCode("");
  }

  const onSendCodeClick = () => {
    setVerificationCode("");
    setShowCreatePassword(false);
    setShowRegisterVerificationCode(true);
    setResendVerificationCode(true);
  }

  const onForgotPasswordClick = () => {
    setShowForgotPass(true);
  }

  const onBackForgotPassClick = () => {
    setShowForgotPass(false);
  }

  const onSocialLoginCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExternalToken("");
    setExternalApp("");
    setError("");
    setGlobalLocationById(EU_LOCATIONID);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (loading && !email) {
      return;
    };

    if (!validateEmail(email)) {
      showMessage(__("invalid_email", "Invalid Email"), "error");
      return;
    }

    prepareLogin(email);
  }

  const onShowGraceModal = auth => {
    setShowGracePeriodModal(true);
    setBaRestrictedAuth(auth);
  }

  const onShowVerificationCode = (show, email, code) => {
    setShowRegisterVerificationCode(show);
    setExternalToken("");
    if (email) {
      setEmail(email);
    }

    if (code) {
      setVerificationCode(code)
    }
  }

  const onCodeVerifaction = (_verificationCode) => {
    const socialLoginsAvailableStatus = getSocialLoginsAvailableStatus();
    setVerificationCode(_verificationCode);
    prepareLogin(email, socialLoginsAvailableStatus, false, _verificationCode);
  }

  const onGracePeriodActivated = () => {
    setGracePeriodActivated(true);
  }

  const onTokenReceive = (token, app) => {
    setExternalToken(token);
    setExternalApp(app);
  }

  const closeGraceModal = () => {
    setShowGracePeriodModal(false);

    if (gracePeriodActivated) {
      //retry login
      document.dispatchEvent(new Event("grace-period-activated"));
    }
  }

  const closeWarning = () => {
    setShowRestrictionWarning(false);
    setExternalToken("");
    setExternalApp("");
  }

  const resetView = () => {
    setEmail("");
    setVerificationCode("");
    setShowRegisterVerificationCode(false);
    showLoginVerificationCode(false);
    setShowCreatePassword(false);
  }

  const renderLoginFlow = () => {
    if (showInsideForm && showGracePeriodModal) {
      return (
        <GracePeriodBisiness
          os={os}
          isModal={showInsideForm}
          onClose={closeGraceModal}
          open={showGracePeriodModal}
          auth={baRestrictedAuth}
          gracePeriodActivated={onGracePeriodActivated}
        />
      );
    }

    return (
      <React.Fragment>
        <Login
          {...props}
          email={email}
          locationId={locationId}
          verificationCode={verificationCode}
          setShowRegisterVerificationCode={onShowVerificationCode}
          showForgotPass={onForgotPasswordClick}
          setTFALoginInfo={setTFALoginInfo}
          setVerifyInfo={setVerifyInfo}
          setRememberMe={setRememberMe}
          rememberme={rememberme}
          setLocationId={setLocationId}
          onSuccess={onSuccess}
          showGracePeriodModal={showGracePeriodModal}
          showInsideForm={showInsideForm}
          showGracePeriodModalCb={onShowGraceModal}
          formFields={formFields}
          onEmailChangeClick={onEmailChangeClick}
          receiveToken={onTokenReceive}
          getRequestId={getRequestId}
          requestId={requestId}
          getToken={getToken}
          themeMode={theme}
          usegrecaptcha={usegrecaptcha}
          language={language}
          state={stateForSocialLoginRedirect}
        />

        {!showInsideForm ? (
          <GracePeriodBisiness
            os={os}
            isModal={showInsideForm}
            onClose={closeGraceModal}
            auth={baRestrictedAuth}
            open={showGracePeriodModal}
            gracePeriodActivated={onGracePeriodActivated}
          />
        ) : null}
      </React.Fragment>
    );
  }

  if (externalToken) {
    return (
      <ThemeProvider theme={AUTH_THEME[os][theme]}>
        <ExternalRegister
          {...props}
          os={os}
          passpcloud={passpcloud}
          language={language}
          token={externalToken}
          externalApp={externalApp}
          onSuccess={onSuccess}
          theme={theme}
          setTFALoginInfo={setTFALoginInfo}
          setVerifyInfo={setVerifyInfo}
          setShowRegisterVerificationCode={onShowVerificationCode}
          onBackClick={onSocialLoginCancelClick}
          setEmail={setEmail}
          email={email}
          error={error}
          setError={setError}
        />
      </ThemeProvider>
    )
  }

  if (showTFALoginForm) {
    return (
      <ThemeProvider theme={AUTH_THEME[os][theme]}>
        <TwoFactorAuthLogin
          {...props}
          hasLoggedDevices={hasdevices}
          token={token}
          tfatype={tfatype}
          rememberme={rememberme}
          logininfo={loginWith}
          email={email}
          theme={theme}
        />
      </ThemeProvider>
    )
  }

  if (isLoginModal && showRestrictionWarning) {
    return <RestrictedAccessWarning verifyToken={verifyToken} email={email} />;
  }

  if (showRegisterVerificationCode) {
    return (
      <ThemeProvider theme={AUTH_THEME[os][theme]}>
        <Wrapper os={os} className="responsive-wrap">
          <RegisterVerificationCode
            os={os}
            email={email}
            language={language}
            resend={resendVerificationCode}
            setShowCreatePassword={setShowCreatePassword}
            setShowRegisterVerificationCode={onShowVerificationCode}
            setLocationId={setLocationId}
          />
        </Wrapper>
      </ThemeProvider>
    );
  }

  if (showLoginVerificationCode) {
    return (
      <ThemeProvider theme={AUTH_THEME[os][theme]}>
        <Wrapper os={os} className="responsive-wrap">
          <LoginVerificationCode
            os={os}
            email={email}
            language={language}
            resend={resendVerificationCode}
            setShowRegisterVerificationCode={onShowVerificationCode}
            setLocationId={setLocationId}
            onCodeVerifaction={onCodeVerifaction}
          />
        </Wrapper>
      </ThemeProvider>
    );
  }
  
  if (showCreatePassword) {
    return (
      <ThemeProvider theme={AUTH_THEME[os][theme]}>
        <Wrapper os={os} className="responsive-wrap">
          <LoginCreatePassword
            {...props}
            locationId={locationId}
            email={email}
            isWebview={isWebview}
            verificationCode={verificationCode}
            resetView={resetView}
            onSendCodeClick={onSendCodeClick}
            onEmailChangeClick={onEmailChangeClick}
            themeMode={theme}
          />
        </Wrapper>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={AUTH_THEME[os][theme]}>
      <React.Fragment>
        <RestrictedAccessWarningModal
          verifyToken={verifyToken}
          email={email}
          show={showRestrictionWarning}
          onClose={closeWarning}
          os={os}
        />

        <Loader os={os} show={formLoading}/>

        <ForgotPassModal
          os={os}
          open={showForgotPass}
          passpcloud={passpcloud}
          onClose={onBackForgotPassClick}
        />
      
        {(flowType === REGISTER_FLOW_TYPE && email) &&
          <Registration
            {...props}
            os={os}
            email={email}
            onEmailChangeClick={onEmailChangeClick}
            formFields={formFields}
            receiveToken={onTokenReceive}
            getRequestId={getRequestId}
            requestId={requestId}
            getToken={getToken}
            themeMode={theme}
            usegrecaptcha={usegrecaptcha}
            language={language}
            state={stateForSocialLoginRedirect}
          />
        }
        {(flowType === LOGIN_FLOW_TYPE && email) &&
          <Wrapper os={os} className="responsive-wrap">
            {renderLoginFlow()}
          </Wrapper>
        }
        {(flowType !== REGISTER_FLOW_TYPE && flowType !== LOGIN_FLOW_TYPE) &&
          <LoginOrSignupUI
            {...props}
            os={os}
            email={email}
            error={error}
            onEmailChange={onEmailChange}
            onSubmit={onFormSubmit}
            formFields={formFields}
            receiveToken={onTokenReceive}
            getRequestId={getRequestId}
            requestId={requestId}
            getToken={getToken}
            loading={loading}
            language={language}
            state={stateForSocialLoginRedirect}
          />}
      </React.Fragment>
    </ThemeProvider>
  )
};

export default LoginOrSignup;
