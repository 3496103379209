import React from "react";

import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import { __ } from "../../../../web-shared/utils";
import InputWrapper from "../MultiplatformComponents/Elements/InputWrapper";
import Input from "../MultiplatformComponents/Elements/Input";

const PasswordOption = ({
  os = DEVICE_OS.Web,
  error = false,
  placeholder = __("form_email", "Email"),
  value = "",
  onChange = () => {},
  children,
  ...rest
}) => (
  <InputWrapper os={os} error={error}>
    <Input
      os={os}
      type="password"
      name="password"
      autoComplete="password"
      autocapitalize="off"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      {...rest}
    />
    { children }
  </InputWrapper>
);

export default PasswordOption;
