import { obLength } from './utils';
import apiMethod from '../api/apiMethod';

const langCookie = 'lang';
const cookieRememberDays = 30;

// default
let language = 'en';
let	languages = [
	{ val: 'English', 		title: 'English', 	code: 'en', rtl: false },
	{ val: 'Bulgarian', 	title: 'Български', code: 'bg', rtl: false },
	{ val: 'Spanish', 		title: 'Еspañol', 	code: 'es', rtl: false },
	{ val: 'Russian', 		title: 'Русский', 	code: 'ru', rtl: false },
	{ val: 'Portuguese', 	title: 'Português', code: 'pt', rtl: false },
	{ val: 'French', 		title: 'Français', 	code: 'fr', rtl: false },
	{ val: 'Italian', 		title: 'Italiano', 	code: 'it', rtl: false },
	{ val: 'German', 		title: 'Deutsch', 	code: 'de', rtl: false },
	{ val: 'Tr. Chinese', 	title: '繁體中文', 	code: 'zh', rtl: false },
	{ val: 'Turkish', 		title: 'Тürkçe', 	code: 'tr', rtl: false },
	{ val: 'Dutch', 		title: 'Nederlands', 	code: 'nl', rtl: false },
	{ val: 'Japanese', 		title: '日本語', 	code: 'ja', rtl: false }
	//{ val: 'Persian', 		title: 'فارسی', 	code: 'fa', rtl: true }
];

let passLanguages = [
  { val: 'English', title: 'English', code: 'en', rtl: false },
  { val: 'Bulgarian', 	title: 'Български', code: 'bg', rtl: false },
  { val: 'Spanish', title: 'Еspañol', code: 'es', rtl: false },
  { val: 'Russian', 		title: 'Русский', 	code: 'ru', rtl: false },
  { val: 'Portuguese', 	title: 'Português', code: 'pt', rtl: false },
  { val: 'French', title: 'Français', code: 'fr', rtl: false },
  { val: 'Italian', 		title: 'Italiano', 	code: 'it', rtl: false },
  { val: 'German', title: 'Deutsch', code: 'de', rtl: false },
  { val: 'Tr. Chinese', 	title: '繁體中文', 	code: 'zh', rtl: false },
  { val: 'Turkish', 		title: 'Тürkçe', 	code: 'tr', rtl: false },
  { val: 'Dutch', 		title: 'Nederlands', 	code: 'nl', rtl: false },
  { val: 'Japanese', 		title: '日本語', 	code: 'ja', rtl: false },
];

let _onChangeLanguage = function () { };
let notTranslatedStrings = {};
let usedTranslatedStrings = [];

function __(key, val, repl) {
	var currentLang = this._language;

	console.log('hello');

	if (DEBUG && typeof val == 'object' && val != null) {
    console.error('key ' + key + ' needs to be fixed, val: ' + val);
  }

	key += ""; // tostring
	key = key.trim();

	_usedString(key);

	if (lang != undefined && currentLang in lang && key in lang[currentLang]) {
		return _repl(lang[currentLang][key], repl);
	} else {
  	_notTranslated(key, val);
		return this._repl(val || key, repl);
	}
}

function _repl(str, repl) {
  // label replace
  if (HFN && HFN.config && HFN.config.label && HFN.config.label.translationReplace) {
    for (var i in HFN.config.label.translationReplace)
      str = str.replace(new RegExp(i, 'g'), HFN.config.label.translationReplace[i]);
  }

  str = _properSpaces(str);

  if (!obLength(repl)) {
    return str;
  }

  for (var n in repl) {
    str = str.replace(new RegExp('\%' + n + '\%', 'g'), repl[n]);
  }

  return str;
}

function _usedString(key) {
	if (usedTranslatedStrings.indexOf(key) === -1) {
		usedTranslatedStrings.push(key);
	}

	_triggerUsed();
}

let usedTimeout = null
function _triggerUsed() {
	if (usedTimeout) {
		clearTimeout(usedTimeout);
		usedTimeout = null;
	}

	console.log("sup");

	usedTimeout = setTimeout(_sendUsedString, 1000);
}

function _sendUsedString() {
	console.log("sending strings", usedTranslatedStrings);
	HFN.apiMethod("getip", {}, function(ret) {
		console.log(ret);
	});
}

function _notTranslated(key, val) {
  if (val && val != key) {
    notTranslatedStrings[key] = val;
    // this._triggerNotTranslated();
  }
}

// let translateTimeout = null;
// function _triggerNotTranslated() {
//   if (translateTimeout) {
//     clearTimeout(translateTimeout);
//     translateTimeout = null;
//   }

//   translateTimeout = setTimeout(_sendToTranslate.bind(this), 1000);
// }

// function _sendToTranslate() {
//   //$.post('https://translate-test.pcloud.com/api', {
//   $.post('https://translate.pcloud.com/api', {
//     user_lang: 1,
//     data: JSON.stringify(notTranslatedStrings)
//   }, function () {});

//   notTranslatedStrings = {};
// }

// Helpers

function _properSpaces(str) {
  return str.split(String.fromCharCode(160)).join(" ");
}

function init(opts) {
  if (opts.language) {
    _setLang(opts.language);
  }

  if (opts.onChangeLanguage) {
    _onChangeLanguage = opts.onChangeLanguage;
  }
}

export function getLanguages(isPass) {
  if (isPass) {
    return passLanguages;
  } else {
    return languages;
  }
}

function getSortedLanguages() {
  return [getLangObj()].concat(languages.filter((lang) => lang.code != language));
}

function getLanguagesHash() {
  return languages.map((lang) => {
    var obj = {};
    obj[lang.code] = lang.title;
    return obj;
  });
};

function getLanguageCodes() {
  return languages.map((lang) => {
    return lang.code;
  });
}

function _setLang(lang) {
  if (getLanguageCodes().indexOf(lang) != -1) {
    language = lang;
    return true;
  }

  return false;
}

function setLang(lang) {
  _setLang(lang);
}

function updateLang(lang) {
  if (_setLang(lang)) {
    _onChangeLanguage();
  }
}

function receivedLang(lang) {
  if (lang != getLang()) {
    setLang(lang);
  }
}

function getLang() {
  return language;
}

function getLangObj() {
  return languages.filter((lang) => {
    return lang.code == language;
  })[0];
}

function setUserLang(lang) {
  if (HFN.config.auth) {
    HFN.apiMethod('setlanguage', { language: lang }, function() {}, { errorCallback: function() {}});
  }

  setcookie(langCookie, lang, this._cookieRememberDays);

  updateLang(lang);
}

function getCookieLang() {
  if (rcookie(_langCookie))
    return rcookie(_langCookie);
  return null;
}

function getBrowserLang() {
  var langs = getLanguageCodes();
  var code;

  if (typeof navigator.languages == 'undefined') {
      return 'en';
  }

  for (var n = 0; n < navigator.languages.length; ++n) {
      code = navigator.languages[n].match(/^[a-z]{2}/i)[0].toLowerCase();

      if (getLanguageCodes().indexOf(code) != -1) {
        return code;
      }
  }

  return 'en';
};

export default { setUserLang, getCookieLang, getLanguages, getBrowserLang }
