// @flow

import * as React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import retina from "../lib/css/retina";
import { TSpan } from "../components/intl";

type OpenInAppProps = {
  onOpen: () => void,
  onClose: () => void
};

const OpenInApp = ({ onOpen, onClose }: OpenInAppProps) =>
  <Wrap>
    <Open onClick={onOpen}><TSpan id="open_in_app">Open in app</TSpan></Open>
    <Close onClick={onClose} />
  </Wrap>;

const Wrap = styled.div`
  display: block;
  background: #17bed0;
  border-radius: 23px;
  position: relative;
  padding: 0 40px 0 0;
  width: calc(100% - 40px);
`;

const Open = styled.a`
  display: inline-block;
  color: #fff;
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  margin: 0 15px 0 15px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  ${retina(
    require("../../root/img/openinapp/close.png"),
    require("../../root/img/openinapp/close@2x.png"),
    "14px 14px"
  )};
`;

export default OpenInApp;
