import apiMethod from "../api/apiMethod";
import { onDocumentReady } from "../lib/utils";

const subscriptions = {
  xhr: null,
  chkint: null,
  resetTo: null,
  resetTimeoutMilisec: 10000,
  subscribers: [],

  add: function (subscriber, instance) {
    console.log("Adding new subscriber: ", subscriber, instance);
    this.remove(subscriber);
    this.checkInstanceInterface(instance);

    var subs = { name: subscriber, state: 'preparing', instance: instance };
    this.subscribers.push(subs);
    return this;
  },

  checkInstanceInterface: function (instance) {
    var requiredMethods = ['getParams', 'handleEvents'];
    requiredMethods.forEach(function (method) {
      if (typeof instance[method] !== 'function') {
        throw Error("Instance must provide method: ", method);
      }
    });
  },

  remove: function (subscriber) {
    console.log("removing subscriber", subscriber);

    var subs = this.subscribers;
    this.subscribers = subs.filter(function (sub) { return sub.name !== subscriber; });

    if (this.subscribers.length === 0) {
      console.log("zero subscribers");
      this.abort();
    }
  },

  setState: function (subscriber, state) {
    var subs = this._getSubscriber(subscriber);

    if (subs === undefined) {
      console.log(subscriber);
      //console.trace();
    }

    subs.state = state;

    this.listen();
    //setTimeout(this.listen.bind(this), 0);

    return this;
  },

  _getSubscriber: function (subscriber) {
    return this.subscribers.filter(function (sub) { return sub.name == subscriber; })[0];
  },

  _getReady: function () {
    return this.subscribers.filter(function (sub) { return sub.state == 'ready'; });
  },

  reset: function() {
    this.abort();
    this.subscribers = [];
  },

  stop: function () {
    this.abort();
  },

  resume: function () {
    this.listen();
  },

  listen: function () {
    var self = this;
    var params = {};
    var subscribefor = [];
    var subsrdy = this._getReady();

    if (this.isRunning()) {
      this.abort();
    }
    if (subsrdy.length == 0) {
      return;
    }

    // waiting all subscribers to get ready
    if (subsrdy.length != this.subscribers.length) {
      return;
    }

    subsrdy.forEach(function (sub) {
      subscribefor.push(sub.name);
      params = $.extend(params, sub.instance.getParams());
    });

    params.subscribefor = subscribefor.join(',');
    params._t = $.now;

    console.log(subsrdy);
    console.log("running", params);

    onDocumentReady(() => {
      this.xhr = apiMethod('subscribe', params, function (ret) {
        if (self.chkint) {
          clearInterval(self.chkint);
        }

        console.log("receiving update: ", ret);

        self.setState(ret.from, 'processing');
        var sub = self._getSubscriber(ret.from);
        if (sub) {
          sub.instance.handleEvents(ret);
        }

        if (self.restartTo) {
          clearTimeout(self.restartTo);
          self.restartTo = null;
        }
        if (!sub) {
          self.restartTo = setTimeout(self.listen.bind(self), 100);
        }
      }, {
        timeout: 0,
        errorCallback: function(ret) {
          console.log("Subscription API Error", ret);

          self.abort();
        },
        onXhrError: function(xhr, status, error) {
          console.log('subscribe rejected: ', arguments, xhr.status);

          if (self.resetTo)
          clearTimeout(self.resetTo);

          self.resetTo = setTimeout(self.listen.bind(self), self.resetTimeoutMilisec);
          self.resetTimeoutMilisec += Math.round(10 / 100 * self.resetTimeoutMilisec);
        }
      });

      this.setCheck();
    });
  },
  setCheck: function () {
    var self = this;

    if (this.chkint) {
      clearInterval(this.chkint);
      this.chkint = null;
    }

    this.chkint = setInterval(function () {
      var xhr = self.xhr;
      if (xhr && xhr.readyState > 0 && xhr.readyState < 4) {
        console.log('checked subscriptions, all good');
      } else if (xhr) {
        console.log('subscriptions: ', xhr.readyState)
        self.listen();
      }
    }, 3000);
  },
  isRunning: function () {
    return (this.xhr && this.xhr.readyState > 0 && this.xhr.readyState < 4);
  },
  abort: function () {
    if (this.xhr && typeof this.xhr.abort == 'function') {
      console.log('Aborting xhr...');
      try {
        this.xhr.abort();
      } catch(e) {};
      this.xhr = null;
      console.log('Done aborting');
    }

    if (this.restartTo) { // this restarts the listen after successfull listen
      clearTimeout(this.restartTo);
      this.restartTo = null;
    }
    if (this.resetTo) { // this resets the listen after killed/aborted listen
      clearTimeout(this.resetTo);
      this.resetTo = null;
    }
    if (this.chkint) { // this checks diff from time to time using interval (mainly for waking apps)
      clearInterval(this.chkint);
      this.chkint = null;
    }
  }
}

export default subscriptions;
