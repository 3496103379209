//  @flow
import {
    ONBOARDING_ADD_ITEM,
    ONBOARDING_REMOVE_ITEM
} from "../constants/onboarding";

export type OnboardingAction =
  | AddItem
  | RemoveItem

type AddItem = { type: typeof ONBOARDING_ADD_ITEM, song: any, id: any };
type RemoveItem = { type: typeof ONBOARDING_REMOVE_ITEM, value: any };


export function addItem(id: any): AddItem {
  return { type: ONBOARDING_ADD_ITEM, id: id };
}

export function removeItem(id: any): RemoveItem {
  return { type: ONBOARDING_REMOVE_ITEM, id: id };
}

export const onboardingActionCreators = {
  addItem,
  removeItem,
};
