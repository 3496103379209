// @flow
import React, { useState, useEffect } from "react";

type TimerProps = {
  timeExpire: string | number,
  textColor: string,
  timerColor: string,
  showLabels?: boolean,
  timeExpireCallback: () => void,
  size: "small" | "big" | "huge",
  translateLabels?: boolean,
  renderTimer: ({
    seconds: string,
    minutes: string,
    hours: string,
    textColor: string,
    timerColor: string,
    showLabels?: boolean,
    size: "small" | "big" | "huge"
  }) => void
};

const TimerContainer = ({
  timeExpire,
  timeExpireCallback,
  renderTimer,
  textColor,
  timerColor,
  showLabels,
  size,
  translateLabels
}: TimerProps) => {
  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [hours, setHours] = useState("00");

  const timeExp = new Date(timeExpire).getTime();
  let timeNow = new Date().getTime();
  let timeBetween = timeExp - timeNow;

  (seconds: string);
  (minutes: string);
  (hours: string);

  const formatTimeXX = (time: number) => {
    const timeStr = String(time);
    return timeStr.length === 1 ? "0" + timeStr : timeStr;
  };

  const countDown = (timeBetween: number) => {
    const newHours = Math.floor(timeBetween / (1000 * 60 * 60));
    const newMinutes = Math.floor(
      (timeBetween % (1000 * 60 * 60)) / (1000 * 60)
    );
    const neSeconds = Math.floor((timeBetween % (1000 * 60)) / 1000);

    setSeconds(formatTimeXX(neSeconds));
    setMinutes(formatTimeXX(newMinutes));
    setHours(formatTimeXX(newHours));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeNow >= timeExp) {
        timeExpireCallback();
        clearInterval(timer);
      } else {
        timeBetween = timeExp - timeNow;
        countDown(timeBetween);
        timeNow = new Date().getTime();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return renderTimer({
    seconds,
    minutes,
    hours,
    textColor,
    timerColor,
    showLabels,
    size,
    translateLabels
  });
};

TimerContainer.defaultProps = {
  timeExpire: new Date(Date.UTC(0, 0, 0, 0, 0, 0)),
  textColor: "fff",
  timerColor: "17bed0",
  showLabels: false,
  timeExpireCallback: () => {},
  renderTimer: () => null,
  translateLabels: true
};

export default TimerContainer;
