import React from "react";
import { ErrorMessage as WebErrorMessage } from "../WebStyles";
// import { ErrorMessage as WindowsErrorMessage } from "../WindowsStyles";
// import { ErrorMessage as MacErrorMessage } from "../MacStyles";
// import { ErrorMessage as LinuxErrorMessage } from "../LinuxStyles";
import { ErrorMessage as IosErrorMessage } from "../iOSStyles";
import { ErrorMessage as AndroidErrorMessage } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ErrorMessage = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidErrorMessage {...rest}/>
    case DEVICE_OS.iOS:
      return <IosErrorMessage {...rest}/>
    case DEVICE_OS.Web:
      return <WebErrorMessage {...rest}/>
    // case DEVICE_OS.Win:
    //   return <WindowsErrorMessage {...rest}/>
    // case DEVICE_OS.MacOSX:
    //   return <MacErrorMessage {...rest}/>
    // case DEVICE_OS.Linux:
    //   return <LinuxErrorMessage {...rest}/>
    default:
      return <WebErrorMessage {...rest}/>
  }
};

export default ErrorMessage;
