// @flow

import * as firebase from "firebase/app";
import "firebase/messaging";

import apiMethod from "../../api/apiMethod";
import type { Notification, PushNotification } from "./types";
import config from "./config";
import { getDeviceID } from "../utils";


let initialized = false;
let messaging;

const saveToken = (authToken: ?string) => (pushToken: string, onSubscribe: () => void) =>
  apiMethod(
    "subscribeforpush",
    {
      auth: authToken,
      pushtoken: pushToken,
      os: 4,
      deviceid: getDeviceID(),
      osversion: "0.0.0"
    },
    () => {
      onSubscribe();
      console.log("User Subscribed.");
    }
  );

function init(authToken: ?string, cb: Function): void {
  if (!isSupported()) {
    return;
  }

  if (initialized) {
    cb(messaging);
  } else {
    firebase.initializeApp(config);
    messaging = firebase.messaging();

    messaging.onTokenRefresh(() => {
      console.log("Refreshed token.");
      messaging.getToken().then(saveToken(authToken));
    });

    if ("serviceWorker" in navigator && navigator.serviceWorker) {
      navigator.serviceWorker.register("/js/workers/fcm.js").then(registration => {
        registration.update();
        messaging.useServiceWorker(registration);

        initialized = true;

        cb(messaging);
      });

      /*
      const promiseLog = (name: string) => (t1: any) => { console.log(name, t1); return t1; };
      setTimeout(() => {
        console.clear();

        messaging.onTokenRefresh(() => { console.log("new token"); });

        messaging.getToken()
          .then(promiseLog("Token"))
          .then(messaging.deleteToken.bind(messaging))
          .then(messaging.getToken.bind(messaging))
          .then(promiseLog("New Token"));
      }, 500);
      */
    }
  }
}

//a

export function isSupported() {
  return "serviceWorker" in navigator && navigator.serviceWorker;
}

export function getToken(authToken: ?string, cb: (token: string) => void, onBlocked?: () => void) {
  init(authToken, messaging => {
    messaging
      .getToken()
      .then(token => cb(token))
      .catch(err => {
        if (err.code === "messaging/notifications-blocked") {
          if (onBlocked) {
            onBlocked();
          }
        }

        console.log("error", err);
      });
  });
}

export function requestToken(authToken: ?string, cb: (token: string) => void) {
  init(authToken, () => {
    messaging.getToken().then(token => {
      if (token) {
        cb(token);
      } else {
        messaging
          .requestPermission()
          .then(() => {
            messaging.getToken().then(cb);
          })
          .catch(error => {
            console.log("No token for us", error);
          });
      }
    });
  });
}

export function listen(authToken: ?string, onMessage: (notification: PushNotification) => void) {
  if (!isSupported()) {
    console.log("device not supported");
    return;
  }

  init(authToken, messaging => {
    messaging.onMessage(onMessage);
    console.log("listening");
  });
}

export function subscribe(
  authToken: ?string,
  onMessage: (notification: PushNotification) => void,
  onSubscribe: () => void = () => {}
) {
  if (!isSupported()) {
    console.log("device not supported");
    return;
  }

  console.log("onSubscribe", onSubscribe)

  init(authToken, messaging => {
    messaging.onMessage(onMessage);

    requestToken(authToken, pushToken => {
      saveToken(authToken)(pushToken, onSubscribe);
    });
  });
}

