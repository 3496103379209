import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

const shake = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;

const spin = keyframes`
0%{
  transform: rotate(0deg)
}

100% {
  transform: rotate(360deg);
}
`;

export const WrapperFolderInvite = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  margin-bottom: 26px;
  font-size: 23px;
  font-weight: bold;
  line-height: normal;
  width: 360px;
  text-align: left;
  color: #000000;

  &.modal {
    margin: 30px 0;
  }

  &.before-continue {
    margin: 30px auto;
    text-align: center;
  }

  @media (max-width: 500px) {
    margin: 20px 0 10px 0;
    padding: 0 10px;
    box-sizing: border-box;
  }
`;

export const FormTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: normal;
  width: 360px;
  text-align: left;
  color: #000000;
`;


export const Message = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 360px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px #a2e5ec;
  background-color: #f7fdfe;
  font-size: 13px;
  line-height: 1.2;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  word-break: break-word;
  margin-bottom: 15px;

  svg {
    margin-right: 20px;
    flex-shrink: 0
  }

  & > span {
    display: block;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  &.modal {
    margin: 0 40px 30px;
    border-radius: 0;
  }

  @media (max-width: 768px) {
    padding: 20px;
    &.modal {
      margin: 0 10px 30px;
    }
  }

  @media (max-width: 500px) {
    margin: 10px;
  }
`;

export const WarningMessage = styled(Message)`
  border: solid 1px #fcf0cd;
  background-color: #fff8df;
`;

export const Description = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.23;
  text-align: center;
  margin-top: 35px;
`;

export const FamilyIcon = styled.div`
  display: block;
  min-width: 64px;
  min-height: 64px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/family.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/family@2x.png")});
  }
`;

export const BusinessIcon = styled(FamilyIcon)`
  min-width: 64px;
  min-height: 64px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/business.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/business@2x.png")});
  }
`;

export const FolderInviteIcon = styled(FamilyIcon)`
  min-width: 64px;
  min-height: 64px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/invite-to.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/invite-to@2x.png")});
  }
`;

export const WarnIcon = styled(FamilyIcon)`
  min-width: 64px;
  min-height: 64px;
  margin-bottom: 0px;
  background-image: url(${require("../../../root/img/data-location/attention.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/attention@2x.png")});
  }

  &.modal {
    margin: 0;
  }
`;

export const ButtonContainerInline = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  a {
    width: 40%;
    max-width: 180px;
    display: inline-block;
    margin: 0px 5px;
    color: #fff;
    text-decoration: none;
  }

  &.modal {
    margin: 0 0 30px 0;
  }
`;

export const Link = styled.div`
  max-width: 640px;
  margin: 25px auto;
  font-size: 13px;
  line-height: 1.23;
  text-decoration: underline;
  text-align: center;
  color: #17bed0;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline-block;
  width: 50%;
  height: 50%;
  background-color: rgb(255, 255, 255);
  z-index: 1;

  div {
    display: inline-block;
    height: 48px;
    width: 48px;
    background-image: url(${require("../../../root/img/loading_blue.png")});
    background-position: center;
    background-size: 48px 48px;
    position: relative;
    top: 50%;
    margin-top: -24px;
    left: 50%;
    margin-left: -24px;
    animation-name: ${spin};
    animation-duration: 500ms;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  .login-title, .register-title, .invite-form {
    font-size: 13px;
  }

  form {
    margin-top: 15px;
  }

  .responsive-wrap {
    width: 450px;
  }

  &.business-invitation-reg-wrapper {
    .input-pass-wrapper {
      margin-bottom: 15px;

      input.input-pass {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 500px) {
    background-color: #fafafa;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding: 0 10px 30px;

    .responsive-wrap {
      width: inherit;
      margin: 0;
    }
  }
`;

export const ContainerCol = styled.div`
  align-items: center;
`;

export const ContainerColIE = styled.div`
  align-items: center;
`;

export const ButtonBlue = styled.div`
  display: inline-block;
  min-width: 100px;
  max-width: 140px;
  margin: 0px 5px;
  background-color: #17bed0;
  padding: 0 20px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  border-radius: 5px;
  height: 40px;
  color: #fff;
`;

export const ButtonGrey = styled(ButtonBlue)`
  background-color: #999999;
`;

export const ComponentifyWrapper = styled.div`
  width: 100%;

  &.modal {
    margin-top: 20px;
    text-align: center;
  }

  a {
    color: #17bed0;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 26px;
  height: 26px;
  margin: 12px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${require("../../../root/img/2fa-login/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;
