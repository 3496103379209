import React from "react";
import WebCheckBox from "../../../../components/InputStyledCheckbox";
import WindowsCheckBox from "./WindowsCheckBox";
import MacCheckBox from "./MacCheckBox";
import LinuxCheckBox from "./LinuxCheckBox";
import IosCheckBox from "./IosCheckBox";
import AndroidCheckBox from "./AndroidCheckBox";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const CheckBox = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidCheckBox {...rest}/>
    case DEVICE_OS.iOS:
      return <IosCheckBox {...rest}/>
    case DEVICE_OS.Web:
      return <WebCheckBox {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsCheckBox {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacCheckBox {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxCheckBox {...rest}/>
    default:
      return <WebCheckBox {...rest}/>
  }
};

export default CheckBox;
