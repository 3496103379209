import React from 'react';
import Label from './Label';

const LabeledText = (props) => {
  const { name, value, ...rest } = props;

  return (
    <Label {...props}>
      <input type="text" name={name} value={value} {...rest} />
    </Label>
  );
}

export default LabeledText;
