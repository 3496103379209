// @flow
import React, { Component } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

import FB_Login from "../../../lib/fb_login"; // DONE
import { EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants"; // DONE
import { __ } from "../../../lib/translate"; // DONE
import apiMethod from "../../../api/apiMethod"; // DONE
import Popup from "../../../lib/Popup"; // DONE

import SocialButton from "../MultiplatformComponents/Elements/SocialButton"; // DONE
import FacebookLogo from "../../../../root/img/svg/signin-with-facebook.svg"; // DONE

type Props = {
  os: number,
  size?: "small" | "big",
  receiveToken: (string, string) => void,
  getRequestId: () => void,
  getToken: () => void,
  redirect_uris: object,
  passpcloud: boolean,
  openUnavailableModal: () => void,
};

class FacebookButton extends Component<Props, {}> {
  static defaultProps = {
    os: 4,
    size: "small",
    passpcloud: false,
    openUnavailableModal: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onButtonClick = this.onButtonClick.bind(this);
    (this: any).receiveTokenCb = this.receiveTokenCb.bind(this);
  }

  onButtonClick() {
    // const { openUnavailableModal } = this.props;
    const { redirect_uris, getRequestId, getToken, receiveToken, passpcloud } = this.props;
    console.log("🚀 ~ file: FacebookButton.js ~ line 35 ~ FacebookButton ~ onButtonClick ~ this.props", this.props)
    console.log("🚀 ~ file: FacebookButton.js ~ line 35 ~ FacebookButton ~ onButtonClick ~ this.props TEST")

    if (redirect_uris) {
      let params = "";
      if (redirect_uris) {
        params = "is_extension";
      }
      if (passpcloud) {
        params = "passpcloud";
      }
      getRequestId(params)
        .then(({ request_id }) => {
          FB_Login.initGetToken(redirect_uris, request_id, passpcloud);
          getToken('fb_oauth2_token')
            .then(({ access_token }) => FB_Login.getTokenCallback(receiveToken(access_token, EXTERNAL_APPS.FACEBOOK), redirect_uris, request_id, passpcloud));
        });
    } else {
      FB_Login.getTokenCallback(this.receiveTokenCb);
    }
    // openUnavailableModal();
  }

  receiveTokenCb(token: string) {
    const { receiveToken } = this.props;

    receiveToken(token, EXTERNAL_APPS.FACEBOOK);
  }

  render() {
    const { size, os } = this.props;

    if (size === "big") {
      return <FacebookBig className="login-button-facebook" onClick={this.onButtonClick}>{__("continue_with_facebook", "Continue with Facebook")}</FacebookBig>
    };

    return (
      <SocialButton os={os} className="login-button-facebook" onClick={this.onButtonClick}>
        <FacebookLogo width="20px"/>
      </SocialButton>
    );

    return <Button onClick={this.onButtonClick}>{buttonText}</Button>;
  }
}

export default FacebookButton;

export const ButtonSmall = styled.div`
  background-color: #ffffff;
  color: black !important;
  border: 1px solid #dfdfdf !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 22px;
  width: 49% !important;
  margin: 0 7px;

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 40px !important;
  line-height: 40px !important;
  padding-left: 15px;
  border-radius: 7px;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  cursor: pointer !important;
`;

export const ButtonBig = styled(ButtonSmall)`
  background-position: left;
  background-color: #1776f1;
  color: #ffffff !important;
  border: none !important;
  width: 100% !important;
  margin: 35px 0 0 0;
`;

const FacebookSmall = styled(ButtonSmall)`
  border: none !important;
  border-radius: 8px;
  background-color: #f2f2f4;
  background-image: url(${require("../../../../root/img/fb-login.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/fb-login@2x.png")});
  }
`;

const FacebookBig = styled(ButtonBig)`
  background-position: 10px;
  background-image: url(${require("../../../../root/img/fb-blue.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../../root/img/fb-blue@2x.png")});
  }
`;
