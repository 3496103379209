import { getPromoParam } from "@pcloud/web-utilities/dist/lib/ptr";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import apiMethod from "./apiMethod";
import login from "./login";
import { rcookie, setcookie, getHash, getSearch, getDeviceID } from '../lib/utils';
import apiConfig from "../api/config";
import { getCJData } from "../../web-shared/cjAffiliate";
import { get6HData, set6HData, send6HData } from "../../web-shared/6HAgancy";
import { detectOS } from "../../web-shared/utils";

export default function register(options) {
  if (!options.username || !options.password) {
    throw new Error('usernames');
  }

  const params = {
    termsaccepted: 'yes',
    mail: options.username,
    password: options.password,
    os: options.os || 4,
    device: options.device || navigator.userAgent,
    deviceid: options.deviceid || getDeviceID(),
    language: options.language || "en",
  };
  const logOS = detectOS(false, true);
  const CJData = getCJData();
  const data6H = get6HData();

  if (data6H) {
    set6HData(data6H);
    send6HData(apiMethod, data6H);
  }

  if (CJData && CJData.cjtoken) {
    params.cjtoken = CJData.cjtoken;

    if (CJData.cjexpiration) {
      params.cjexpiration = CJData.cjexpiration;
    }
  }

  if (options.gresponsetoken) {
    params.gresponse = options.gresponsetoken;
  }

  if (options.noDefaultFiles) {
    params.nodefaultfiles = 1;
  }

  if (options.promocode) {
    params.promocode = options.promocode;
  }

  if (options.passpcloud) {
    params.passpcloud = options.passpcloud;
  }

  if (getPromoParam("label")) {
    params.channelid = getPromoParam("channelid");
    params.label = getPromoParam("label");
  }

  if (typeof sessionStorage !== "undefined" && sessionStorage.logreferrer) {
    params.logreferrer = sessionStorage.logreferrer;
  } else if (typeof document !== "undefined" && typeof document.cookie !== "undefined" && rcookie("logreferrer")) {
    params.logreferrer = rcookie("logreferrer");
  }

  if (logOS === "ios") {
    params.logos = DEVICE_OS.iOS;
  } else if (logOS === "android") {
    params.logos = DEVICE_OS.Android;
  } else {
    params.logos = DEVICE_OS.Web;
  }

  apiMethod('register', params, function (ret) {
    if (params.ref >= 200 && params.ref <= 300) {
      var i = new Image();
      i.src = 'https://www.facebook.com/offsite_event.php?id=6012310707486&value=0&currency=USD';
    }

    if (typeof fbq === "function") {
      fbq('track', 'CompleteRegistration');
    }

    if (!rcookie('ref')) {
      setcookie('ref', 0);
    }

    setcookie('invcd', 0);

    login(options.username, options.password, true, {
      successCallback: (userdata) => {
        if (options.onDone) {
          options.onDone(userdata, ret.promo);
        }

      },
      errorCallback: (ret) => {
        if (options.onError) {
          options.onError(ret);
        }
      },
      showErrorMessage: false,
      apiServer: options.apiServer || apiConfig.apiServer,
      os: options.os || 4,
      registertemptoken: ret.registertemptoken,
      isRegister: true
    });
  }, {
    errorCallback: function (ret) {
      if (options.onError) {
        options.onError(ret);
      }
    },
    showErrorMessage: false,
    onTimeout: () => {
      if (options.onTimeout) {
        options.onTimeout();
      }
    },
    apiServer: options.apiServer || apiConfig.apiServer,
    type: "post"
  });
}
