import React from "react";
import { SigninOptionsWrapper as WindowsSigninOptionsWrapper } from "../WindowsStyles";
import { SigninOptionsWrapper as WebSigninOptionsWrapper } from "../WebStyles";
import { SigninOptionsWrapper as MacSigninOptionsWrapper } from "../MacStyles";
import { SigninOptionsWrapper as LinuxSigninOptionsWrapper } from "../LinuxStyles";
import { SigninOptionsWrapper as IosSigninOptionsWrapper } from "../iOSStyles";
import { SigninOptionsWrapper as AndroidSigninOptionsWrapper } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const SigninOptionsWrapper = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return <AndroidSigninOptionsWrapper {...rest} />
    case DEVICE_OS.iOS:
      return <IosSigninOptionsWrapper {...rest} />
    case DEVICE_OS.Web:
      return <WebSigninOptionsWrapper {...rest} />
    case DEVICE_OS.Win:
      return <WindowsSigninOptionsWrapper {...rest} />
    case DEVICE_OS.MacOSX:
      return <MacSigninOptionsWrapper {...rest} />
    case DEVICE_OS.Linux:
      return <LinuxSigninOptionsWrapper {...rest} />
    default:
      return <WebSigninOptionsWrapper {...rest} />
  }
};

export default SigninOptionsWrapper;
