//  @flow
import {
  PLAYER_ADD_LOADED_SONG,
} from "../constants/audioPlayer";

export type AudioPlayerAction =
  | AddLoadedSong

type AddLoadedSong = { type: typeof PLAYER_ADD_LOADED_SONG, song: any, id: any };


export function addLoadedSong(song: any, id: any): AddLoadedSong {
  return { type: PLAYER_ADD_LOADED_SONG, song: song, id: id };
}

export const audioPlayerActionCreators = {
  addLoadedSong
};
