// @flow

import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

export type InputCheckboxProps = {
  name: ?string,
  checked: boolean,
  onChange: any => void,
  color?: "green" | "lightGreen" | "cyan",
  colorDefault?: "gray" | "lightGray",
  size?: "big" | "small" | "extrasmall",
  disabled?: boolean
};

class InputCheckbox extends Component<InputCheckboxProps> {
  static defaultProps = {
    name: "",
    checked: false,
    onChange: () => {},
    color: "green",
    colorDefault: "gray",
    size: "big",
    disabled: false
  };

  render() {
    const { color, colorDefault, name, onChange, size, disabled, ...rest } = this.props;

    return (
      <Container color={color} size={size}>
        <input type="checkbox" name={name} onChange={onChange} {...rest} />
        <Label size={size} colorDefault={colorDefault}/>
        {disabled ? <Overlay/> : null}
      </Container>
    );
  }
}

export default InputCheckbox;

const colors = {
  green: "#53b635",
  lightGreen: "#86c600",
  cyan: '#17bed0'
};

const colorsDefault = {
  gray: "#b4b4b8",
  lightGray: "rgba(255, 255, 225, 0.8)"
};

const Container = styled.div`
  position: relative;
  display: inline-block;
  /* width: ${props => props.size == "small" ? "40px" : "80px" };
  height: ${props => props.size == "small" ? "22px" : "31px" }; */
  ${props => {
    if (props.size == "extrasmall")
      return `width: 23px; height: 16px;`;
    if (props.size == "small")
      return `width: 40px; height: 22px;`;
    if (props.size == "big")
      return `width: 80px; height: 31px;`;
  }};
  line-height: 31px;

  &::after,
  &::before {
    font-family: "Roboto", sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
    margin: 0;
  }

  input:checked + label {
    background: ${props => colors[props.color]};

    &:after {
      background: #fff;
      /* left: ${props => props.size == "small" ? "21px" : "51px" }; */
      ${props => {
        if (props.size == "extrasmall")
          return `left: 8px;`;
        if (props.size == "small")
          return `left: 21px;`;
        if (props.size == "big")
          return `left: 51px;`;
      }};
    }

    &:before {
      content: '${props => props.size == "small" || props.size == "extrasmall" ? "" : "ON" }';
      color: #fff;
      padding-left: 15px;
    }
  }

  @media only screen and (max-width: 400px) {
    /* width: ${props => props.size == "small" ? "40px" : "65px" }; */
    ${props => {
      if (props.size == "extrasmall")
        return `width: 23px;`;
      if (props.size == "small")
        return `width: 40px;`;
      if (props.size == "big")
        return `width: 65px;`;
    }};

    input:checked + label {
      &:after {
        background: #fff;
        /* left: 36px; */
        /* left: ${props => props.size == "small" ? "21px" : "35px" }; */
        left:  ${props => {
          if (props.size == "extrasmall")
            return `width: 8px;`;
          if (props.size == "small")
            return `width: 21px;`;
          if (props.size == "big")
            return `width: 35px;`;
        }};
      }

      &:before {
        font-size: 12px;
        padding-left: 12px;
      }
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  /* width: ${props => props.size == "small" ? "40px" : "80px" };
  height: ${props => props.size == "small" ? "22px" : "31px" }; */
  ${props => {
    if (props.size == "extrasmall")
      return `width: 23px; height: 16px;`;
    if (props.size == "small")
      return `width: 40px; height: 22px;`;
    if (props.size == "big")
      return `width: 80px; height: 31px;`;
  }};
  /* background: #b4b4b8; */
  background: ${props => colorsDefault[props.colorDefault]};
  line-height: 31px;
  border-radius: 20px;
  padding-bottom: 0 !important; 
  transition: 0.4s;

  &:after {
    content: "";
    position: absolute;
    /* width: ${props => props.size == "small" ? "16px" : "25px" };
    height: ${props => props.size == "small" ? "16px" : "25px" }; */
    border-radius: 100%;
    left: 4px;
    top: 3px;
    ${props => {
      if (props.size == "extrasmall")
        return `width: 14px; height: 14px; top: 1px; left: 2px;`;
      if (props.size == "small")
        return `width: 16px; height: 16px;`;
      if (props.size == "big")
        return `width: 25px; height: 25px;`;
    }};
    z-index: 2;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
  }

  &:before {
    content: '${props => props.size == "small" || props.size == "extrasmall" ? "" : "OFF"}';
    font-weight: 400;
    color: #fff;
    /* padding-left: 38px; */
    padding-left: ${props => props.size == "extrasmall" ? "23px" : "38px"};
  }

  @media only screen and (max-width: 400px) {
    /* width: 65px; */
    /* width: ${props => props.size == "small" ? "40px" : "65px" }; */
    ${props => {
      if (props.size == "extrasmall")
        return `width: 23px;`;
      if (props.size == "small")
        return `width: 40px;`;
      if (props.size == "big")
        return `width: 65px;`;
    }};

    &:before {
      font-size: 12px;
      padding-left: 33px;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 1px;
  left: 0px;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.65;
  z-index: 101;
  cursor: not-allowed;
`;
