// @flow

import React, { useState } from "react";
import styled from "styled-components";

import { activateGracePeriod } from "../../api/business"; // DONE
import { __ } from "../../lib/translate"; // DONE
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants"; // DONE

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../components/Modal"; // DONE
import Heading from "./MultiplatformComponents/Elements/Heading"; // DONE
import Message from "./MultiplatformComponents/Elements/Message"; // DONE
import MessageBoxWarning from "./MultiplatformComponents/Elements/MessageBoxWarning"; // DONE
import MessageBoxSuccess from "./MultiplatformComponents/Elements/MessageBoxSuccess"; // DONE
import FormWrapper from "./MultiplatformComponents/Elements/FormWrapper"; // DONE
import ButtonWrapper from "./MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import Button from "./MultiplatformComponents/Elements/Button"; // DONE
import ButtonLink from "./MultiplatformComponents/Elements/ButtonLink"; // DONE
import Wrapper from "./MultiplatformComponents/Elements/Wrapper"; // DONE
import Footer from "./MultiplatformComponents/Elements/Footer"; // DONE

import WarningIconWeb from "../../../root/img/svg/ba_grace_period/warning_web.svg"; // DONE
import WarningIconIos from "../../../root/img/svg/ba_grace_period/warning_ios.svg"; // DONE
import SuccessIconWeb from "../../../root/img/svg/ba_grace_period/success_web.svg"; // DONE
import SuccessIconIos from "../../../root/img/svg/ba_grace_period/success_ios.svg"; // DONE
import LogoIcon from "../../../root/img/svg/ba_grace_period/pcloud_logo.svg"; // DONE

const iconByOsDescription = {
  [DEVICE_OS.iOS]: <WarningIconIos width="46px" height="46px" />,
  [DEVICE_OS.Android]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.Web]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.Win]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.Linux]: <WarningIconWeb width="32px" height="28px" />,
  [DEVICE_OS.MacOSX]: <WarningIconIos width="46px" height="46px" />
};

const iconByOsSuccess = {
  [DEVICE_OS.iOS]: <SuccessIconIos width="46px" height="46px" />,
  [DEVICE_OS.Android]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.Web]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.Win]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.Linux]: <SuccessIconWeb width="32px" height="32px" />,
  [DEVICE_OS.MacOSX]: <SuccessIconIos width="46px" height="46px" />
};

const buttonNameByOs = {
  [DEVICE_OS.iOS]: "continue_to_pcloud",
  [DEVICE_OS.Android]: "continue_to_pcloud",
  [DEVICE_OS.Web]: "continue_to_pcloud",
  [DEVICE_OS.Win]: "continue_to_pcloud_drive",
  [DEVICE_OS.Linux]: "continue_to_pcloud_drive",
  [DEVICE_OS.MacOSX]: "continue_to_pcloud_drive"
};

const GracePeriodBisiness = ({
  open = false,
  isModal = false,
  onClose = () => {},
  os = 4,
  auth = false,
  gracePeriodActivated = () => {}
}) => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    activateGracePeriod(
      { auth: auth },
      ret => {
        setShowSuccessMsg(true);
        gracePeriodActivated();
        setLoading(false);
      },
      err => {
        console.log("ERROR", err);
        setLoading(false);
      }
    );
  };

  const renderActivateSuccessMsg = () => {
    return (
      <React.Fragment>
        <FormWrapperOverwrite os={os} isModal={isModal}>
          {os === DEVICE_OS.Android || os === DEVICE_OS.iOS ? <LogoIcon width="4rem" style={{ marginBottom: "1rem" }} /> : null}
          <Header>
            <Heading os={os} style={{ marginBottom: "10px" }}>
              {__("ba_account_reactivated", "Account reactivated")}
            </Heading>
            <MessageBoxSuccess os={os}>
              <div className="icon-wrapper">{iconByOsSuccess[os]}</div>
              <SubHeadingOverwrite os={os}>
                {__(
                  "ba_account_reactivated_success_msg",
                  "Your business account has been reactivated successfully. Please check your e-mail for further information."
                )}
              </SubHeadingOverwrite>
            </MessageBoxSuccess>
          </Header>
          <ButtonWrapper os={os}>
            <Button
              type="submit"
              os={os}
              minWidth="100%"
              loading={loading}
              onClick={() => {
                /**continue to pCloud or pCloud Drive */
                setLoading(true);
                document.dispatchEvent(new Event("grace-period-activated"));
              }}
            >
              {__(buttonNameByOs[os])}
            </Button>
          </ButtonWrapper>
        </FormWrapperOverwrite>
        {os === DEVICE_OS.Win || os === DEVICE_OS.Linux || os === DEVICE_OS.MacOSX ? (
          <Footer>
            <ButtonLink
              type="button"
              os={os}
              style={{ fontSize: "inherit" }}
              onClick={() => {
                document.dispatchEvent(new CustomEvent("grace-period-activated", { detail: "GO_TO_BILLING" }));
              }}
            >
              {__("ba_renew_account_btn", "Go to pCloud billing to renew your account")}
            </ButtonLink>
          </Footer>
        ) : null}
      </React.Fragment>
    );
  };

  const renderDescription = () => {
    return (
      <React.Fragment>
        <FormWrapperOverwrite os={os} isModal={isModal}>
          {os === DEVICE_OS.Android || os === DEVICE_OS.iOS ? <LogoIcon width="4rem" style={{ marginBottom: "1rem" }} /> : null}
          <Header>
            <Heading os={os} style={{ marginBottom: "15px" }}>
              {__("ba_expired_owner_grace_period_title", "Your business subscription has expired")}
            </Heading>
            <Message className="centered" style={{ fontSize: "15px" }} os={os}>
              {__("ba_expired_owner_grace_period_description")}
            </Message>
            <MessageBoxWarning os={os}>
              <div className="icon-wrapper">{iconByOsDescription[os]}</div>
              <SubHeadingOverwrite os={os}>{__("ba_expired_owner_grace_period_warning")}</SubHeadingOverwrite>
            </MessageBoxWarning>
          </Header>
          <ButtonWrapper os={os}>
            <Button type="submit" os={os} minWidth="100%" onClick={onClick} loading={loading}>
              {__("ba_get_grace_period", "Get grace period")}
            </Button>
          </ButtonWrapper>
        </FormWrapperOverwrite>
      </React.Fragment>
    );
  };

  const renderBody = () => {
    return showSuccessMsg ? renderActivateSuccessMsg() : renderDescription();
  };

  const renderModal = () => {
    return (
      <Modal animate onClose={onClose} opened={open} shouldCloseOnOverlayClick={false}>
        <ModalWrap>
          <OvalModalCloseV2 onClick={onClose} />
          <Wrapper os={os}>{renderBody()}</Wrapper>
        </ModalWrap>
      </Modal>
    );
  };

  return isModal ? renderBody() : renderModal();
};

export default GracePeriodBisiness;

const ModalWrap = styled(OvalModalWrap)`
  padding: 40px;
  min-width: 320px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0px 20px 20px;
  }
`;

const Header = styled.div`
  text-align: center;
  /* padding: 20px 10px 0px 10px; */

  @media (max-width: 460px) {
    padding: 20px 10px;
    text-align: left;
  }
`;

const FormWrapperOverwrite = styled(FormWrapper)`
  max-width: 340px;
`;

const SubHeadingOverwrite = styled(Message)`
  display: block;
  margin-left: 0;
  text-align: center;
`;
