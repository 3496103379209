// @flow

import $ from "jquery";
import store from "../lib/state/store";
import { getUserInfo, getTeamInfo } from "../lib/business-utils";

const getFolderShares = (folderid: number, callback: any => void, opts: any) => {
  opts = $.extend(
    {
      fresh: false // force fresh on the call
    },
    opts
  );

  let wait = [];
  let res = [];
  const promise = $.Deferred();

  wait.push(
    getFolderOutShares(
      folderid,
      ret => {
        res = res.concat(ret);
      },
      opts
    )
  );

  if (HFN.config.isBusiness()) {
    wait.push(
      getFolderOutBusinessShares(
        folderid,
        ret => {
          res = res.concat(ret);
        },
        opts
      )
    );
  }

  $.when.apply($, wait).then(() => {
    res.sort((item1, item2) => {
      var a = item1.shared || item1.created,
        b = item2.shared || item2.created;
      return new Date(a).getTime() > new Date(b).getTime();
    });

    callback(res);
    promise.resolve();
  });

  return promise;
};

const getFolderOutShares = (folderid, callback, opts) => {
  opts = $.extend(
    {
      fresh: false, // force fresh on the call
      includeRequests: true
    },
    opts
  );

  const promise = $.Deferred();
  HFN.apiMethod(
    "listshares",
    { iconformat: "id" },
    ({ shares, requests }) => {
      let list = shares.outgoing;

      if (opts.includeRequests) {
        list = list.concat(requests.outgoing);
      }
      const sharesResult = list.filter(el => el.folderid === folderid);

      callback(sharesResult);
      promise.resolve();
    },
    { forceFresh: opts.fresh }
  );
  return promise;
};

const getFolderOutBusinessShares = (folderid, callback, opts) => {
  opts = $.extend(
    {
      fresh: false // force fresh on the call
    },
    opts
  );

  const businessState = store.getState().business;
  const { users, teams } = businessState;

  let shareXhr = [];
  let list = [];
  const promise = $.Deferred();

  shareXhr = HFN.apiMethod(
    "account_listshares",
    { iconformat: "id" },
    ret => {
      list = ret.shares.outgoing.concat(ret.shares.manage);
    },
    { forceFresh: opts.fresh }
  );

  $.when(shareXhr).then(() => {
    let shares = [];
    const sharedFolder = list.find(share => share.folder.folderid === folderid);
    if (sharedFolder) {
      const { sharedwith = [], folder: { folderid, userid } = {} } = sharedFolder;
      shares = sharedwith.map(item => {
        return Object.assign({}, item, {
          isowner: false,
          folderid: folderid,
          ownerid: userid || HFN.config.user.userid, // folder metas in outgoing shares is missing the userid property
          userobj: getUserInfo(item, users),
          teamobj: getTeamInfo(item, teams)
        });
      });
    }
    callback(shares);
    promise.resolve();
  });

  return promise;
};

export default getFolderShares;
