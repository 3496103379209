// @flow
const DONE = 4;

export function abortAll(xhrs: Array<XMLHttpRequest>) {
  xhrs.forEach(xhr => {
    if (xhr && xhr.readyState && xhr.readyState !== DONE) {
      xhr.abort();
      xhrs.splice(xhrs.indexOf(xhr), 1);
    }
  });
}
