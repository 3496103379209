import React from 'react';
import Button from './Button';

const OpenAccountButton = (props) => {
  return (
    <Button
      {...props}
      active={true}
      target="_blank"
      href="https://my.pcloud.com/">
        Open Account
    </Button>
  );
}

export default OpenAccountButton;
