import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 1.875rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  position: relative;
`;

export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: #000;
`;

export const SubHeading = styled.div`
  display: none;
  font-size: 1rem;
  text-align: center;
  margin-top: 0.85rem;
  letter-spacing: 0.2px;
`;

export const MessageBox = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: solid 1px #f6f6f6;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.125rem;

  .icon-wrapper {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MessageBoxWarning = styled(MessageBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.242rem;
  padding: 2.125rem;
  border-radius: 10px;
  border: solid 1px #f6e2a6;
  background-color: #fff9e4;
  margin-bottom: 0;
  margin-top: 2.125rem;

  .icon-wrapper {
    background-color: transparent;
  }
`;

export const MessageBoxSuccess = styled(MessageBoxWarning)`
  border-radius: 10px;
  border: solid 1px #e3f3e2;
  background-color: #e7f8e6;
`;

export const Message = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.06rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.86);
  margin-left: 1.438rem;
  line-height: 20px;
  
  strong, b {
    font-weight: bold;
  }
  
  span {
    max-height: 20px;
  }
  
  .ellipsis {
    font-weight: bold;
    text-overflow: ellipsis;
    max-width: 210px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
  }

  &.centered {
    text-align: center;
    margin-left: 0;
  }
`;

export const ErrorBox = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: solid 1px #fbdada;
  background-color: #fff7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .icon-wrapper {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 0.875rem;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.86);
  flex-grow: 1;

  strong, b {
    font-weight: bold;
  }
`;

export const Form = styled.form`
  margin-top: 1.5rem;
  width: 100%;
  max-width: 340px;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 1rem auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .edit-email-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  font-family: inherit !important;
  font-size: 1rem;
  text-align: left;
  color: #000;
  outline: none;

  &:focus {
    border-color: #17bed0;
  }

  &:disabled {
    border: solid 1px rgba(0, 0, 0, 0.12);
    color: #9f9f9f;
  }

    
  &[type="email"],
  &:read-only {
    padding-right: 35px !important;
  }

  &[type="password"] {
    padding-right: 35px !important;
  }
`;

export const SigninOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin: 2.125rem auto;

  label {
    margin-right: 0.625rem;
  }

  button {
    margin-left: 0.625rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2.125rem;
  position: relative;
`;

export const Button = styled.button`
  font-family: inherit !important;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.625rem;
  border-radius: 5px;
  background-color: #17bed0;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    color: #888;
    cursor: auto;
  }
`;

export const ButtonLink = styled.button`
  font-size: ${({fontSize}) => fontSize ? fontSize : "1rem"};
  font-family: inherit !important;
  background-color: transparent;
  border: none;
  color: ${({color}) => color ? color : "#17bed0"};
  padding: 0;
  cursor: pointer;
`;

export const FooterLink = styled(ButtonLink)`
  font-size: 0.875rem;
  margin-left: 0.25rem;
`

export const SocialButtonWrapper = styled.div`
  width: 100%;
  max-width: 340px;
`;

export const SocialButton = styled.div`
  width: 33.33%;
  height: 40px;
  padding: 0.375rem;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px #b1b1b1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  cursor: pointer;

  &.login-button-apple {
    order: 2;
  }

  &.login-button-google {
    order: 1;
    margin-left: 0px;
  }

  &.login-button-facebook {
    order: 3;
    margin-right: 0px;
  }
`;

export const ContinueWith = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: #000;
  opacity: 0.4;
  margin: 1.125rem auto;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 0.625rem;
  text-align: center;

  & button {
    font-size: 1.063rem;
  }
`;