// @flow
// USER CONSTANTS
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_MODIFY = "USER_MODIFY";
export const USER_COMPLETE_JOURNEY = "USER_COMPLETE_JOURNEY";
export const USER_SET_HAS_PASSWORD = "USER_SET_HAS_PASSWORD";
export const USER_SET_PASSWORD = "USER_SET_PASSWORD";
export const USER_DELETE_PASSWORD = "USER_DELETE_PASSWORD";
export const USER_SET_PHONE_COUNTRY_CODE = "USER_SET_PHONE_COUNTRY_CODE";
export const USER_SET_PHONE_NUMBER = "USER_SET_PHONE_NUMBER";
export const USER_DELETE_PHONE_NUMBER = "USER_DELETE_PHONE_NUMBER";
export const USER_SET_VERIFIED_PHONE_NUMBER = "USER_SET_VERIFIED_PHONE_NUMBER";
export const USER_SET_ACTIVE_TWO_FACTOR_TYPE = "USER_SET_ACTIVE_TWO_FACTOR_TYPE";
export const USER_SET_COUNTRY = "USER_SET_COUNTRY";
export const USER_SET_CONTACTS = "USER_SET_CONTACTS";
export const USER_SET_REGION_ID = "USER_SET_REGION_ID";
export const USER_SET_RELOCATION_INFO = "USER_SET_RELOCATION_INFO";
export const USER_SET_SETTINGS_INFO = "USER_SET_SETTINGS_INFO";
export const USER_UPDATE_BILLING_INFO = "USER_UPDATE_BILLING_INFO";
export const USER_UPDATE_PAYMENT_INFO = "USER_UPDATE_PAYMENT_INFO";
export const USER_UPDATE_RELOCATION_STATUS = "USER_UPDATE_RELOCATION_STATUS";
export const USER_SET_PAYMENT_SOURCE = "USER_SET_PAYMENT_SOURCE";
export const USER_SET_PAYMENT_PROVIDER = "USER_SET_PAYMENT_PROVIDER";
