const languageIcons = {
  en: {
    normal: require("../../../root/img/flags/en.png"),
    retina: require("../../../root/img/flags/en.png")
  },
  bg: {
    normal: require("../../../root/img/flags/bg.png"),
    retina: require("../../../root/img/flags/bg.png")
  },
  es: {
    normal: require("../../../root/img/flags/es.png"),
    retina: require("../../../root/img/flags/es.png")
  },
  ru: {
    normal: require("../../../root/img/flags/ru.png"),
    retina: require("../../../root/img/flags/ru.png")
  },
  pt: {
    normal: require("../../../root/img/flags/pt.png"),
    retina: require("../../../root/img/flags/pt.png")
  },
  fr: {
    normal: require("../../../root/img/flags/fr.png"),
    retina: require("../../../root/img/flags/fr.png")
  },
  it: {
    normal: require("../../../root/img/flags/it.png"),
    retina: require("../../../root/img/flags/it.png")
  },
  de: {
    normal: require("../../../root/img/flags/de.png"),
    retina: require("../../../root/img/flags/de.png")
  },
  zh: {
    normal: require("../../../root/img/flags/zh.png"),
    retina: require("../../../root/img/flags/zh.png")
  },
  tr: {
    normal: require("../../../root/img/flags/tr.png"),
    retina: require("../../../root/img/flags/tr.png")
  },
  fa: {
    normal: require("../../../root/img/flags/fa.png"),
    retina: require("../../../root/img/flags/fa.png")
  }
}

export default languageIcons;
