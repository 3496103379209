import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Link from './Link';

const CyanLink = styled(Link)`
  color: #5eceda;
`;

export default CyanLink;
