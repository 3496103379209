import $ from "jquery";

const NOT_STARTED = 0;
const STARTED = 1;
const FINISHED = 2;
const ABORTED = 3;

let uploadStatus = NOT_STARTED;
let uploadData = {};
let uploadId = 0;
let uploadXhr = null;
let callbacks = {
  onBegin: () => {},
  onProgress: () => {},
  onEnd: () => {},
  onAbort: () => {},
  onError: () => {}
};

function createUploader(options) {
  uploadData = options;

  if (options.onBegin) {
    callbacks.onBegin = options.onBegin;
  }
  if (options.onProgress) {
    callbacks.onProgress = options.onProgress;
  }
  if (options.onEnd) {
    callbacks.onEnd = options.onEnd;
  }
  if (options.onAbort) {
    callbacks.onAbort = options.onAbort;
  }
  if (options.onError) {
    callbacks.onError = options.onError;
  }

  return {
    isRunning: () => uploadStatus == STARTED,
    start: () => {
      getServer(host => {
        uploadData.host = host;
        _start();
      });
    },
    abort: _abort,
    kill: _kill,
    xhr: () => uploadXhr
  };
}

function prepUpload(options) {
  return uploads.push(options) - 1;
}

function getServer(cb) {
  HFN.apiMethod("currentserver", {}, ret => {
    cb(ret.hostname);
  });
}

function start() {
  getServer(host => {
    uploadData.host = host;
    _start();
  });
}

function _start() {
  const { files, host, code, names } = uploadData;
  const url = HFN.apiMethodUrl(host, "uploadtolink", {
    code: code,
    names: names,
    filescount: files.length
  });
  let formData = new FormData();

  each(files, file => formData.append("file", file));

  uploadStatus = STARTED;
  callbacks.onBegin();

  uploadXhr = $.ajax({
    url: url,
    type: "post",
    data: formData,
    dataType: "json",
    processData: false,
    contentType: false,
    success: wr => {
      if (wr.result === 0) {
        callbacks.onEnd();
      } else {
        if (uploadStatus != ABORTED) {
          callbacks.onError(wr.result);
          uploadStatus = ABORTED;
        }
      }
    },
    error: (xhr, error) => {
      if (uploadStatus != ABORTED) {
        callbacks.onError("Upload Interrupted.");
        uploadStatus = ABORTED;
      } else {
        // already handled. quiet.
      }
    },
    xhr: () => {
      var xhr = new window.XMLHttpRequest();
      if (xhr.upload) {
        xhr.upload.onprogress = function(e) {
          if (uploadStatus == ABORTED) return;

          //console.log(block.cipherOffset + e.loaded, upload.offsets.ciphersize, Number((block.cipherOffset + e.loaded) / upload.offsets.ciphersize * 100).toFixed(1));
          callbacks.onProgress(e.loaded, e.total);
        };
      }
      return xhr;
    }
  });
}

function _abort() {
  uploadStatus = ABORTED;
  callbacks.onAbort();
  if (uploadXhr) {
    uploadXhr.abort();
  }
}

// like abort, but quiet
function _kill() {
  uploadStatus = ABORTED;
  if (uploadXhr) {
    uploadXhr.abort();
  }
}

export { createUploader };
