import { __ } from "../../../web-shared/utils";
import { LOGIN_OR_SIGNUP_SCREEN, LOGIN_SCREEN, SIGNUP_SCREEN, CREATEPASS_SCREEN } from "./constants.signin";

export const FORM_TEXTS = {
  [LOGIN_OR_SIGNUP_SCREEN]: {
    heading: "login_or_signup_title",
    subheading: "login_or_signup_subtitle",
    inputPlaceHolder: "login_or_signup_placeholder",
  },
  [SIGNUP_SCREEN]: {
    heading: "Sign up to pCloud",
    subheading: "login_or_signup_continuewith",
    inputPlaceHolder: "login_or_signup_setuppass_placeholder",
  },
  [LOGIN_SCREEN]: {
    heading: "Log in to pCloud",
    subheading: "login_or_signup_welcomeback",
    inputPlaceHolder: "login_or_signup_password_placeholder",
  },
  [CREATEPASS_SCREEN]: {
    heading: "create_password_title",
    subheading: "login_or_signup_continuewith",
    inputPlaceHolder: "login_or_signup_setuppass_placeholder",
  },
}