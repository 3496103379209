// @flow

import { DEVICE_OS, EXTERNAL_APPS } from "@pcloud/web-utilities/dist/config/constants";

const APPLELogin = {
  init: (showPopup: boolean, state?: Object, os: string) => {
    const useHost = location.host;
    const params = {
      clientId: "com.pcloud.AppleSignIn",
      scope: "name email",
      responseType: "code",
      response_mode: "form_post",
      redirectURI: `https://${useHost}/${os === DEVICE_OS.Android ? "appleloginmobile" : "applelogin"}`,
      usePopup: showPopup
    };

    if (state) {
      params.state = JSON.stringify(state);
    }

    AppleID.auth.init(params);
  },
  signIn: () => {
    console.trace("AppleID.auth.signIn");
    return AppleID.auth.signIn();
  }
};

export default APPLELogin;
