import React from "react";
import styled from "styled-components";

const WindowsCheckBox = ({
  children,
  checked = false,
  text = "",
  onChange = () => {},
  ...rest
}) => {
  return (
    <Label className="container">
      {text ? <TextWrapper>{text}</TextWrapper> : null}
      {children}
      <Input type="checkbox" onChange={onChange} checked={checked} {...rest}/>
      <Checkmark className="checkmark"></Checkmark>
    </Label>
  )
};

export default WindowsCheckBox;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 20px;
  font-size: inherit;
  user-select: none;
  color: #201f1e;
  font-weight: normal;
  padding-bottom: 0px;
  padding-bottom: 0px;
  line-height: 20px;
  cursor: pointer;

  & input:checked ~ .checkmark {
    background-color: #17bed0;
    border: none;

    &:after {
      display: block;
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const TextWrapper = styled.div`
  margin-left: 10px;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #605e5c;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
