// @flow
import {
  LOAD_LINK_DATA,
  SET_UPLOAD_SETTINGS,
  SET_DOWNLOAD_SETTING,
  SET_HAS_PASSWORD,
  SET_EXPIRES,
  SET_EMAILS_WITH_UPLOAD_ACCESS,
  SET_SHORT_LINK_URL
} from "../constants/sharedLinksSettings";
import type { Action } from "../actions/types";

export type UploadSettings = {
  isUploadDisabled: boolean,
  canEveryoneUpload: boolean,
  isRestrictedUpload: boolean
}

export type LinkData = {
  code: string,
  linId: string,
  linkUrl: string,
  shortLinkUrl: string,
  type: number,
  linkName: string,
  hasPassword: boolean,
  expirationDate: boolean | Date,
  created: string,
  downloads: number,
  traffic: number,
  uploadSettings: UploadSettings,
  emailsWithUploadAccess: Array<string>,
  canDownload: boolean
};

export type SharedLinkState = {
  loaded: boolean,
  linkData: LinkData,
  isUploadSerringsDropdownOpen: boolean
};

const initialState: SharedLinkState = {
  loaded: false,
  linkData: {
    code: "",
    linId: "",
    linkUrl: "",
    shortLinkUrl: "",
    linkName: "",
    hasPassword: false,
    expirationDate: false,
    created: "",
    downloads: 0,
    traffic: 0,
    uploadSettings: {
      isUploadDisabled: true,
      canEveryoneUpload: false,
      isRestrictedUpload: false
    },
    canDownload: false,
    emailsWithUploadAccess: []
  },
  isUploadSerringsDropdownOpen: false
};

export function sharedLinkReducer(
  state: SharedLinkState = initialState,
  action: Action
): SharedLinkState {
  switch (action.type) {
    case LOAD_LINK_DATA:
      return { ...state, loaded: true, linkData: action.linkData };
    case SET_UPLOAD_SETTINGS:
      return { ...state, linkData: { ...state.linkData, uploadSettings: action.settings } };
    case SET_DOWNLOAD_SETTING:
      return { ...state, linkData: { ...state.linkData, canDownload: action.canDownload } };
    case SET_HAS_PASSWORD:
      return { ...state, linkData: { ...state.linkData, hasPassword: action.payload } };
    case SET_EXPIRES:
      return { ...state, linkData: { ...state.linkData, expirationDate: action.payload } };
    case SET_EMAILS_WITH_UPLOAD_ACCESS:
      return { ...state, linkData: { ...state.linkData, emailsWithUploadAccess: action.emails } };
    case SET_SHORT_LINK_URL:
      return { ...state, linkData: { ...state.linkData, shortLinkUrl: action.payload } };
    default:
      return state;
  }
}
