import { fileext } from "./utils";
import mimetypes from "./mimetypes";
import config, { CDN } from "@pcloud/web-utilities/dist/config";
import iconspCloud from "./iconspCloud";

export const ICONS = {
  LIST: 1,
  GRID_SMALL: 2,
  GRID: 3,
  GRID_LARGE: 4,
  LIST_SMALL: 5,
  PUBLINK: 6,
  PUBLINK_MOB: 7,
  GRID_ALBUM: 8,
  AUDIOPLAYING: 9,
  ARTIST_ALBUM: 10,
  ALBUMGRID: 11,
  NOTIFICATIONS: 12,
  FOLDER_BROWSE: 13,
  MOBILEGRID: 14,
  AUDIO_PUBLINK: 15,
  OVERTRAFFIC_PUBLINK: 16,
  GRID_GALLERY: 17,
  MOBILEGRID_GALLERY: 18,
  FILTER_DROPDOWN: 19
};

export const ICO = {
  FOLDER: 20,
  CRYPTO_FOLDER: 24,
  CRYPTO_FILE: 26,
  BOOKMARK: 27,
  AUDIO: 3,
  ARCHIVE: 5,
  FOLDER_SHARED: 1000,
  FOLDER_SHAREDWITHME: 1001,
  VIRTUALFOLDER: 1002,
  PLAYLIST: 1003,
  DELETED: 1004,
  AUTOMATICUPLOAD: 1005,
  PUBLIC: 1006,
  FOLDER_SHARED_CRYPTO: 1007,
  FOLDER_SHAREDWITHME_CRYPTO: 1008,
  UPLOAD_FOLDER: 1009,
  FOLDER_BACKUP: 1010,
};

export const iconFormatPath = {
  id: "icons-id",
  string: "icons"
};

export const iconTypes = {
  /*
  1: {
    width: '32px',
    height: '32px',
    path: '40',
    thumb: '32x32'
  },
  */
  1: {
    icon: {
      width: "24px",
      height: "24px",
      path: "24",
      retina: true
    },
    thumb: {
      width: "32px",
      height: "32px",
      size: "32x32"
    }
  },
  3: {
    icon: {
      width: "48px",
      height: "48px",
      path: "48",
      retina: true
    },
    thumb: {
      width: "232px",
      height: "232px",
      size: "232x232"
    }
  },
  5: {
    icon: {
      width: "24px",
      height: "24px",
      path: "24",
      retina: true
    },
    thumb: {
      width: "24px",
      height: "24px",
      size: "24x24"
    }
  },
  6: {
    icon: {
      width: "160px",
      height: "160px",
      path: "160"
    },
    thumb: {
      width: "160px",
      height: "160px",
      size: "160x160"
    }
  },
  7: {
    icon: {
      width: "80px",
      height: "80px",
      path: "80"
    },
    thumb: {
      width: "80px",
      height: "80px",
      size: "80x80"
    }
  },
  /*
  8: {
    width: '160px',
    height: '160px',
    path: '160',
    thumb: '200x200'
  },
  */
  8: {
    icon: {
      width: "160px",
      height: "160px",
      path: "160"
    },
    thumb: {
      width: "220px",
      height: "250px",
      size: "220x250"
    }
  },
  9: {
    icon: {
      width: "100px",
      height: "100px",
      path: "100"
    },
    thumb: {
      width: "100px",
      height: "100px",
      size: "100x100"
    }
  },
  10: {
    icon: {
      width: "128px",
      height: "128px",
      path: "120",
      retina: true
    },
    thumb: {
      width: "128px",
      height: "128px",
      size: "128x128"
    }
  },
  11: {
    icon: {
      width: "128px",
      height: "128px",
      path: "120",
      retina: true
    },
    thumb: {
      width: "128px",
      height: "128px",
      size: "128x128"
    }
  },
  12: {
    icon: {
      width: "32px",
      height: "32px",
      path: "32",
      retina: true
    },
    thumb: {
      width: "32px",
      height: "32px",
      size: "32x32"
    }
  },
  13: {
    icon: {
      width: "20px",
      height: "20px",
      path: "20"
    },
    thumb: {
      width: "20px",
      height: "20px",
      size: "20x20"
    }
  },
  14: {
    icon: {
      width: "100px",
      height: "100px",
      path: "120",
      retina: true
    },
    thumb: {
      width: "100px",
      height: "100px",
      size: "100x100"
    }
  },
  15: {
    icon: {
      width: "120px",
      height: "120px",
      path: "120",
      retina: true
    },
    thumb: {
      width: "200px",
      height: "200px",
      size: "200x200"
    }
  },
  16: {
    icon: {
      width: "100px",
      height: "100px",
      path: "120",
      retina: true
    },
    thumb: {
      width: "120px",
      height: "120px",
      size: "120x120"
    }
  },
  17: {
    icon: {
      width: "48px",
      height: "48px",
      path: "48",
      retina: true
    },
    thumb: {
      width: "auto",
      height: "232px",
      size: "1000x232"
    }
  },
  18: {
    icon: {
      width: "100px",
      height: "100px",
      path: "120",
      retina: true
    },
    thumb: {
      width: "auto",
      height: "100px",
      size: "1000x100"
    }
  },
  19: {
    icon: {
      width: "20px",
      height: "20px",
      path: "20",
      retina: true
    },
    thumb: {}
  }
};

export const ICONID_FILE = {
  0: "file",
  1: "image",
  2: "video",
  3: "audio",
  4: "document",
  5: "archive",
  6: "document", //word
  7: "presentation",
  8: "spreadsheet",
  9: "presentation", // PDF
  10: "document", // pages (mac word)
  11: "presentation", // keynote (mac presentation)
  12: "spreadsheet", // numbers (mac )
  13: "document", // odt
  14: "spreadsheet", // ods
  15: "presentation", // odp
  16: "executable",
  17: "presentation", // PDF ????
  18: "web", // html
  19: "file", // delete event ???
  24: "2fa"
};

//

export function createIcon(file, type, className = "") {
  return $("<img>", getIconInfo(file, type, className));
}

export function getIconInfo(file, type, className = "") {

  return {
    src: createIconSrc(file, type),
    width: iconTypes[type].icon.width,
    height: iconTypes[type].icon.height,
    class: className
  };
}

export function getThumbInfo(type) {
  return {
    width: iconTypes[type].thumb.width,
    height: iconTypes[type].thumb.height
  };
}

export function createIconSrc(file, type) {
  var iconFormat = "string";

  if (typeof file === "number") {
    iconFormat = "id";
  }

  if (file === undefined) {
    return '';
  }


  var iconSet = iconspCloud[iconFormatPath[iconFormat]][
  iconTypes[type].icon.path +
  (iconTypes[type].icon.retina && isRetina()
      ? "@2x"
      : "")
      ];

  return iconSet && iconSet.hasOwnProperty(file) ? iconSet[file] : '';
}

function isRetina() {
  if (typeof window == "undefined") {
    return false;
  }

  return window.devicePixelRatio > 1;
}

export function createImage(file, type) {
  var params = { src: "/ZCDNURL/ZREPLACETHISWITHVERSION/img/" + file };
  var size = imageSize(type);

  if (size) {
    params.width = size.width;
    params.height = size.height;
  }

  return $("<img>", params);
}

export function imageSize(type) {
  var sizes = {
    leftmenu: { width: "23px", height: "18px" },
    left: { width: "19px", height: "19px" }
  };

  if (type in sizes) return sizes[type];
  return false;
}

const defaultIcon = 0;
export function getFileIcon({ name }) {
  const ext = fileext(name);

  if (ext in mimetypes) {
    return mimetypes[ext].iconid;
  } else {
    return defaultIcon;
  }
}
