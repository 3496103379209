function _properSpaces(str) {
  return str.split(String.fromCharCode(160)).join(" ");
}

function _repl(str: string, repl: {} = {}) {
  str = _properSpaces(str);

  if (!Object.keys(repl).length) {
    return str;
  }

  for (var n in repl) {
    str = str.replace(new RegExp("%" + n + "%", "g"), repl[n]);
  }

  return str;
}

export function __(key: string, val?: string, repl?: { [string]: mixed }, description?: string) {
	if (typeof (window.pCloudIntl: any) !== "undefined") {
		return window.pCloudIntl.__(key, val, repl, description);
	}

	if (typeof window === "object" && typeof window.__ === "function") {
		return window.__(key, val, repl, description);
	}

	if (val) {
		return _repl(val, repl);
	}

	return _repl(key, repl);
}
