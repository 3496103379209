import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const Scrollbar = ({ children, ...props }) => {
  return (
    <Scrollbars
      autoHide={true}
      hideTracksWhenNotNeeded={true}
      autoHideDuration={200}
      {...props}
    >
      {children}
    </Scrollbars>
  );
};

export default Scrollbar;
