// @flow
// WARNING: Do not use HFN in this component

import React, { useState } from "react";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { getDeviceID, validateEmail, validatePassword, rcookie, setcookie } from "../../lib/utils";
import { DEVICE_OS, EU_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";
import { notify } from "./MultiplatformComponents/Notification";
import errors from "../../lib/errors";
import LoginCreatePasswordUI from "./AuthenticationStepsUI/LoginCreatePasswordUI";

const LoginCreatePassword = ({
  isWebview = false,
  email = "",
  os = DEVICE_OS.Web,
  osversion = "",
  appversion = "",
  appversioncode = "",
  device = "",
  clientid = "",
  locationId = EU_LOCATIONID,
  verificationCode = "",
  themeMode = "light",
  onEmailChangeClick = () => {},
  onSuccess = () => {},
  resetView = () => {},
  onSendCodeClick = () => {}
}) => {
  const [disabledForm, setDisabledForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [wrongCodeError, setWrongCodeError] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const onPassChange = e => {
    setPassword(e.target.value);
    setWrongCodeError(false);
  };

  const showMessage = (message, type) => {
    if ((os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) && type === "error") {
      setError(message);
    } else if (os !== DEVICE_OS.Web) {
      notify(message, type, { os });
    }
  };

  const onContinueClick = e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    let error = validatePassword(password);

    if (!validateEmail(email)) {
      error = __("invalid_email", "Invalid Email");
    }

    if (error) {
      showMessage(error, "error");
      return;
    }

    let _params = {
      email: email,
      code: verificationCode.trim(),
      password: password
    };

    if (os === DEVICE_OS.Web) {
      _params = {
        ..._params,
        _t: $.now,
        logout: 1,
        getlastsubscription: 1,
        promoinfo: 1,
        deviceid: getDeviceID(),
        os: os,
        osversion: "0.0.0"
      };

      if (rcookie("restrictedauth")) {
        _params.restrictedauth = rcookie("restrictedauth");
        setcookie("restrictedauth", "", -1);
      }  
    } else {
      if (os) {
        _params.os = os;
      }

      if (osversion) {
        _params.osversion = osversion;
      }

      if (appversion) {
        _params.appversion = appversion;
      }

      if (appversioncode) {
        _params.appversioncode = appversioncode;
      }

      if (device) {
        _params.device = device;
      }

      if (clientid) {
        _params.clientid = clientid;
      }
    }

    setIsLoading(true);

    apiMethod(
      "user/setuppassbycode",
      _params,
      response => {
        const { result, ...userinfo } = response;

        if (result === 0) {
          onSuccess({
            action: "login",
            userinfo: userinfo,
            rememberme: rememberMe,
            locationid: locationId
          });
        }
      },
      {
        errorCallback: ({ result }) => {
          console.log(result, "result");
          setIsLoading(false);
          if (result === 2012) {
            setWrongCodeError(true);
            showMessage(__("login_verification_code_expired", "Sign-in failed. Your verification code has expired."), "error");
          }

          if (errors[result]) {
            showMessage(errors[result], "error");
          } else {
            showMessage(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        },
        showErrorMessage: false,
      }
    );

    // Errors:
    // 1130="Please provide 'email'."
    // 1022="Please provide 'code'."
    // 2012="Invalid 'code' provided."
    // 2035="Password can not contain only consecutive characters."
  };

  return (
    <LoginCreatePasswordUI
      os={os}
      isWebview={isWebview}
      email={email}
      password={password}
      rememberMe={rememberMe}
      error={error}
      setRememberMe={setRememberMe}
      onPasswordChange={onPassChange}
      onSendCodeClick={onSendCodeClick}
      onSubmit={onContinueClick}
      resetView={resetView}
      onEmailChangeClick={onEmailChangeClick}
      themeMode={themeMode}
    />
  )
};

export default LoginCreatePassword;
