import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const GridSortableHeader = styled.div`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 10px;
  height: 100%;
  width: 100%;
`;

export default GridSortableHeader;
