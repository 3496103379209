// @flow

import React from "react";
import styled from "styled-components";

import { SecurityCodeStyledInput as WebInput } from "../MultiplatformComponents/WebStyles";
import { SecurityCodeStyledInput as IosInput } from "../MultiplatformComponents/iOSStyles";
import { SecurityCodeStyledInput as АndroidInput } from "../MultiplatformComponents/AndroidStyles";
import { SecurityCodeStyledInput as MacInput } from "../MultiplatformComponents/MacStyles";
import { SecurityCodeStyledInput as LinuxInput } from "../MultiplatformComponents/LinuxStyles";
import { SecurityCodeStyledInput as WindowsInput } from "../MultiplatformComponents/WindowsStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  handleCode: () => void,
  handleKey: () => void,
  char: string,
  index: number,
  maxLength: number,
  chunk: number,
  os: number,
  allowLetters: boolean,
  className: string
};

const VerificationCodeInput = ({ handleCode, handleKey, char, index, maxLength, os, chunk, allowLetters, className }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isPasting = e.nativeEvent.inputType === "insertFromPaste"
    if (!isPasting) {
      const pattern = allowLetters ? /^[A-Za-z0-9]*$/ : /^\d*$/;
      const target = e.currentTarget;
      const isValidChar = pattern.test(target.value);

      if (!isValidChar) return;

      handleCode(e, target.value, index);
    }
  };

  const onPaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    paste = paste.replace(allowLetters ? /([^\w ]|_)/g : /\D/g, '');
    handleCode(e, paste, index);
  }

  const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    ev.currentTarget.select();
  };

  switch (os) {
    case DEVICE_OS.Web:
      return <WebInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        autoComplete="one-time-code"
        className={className}
      />
    case DEVICE_OS.iOS:
      return <IosInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        className={className}
        autoComplete="one-time-code"
      />
    case DEVICE_OS.Android:
      return <АndroidInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        autoComplete="one-time-code"
        className={className}
      />
    case DEVICE_OS.MacOSX:
      return <MacInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        autoComplete="one-time-code"
        className={className}
      />
    case DEVICE_OS.Linux:
      return <LinuxInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        autoComplete="one-time-code"
        className={className}
      />
    case DEVICE_OS.Win:
      return <WindowsInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        autoComplete="one-time-code"
        className={className}
      />
    default:
      return <WebInput
        type={allowLetters ? "text" : "number"}
        inputMode={allowLetters ? "text" : "numeric"}
        onChange={handleChange}
        onKeyDown={(ev) => handleKey(ev, index)}
        value={char}
        onFocus={handleFocus}
        maxLength={maxLength}
        autoFocus={index === 0}
        os={os}
        chunk={chunk}
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onPaste={onPaste}
        autoComplete="one-time-code"
        className={className}
      />
  }
};

export default VerificationCodeInput;
