import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import { __ } from '../../lib/translate';
import { obLength } from '../../lib/utils';

const HtmlTString = ({ id, tagName, text, replace }) => {
	return createElement('span', {
		dangerouslySetInnerHTML: {
			__html: __(id, text, replace)
		}
	});
}

HtmlTString.propTypes = {
	id: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	replace: PropTypes.object,
	tagName: PropTypes.oneOf(['span', 'div'])
}

HtmlTString.defaultProps = {
	replace: {},
	tagName: 'span'
}

export default HtmlTString;
