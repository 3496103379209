// WARNING: Do not use HFN in this component

import React, { useState } from "react";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

import * as FormStyles from "../../../components/FormSharedComponents";
import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper";
import Form from "../MultiplatformComponents/Elements/Form";
import Heading from "../MultiplatformComponents/Elements/Heading";
import SubHeading from "../MultiplatformComponents/Elements/SubHeading";
import InputWrapper from "../MultiplatformComponents/Elements/InputWrapper";
import Input from "../MultiplatformComponents/Elements/Input";
import Button from "../MultiplatformComponents/Elements/Button";
import Footer from "../MultiplatformComponents/Elements/Footer";
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink";
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper";
import MessageBox from "../MultiplatformComponents/Elements/MessageBox";
import Message from "../MultiplatformComponents/Elements/Message";
import SigninOptionsWrapper from "../MultiplatformComponents/Elements/SigninOptionsWrapper";
import RevealPassword from "../MultiplatformComponents/Elements/RevealPassword";
import HidePassword from "../MultiplatformComponents/Elements/HidePassword";
import CheckBox from "../MultiplatformComponents/Elements/CheckBox";
import SystemMessage from "./Message";
import ResponsiveEmail from "../../ResponsiveEmail";
import PasswordOption from "../LoginOptions/PasswordOption";
import InputInnerButton from "../LoginOptions/InputInnerButton";
import CreatePassIcon from "../../../../root/img/svg/create-pass.svg";
import CreatePassIconiOS from "../../../../root/img/svg/create-pass-ios.svg";
import CreatePassIconAndroid from "../../../../root/img/svg/create-pass-android.svg";
import NarrowArrow from "../../../../web-shared/images/svg/narrow-arrow.svg";
import { FORM_TEXTS } from "../texts.signin";
import { CREATEPASS_SCREEN } from "../constants.signin";
import PcloudLogo from "../../../../web-shared/images/svg/pcloud-gradient-logo.svg"

const LoginCreatePasswordUI = ({
  os = DEVICE_OS.Web,
  isWebview = false,
  email = "",
  password = "",
  rememberMe = false,
  error = "",
  wrongCodeError = "",
  themeMode = "light",
  setRememberMe = () => { },
  onPasswordChange = () => { },
  onSendCodeClick = () => { },
  onSubmit = () => { },
  resetView = () => { },
  onEmailChangeClick = () => { }
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const PassIcon = showPassword ? HidePassword : RevealPassword;

  const onShowPass = () => setShowPassword(!showPassword);

  const renderRememnerMe = () => (
    <CheckBox
      size="small"
      os={os}
      checked={rememberMe}
      onChange={e => setRememberMe(e.target.checked)}
      text={__("remember me", "Remember me")}
    />
  );

  const renderPasswordSection = () => {
    const disabledButton = password.length === 0;

    return (
      <PasswordSectionWrapper>
        <PasswordOption
          onChange={onPasswordChange}
          os={os}
          name="password"
          placeholder={__("login_or_signup_setuppass_placeholder")}
          value={password}
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          error={error}
          className="login-input-password"
          autoFocus
        >
          {password.length !== 0 && <PassIcon themeMode={themeMode} os={os} onClick={onShowPass} className="login-input-password-icon" />}
          {os !== DEVICE_OS.Android &&
            <InputInnerButton os={os} onClick={onSubmit} disabled={disabledButton} disabledColor="#ebedf0">
              <NarrowArrow />
            </InputInnerButton>}
        </PasswordOption>
        <SigninOptionsWrapper os={os}>
          {os !== DEVICE_OS.iOS && os !== DEVICE_OS.Android ? renderRememnerMe() : <div />}
        </SigninOptionsWrapper>
        {os === DEVICE_OS.Android &&
          <ButtonWrapper os={os}>
            <Button className="login-button-submit" loading={false} os={os} onClick={onSubmit} disabled={disabledButton} disabledColor="#ebedf0">{__("signin", "Sign in")}</Button>
          </ButtonWrapper>}
      </PasswordSectionWrapper >
    );
  };

  return (
    <React.Fragment>
      <FormWrapper os={os}>
        {os !== DEVICE_OS.Web &&
          <LogoWrapper>
            <PcloudLogo />
          </LogoWrapper>
        }
        <Heading os={os} className={isWebview ? "webview" : "login-title"}>
          {__("create_password_title")}
        </Heading>
        {
          email ? (
            <React.Fragment>
              <SubHeading os={os}>
                {reactStringReplace(__("login_or_signup_continuewith"), /(%email%)/g, (match, i) => (
                  <strong>
                    <ResponsiveEmail os={os} email={email} />
                  </strong>
                ))}
              </SubHeading>
              <ButtonLink os={os} onClick={onEmailChangeClick} style={{ marginTop: "4px" }}>{__("Change")}</ButtonLink>
            </React.Fragment>
          ) : null
        }
        <Form os={os} onSubmit={onSubmit}>
          <Message os={os}>{__("create_password_message", "You'll use this password to access your files in pCloud")}</Message>
          <SystemMessage os={os} error={error} />
          {renderPasswordSection()}
        </Form>
      </FormWrapper>
      <Footer os={os}>
        {wrongCodeError ? (
          <ButtonLink os={os} onClick={onSendCodeClick} type="button">
            {__("login_verification_code_send_again", "Send code again")}
          </ButtonLink>
        ) : null}
      </Footer>
    </React.Fragment>
  );
};

export default LoginCreatePasswordUI;

const PasswordSectionWrapper = styled.div`
  width: 100%;
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;
