import React from "react";
import { ContinueWith as WebContinueWith } from "../WebStyles";
import { ContinueWith as WindowsContinueWith } from "../WindowsStyles";
import { ContinueWith as MacContinueWith } from "../MacStyles";
import { ContinueWith as LinuxContinueWith } from "../LinuxStyles";
import { ContinueWith as IosContinueWith } from "../iOSStyles";
import { ContinueWith as AndroidContinueWith } from "../AndroidStyles";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

const ContinueWith = ({ os, ...rest}) => {
  switch (os) {
    case DEVICE_OS.Android:
      return  <AndroidContinueWith {...rest}/>
    case DEVICE_OS.iOS:
      return  <IosContinueWith {...rest}/>
    case DEVICE_OS.Web:
      return <WebContinueWith {...rest}/>
    case DEVICE_OS.Win:
      return <WindowsContinueWith {...rest}/>
    case DEVICE_OS.MacOSX:
      return <MacContinueWith {...rest}/>
    case DEVICE_OS.Linux:
      return <LinuxContinueWith {...rest}/>
    default:
      return <WebContinueWith {...rest}/>
  }
};

export default ContinueWith;
