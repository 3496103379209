// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../../components/Modal"; // DONE

import { __ } from "../../../lib/translate"; // DONE
import { BackButton } from "../../TwoFactorAuth/SharedComponents"; // DONE
import Heading from "../MultiplatformComponents/Elements/Heading"; // DONE
import SubHeading from "../MultiplatformComponents/Elements/SubHeading"; // DONE
import FormWrapper from "../MultiplatformComponents/Elements/FormWrapper"; // DONE
import Form from "../MultiplatformComponents/Elements/Form"; // DONE
import InputWrapper from "../MultiplatformComponents/Elements/InputWrapper"; // DONE
import Input from "../MultiplatformComponents/Elements/Input"; // DONE
import ButtonWrapper from "../MultiplatformComponents/Elements/ButtonWrapper"; // DONE
import Button from "../MultiplatformComponents/Elements/Button"; // DONE
import Wrapper from "../MultiplatformComponents/Elements/Wrapper"; // DONE
import Footer from "../MultiplatformComponents/Elements/Footer"; // DONE
import ButtonLink from "../MultiplatformComponents/Elements/ButtonLink"; // DONE
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants"; // DONE

const SocialLoginUnavailableModal = ({
  open = false,
  isModal = false,
  onClose = () => {},
  onContinue = () => {},
  os = 4
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onContinue();
      }
    },
    [onContinue]
  );

  const renderWrapper = () => {
    return (
      <React.Fragment>
        <FormWrapperOverwrite os={os} isModal={isModal}>
          {/* {isModal ? <BackButton onClick={onClose}>{__("Back")}</BackButton> : null} */}
          <Header>
            <Heading os={os}>{__("facebook_signin_unavailable_title", "Temporarily unavailable")}</Heading>
            <SubHeadingOverwrite os={os}>{__("facebook_signin_unavailable_message", "The Facebook sign-in option is temporarily unavailable. To access your pCloud account, continue with your email address.")}</SubHeadingOverwrite>
          </Header>
          <ButtonWrapper os={os}>
            {/* {!isModal ? <Button type="submit" color="lightgray4" os={os} minWidth={!isModal ? "49%" : "100%"} onClick={onClose} disabledColor="#dddddd">{__("Close")}</Button> : null} */}
            <Button type="submit" os={os} minWidth={"100%"} onClick={onContinue} disabledColor="#dddddd">
              {__("Continue")}
            </Button>
          </ButtonWrapper>

        </FormWrapperOverwrite>
        { isModal ? (
          <Footer>
            <ButtonLink type="button" onClick={onClose}>
              {__("Back")}
            </ButtonLink>
          </Footer>
        ) : null }
      </React.Fragment>
    );
  };

  const renderModal = () => {
    return (
      <Modal animate onClose={onClose} opened={open}>
        <ModalWrap>
          <OvalModalCloseV2 onClick={onClose} />
          <Wrapper os={os}>
            {renderWrapper()}
          </Wrapper>
        </ModalWrap>
      </Modal>
    );
  };

  return isModal ? renderWrapper() : renderModal();
};

export default SocialLoginUnavailableModal;

const ModalWrap = styled(OvalModalWrap)`
  padding: 40px;
  min-width: 320px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0px 20px 20px;
  }
`;

const Header = styled.div`
  text-align: center;
  /* padding: 20px 10px 0px 10px; */

  @media (max-width: 460px) {
    padding: 20px 10px;
    text-align: left;
  }
`;

const FormWrapperOverwrite = styled(FormWrapper)`
  max-width: 340px;
`;

const SubHeadingOverwrite = styled(SubHeading)`
  display: block;
`;
