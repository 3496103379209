// @flow
// WARNING: Do not use HFN in this component

import React, { useState } from "react";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { setcookie, getGlobalLocationId } from "../../lib/utils";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";
import TFALoginCodeFormUI from "./AuthenticationStepsUI/TFALoginCodeFormUI";
import Modal from "../../components/Modal";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

import Heading from "./MultiplatformComponents/Elements/Heading";
import SubHeading from "./MultiplatformComponents/Elements/SubHeading";
import Wrapper from "./MultiplatformComponents/Elements/Wrapper";
import { FormWrapper } from "./MultiplatformComponents/WindowsStyles";
import { notify } from "./MultiplatformComponents/Notification";

import BackIconTFA from "../../../root/img/2fa-login/back.svg";
import { TFA_AUTH_FORM_ID, TFA_NOTIFICATION_FORM_ID, TFA_RECOVERY_FORM_ID, TFA_SMS_FORM_ID } from "./constants.signin";
import TFALoggedDevicesModalUI from "./AuthenticationStepsUI/TFALoggedDevicesModalUI";
import { tfaSendCodeViaSMS, tfaSendNotification } from "./methods.signin";
import TFACantReceiveCodeSupportUI from "./AuthenticationStepsUI/TFACantReceiveCodeSupportUI";

const TwoFactorAuthLogin = ({
  os = 4,
  token = "",
  theme = "light",
  tfatype,
  hasLoggedDevices,
  rememberme,
  isLoginModal,
  logininfo = "",
  osversion = "0.0.0",
  appversion,
  appversioncode,
  device,
  gresponsetoken = "",
  registertemptoken = "",
  giveawaykey = "",
  passpcloud = false,
  clientid,
  onSuccess = () => {},
}) => {
  const getFormID = () => {
    let newformID;

    if (tfatype == 1) {
      if (hasLoggedDevices) {
        newformID = TFA_NOTIFICATION_FORM_ID;
      } else {
        newformID = TFA_SMS_FORM_ID;
      }
    } else if (tfatype == 2) {
      newformID = TFA_AUTH_FORM_ID;
    }

    return newformID;
  }

  const [formID, setFormID] = useState(getFormID());
  const [sessionTimedOut, setSessionTimedOut] = useState(false);
  const [code, setCode] = useState("");
  const [isSafeDevice, setIsSafeDevice] = useState(false);
  const [error, setError] = useState({
    errorMessage: "",
    wrongCode: false,
    showError: false
  });
  const [showLoading, setShowLoading] = useState(false);
  const [showLoggedDevices, setShowLoggedDevices] = useState(false);
  const [showHelpSteps, setShowHelpSteps] = useState(false);
  const [requestFinished, setRequestFinished] = useState(true);
  const [canResend, setCanResend] = useState(true);
  const [waitingTimeForSend, setWaitingTimeForSend] = useState(60);
  const [loggedDevices, setLoggedDevices] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");

  const showError = (errorData) => {
    if (errorData.result == 2064) {
      setSessionTimedOut(true);
    }
    const tfaErrorList = {
      "1000": __("tfa_login_error_1000", "Log in required."),
      "1022": __("tfa_login_error_2012", "Incorrect security code."),
      "2000": __("tfa_login_error_2000", "Log in failed."),
      "2012": __("tfa_login_error_2012", "Incorrect security code."),
      "2064": __("tfa_login_error_2064", "You session timed out. Please, login again."),
      "2074": __("tfa_login_error_2074", "Expired security code"),
      "2092": __("tfa_login_error_2092", "This 'code' was already used"),
      "2255": __("tfa_login_error_2255", "You've reached your limit for receiving security codes. Try again later."),
      "2301": __("tfa_login_error_2301", "You don't have any devices with logged pCloud account."),
      "3012": __("tfa_login_error_3012", "We can't deliver an SMS to the provided phone number."),
      "4000": __("tfa_login_error_4000", "Too many login tries from this IP address."),
      "4007": __("tfa_login_error_4007", "Couldn't send SMS at this time. Please, try again later.")
    };
    
    if (os === DEVICE_OS.Web || os === DEVICE_OS.Android || os === DEVICE_OS.iOS) {
      setError({
        errorMessage: tfaErrorList[errorData.result],
        wrongCode: errorData.result == 2012,
        showError: true
      });
    } else if (os !== DEVICE_OS.Web) {
      notify(tfaErrorList[errorData.result], "error");
    }
  }

  const resetError = () => {
    setError({
      errorMessage: "",
      wrongCode: false,
      showError: false
    });
  }

  const sendNotification = () => {
    tfaSendNotification({ token })
      .then(res => {
        if (res.devices) {
          setLoggedDevices(res.devices);
        }
        setRequestFinished(true); 
      })
      .catch(ret => {
        setRequestFinished(true);
        showError(ret);
      });
  }

  const sendSMS = () => {
    tfaSendCodeViaSMS({ token })
      .then(res => {
        const _phoneNumber = "+" + res.phonedata.countrycode + " " + res.phonedata.msisdn;
        setPhoneNumber(_phoneNumber);
        setRequestFinished(true);
      })
      .catch(ret => {
        setRequestFinished(true);
        showError(ret)
      })
  }

  const tfaLogin = () => {
    setShowLoading(true);
    const loginMethod = formID === TFA_RECOVERY_FORM_ID ? "tfa_loginwithrecoverycode" : "tfa_login";
    const params = {
      token,
      code,
      trustdevice: isSafeDevice
    }

    if (os === DEVICE_OS.Web) {
      if (gresponsetoken) {
        params.gresponse = gresponsetoken;
      }
    
      if (registertemptoken) {
        params.registertemptoken = registertemptoken;
      }
    
      if (giveawaykey) {
        params.giveawaykey = giveawaykey;
      }
    } else {
      if (os) {
        params.os = passpcloud ? DEVICE_OS.Web : os;
      }
  
      if (osversion) {
        params.osversion = osversion;
      }
  
      if (appversion) {
        params.appversion = appversion;
      }
  
      if (appversioncode) {
        params.appversioncode = appversioncode;
      }
  
      if (device) {
        params.device = device;
      }
  
      if (clientid) {
        params.clientid = clientid;
      }
    }

    apiMethod(
      loginMethod,
      params,
      userInfo => {
        // setShowLoading(false);
        setcookie("logininfo", logininfo);
        onSuccess({
          action: "login",
          userinfo: userInfo,
          rememberme,
          locationid: getGlobalLocationId()
        });
      },
      {
        errorCallback: ret => {
          showError(ret)
          setShowLoading(false);
        },
        showErrorMessage: false,
      }
    );
  }

  const updateCode = value => {
    setCode(value)
    resetError();
  }

  const updateIsSafeDevice = () => {
    setIsSafeDevice(!isSafeDevice);
  }

  const toggleHelpSteps = () => {
    setShowHelpSteps(!showHelpSteps)
  }

  const toggleLoggedDevices = () => {
    setShowLoggedDevices(!showLoggedDevices);
  }

  const changeForm = _formID => {
    setFormID(_formID);
    setCode("");
    setPhoneNumber("");
    setError({
      errorMessage: "",
      wrongCode: false,
      showError: false
    });
    setRequestFinished(formID == TFA_RECOVERY_FORM_ID || (formID == TFA_SMS_FORM_ID && !canResend) || formID == TFA_AUTH_FORM_ID)

    if (_formID == TFA_NOTIFICATION_FORM_ID) {
      sendNotification();
    }

    if (_formID == TFA_SMS_FORM_ID && canResend) {
      sendSMS();
    }
  }

  const toggleCanResend = () => {
    if (waitingTimeForSend == 0) {
      setCanResend(!canResend);
    }

    if (canResend) {
      let remainingTime = waitingTimeForSend;
      const timer = setInterval(() => {
        remainingTime--;

        if (remainingTime === 0) {
          clearInterval(timer);
          setCanResend(true);
          remainingTime = 60;
        }
        setWaitingTimeForSend(remainingTime)
      }, 1000);
      
      setCanResend(false);
    }
  }

  return (
    <React.Fragment>
      {showLoggedDevices ? (
        <TFALoggedDevicesModalUI
          os = {os}
          devices={loggedDevices}
          toggleLoggedDevices={toggleLoggedDevices}
          isLoginModal={isLoginModal}
        />
      ) : null}
      {showHelpSteps ? (
        <TFACantReceiveCodeSupportUI
          os = {os}
          changeForm={changeForm}
          toggleHelpSteps={toggleHelpSteps}
          isLoginModal={isLoginModal}
          canResend={canResend}
          toggleCanResend={toggleCanResend}
          waitingTimeForSend={waitingTimeForSend}
          theme={theme}
        />
      ) : null}
      <TFALoginCodeFormUI
        os = {os}
        formID={formID}
        error={error}
        userPhoneNumber={phoneNumber}
        updateCode={updateCode}
        updateIsSafeDevice={updateIsSafeDevice}
        isSafeDevice={isSafeDevice}
        code={code}
        showLoading={showLoading}
        toggleLoggedDevices={toggleLoggedDevices}
        changeForm={changeForm}
        toggleHelpSteps={toggleHelpSteps}
        sessionTimedOut={sessionTimedOut}
        tfaLogin={tfaLogin}
        sendSMS={sendSMS}
        sendNotification={sendNotification}
        hide={showHelpSteps || (showLoggedDevices && isLoginModal)}
        requestFinished={requestFinished}
        canResend={canResend}
        toggleCanResend={toggleCanResend}
        waitingTimeForSend={waitingTimeForSend}
        hasLoggedDevices={hasLoggedDevices}
      />
    </React.Fragment>
  );
}

export default TwoFactorAuthLogin;

